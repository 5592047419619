<div class="container">
    <div class="pwd-content-card pt-4">

        <!-- <div class="col-md-8"> -->
        <!-- <h2  translateValues="{username: '{{account.login}}'}" *ngIf="account">Password for [<b>{{account.login}}</b>]</h2> -->
        <h3 class="pwd-title" style="border-left:none; text-indent: 0;">Đổi mật khẩu</h3>

        <div class="alert alert-success" *ngIf="success" >
            <strong>Password changed!</strong>
        </div>
        <div class="alert alert-danger" *ngIf="error">
            {{errMsg}}
        </div>
        <!-- <div class="alert alert-danger" *ngIf="error"  >
                <strong>An error has occurred!</strong> The password could not be changed.
            </div> -->

        <div class="alert alert-danger" *ngIf="doNotMatch" >
            The password and its confirmation do not match!
        </div>

        <form name="form" role="form" (ngSubmit)="changePassword()" #passwordForm="ngForm">

            <div class="form-group">
                <!-- <label class="form-control-label" for="currentPassword" >Current password</label> -->
                <label class="form-control-label" for="currentPassword">Mật khẩu cũ</label>
                <!-- <input type="password" class="form-control" id="currentPassword" name="currentPassword" #currentPasswordInput="ngModel"
                           placeholder="{{'global.form.currentpassword.placeholder' | translate}}"
                           [(ngModel)]="currentPassword" required> -->
                <input type="password" class="form-control" id="currentPassword" name="currentPassword"
                    #currentPasswordInput="ngModel" placeholder="Nhập mật khẩu cũ" [(ngModel)]="currentPassword"
                    required>
                <div *ngIf="currentPasswordInput.dirty && currentPasswordInput.invalid">
                    <small class="form-text text-danger" *ngIf="currentPasswordInput.errors.required"
                        >
                        Your password is required.
                    </small>
                </div>
            </div>
            <!-- <div class="form-group">
                <label class="form-control-label" for="newPassword" >New
                    password</label>
                <input type="password" class="form-control" id="newPassword" name="newPassword"
                    #newPasswordInput="ngModel" placeholder="{{'global.form.newpassword.placeholder' | translate}}"
                    [(ngModel)]="newPassword" minlength=4 maxlength=50 required>
                <div *ngIf="newPasswordInput.dirty && newPasswordInput.invalid">
                    <small class="form-text text-danger" *ngIf="newPasswordInput.errors.required"
                        >
                        Your password is required.
                    </small>
                    <small class="form-text text-danger" *ngIf="newPasswordInput.errors.minlength"
                        >
                        Your password is required to be at least 4 characters.
                    </small>
                    <small class="form-text text-danger" *ngIf="newPasswordInput.errors.maxlength"
                        >
                        Your password cannot be longer than 50 characters.
                    </small>
                </div>
                <app-password-strength-bar [passwordToCheck]="newPassword"></app-password-strength-bar>
            </div> -->
            <div class="form-group">
                <label class="form-control-label" for="newPassword">Mật khẩu mới</label>
                <input type="password" class="form-control" id="newPassword" name="newPassword"
                    #newPasswordInput="ngModel" placeholder="Nhập mật khẩu mới"
                    [(ngModel)]="newPassword" minlength=4 maxlength=50 required>
                <div *ngIf="newPasswordInput.dirty && newPasswordInput.invalid">
                    <small class="form-text text-danger" *ngIf="newPasswordInput.errors.required">
                       Mật khẩu mới không thể bỏ trống
                    </small>
                    <small class="form-text text-danger" *ngIf="newPasswordInput.errors.minlength">
                       Mật khẩu của bạn phải có ít nhất 4 kí tự
                    </small>
                    <small class="form-text text-danger" *ngIf="newPasswordInput.errors.maxlength">
                       Mật khẩu của bạn không quá 50 kí tự
                    </small>
                </div>
                <app-password-strength-bar [passwordToCheck]="newPassword"></app-password-strength-bar>
            </div>
            <!-- <div class="form-group">
                <label class="form-control-label" for="confirmPassword" >New
                    password confirmation</label>
                <input type="password" class="form-control" id="confirmPassword" name="confirmPassword"
                    #confirmPasswordInput="ngModel"
                    placeholder="{{'global.form.confirmpassword.placeholder' | translate}}"
                    [(ngModel)]="confirmPassword" minlength=4 maxlength=50 required>
                <div *ngIf="confirmPasswordInput.dirty && confirmPasswordInput.invalid">
                    <small class="form-text text-danger" *ngIf="confirmPasswordInput.errors.required"
                        >
                        Your confirmation password is required.
                    </small>
                    <small class="form-text text-danger" *ngIf="confirmPasswordInput.errors.minlength"
                        >
                        Your confirmation password is required to be at least 4 characters.
                    </small>
                    <small class="form-text text-danger" *ngIf="confirmPasswordInput.errors.maxlength"
                        >
                        Your confirmation password cannot be longer than 50 characters.
                    </small>
                </div>
            </div> -->
            <div class="form-group">
                <label class="form-control-label" for="confirmPassword">Xác nhận mật khẩu mới</label>
                <input type="password" class="form-control" id="confirmPassword" name="confirmPassword"
                    #confirmPasswordInput="ngModel"
                    placeholder="Nhập lại mật khẩu mới"
                    [(ngModel)]="confirmPassword" minlength=4 maxlength=50 required>
                <div *ngIf="confirmPasswordInput.dirty && confirmPasswordInput.invalid">
                    <small class="form-text text-danger" *ngIf="confirmPasswordInput.errors.required">
                      Mật khẩu xác nhận không thể bỏ trống
                    </small>
                    <small class="form-text text-danger" *ngIf="confirmPasswordInput.errors.minlength">
                        Mật khẩu xác nhận phải có ít nhất 4 kí tự
                    </small>
                    <small class="form-text text-danger" *ngIf="confirmPasswordInput.errors.maxlength">
                       Mật khẩu xác nhận không thể quá 50 kí tự
                    </small>
                </div>
            </div>
            <!-- <form class="captcha-form" [formGroup]="reactiveForm">
                    <re-captcha  class="captcha-tag" formControlName="recaptchaReactive"></re-captcha>
                </form> -->
            <button type="submit" [disabled]="passwordForm.form.invalid || (newPassword !== confirmPassword)"
                class="btn appraisal-btn">Đổi mật khẩu</button>
            <!-- <button type="submit" [disabled]="passwordForm.form.invalid" class="btn appraisal-btn" >Save</button> -->
        </form>
        <!-- </div> -->
    </div>
</div>