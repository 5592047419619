import { RouterModule } from '@angular/router';

import { AppShareModule } from './../../../../app-share.modules';
import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { UserMenuComponent } from "./user-menu.component";
import { DangKyNhanBaoCaoModule } from '../../../landing/dang-ky-nhan-bao-cao/dang-ky-nhan-bao-cao.module';
import { PopupModule } from '../../../../shared/popup/popup.module';
import { FormsModule } from '@angular/forms';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzBadgeModule } from 'ng-zorro-antd/badge';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    AppShareModule,
    FormsModule,
    PopupModule,
    DangKyNhanBaoCaoModule,
    NzIconModule,
    NzBadgeModule
  ],
  exports: [
    UserMenuComponent
  ],
  declarations: [
    UserMenuComponent
  ]
})

export class UserMenuModule { }

