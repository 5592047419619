import { catchError } from 'rxjs/operators';
import { MapService } from './map/map.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AddressType, FormSearchRequest } from './models/search-form.model';
import { getBoundsFromLeaflet } from './map/map.const';
import { zip } from 'rxjs';
import { StaticInfoService } from 'src/app/shared/static-info.service';
import { of } from 'zen-observable';
import { SnowplowService } from '../../../shared/util/tracking/snowplowService';
import { SnowPlowTrackerType } from '../../../shared/util/interface';
declare var L: any;

export const loadMap = [
  { level: 'tinh-id', load: [{ idPre: 'tinh-id', type: 'quan', danhSach: 'quanHuyen' }] },
  { level: 'quan-id', load: [{ idPre: 'quan-id', type: 'phuong', danhSach: 'phuongXa' }, { idPre: 'quan-id', type: 'duong', danhSach: 'duong' }] },
  { level: 'phuong-id', load: [{ idPre: 'phuong-id', type: 'thonxom', danhSach: 'thonXom' }] },
  { level: 'duong-id', load: [{ idPre: 'duong-id', type: 'ngo', danhSach: 'ngo' }] },
  { level: 'ngo', load: [{ idPre: 'duong-id', type: 'ngachhem', danhSach: 'ngachHem', additionParam: 'ngo', additionValue: 'ngo' }] }
];

@Injectable({
  providedIn: 'root'
})
export class SearchFormService {
  danhSach = {
    tinh: [],
    quanHuyen: [],
    phuongXa: [],
    thonXom: [],
    duong: [],
    ngo: [],
    ngachHem: []
  }
  public form: any;
  public title: any;

  public xLat: number;
  public yLng: number;
  public searchGgForm;

  public listSubscribe: any;

  public snowPlowTracker: SnowPlowTrackerType;

  constructor(
    public mapService: MapService,
    private fb: FormBuilder,
    private router: Router,
    private http: HttpClient,
    private staticInfoService: StaticInfoService,
    private snowplowService: SnowplowService
  ) {
    this.listSubscribe = [];
    this.http.get<any>(`${environment.API.SERVER_ADDRESS}public-dia-chis?type=tinh`).subscribe(data => {
      this.danhSach.tinh = data;
    });
    this.title = {
      tinh: '',
      quanHuyen: '',
      phuongXa: '',
      thonXom: '',
      duong: '',
      ngo: '',
      ngachHem: '',
      soNha: ''
    };
  }

  getAddress(level): void {
    console.log('getAddress', level, this.form[level]);
    const load = loadMap.find(a => a.level === level);
    if (load && this.form[level]) {
      load.load.forEach(param => {
        const key = `${param.type}:${this.form[param.idPre] || ''}:${param['additionParam'] || ''}:${this.form[param['additionValue']] || ''}`;

        try {
          const data = JSON.parse(sessionStorage.getItem(key));
          console.log('getAddress', key, data)
          if (data) {
            this.danhSach[param.danhSach] = data;
          } else {
            console.log('catch and save')
            const additionParam = param['additionParam'] ? `&${param['additionParam']}=${this.form[param['additionValue']]}` : ``;
            this.http.get<any>(`${environment.API.SERVER_ADDRESS}public-dia-chis?type=${param.type}&idPre=${this.form[param.idPre]}${additionParam}`)
              .pipe(catchError(err => {
                return of([]);
              }))
              .subscribe(data => {
                this.danhSach[param.danhSach] = data;
                sessionStorage.setItem(key, JSON.stringify(data));
              });
          }
        } catch (err) {

        }

      });
    }
  }

  initForm(address: any, callback): void {
    console.log('initForm', address);
    this.staticInfoService.getById(Number(address.city));
    this.listSubscribe = [];
    this.form = {
      'tinh-id': address.city,
      'quan-id': address.district,
      'phuong-id': address.ward,
      'thon-xom-id': address.village,
      'duong-id': address.street,
      'ngo': address.lane,
      'ngach-hem': address.alley,
      'so-nha': address.address,
      'so-thua': address.so_thua ?? address.so_thua_cu,
      'so-to-ban-do': address.so_to_ban_do ?? address.so_to_ban_do_cu
    };
    loadMap.forEach(item => {
      if (this.form[item.level]) {
        item.load.forEach(param => {
          const key = `${param.type}:${this.form[param.idPre] || ''}:${param['additionParam'] || ''}:${this.form[param['additionValue']] || ''}`;
          const data = JSON.parse(sessionStorage.getItem(key));
          // console.log('getAddress', key, data)
          if (data) {
            this.danhSach[param.danhSach] = data;
          } else {
            const additionParam = param['additionParam'] ? `&${param['additionParam']}=${this.form[param['additionValue']]}` : ``;
            const req = this.http.get<any>(`${environment.API.SERVER_ADDRESS}public-dia-chis?type=${param.type}&idPre=${this.form[param.idPre]}${additionParam}`);
            this.listSubscribe.push(req);
            req.subscribe(data => {
              this.danhSach[param.danhSach] = data;
              sessionStorage.setItem(key, JSON.stringify(data));
            });
          }

        });
      }
    });
    zip(...this.listSubscribe).subscribe(initDone => {
      console.log('getCurrentTitle', this.getCurrentTitle());
      callback({
        title: this.getCurrentTitle()
      });
    })

  }
  change(level, $event) {

    // console.log(level)
    const levelMap = {
      'tinh-id': 'tinh',
      'quan-id': 'quanHuyen',
      'phuong-id': 'phuongXa',
      'duong-id': 'duong',
      'ngo': 'ngo'
    };
    const find = this.danhSach[levelMap[level]] && this.danhSach[levelMap[level]].find(a => a.id === $event);
    if (find) {
      this.title[levelMap[level]] = `${find.tienTo} ${find.ten}`;
    } else {
      this.title[levelMap[level]] = ``;
    }

    // console.log('change', $event, this.title)


    this.resetChild(level);
    if ($event) {
      this.form[level] = $event;
      this.getAddress(level);
    }
    this.getCurrentTitle();
  }

  submitByChangeRoute(isSearchSoToSoThuaCu = 0) {
    this.snowPlowTracker = {
      schema_type: 'log_search_tracker',
      page_url: this.router.url,
      timestamp: '' + new Date().getTime(),
      finger: this.snowplowService.getFinger(),
      referer_url: this.snowplowService.getRefererUrl(),
      user_id: this.snowplowService.getUserId(),
      user_name: this.snowplowService.getUsername(),
      full_name: this.snowplowService.getFullname(),
      advanced_search_city: this.getTinhName(),
      advanced_search_district: this.getQuanName(),
      advanced_search_ward: this.getPhuongName(),
      advanced_search_group: this.getThonXomName(),
      advanced_search_street: this.getDuongName(),
      advanced_search_lane: this.getNgo(),
      advanced_search_alley: this.getNgach(),
      advanced_search_house_number: this.getSoNha(),
      advanced_search_lat: null,
      advanced_search_lon: null,
    }
    this.snowplowService.trackOnSelect(this.snowPlowTracker);

    if (isSearchSoToSoThuaCu) {
      this.router.navigate(['/ban-do'], {
        queryParams: {...this.removeNullItem(this.form), isSearchSoToSoThuaCu}
      });
    } else {
      this.router.navigate(['/ban-do'], {
        queryParams: this.removeNullItem(this.form)
      });
    }
    this.mapService.markerPinLayer.clearLayers();
  }
  resetChild(level): void {
    this.form['so-to-ban-do'] = null;
    this.form['so-thua'] = null;

    if (level === 'so-nha') {
      return;
    }
    this.form['so-nha'] = null;
    if (level === 'ngach-hem') {
      return;
    }
    this.form['ngach-hem'] = null;
    this.danhSach.ngachHem = [];
    if (level === 'ngo') {
      return;
    }
    this.form['ngo'] = null;
    this.danhSach.ngo = [];
    if (level === 'duong-id') {
      return;
    }
    this.form['thon-xom-id'] = null;
    this.danhSach.thonXom = [];
    if (level === 'phuong-id') {
      return;
    }

    this.form['duong-id'] = null;
    this.danhSach.duong = [];
    this.form['phuong-id'] = null;
    this.danhSach.phuongXa = [];
    if (level === 'quan-id') {
      return;
    }

    this.form['quan-id'] = null;
    this.danhSach.quanHuyen = [];
    if (level === 'tinh-id') {
      return;
    }
  }
  removeNullItem(object): any {
    const newObject = {};
    for (let item in object) {
      if (object[item]) {
        newObject[item] = object[item];
      }
    }
    newObject['mode'] = 'dia-chi';
    newObject['t'] = new Date().getTime();
    return newObject;
  }
  traCuuThuaDat(isSearchSoToSoThuaCu = 0): void {
    this.router.navigate(['/ban-do'], {
      queryParams: {
        'tinh-id': this.form['tinh-id'],
        'quan-id': this.form['quan-id'],
        'phuong-id': this.form['phuong-id'],
        'so-to-ban-do': this.form['so-to-ban-do'],
        'so-thua': this.form['so-thua'],
        'mode': 'thua-dat',
        isSearchSoToSoThuaCu
      }
    });
  }
  traCuuToaDo(): void {
    const bounds = L.latLng(this.xLat, this.yLng).toBounds(100);
    const topLeftBottomRight = getBoundsFromLeaflet(bounds);
    this.xLat = null;
    this.yLng = null;
    this.searchGgForm = null;

    this.snowPlowTracker = {
      schema_type: 'log_search_tracker',
      page_url: this.router.url,
      timestamp: '' + new Date().getTime(),
      finger: this.snowplowService.getFinger(),
      referer_url: this.snowplowService.getRefererUrl(),
      user_id: this.snowplowService.getUserId(),
      user_name: this.snowplowService.getUsername(),
      full_name: this.snowplowService.getFullname(),
      advanced_search_city: this.getTinhName(),
      advanced_search_district: this.getQuanName(),
      advanced_search_ward: this.getPhuongName(),
      advanced_search_lat: this.xLat,
      advanced_search_lon: this.yLng,
    }
    this.snowplowService.trackOnSelect(this.snowPlowTracker);

    this.router.navigate(['/ban-do'], {
      queryParams: {
        'top-left': `${topLeftBottomRight['topLeft'].lat},${topLeftBottomRight['topLeft'].lng}`,
        'bottom-right': `${topLeftBottomRight['bottomRight'].lat},${topLeftBottomRight['bottomRight'].lng}`,
        'mode': 'toa-do',
        't': new Date().getTime()
      }
    });
  }
  getCurrentTitle() {
    const listArea = [];

    const soNha = this.form['so-nha'];
    if (soNha) {
      listArea.push(`${soNha}`)
    }

    const ngach = this.danhSach.ngachHem.find(a => `${a}` === `${this.form['ngach-hem']}`);
    if (ngach) {
      listArea.push(`${ngach}`)
    }

    const ngo = this.danhSach.ngo.find(a => `${a}` === `${this.form['ngo']}`);
    if (ngo) {
      listArea.push(`${ngo}`)
    }

    const duong = this.danhSach.duong.find(a => `${a.id}` === `${this.form['duong-id']}`);
    if (duong) {
      listArea.push(`${duong.tienTo} ${duong.ten}`)
    }

    const phuong = this.danhSach.phuongXa.find(a => `${a.id}` === `${this.form['phuong-id']}`);
    if (phuong) {
      listArea.push(`${phuong.tienTo} ${phuong.ten}`)
    }

    const thonXom = this.danhSach.thonXom.find(a => `${a.id}` === `${this.form['thon-xom-id']}`);
    if (thonXom) {
      listArea.push(`${thonXom.tienTo} ${thonXom.ten}`)
    }

    const quan = this.danhSach.quanHuyen.find(a => `${a.id}` === `${this.form['quan-id']}`);
    if (quan) {
      listArea.push(`${quan.tienTo} ${quan.ten}`)
    }

    const tinh = this.danhSach.tinh.find(a => `${a.id}` === `${this.form['tinh-id']}`);
    if (tinh) {
      listArea.push(`${tinh.tienTo} ${tinh.ten}`)
    }

    console.log('change', listArea);
    return listArea.join(', ');
  }

  getTinhName() {
    const tinh = this.danhSach.tinh.find(a => `${a.id}` === `${this.form['tinh-id']}`);
    if (tinh) {
      return `${tinh.tienTo} ${tinh.ten}`;
    }
    return '';
  }
  getQuanName() {
    const quan = this.danhSach.quanHuyen.find(a => `${a.id}` === `${this.form['quan-id']}`);
    if (quan) {
      return `${quan.tienTo} ${quan.ten}`;
    }
    return '';
  }
  getDuongName() {
    const duong = this.danhSach.duong.find(a => `${a.id}` === `${this.form['duong-id']}`);
    if (duong) {
      return `${duong.tienTo} ${duong.ten}`;
    }
    return '';
  }
  getPhuongName() {
    const phuong = this.danhSach.phuongXa.find(a => `${a.id}` === `${this.form['phuong-id']}`);
    if (phuong) {
      return `${phuong.tienTo} ${phuong.ten}`;
    }
    return '';
  }
  getThonXomName() {
    const thonXom = this.danhSach.thonXom.find(a => `${a.id}` === `${this.form['thon-xom-id']}`);
    if (thonXom) {
      return `${thonXom.tienTo} ${thonXom.ten}`;
    } else {
      return ''
    }
  }
  getNgo() {
    const ngo = this.danhSach.ngo.find(a => `${a}` === `${this.form['ngo']}`);
    if (ngo) {
      return `${ngo}`
    }
    return '';
  }
  getNgach() {
    const ngach = this.danhSach.ngachHem.find(a => `${a}` === `${this.form['ngach-hem']}`);
    if (ngach) {
      return `${ngach}`;
    }
    return '';
  }
  getSoNha() {
    const soNha = this.form['so-nha'];
    if (soNha) {
      return `${soNha}`
    }
    return '';
  }
}
