import { PhoneMaskDirective } from './phone-mask.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LazyImgDirective } from './lazy-img.directive';
import { ValidateToaDoFormDirective } from './validate-toa-do-form.directive';
import { InputRefDirective } from './input-ref.directive';



@NgModule({
  declarations: [LazyImgDirective, ValidateToaDoFormDirective, PhoneMaskDirective, InputRefDirective],
  imports: [
    CommonModule
  ],
  exports: [LazyImgDirective, ValidateToaDoFormDirective, PhoneMaskDirective, InputRefDirective]
})
export class DirectiveModule { }
