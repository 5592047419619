import { EstimateHttpService } from 'src/app/shared/http/estimate-http.service';
import { Router } from '@angular/router';
import { PopupType } from './../../shared/popup/popup.model';
import { SeoService } from 'src/app/shared/util/seo.service';
import { VERSION } from 'src/app/app.constants';
import { CookieService } from 'ngx-cookie-service';
import { Subject, Observable, of, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import * as Fingerprint2 from 'fingerprintjs2';
import { SessionStorageService, LocalStorageService } from 'ngx-webstorage';
import { environment } from 'src/environments/environment';
import { LoadExternalScriptService } from 'src/app/layouts/footer/load-external-script';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService {
  public finger: string;
  public fingerBehaviorSubject: BehaviorSubject<string>;
  public jwt: string;
  public SID: string;
  public jwtBehaviorSubject: BehaviorSubject<string>;
  public authorization: string;
  public fingerSubcribe: Subject<string>;
  public clock: any;
  public throwError: boolean;
  public errorTitle: string;
  public errorContent: string;
  public okAction: Function;
  public timestamp: number;
  public authReady: boolean;
  public authReadySubject: Subject<boolean>;

  public gettingJWT: boolean;
  public jwtReady: boolean;
  public jwtReadySubject: Subject<boolean>;

  public cenhomesSSO: any;

  public requestedAccount: boolean;

  public limitAccessPopup: PopupType;

  constructor(
    private localStorage: LocalStorageService,
    private cookieService: CookieService,
    public seoService: SeoService,
    private estimateHttpService: EstimateHttpService,
    private router: Router,
    private http: HttpClient
    // public loadExternalScriptService: LoadExternalScriptService
    ) {
    this.limitAccessPopup = {
      open: false,
      header: `Truy cập bị giới hạn`,
      body: ''      
    };
    if (this.seoService.onBrowser) {


      // let access_token = sessionStorage.access_token;
      // console.log('access_token', access_token);
      // this.http.get<any>(`https://gapi-sandbox.cenhomes.vn/khangna/g-sso-gateway/v2/api/oauth/map-user`,
      //     {
      //       headers: {
      //         Authorization: 'Bearer ' + access_token
      //       }
      //     }
      // ).subscribe(info => {
      //   console.log('info', info);
      //   this.http.get<any>(`https://gapi-sandbox.cenhomes.vn/khangna/g-sso-gateway/v2/api/oauth/map-user`,
      //     {
      //       headers: {
      //         Authorization: 'Bearer ' + access_token
      //       }
      //     }
      //   ).subscribe(account => {
      //     console.log('received account');
      //     this.jwtBehaviorSubject.next(access_token);
      //   });
      // });




      // this.loadExternalScriptService.loadDynamicScript(environment.sso.ssoLoginDomain + 'assets/sso.js').then(() => {
      //   this.cenhomesSSO = new (window as any).CenhomesSSO({
      //     cookieDomain: environment.sso.cookieDomain
      //   });
      //   this.jwt = this.cenhomesSSO.getLargeCookie('TK');
      //   console.log('JWT from cookie', this.jwt);
      //   if (this.jwt) {
      //     this.jwtBehaviorSubject.next(this.jwt);
      //   }
      // });


      this.throwError = false;

      this.fingerSubcribe = new Subject();
      this.fingerBehaviorSubject = new BehaviorSubject(null);
      this.jwtBehaviorSubject = new BehaviorSubject(null);
      this.authReadySubject = new Subject();

      this.jwtReady = false;
      this.jwtReadySubject = new Subject();
      this.jwtReadySubject.next(null);

      this.finger = this.cookieService.get('g-finger');
      if (this.finger) {
        this.fingerBehaviorSubject.next(this.finger);
      }
      // this.jwt = this.localStorage.retrieve('g-auth-jwt') || this.sessionStorage.retrieve('g-auth-jwt');

      // this.jwt = window && window.localStorage.getItem('jhi-g-auth-jwt') || this.sessionStorage.retrieve('jhi-g-auth-jwt');
      // this.jwt = this.jwt && this.jwt.replace('"', '');
      // console.log('Lay this.jwt', this.jwt);
      // if (this.jwt) {
      //   this.jwtBehaviorSubject.next(this.jwt);
      // }
      // this.authorization = 'Bearer ' +  ;
      /*
      Độ dài finger qua các vesion
      Version 1: 32 character. Ex: 6d5317668df960d45198aacd35fa7d3d
      Version 2: 33 or 34 character. Ex: 6d531710668df960d45198aacd35fa7d3d
      Version 3: 60 character
      */
      // if (true) {
      if (this.fingerInvalid()) {
          Fingerprint2.get(components => {
            const values = components.map(component => {
                return component.value;
            });
            this.finger = Fingerprint2.x64hash128(values.join(''), 31);
            // console.log('this.finger', this.finger);
            const now = new Date();
            const expire = new Date();
            expire.setUTCFullYear(now.getFullYear());
            expire.setUTCMonth(now.getMonth());
            expire.setUTCDate(now.getDate() + 1 );
            expire.setUTCHours(0);
            expire.setUTCMinutes(0);
            expire.setUTCSeconds(0);

            this.finger = this.encodeFinger(now, this.finger);

            this.cookieService.set('g-finger', this.finger, expire);
            // this.localStorage.store('g-finger', this.finger);
            this.fingerSubcribe.next(this.finger);
            this.fingerBehaviorSubject.next(this.finger);
          });
      }
    }
  }
  // public getFinger(): Observable<string> {
  //   if (this.finger) {
  //     return of(this.finger);
  //   } else {
  //     this.fingerSubcribe.subscribe(finger => {
  //       return finger;
  //     });
  //   }
  // }
  public setErrorNetwork(): void {
    this.throwError = true;
    this.errorTitle = 'Kết nối mạng không ổn định';
    this.errorContent = 'Không kết nối được đến máy chủ. Vui lòng ấn F5 để thử lại';
  }
  public setErrorBadTime(time): void {
    this.timestamp = time;
    let date = new Date(this.timestamp * 1000);
    let formattedTime = date.toUTCString();

    this.throwError = true;
    this.errorTitle = 'Thời gian thiết bị chưa đúng';

    if (this.clock) {
      this.clock.clearInteval();
    }
    this.clock = setInterval(() => {
      this.timestamp = this.timestamp + 1;
      date = new Date(this.timestamp * 1000);
      formattedTime = date.toUTCString();
      this.errorContent = `
      Vì lý do bảo mật, thời gian máy tính của bạn cần phải đúng với thời gian của máy chủ. Vui lòng chỉnh lại rồi tải lại trang (F5) để tiếp tục sử dụng dịch vụ.
      <br /><i>Giờ đúng hiện tại của máy chủ đang là: ` + formattedTime + `</i>
      `;
    }, 1000);

  }
  public setErrorForceResetFinger(): void {
    this.throwError = true;
    this.errorTitle = 'Có lỗi xảy ra';
    this.errorContent = 'Có lỗi xảy ra. Vui lòng Làm mới trang (F5) để thử lại.';
  }
  public setErrorConfirmUpdateVersion(): void {
    this.throwError = true;
    this.errorTitle = 'Phiên bản mới có sẵn';
    this.errorContent = `
      <div>Nhằm cải thiện các chức năng và tối ưu trải nghiệm người dùng, chúng tôi vừa đưa ra một phiên bản mới.</div>
      <div>Việc cập nhật được thực hiện bằng cách tải lại trang và không mất thời gian chờ đợi.</div>
    `;
  }
  public setErrorServerTokenHasExpired(): void {
    this.throwError = true;
    this.errorTitle = 'Lỗi token';
    this.errorContent = 'Vui lòng Làm mới trang (F5) để thử lại.';
  }

  public setErrorCustomMessage(title: string, content: string, callBackFunction?: Function): void {
    this.throwError = true;
    this.errorTitle = title;
    this.errorContent = content;
    if (callBackFunction) {
      callBackFunction();
    }
  }

  public canNotGetAPI(status: number, message: string): void {
      this.throwError = true;
      this.errorTitle = 'Không kết nối được API';
      this.errorContent = 'Status: ' + status + '<br />' + 'Message: ' + message;
  }
  public refreshPage(): void {
    if (window && window.location) {
      window.location.reload();
    }
  }

  private encodeFinger(now, finger): string {
    const date = now;
    const timestamp = String(date.getTime()); // timestamp with milliseconds
    const chs = [];
    // Copy character by character into array
    for (let i = 0; i < finger.length; i++) {
        chs.push(finger.charAt(i));
    }
    const day = date.getUTCDate();
    let salt = 0;
    if (day % 2 !== 0){
        salt = 1;
    }
    for (let i = 0; i < timestamp.length; i++){
        chs.splice(i * 2 + salt, 0, timestamp.charAt(i));
    }
    const resultChs = [];
    for (let i = 0; i < chs.length; i ++){
        resultChs.push(this.convertASCII(chs[i], day));
    }

    return btoa(resultChs.join(''));
  }
  private convertASCII<String>(char: any, index: number): string {
      const ascii = char.charCodeAt(0);
      return String.fromCharCode(ascii + index);
  }
  private fingerInvalid(): boolean {
      return !this.finger || (this.finger.length !== 60);
  }

  appendCommonHeader(finger: string, jwt: string): any {
    const datetime = new Date().toLocaleString();

    return {
      'Content-Type': 'application/json',
      Version: VERSION,
      DateTime: datetime,
      Finger: finger,
      Authorization: jwt ? 'Bearer ' + jwt : 'Finger ' + finger,
    };
  }


  public getUserinfo(): void {

  }

  public resolvedCaptcha(captchaResponse: string) {
    // console.log(`Resolved captcha with response: ${captchaResponse}`);
    if (null === captchaResponse) {
      this.limitAccessPopup.body = 'Captcha không hợp lệ';
    } else {
      this.estimateHttpService.doVerifyCaptcha(captchaResponse).subscribe(data => {
          // console.log(data.payload.data);
          if (data.payload.data === true) {
              this.limitAccessPopup.open = false;
              /* Reload lại router sau khi verify xong captcha. Cách này không official lắm, cần imprrove khi có thời gian */
              window.location.reload();
              // this.router.navigateByUrl(this.router.url + '&reload=true', { skipLocationChange: true });
          } else {
              this.limitAccessPopup.body = 'Không thể xác minh. Vui lòng liên hệ nhân viên hỗ trợ.';
              // this.setErrorCustomMessage('Lỗi Captcha', data.description + '<br />Vui lòng liên hệ nhân viên hỗ trợ');
              // window.location.reload();
          }
      });
    }
  }
}
