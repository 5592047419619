import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class NavbarService {
    navbarVisible: boolean;
    footerVisible: boolean;
    headerVisible: boolean;

    overflowY: string;
    constructor(
        private router: Router
    ) {
        this.navbarVisible = true;
        this.footerVisible = true;
        this.headerVisible = true;
        this.overflowY = 'scroll';
    }
    hideNavbar() {
        this.navbarVisible = false;
    }

    showNavbar() {
        this.navbarVisible = true;
    }

    hideFooter() {
        this.footerVisible = false;
    }

    showFooter() {
        this.footerVisible = true;
    }
    hideHeader() {
        this.headerVisible = false;
    }

    showHeader() {
        this.headerVisible = true;
    }

    noScroll() {
        document.getElementById('cen-main').style.overflowY = 'hidden';
        this.overflowY = 'unset';
    }
    isCheckRouterDinhGia() {
        const checkUrl = this.router.url.substring(0, 9);
        const thongTinMoiGioi = this.router.url.substring(0, 19);
        const phanTichKhuvuc = this.router.url.substring(0, 18);
        if (checkUrl === '/dinh-gia' || thongTinMoiGioi === '/thong-tin-moi-gioi' || phanTichKhuvuc === '/phan-tich-khu-vuc') {
            return false;
        }
        return true;
    }
}
