import { CommonModule } from '@angular/common';

import { NgModule } from '@angular/core';
import { ButtonComponent, LabelButtonDirective } from './button.component';

@NgModule({
    declarations: [
    ButtonComponent,
    LabelButtonDirective
    ],
    imports: [
    CommonModule
    ],
    exports: [
    ButtonComponent,
    LabelButtonDirective
    ]
})
export class ButtonModule {}
