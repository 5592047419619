<div class="flex items-center">
    <div [ngSwitch]="accountSsoService.isAuthenticated()">
        <div class="navbar-link-padding" *ngSwitchCase="false">
            <div *ngIf="environment.ssoType === 'sso'" class="navbar-link ml-3" (click)="login()">Đăng nhập</div>
            <button *ngIf="environment.ssoType === 'cenhomesSSO'" (click)="oauth2Login()" class="btn btn-default ml-3">Đăng nhập</button>
        </div>
        <div class="navbar-link-padding" *ngSwitchCase="true">
            <app-dropdown-menu>
                <ng-template app-dropdown-menu-label>
                    <div class="navbar-link d-flex text-16 items-center" >
                        <!-- <div class="avatar-small">
                            <svg width="20px" height="20px" viewBox="0 0 16 16" class="bi bi-person-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                            </svg>
                        </div> -->
                        <div style="text-shadow: 0px 0px 3px #fff, 0px 0px 3px white;" *ngIf="accountSsoService.account">{{accountSsoService.account.fullName}}</div>
                        <div class="ml-1">
                            <svg width="12px" height="12px" viewBox="0 0 16 16" class="bi bi-caret-down-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                        </div>
                    </div>
                </ng-template>
                <ng-template app-dropdown-menu-items>
                    <div class="menu">
                        <div class="d-flex flex-row info"
                            style="
                            background: linear-gradient(180deg, #FF9E2C 0%, #F87E0D 100%);
                            margin-left: -8px;
                            margin-right: -8px;
                            border-top-left-radius: 8px;
                            border-top-right-radius: 8px;
                            margin-top: -12px;
                            padding-top: 22px;
                            padding-bottom: 19px!important;
                            padding-left: 17px;
                            color: #fff;">
                            <div class="avatar">
                                <svg width="30px" height="30px" viewBox="0 0 16 16" class="bi bi-person-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                </svg>
                            </div>
                            <div class="d-flex flex-column">
                                <span class="title" *ngIf="accountSsoService.account" class="text-[#ffffff] text-[15px] font-[600]">{{accountSsoService.account.fullName}}</span>
                                <span class="description" *ngIf="accountSsoService.account">{{accountSsoService.account.login}}</span>
                                <div class="text-[#fff] font-[400] flex flex-row align-items-center">
                                    <div class="mr-[5px]" (click)="accountSsoService.balance.show = !accountSsoService.balance.show">
                                        <span [hidden]="accountSsoService.balance.show" style="font-size: 18px;" nz-icon nzType="eye" nzTheme="outline"></span>
                                        <span [hidden]="!accountSsoService.balance.show" style="font-size: 18px;" nz-icon nzType="eye-invisible" nzTheme="outline"></span>
                                    </div>
                                    <span class="text-[13px] text-[#ffffff] font-[400]">Số dư ví:</span>
                                    <span *ngIf="accountSsoService.balance.show" style="margin-left: 8px; font-weight: 600;">{{accountSsoService.balance.value | number:'1.0-1'}}đ</span>
                                    <span *ngIf="!accountSsoService.balance.show" style="margin-bottom: -6px; letter-spacing: 3px; margin-left: 8px;">******</span>
                                </div>
                            </div>
                        </div>

                        
                        <a *jhiHasAnyAuthority="['Quản lý - giám đốc']" [routerLink]="['/thong-tin-nguoi-dung']" class="menu-item d-flex flex-row align-items-center" app-dropdown-menu-click-close>
                            <div class="icon">
                                <svg width="16px" height="16px" viewBox="0 0 16 16" class="bi bi-people-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/>
                                </svg>
                            </div>
                            <div class="d-flex flex-column">
                                <div class="item">Người dùng hệ thống</div>
                                <div class="description">Xem phân tích người dùng hệ thống</div>
                            </div>
                        </a>
                        <a *jhiHasAnyAuthority="['Quản lý - giám đốc']" [routerLink]="['/thong-tin-moi-gioi']" class="menu-item d-flex flex-row align-items-center" app-dropdown-menu-click-close>
                            <div class="icon">
                                <svg width="16px" height="16px" viewBox="0 0 16 16" class="bi bi-person-badge" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M2 2.5A2.5 2.5 0 0 1 4.5 0h7A2.5 2.5 0 0 1 14 2.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2.5zM4.5 1A1.5 1.5 0 0 0 3 2.5v10.795a4.2 4.2 0 0 1 .776-.492C4.608 12.387 5.937 12 8 12s3.392.387 4.224.803a4.2 4.2 0 0 1 .776.492V2.5A1.5 1.5 0 0 0 11.5 1h-7z"/>
                                    <path fill-rule="evenodd" d="M8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM6 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5z"/>
                                </svg>
                            </div>
                            <div class="d-flex flex-column">
                                <div class="item">Thông tin môi giới</div>
                                <div class="description">Xem phân tích thông tin môi giới</div>
                            </div>
                            <hr />
                        </a>
    
                        <a *ngIf="accountSsoService.hasAnyAuthority(['ROLE_ADMIN'])" [routerLink]="['/quan-ly-thanh-toan']" class="menu-item d-flex flex-row align-items-center" app-dropdown-menu-click-close>
                            <div class="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
                                  </svg>
                            </div>
                            <div class="d-flex flex-column">
                                <div class="item">Quản lý thanh toán</div>
                                <!-- <div class="description">Lịch sử xem giá của bạn</div> -->
                            </div>
                        </a>
    
                        <a *ngIf="accountSsoService.hasAnyAuthority(['ROLE_ADMIN', 'KE_TOAN'])" [routerLink]="['/doi-soat-giao-dich']" class="menu-item d-flex flex-row align-items-center" app-dropdown-menu-click-close>
                            <div class="icon">
                                <span nz-icon nzType="transaction" nzTheme="outline"></span>
                            </div>
                            <div class="d-flex flex-column">
                                <div class="item">Đối soát giao dịch</div>
                                <!-- <div class="description">Lịch sử xem giá của bạn</div> -->
                            </div>
                        </a>
                        
                        
                        <a [routerLink]="['/thanh-toan']" class="menu-item d-flex flex-row align-items-center" app-dropdown-menu-click-close>
                            <div class="icon">
                                <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16 0C17.0938 0 18 0.90625 18 2V10C18 11.125 17.0938 12 16 12H2C0.875 12 0 11.125 0 10V2C0 0.90625 0.875 0 2 0H16ZM8.5 4C8.21875 4 8 4.25 8 4.5C8 4.78125 8.21875 5 8.5 5H15.5C15.75 5 16 4.78125 16 4.5C16 4.25 15.75 4 15.5 4H8.5ZM8.5 8H15.5C15.75 8 16 7.78125 16 7.5C16 7.25 15.75 7 15.5 7H8.5C8.21875 7 8 7.25 8 7.5C8 7.78125 8.21875 8 8.5 8ZM5.125 3C5.125 2.65625 4.84375 2.375 4.46875 2.375C4.125 2.375 3.84375 2.65625 3.84375 3V3.1875C3.6875 3.25 3.5 3.28125 3.375 3.375C2.90625 3.5625 2.5 3.96875 2.375 4.53125C2.34375 4.84375 2.375 5.15625 2.5 5.4375C2.625 5.71875 2.84375 5.90625 3.03125 6.0625C3.40625 6.3125 3.875 6.4375 4.25 6.5625H4.3125C4.75 6.71875 5.03125 6.8125 5.21875 6.9375C5.3125 7 5.34375 7.03125 5.34375 7.0625C5.34375 7.09375 5.375 7.15625 5.34375 7.28125C5.34375 7.375 5.28125 7.46875 5.09375 7.5625C4.90625 7.625 4.59375 7.65625 4.1875 7.59375C4 7.5625 3.6875 7.46875 3.375 7.375C3.3125 7.34375 3.25 7.3125 3.1875 7.28125C2.84375 7.1875 2.5 7.375 2.40625 7.6875C2.28125 8.03125 2.46875 8.375 2.78125 8.46875C2.8125 8.5 2.875 8.5 2.9375 8.53125C3.15625 8.625 3.5625 8.75 3.84375 8.8125V9C3.84375 9.375 4.125 9.65625 4.46875 9.65625C4.84375 9.65625 5.125 9.375 5.125 9V8.84375C5.28125 8.8125 5.4375 8.75 5.59375 8.6875C6.09375 8.5 6.46875 8.09375 6.59375 7.5C6.625 7.15625 6.625 6.84375 6.5 6.5625C6.375 6.28125 6.15625 6.09375 5.96875 5.9375C5.59375 5.65625 5.0625 5.5 4.6875 5.375H4.65625C4.21875 5.25 3.9375 5.15625 3.75 5.03125C3.65625 4.96875 3.625 4.9375 3.625 4.90625C3.625 4.90625 3.59375 4.875 3.625 4.75C3.625 4.6875 3.6875 4.59375 3.875 4.5C4.0625 4.40625 4.375 4.375 4.78125 4.40625C4.90625 4.4375 5.34375 4.53125 5.4375 4.5625C5.78125 4.65625 6.125 4.4375 6.21875 4.125C6.3125 3.78125 6.09375 3.4375 5.78125 3.34375C5.625 3.3125 5.3125 3.25 5.125 3.21875V3Z" fill="#616770"/>
                                </svg>
                            </div>
                            <div class="d-flex flex-column">
                                <div class="item">Thanh toán</div>
                            </div>
                        </a>
                        <a [routerLink]="['/thanh-toan', 'danh-sach-san-pham']" class="menu-item d-flex flex-row align-items-center" app-dropdown-menu-click-close>
                            <div class="icon">
                                <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19 3.25C19 3.96875 18.4375 4.5 17.75 4.5C17.7188 4.5 17.7188 4.5 17.7188 4.5L16.125 13.1875C16.0625 13.6562 15.625 14 15.1562 14H4.8125C4.34375 14 3.90625 13.6562 3.84375 13.1875L2.25 4.5C2.25 4.5 2.25 4.5 2.21875 4.5C1.53125 4.5 0.96875 3.96875 0.96875 3.25C0.96875 2.5625 1.53125 2 2.21875 2C2.875 2 3.46875 2.5625 3.46875 3.25C3.46875 3.53125 3.34375 3.78125 3.1875 4L6 6.25C6.5 6.625 7.21875 6.46875 7.5 5.90625L9.3125 2.3125C8.96875 2.09375 8.71875 1.71875 8.71875 1.25C8.71875 0.5625 9.28125 0 10 0C10.6875 0 11.2188 0.5625 11.2188 1.25C11.2188 1.71875 10.9688 2.09375 10.5938 2.3125L12.4062 5.90625C12.6875 6.46875 13.4375 6.625 13.9375 6.25L16.7188 4C16.5938 3.78125 16.5 3.53125 16.5 3.25C16.5 2.5625 17.0312 2 17.75 2C18.4375 2 19 2.5625 19 3.25Z" fill="#616770"/>
                                </svg>
                            </div>
                            <div class="d-flex flex-column">
                                <div class="item">Mua dịch vụ</div>
                            </div>
                        </a>
                        <a [routerLink]="['/cong-cu']" class="menu-item d-flex flex-row align-items-center" app-dropdown-menu-click-close>
                            <div class="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75a4.5 4.5 0 01-4.884 4.484c-1.076-.091-2.264.071-2.95.904l-7.152 8.684a2.548 2.548 0 11-3.586-3.586l8.684-7.152c.833-.686.995-1.874.904-2.95a4.5 4.5 0 016.336-4.486l-3.276 3.276a3.004 3.004 0 002.25 2.25l3.276-3.276c.256.565.398 1.192.398 1.852z" />
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.867 19.125h.008v.008h-.008v-.008z" />
                                  </svg>
                                  
                            </div>
                            <div class="d-flex flex-column">
                                <div class="item">Công cụ</div>
                                <!-- <div class="description">Lịch sử xem giá của bạn</div> -->
                            </div>
                        </a>
                        <a [routerLink]="['/user-activity']" [queryParams]="{tab: 0}" class="menu-item d-flex flex-row align-items-center" app-dropdown-menu-click-close>
                            <div class="icon">
                                <svg width="20px" height="20px" viewBox="0 0 16 16" class="bi bi-heart-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"/>
                                </svg>
                            </div>
                            <div class="d-flex flex-column">
                                <div class="item">Tài sản ưa thích</div>
                                <div class="description">Những tài sản ưa thích mà bạn đã lưu</div>
                            </div>
                        </a>
                        <a [routerLink]="['/user-activity']" [queryParams]="{tab: 1}" class="menu-item d-flex flex-row align-items-center" app-dropdown-menu-click-close>
                            <div class="icon">
                                <svg width="20px" height="20px" viewBox="0 0 16 16" class="bi bi-hourglass-bottom" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M2 1.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1-.5-.5zm2.5.5v1a3.5 3.5 0 0 0 1.989 3.158c.533.256 1.011.791 1.011 1.491v.702s.18.149.5.149.5-.15.5-.15v-.7c0-.701.478-1.236 1.011-1.492A3.5 3.5 0 0 0 11.5 3V2h-7z"/>
                                </svg>
                            </div>
                            <div class="d-flex flex-column">
                                <div class="item">Lịch sử xem giá</div>
                                <div class="description">Lịch sử xem giá của bạn</div>
                            </div>
                            <hr />
                        </a>
                        
                        <a [href]="linkBaoMat" target="_blank" class="menu-item d-flex flex-row align-items-center" app-dropdown-menu-click-close>
                            <div class="icon">
                                <svg width="20px" height="20px" viewBox="0 0 16 16" class="bi bi-lock-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.5 9a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-7a2 2 0 0 1-2-2V9z"/>
                                    <path fill-rule="evenodd" d="M4.5 4a3.5 3.5 0 1 1 7 0v3h-1V4a2.5 2.5 0 0 0-5 0v3h-1V4z"/>
                                </svg>
                            </div>
                            <div class="d-flex flex-column">
                                <div class="item">Bảo mật</div>
                                <div class="description">Chức năng bảo mật, đổi mật khẩu,...</div>
                            </div>
                        </a>
                        <a (click)="openThamGiaDoanhNghiep = true" class="menu-item d-flex flex-row align-items-center" app-dropdown-menu-click-close>
                            <div class="icon">
                                <svg width="20px" height="20px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user-tie" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-user-tie fa-w-14 fa-2x"><path fill="currentColor" d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm95.8 32.6L272 480l-32-136 32-56h-96l32 56-32 136-47.8-191.4C56.9 292 0 350.3 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-72.1-56.9-130.4-128.2-133.8z" class=""></path></svg>
                            </div>
                            <div class="d-flex flex-column">
                                <div class="item">Tham gia doanh nghiệp</div>
                                <div class="description">Trở thành tài khoản doanh nghiệp</div>
                            </div>
                        </a>
                        <a *ngIf="accountService.hasAnyAuthority(['ROLE_ADMIN', 'QUYEN_QUAN_TRI_DOI_TAC_NGAN_HANG'])" routerLink='/admin' class="menu-item d-flex flex-row align-items-center" app-dropdown-menu-click-close>
                            <div class="icon">
                                <svg width="20px" height="20px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user-friends" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="svg-inline--fa fa-user-friends fa-w-20 fa-2x"><path fill="currentColor" d="M192 256c61.9 0 112-50.1 112-112S253.9 32 192 32 80 82.1 80 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C51.6 288 0 339.6 0 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zM480 256c53 0 96-43 96-96s-43-96-96-96-96 43-96 96 43 96 96 96zm48 32h-3.8c-13.9 4.8-28.6 8-44.2 8s-30.3-3.2-44.2-8H432c-20.4 0-39.2 5.9-55.7 15.4 24.4 26.3 39.7 61.2 39.7 99.8v38.4c0 2.2-.5 4.3-.6 6.4H592c26.5 0 48-21.5 48-48 0-61.9-50.1-112-112-112z" class=""></path></svg>
                            </div>
                            <div class="d-flex flex-column">
                                <div class="item">Quản lý người dùng</div>
                                <div class="description">Quản lý người dùng doanh nghiệp,...</div>
                            </div>
                        </a>
                        <a [href]="linkCaiDat" target="_blank" class="menu-item d-flex flex-row align-items-center" app-dropdown-menu-click-close>
                            <div class="icon">
                                <svg width="20px" height="20px" viewBox="0 0 16 16" class="bi bi-gear-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 0 0-5.86 2.929 2.929 0 0 0 0 5.858z"/>
                                </svg>
                            </div>
                            <div class="d-flex flex-column">
                                <div class="item">Cài đặt</div>
                                <div class="description">Cài đặt chức năng cho tài khoản</div>
                            </div>
                            <hr />
                        </a>
                        
                        <a (click)="loginSsoService.logout()" class="menu-item d-flex flex-row align-items-center" app-dropdown-menu-click-close>
                            <div class="icon">
                                <svg width="20px" height="20px" viewBox="0 0 16 16" class="bi bi-door-open-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M1.5 15a.5.5 0 0 0 0 1h13a.5.5 0 0 0 0-1H13V2.5A1.5 1.5 0 0 0 11.5 1H11V.5a.5.5 0 0 0-.57-.495l-7 1A.5.5 0 0 0 3 1.5V15H1.5zM11 2v13h1V2.5a.5.5 0 0 0-.5-.5H11zm-2.5 8c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1z"/>
                                </svg>
                            </div>
                            <div class="d-flex flex-column">
                                <div class="item">Đăng xuất</div>
                                <div class="description">Đăng xuất khỏi hệ thống</div>
                            </div>
                        </a>
                    </div>
                </ng-template>
            </app-dropdown-menu>
        </div>
    </div>
</div>


<app-popup mode="NORMAL" desktopWidth="40" mobileWidth="95" [(open)]="openThamGiaDoanhNghiep">
    <ng-template app-popup-header>Tham gia doanh nghiệp</ng-template>
    <ng-template app-popup-body>
        <p class="mb-0">Mã tham gia được tạo bởi tài khoản Admin của doanh nghiệp. Vui lòng liên hệ Admin của doanh nghiệp của bạn để lấy mã tham gia, điền mã vào ô dưới đây để tham gia doanh nghiệp.</p>
        <p class="text-xs">(Chú ý: Trong trường hợp đã mua lượt tra cứu cá nhân, người dùng sẽ bị mất lượt và sử dụng lượt tra cứu của doanh nghiệp.)</p>
        <div class="d-flex">
            <div class="mr-auto" style="width: 100%;">
                <input class="text mt-0 link-tham-gia"
                placeholder="Mã tham gia"
                [(ngModel)]="linkThamGia" />
            </div>
            <button (click)="thamGiaDoanhNghiep()" style="width: 180px;" class="btn btn-cenhomes button-link-tham-gia">Tham gia</button>
        </div>
        <br />

    </ng-template>
</app-popup>
