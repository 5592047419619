import { ThamDinhGiaService } from './../../../../shared/tham-dinh-gia/tham-dinh-gia.service';
import { Component, OnInit } from '@angular/core';
import { UserMenuComponent } from '../user-menu/user-menu.component';
import { environment } from '../../../../../environments/environment';
import { AccountSsoService } from '../../../../core/auth/account-sso.service';
import { LoginSsoService } from '../../../../core/login/login-sso.service';
import { NavbarMobileService } from '../../navbar-mobile/navbar-mobile.service';
import { DangKyNhanBaoCaoService } from '../../../landing/dang-ky-nhan-bao-cao/dang-ky-nhan-bao-cao.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-user-menu-mobile',
  templateUrl: './user-menu-mobile.component.html',
  styleUrls: ['./user-menu-mobile.component.scss']
})
export class UserMenuMobileComponent implements OnInit {
  public linkBaoMat: string;
  public linkCaiDat: string;
  environment: any;
  openThamGiaDoanhNghiep: any = false;
  public linkThamGia: string;
  public linkCenPay: string;
  ngOnInit(): void {

  }
  constructor(
    public accountSsoService: AccountSsoService,
    public loginSsoService: LoginSsoService,
    public navbarMobileService: NavbarMobileService,
    public dangKyNhanBaoCaoService: DangKyNhanBaoCaoService,
    public thamDinhGiaService: ThamDinhGiaService,
    private http: HttpClient
  ) {
    this.environment = environment;
    if (this.environment.ssoType === 'sso') {
      this.linkBaoMat = `${this.environment.sso.ssoLoginDomain}cai-dat?tab=1`;
      this.linkCaiDat = `${this.environment.sso.ssoLoginDomain}cai-dat?tab=0`;
    } else if (this.environment.ssoType === 'cenhomesSSO') {
      this.linkBaoMat = `${this.environment.cenhomesSSO.issuer}/account/change-password`;
      this.linkCaiDat = `${this.environment.cenhomesSSO.issuer}/account/info`;
    }
    this.linkCenPay = this.environment.API.SERVER_WALLET;
  }
  public login(): void {
    this.loginSsoService.login();
    // this.modalRef = this.loginModalService.open();
  }
  oauth2Login() {
    localStorage.setItem('afterLogin', location.href);
    const loginLink = `${environment.cenhomesSSO.loginLink}?response_type=code&client_id=${environment.cenhomesSSO.clientId}&redirect_uri=${environment.cenhomesSSO.redirectUri}&scope=openid%20offline_access%20profile%20web`
    location.href = loginLink;
  }
  thamGiaDoanhNghiep(): void {
    console.log('linkThamGia', this.linkThamGia);
    this.http.post<any>(`${environment.API.SERVER_API_G_AUTH}/api/tham-gia-doanh-nghiep`, {maThamGia: this.linkThamGia})
    .subscribe(res => {
      if (res.status === 1) {
        alert('Đã có lỗi xảy ra. Vui lòng thử lại!');
      } else {
        this.openThamGiaDoanhNghiep = false;
        this.navbarMobileService.show = false;
        alert(res.description);
      }
    }, err => {
      alert('Đã có lỗi xảy ra. Vui lòng thử lại!');
    });
  }
}
