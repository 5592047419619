import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class Register {
    constructor(private http: HttpClient) {}

    save(account: any): Observable<any> {
        return this.http.post(environment.API.SERVER_API_G_AUTH + '/auth/register', account);
    }
}
