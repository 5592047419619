import { map, catchError } from 'rxjs/operators';
import { createRequestOption } from 'src/app/shared';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SessionStorageService } from 'ngx-webstorage';
import { SeoService } from 'src/app/shared/util/seo.service';
import { environment } from '../../../environments/environment';

export class RequestParamsAddress {
    type?: string;
    idPre?: number;
    ngo?: string;
}

@Injectable({
    providedIn: 'root'
})
export class AddressHttpService {
    private sourceApi = environment.API.SERVER_ADDRESS;

    constructor(private http: HttpClient, private $sessionStorage: SessionStorageService, private seoService: SeoService) { }
    
    // get danh sách các tỉnh
    getProvinces() {
        return this.http.get(`https://gapi.cenhomes.vn/c-address/v1/public-dia-chis?type=tinh`);
    }
    // Get danh sách địa chỉ (quận/phường/dự án)
    queryadress(type: string, id: number) {
        return this.http.get(`https://gapi.cenhomes.vn/c-address/v1/public-dia-chis?type=${type}&idPre=${id}`);
    }

    // get danh ngách hẻm
    getDanhSachNgachHem(type: string, id: number, ngo: string) {
        return this.http.get(`https://gapi.cenhomes.vn/c-address/v1/public-dia-chis?type=${type}&idPre=${id}&ngo=${ngo}`);
    }

    queryAddress<T>(req: RequestParamsAddress): Observable<T[]> {
        // console.log('queryAddress');
        const type = req.type;
        const idPre = req.idPre ? '-'+ req.idPre : '';

        let publicDiaChis = this.$sessionStorage.retrieve('public-dia-chi') || {};
        if (publicDiaChis[type + idPre]) {
            return of(publicDiaChis[type + idPre].data);
        }
        const options = createRequestOption(req);
        // console.log(options, req);
        // let dataAddress = [];
        // if (this.seoService.onBrowser) {
        //     dataAddress = this.$sessionStorage.retrieve('address-' + req.type);
        // }
        // if (dataAddress !== null && dataAddress.length > 0) {
        //     let idPre = null;
        //     if (req.type === 'tinh') {
        //         return of(dataAddress);
        //     } else if (req.type === 'quan') {
        //         idPre = dataAddress[0].tinhId;
        //     } else if (req.type === 'thonxom') {
        //         idPre = dataAddress[0].phuongId;
        //     } else {
        //         idPre = dataAddress[0].quanId;
        //     }
        //     if (idPre && idPre === req.idPre) {
        //         return of(dataAddress);
        //     }
        // }
        return this.http
            .get<any>(this.sourceApi + 'public-dia-chis', {
                params: options,
                observe: 'response'
            })
            .pipe(
                map((diaChis: any) => {
                    publicDiaChis[type + idPre] = ({type: req.type, idPre: req.idPre, data: diaChis.body});
                    if (this.seoService.onBrowser) {
                        // this.$sessionStorage.store('public-dia-chi', publicDiaChis);
                    }
                    return diaChis.body;

                    // if (req.type === 'ngo' || req.type === 'ngachhem') {
                    //     if (this.seoService.onBrowser) {
                    //         this.$sessionStorage.store('address-' + req.type, diaChis.body);
                    //     }
                    //     return diaChis.body;
                    // } else {
                    //     if (this.seoService.onBrowser) {
                    //         this.$sessionStorage.store('address-' + req.type, diaChis.body);
                    //     }
                    //     return diaChis.body;
                    // }
                }),
                catchError((err, caught) => {
                    return of({status: 'error'});
                })
            );
    }

    normalize(diaChi) {
        return this.http.post<any>(this.sourceApi + 'normal', {
            address: diaChi
        });
    }
}
