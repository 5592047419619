<div class="w-100 z-20 hidden md:block bg-white" [ngClass]="{'cen-navbar': inPageNeedFixed(), 'has-scrolled': (inPageNeedFixed() && hasScrolled) || !inPageNeedFixed()}">
    <!-- wrapper -->
    <div class=" wrapper">

        <div class="d-flex align-items-center">
            <div class=" d-flex align-items-center navbar-link justify-content-between w-100"  [ngClass]="{'navbar-link-scrolled': !inPageNeedFixed() || hasScrolled}" [ngSwitch]="accountSsoService.isAuthenticated()">
                <div class="d-flex align-items-center">
                    <a class="logo-link px-0 py-0" [ngClass]="{'logo-link-scrolled': (inPageNeedFixed() && hasScrolled) || !inPageNeedFixed()}" [routerLink]="['/']">
                        <img class="logo-image" src="/assets/images/img-cenhome.png " alt="CenHomes.vn">
                        <!-- <img class="logo-image-white" src="/assets/images/img-cenhome-white.png" alt="CenHomes.vn"> -->
                    </a>
                </div>

                    <!-- <span *ngIf="account" class="nav-text font-size-nav-text">
                        {{account.fullName !== null ? account.fullName : account.login}}
                    </span> -->
                <app-main-link-menu></app-main-link-menu>
                <app-user-menu class="d-flex align align-items-center"></app-user-menu>

            </div>
        </div>
    </div>
</div>
