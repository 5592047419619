<div class="container">
    <div class="pwd-content-card">
        <!-- <div class="col-md-8"> -->
        <!-- <h3 class="pwd-title" >Reset your password</h3> -->
        <h3 class="pwd-title">Quên mật khẩu</h3>

        <div class="alert alert-danger" *ngIf="errorEmailNotExists">
            <strong>Email address isn't registered!</strong> Please check and try again.
        </div>

        <div class="" style="font-style: italic;" *ngIf="!success">
            <!-- <p>Enter the email address you used to register.</p> -->
            <p>Nhập vào email của bạn</p>
        </div>
        <!-- <div class="alert alert-info" *ngIf="!success">
                 <p>Enter the email address you used to register.</p> 
                <p>Nhập vào email của bạn</p>
            </div> -->

        <div class="alert alert-success" *ngIf="success === 'OK'">
            <p >Check your emails for details on how to reset your
                password.</p>
        </div>

        <form *ngIf="!success" name="form" role="form" (ngSubmit)="requestReset()" #resetRequestForm="ngForm">
            <div class="form-group">
                <label class="form-control-label" for="email">Email</label>
                <input type="email" class="form-control" id="email" name="email"
                    placeholder="{{'global.form.email.placeholder'}}" [(ngModel)]="resetAccount.email"
                    minlength=5 maxlength=254 #emailInput="ngModel" email required>
                <div *ngIf="emailInput.dirty && emailInput.invalid">
                    <small class="form-text text-danger" *ngIf="emailInput.errors.required"
                        >
                        Your email is required.
                    </small>
                    <small class="form-text text-danger" *ngIf="emailInput.errors.email"
                        >
                        Your email is invalid.
                    </small>
                    <small class="form-text text-danger" *ngIf="emailInput.errors.minlength"
                        >
                        Your email is required to be at least 5 characters.
                    </small>
                    <small class="form-text text-danger" *ngIf="emailInput.errors.maxlength"
                        >
                        Your email cannot be longer than 100 characters.
                    </small>
                </div>
            </div>
            <!-- <re-captcha formControlName="recaptchaReactive"></re-captcha> -->
            <!-- <form class="captcha-form" [formGroup]="reactiveForm">
                <re-captcha  class="captcha-tag" formControlName="recaptchaReactive"></re-captcha>
            </form> -->
            <button type="submit"
                [disabled]="resetRequestForm.form.invalid || reactiveForm.invalid"
                class="btn appraisal-btn">Gửi xác nhận</button>
            <!-- <button type="submit" [disabled]="resetRequestForm.form.invalid" class="btn appraisal-btn" >Đặt lại</button> -->
        </form>
        <!-- <form [formGroup]="reactiveForm">
            <re-captcha formControlName="recaptchaReactive"></re-captcha>
        </form> -->
        <!-- </div> -->
    </div>
</div>