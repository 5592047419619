import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  public loaiBDSId: any;
  public loaiKhuVucId: any;
  public khoangGiaId: any;
  public loaiTangTruongId: any;
  public huongTangTruongId: any;
  public kieuBienDongId: any;
  public capHanhChinhId: any;
  constructor() {
    this.loaiBDSId = {
      5: 'Nhà mặt phố',
      7: 'Nhà trong ngõ',
      8: 'Căn hộ chung cư',
    };
    this.loaiKhuVucId = {
      0: 'Cả nước',
      1: 'Hà Nội',
      2: 'TP Hồ Chí Minh',
      3: 'Hải Phòng',
      4: 'Đà Nẵng',
    };
    this.khoangGiaId = {
      0: 'Bất kỳ giá nào',
      1: 'Dưới 20 triệu/m2',
      2: '20 triệu/m2 đến 50 triệu/m2',
      3: '50 triệu/m2 đến 100 triệu/m2',
      4: '100 triệu/m2 đến 200 triệu/m2',
      5: '200 triệu/m2 đến 300 triệu/m2',
      6: 'Trên 300 triệu/m2'
    };

    this.loaiTangTruongId = {
      0: 'Giá',
      1: 'Tin rao'
    };

    this.huongTangTruongId = {
      0: 'Đang tăng',
      1: 'Bắt đầu tăng',
      2: 'Đang giảm',
      3: 'Bắt đầu giảm'
    };

    this.kieuBienDongId = {
      0: 'Tháng trước',
      1: 'Khu vực xung quanh'
    };

    this.capHanhChinhId = {
      0: 'Tỉnh/Thành Phố',
      1: 'Quận/Huyện',
      2: 'Phường/Xã',
      3: 'Đường'
    };
  }
}
