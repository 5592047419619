import { Observable, forkJoin, Subscriber } from 'rxjs';
import { AddressHttpService } from '../http/address-http.service';

export class QueryParamAddress {
    'tinh-id': string;
    'quan-id': string;
    'phuong-id': string;
    'du-an-id': string;
    'duong-id': string;
    'thon-xom-id': string;
    'ngo': string;
    'ngach-hem': string;
    'so-nha': string;

    init(
        tinhId: number,
        quanId: number,
        duongId: number,
        phuongId: number,
        thonXomId: number,
        duAnId: number,
        ngo: string,
        ngachHem: string,
        soNha: string
    ) {
        this['tinh-id'] = tinhId ? String(tinhId) : undefined;
        this['quan-id'] = quanId ? String(quanId) : undefined;
        this['duong-id'] = duongId ? String(duongId) : undefined;
        this['phuong-id'] = phuongId ? String(phuongId) : undefined;
        this['thon-xom-id'] = thonXomId ? String(thonXomId) : undefined;
        this['du-an-id'] = duAnId ? String(duAnId) : undefined;
        this.ngo = ngo ? ngo : undefined;
        this['ngach-hem'] = ngachHem ? ngachHem : undefined;
        this['so-nha'] = soNha ? soNha : undefined;
    }
}

export class DiaChi {
    id: number;
    phuongId?: number;
    quanId?: number;
    tinhId?: number;
    ten?: string;
    tenDayDu?: string;
    tenKhongDau?: string;
    tienTo?: string;
    updateFromSourceEs(source: any) {
        this.id = source.id;
        this.ten = source.ten;
        this.tienTo = source.tien_to;
    }
}

class AddressItem<T> {
    list: T[];
    choose: T;
    constructor() {
        this.list = [];
        this.choose = null;
    }
}

export class Address {
    tinh: AddressItem<DiaChi>;
    quan: AddressItem<DiaChi>;
    duong: AddressItem<DiaChi>;
    phuong: AddressItem<DiaChi>;
    duan: AddressItem<DiaChi>;
    thonxom: AddressItem<DiaChi>;
    ngo: AddressItem<string>;
    ngachhem: AddressItem<string>;
    initFinish: Observable<any>;
    onChangeAddress: Observable<string>;
    private callBack: Subscriber<string>;
    constructor(
        private addressHttpService: AddressHttpService,
        private tinhId: number,
        private quanId: number,
        private duongId: number,
        private phuongId: number,
        private thonXomId: number = null,
        private duanId: number = null,
        private ngoStr: string = null,
        private ngachStr: string = null,
        public soNha: string = null
    ) {
        this.init(tinhId, quanId, duongId, phuongId, thonXomId, duanId, ngoStr, ngachStr, soNha);
        const that = this;
        this.onChangeAddress = new Observable(subcriber => {
            that.callBack = subcriber;
        });
    }

    init(
        tinhId: number,
        quanId: number,
        duongId: number,
        phuongId: number,
        thonXomId: number = null,
        duanId: number = null,
        ngoStr: string = null,
        ngachStr: string = null,
        soNha: string = null
    ): Observable<any> {
        // init dia chi
        const params: any = arguments;
        ['tinh', 'quan', 'duong', 'phuong', 'thonxom', 'duan'].forEach((name, index) => {
            this[name] = {
                list: [],
                choose: params[index]
                    ? {
                          id: params[index]
                      }
                    : null
            };
        });
        this.ngo = {
            list: [],
            choose: ngoStr
        };
        this.ngachhem = {
            list: [],
            choose: ngachStr
        };
        this.soNha = soNha;

        const listObservable: Observable<any>[] = [];

        // Load danh sach cac tinh
        const tinhLoader = this.addressHttpService.queryAddress<DiaChi>({
            type: 'tinh'
        });
        tinhLoader.subscribe(data => {
            this.tinh.list = data;
            if (tinhId) {
                const tinhChoose = this.tinh.list.find(tinh => tinh.id === tinhId);
                if (tinhChoose) {
                    this.tinh.choose = tinhChoose;
                }
            }
        });
        listObservable.push(tinhLoader);
        if (tinhId) {
            const quanLoader = this.addressHttpService.queryAddress<DiaChi>({
                type: 'quan',
                idPre: tinhId
            });
            quanLoader.subscribe(data => {
                this.quan.list = data;
                if (quanId) {
                    const quanChoose = this.quan.list.find(quan => quan.id === quanId);
                    if (quanChoose) {
                        this.quan.choose = quanChoose;
                    }
                }
            });
            listObservable.push(quanLoader);
            if (quanId) {
                const dcName = ['duong', 'phuong', 'duan'];
                [duongId, phuongId, duanId].forEach((id, index) => {
                    const name = dcName[index];
                    const loader = this.addressHttpService.queryAddress<DiaChi>({
                        type: name,
                        idPre: quanId
                    });
                    loader.subscribe(data => {
                        this[name].list = data;
                        if (id) {
                            const elementChoose = this[name].list.find(dc => dc.id === id);
                            if (elementChoose) {
                                this[name].choose = elementChoose;
                            }
                        }
                    });
                    listObservable.push(loader);
                });
            }

            this.ngo.choose = ngoStr;
            this.ngachhem.choose = ngachStr;
            if (duongId) {
                this.addressHttpService
                    .queryAddress<string>({
                        type: 'ngo',
                        idPre: duongId
                    })
                    .subscribe(data => {
                        this.ngo.list = data;
                        // if (ngoStr && (data.length == 0 || !data.includes(ngoStr))) {
                        //     this.ngo.list.push(ngoStr);
                        // }
                    });
                if (ngoStr) {
                    this.addressHttpService
                        .queryAddress<string>({
                            type: 'ngachhem',
                            idPre: duongId,
                            ngo: ngoStr
                        })
                        .subscribe(data => {
                            this.ngachhem.list = data;
                            // if (ngachStr && (data.length == 0 || !data.includes(ngachStr))) {
                            //     this.ngachhem.list.push(ngachStr);
                            // }
                        });
                }
            }

            if (phuongId) {
                const thonXomLoader = this.addressHttpService.queryAddress<DiaChi>({
                    type: 'thonxom',
                    idPre: phuongId
                });
                thonXomLoader.subscribe(data => {
                    this.thonxom.list = data;
                    if (thonXomId) {
                        const thonxom = data.find(thonXom => thonXom.id === thonXomId);
                        if (thonxom) {
                            this.thonxom.choose = thonxom;
                        }
                    }
                });
                listObservable.push(thonXomLoader);
            }
        }
        const initFinish = forkJoin.apply(null, listObservable);
        this.initFinish = initFinish;
        return initFinish;
    }

    getQuery(): any {
        const params = new QueryParamAddress();
        params.init(
            this.tinh.choose ? this.tinh.choose.id : null,
            this.quan.choose ? this.quan.choose.id : null,
            this.duong.choose ? this.duong.choose.id : null,
            this.phuong.choose ? this.phuong.choose.id : null,
            this.thonxom.choose ? this.thonxom.choose.id : null,
            this.duan.choose ? this.duan.choose.id : null,
            this.ngo.choose ? this.ngo.choose : null,
            this.ngachhem.choose ? this.ngachhem.choose : null,
            this.soNha
        );
        return params;
    }

    // getTitle(): string {
    //     let tieuDe = '';
    //     if (this.soNha) {
    //         tieuDe += 'Số nhà ' + this.soNha + ', ';
    //     }
    //     if (this.ngo.choose) {
    //         tieuDe += 'ngõ ' + this.ngo.choose + ', ';
    //     }
    //     if (this.ngachhem.choose) {
    //         tieuDe += 'ngách ' + this.ngachhem.choose + ', ';
    //     }
    //     if (this.duong.choose) {
    //         const duong = this.duong.choose;
    //         tieuDe += '' + duong.tienTo + ' ' + duong.ten + ', ';
    //     }
    //     if (this.phuong.choose) {
    //         const phuong = this.phuong.choose;
    //         tieuDe += '' + phuong.tienTo + ' ' + phuong.ten + ', ';
    //     }
    //     if (this.thonxom.choose) {
    //         const thonXom = this.thonxom.choose;
    //         tieuDe += '' + thonXom.tienTo + ' ' + thonXom.ten + ', ';
    //     }
    //     if (this.quan.choose) {
    //         const quan = this.quan.choose;
    //         tieuDe += '' + (quan.tienTo || '') + ' ' + (quan.ten && quan.ten + ', ' || '');
    //     }
    //     if (this.tinh.choose) {
    //         const tinh = this.tinh.choose;
    //         tieuDe += '' + (tinh.tienTo || '') + ' ' + (tinh.ten || '');
    //     }
    //     return tieuDe;
    // }

    getTitle(): string {
        let tieuDe = '';
        if (this.soNha) {
            tieuDe += 'Số nhà ' + this.soNha + ', ';
        }
        if (this.ngo.choose) {
            tieuDe += 'ngõ ' + this.ngo.choose + ', ';
        }
        if (this.ngachhem.choose) {
            tieuDe += 'ngách ' + this.ngachhem.choose + ', ';
        }
        // if (this.duong.choose) {
        //     const duong = this.duong.choose;
        //     tieuDe += '' + duong.tienTo + ' ' + duong.ten + ', ';
        // }
        // if (this.phuong.choose) {
        //     const phuong = this.phuong.choose;
        //     tieuDe += '' + phuong.tienTo + ' ' + phuong.ten + ', ';
        // }
        // if (this.thonxom.choose) {
        //     const thonXom = this.thonxom.choose;
        //     tieuDe += '' + thonXom.tienTo + ' ' + thonXom.ten + ', ';
        // }
        // if (this.quan.choose) {
        //     const quan = this.quan.choose;
        //     tieuDe += '' + (quan.tienTo || '') + ' ' + (quan.ten && quan.ten + ', ' || '');
        // }
        // if (this.tinh.choose) {
        //     const tinh = this.tinh.choose;
        //     tieuDe += '' + (tinh.tienTo || '') + ' ' + (tinh.ten || '');
        // }
        ['duong', 'phuong', 'thonxom', 'quan', 'tinh'].forEach(item => {
            if (this[item].choose) {
                tieuDe += '' + (this[item].choose.tienTo || '') + ' ' + (this[item].choose.ten && this[item].choose.ten + (item === 'tinh' ? '' : ', ') || '');
            }
        });
        return tieuDe;
    }

    getPhuongQuanTinh(): string {
        let tieuDe = '';
        if (this.phuong.choose) {
            const phuong = this.phuong.choose;
            tieuDe += '' + phuong.tienTo + ' ' + phuong.ten + ', ';
        }
        if (this.thonxom.choose) {
            const thonXom = this.thonxom.choose;
            tieuDe += '' + thonXom.tienTo + ' ' + thonXom.ten + ', ';
        }
        if (this.quan.choose) {
            const quan = this.quan.choose;
            tieuDe += '' + quan.tienTo + ' ' + quan.ten + ', ';
        }
        if (this.tinh.choose) {
            const tinh = this.tinh.choose;
            tieuDe += '' + tinh.tienTo + ' ' + tinh.ten;
        }
        return tieuDe;
    }

    getAddressEs() {
        const result: any = {};
        result.so_nha = this.soNha;
        result.dl_ngo = this.ngo.choose;
        result.dl_ngach_hem = this.ngachhem.choose;
        result.duong = this.duong.choose ? this.getObjectEs(this.duong.choose) : null;
        result.phuong_xa = this.phuong.choose ? this.getObjectEs(this.phuong.choose) : null;
        result.quan_huyen = this.quan.choose ? this.getObjectEs(this.quan.choose) : null;
        result.tinh = this.tinh.choose ? this.getObjectEs(this.tinh.choose) : null;
        return result;
    }

    getObjectEs(diaChi) {
        return {
            id: diaChi.id,
            ten: diaChi.ten,
            tien_to: diaChi.tienTo
        };
    }

    changeTinh() {
        ['quan', 'duong', 'phuong', 'duan', 'thonxom', 'ngo', 'ngachhem'].forEach(name => {
            this[name] = {
                list: [],
                choose: null
            };
        });
        this.soNha = null;
        if (this.tinh.choose) {
            this.addressHttpService
                .queryAddress<DiaChi>({
                    type: 'quan',
                    idPre: this.tinh.choose.id
                })
                .subscribe(data => {
                    this.quan.list = data;
                });
        }
        if (this.callBack) {
            this.callBack.next('tinh');
        }
    }
    changeQuan() {
        ['duong', 'phuong', 'duan', 'thonxom', 'ngo', 'ngachhem'].forEach(name => {
            this[name] = {
                list: [],
                choose: null
            };
        });
        this.soNha = null;
        if (this.quan.choose) {
            ['duong', 'phuong', 'duan'].forEach(name => {
                this.addressHttpService
                    .queryAddress({
                        type: name,
                        idPre: this.quan.choose.id
                    })
                    .subscribe(data => {
                        this[name].list = data;
                    });
            });
        }
        if (this.callBack) {
            this.callBack.next('quan');
        }
    }
    changePhuong() {
        this.thonxom = {
            list: [],
            choose: null
        };
        if (this.phuong.choose) {
            this.addressHttpService
                .queryAddress<DiaChi>({
                    type: 'thonxom',
                    idPre: this.phuong.choose.id
                })
                .subscribe(data => {
                    this.thonxom.list = data;
                });
        }
        if (this.callBack) {
            this.callBack.next('phuong');
        }
    }

    changeDuong() {
        this.ngo = {
            list: [],
            choose: null
        };
        this.ngachhem = {
            list: [],
            choose: null
        };
        this.soNha = null;
        if (this.duong.choose) {
            this.addressHttpService
                .queryAddress<string>({
                    type: 'ngo',
                    idPre: this.duong.choose.id
                })
                .subscribe(data => {
                    this.ngo.list = data;
                });
        }
        if (this.callBack) {
            this.callBack.next('duong');
        }
    }

    changeNgo() {
        this.ngachhem = {
            list: [],
            choose: null
        };
        this.soNha = null;
        if (this.ngo.choose) {
            this.addressHttpService
                .queryAddress<string>({
                    type: 'ngachhem',
                    idPre: this.duong.choose.id,
                    ngo: this.ngo.choose
                })
                .subscribe(data => {
                    this.ngachhem.list = data;
                });
        }
        if (this.callBack) {
            this.callBack.next('ngo');
        }
    }

    changeNgachHem() {
        this.soNha = null;
        if (this.callBack) {
            this.callBack.next('ngachhem');
        }
    }

    changeSoNha() {
        if (this.callBack) {
            this.callBack.next('soNha');
        }
    }

    changeDuAn() {
        this.soNha = null;
        if (this.callBack) {
            this.callBack.next('duan');
        }
    }

    getAddress(): DiaChi {
        let choose: any;
        choose = null;
        ['duan', 'duong', 'phuong', 'quan', 'tinh'].forEach(name => {
            if (!choose && this[name].choose) {
                choose = this[name].choose;
            }
        });
        return choose;
    }
}

export function createAddressFromParams(addressHttpService: AddressHttpService, params: any, defaultTinhId = 1): Address {
    return new Address(
        addressHttpService,
        params['tinh-id'] ? +params['tinh-id'] : defaultTinhId,
        +params['quan-id'],
        +params['duong-id'],
        +params['phuong-id'],
        +params['thon-xom-id'],
        +params['du-an-id'],
        params.ngo,
        params['ngach-hem'],
        params['so-nha']
    );
}
