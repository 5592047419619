import { PopupModule } from './../../../../shared/popup/popup.module';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AppShareModule } from './../../../../app-share.modules';
import { MainLinkMenuComponent } from './main-link-menu.component';
import { NgModule } from "@angular/core";
import { ThamDinhGiaModule } from 'src/app/shared/tham-dinh-gia/tham-dinh-gia.module';
import { DangKyNhanBaoCaoModule } from '../../../landing/dang-ky-nhan-bao-cao/dang-ky-nhan-bao-cao.module';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    AppShareModule,
    PopupModule,
    ThamDinhGiaModule,
    DangKyNhanBaoCaoModule
  ],
  exports: [
    MainLinkMenuComponent
  ],
  declarations: [
    MainLinkMenuComponent
  ]
})

export class MainLinkMenuModule { }
