import { Subject, Observable, forkJoin, of } from 'rxjs';
import { createSlugFromTitle } from './helpers';

export type AreaType = 'du_an' | 'khu_vuc';
export interface Area {
    id: number;
    name: string;
    prefix?: string;
    fullName: string;
    slug: string;
}
export interface AreaPhanTich {
    type: AreaType;
    tinhThanh: Area;
    quanHuyen?: Area;
    phuongXa?: Area;
    duongPho?: Area;
    duAn?: Area;
}

export type TinhThanh = 'tinhThanh';
export type QuanHuyen = 'quanHuyen';
export type PhuongXa = 'phuongXa';
export type DuongPho = 'duongPho';
export type DuAn = 'duAn';

export type UseFor = 'SALE' | 'RENT';
export type LoaiDiaChi = 'tinh' | 'quan' | 'duong' | 'phuong' | 'duan';
export type TypeRequestTienIch = 'city' | 'district' | 'ward' | 'street';
export type AddressLevel = TinhThanh | QuanHuyen | PhuongXa | DuongPho | DuAn;
export interface AddressInitParams {
    tinhThanhId?: number;
    quanHuyenId?: number;
    phuongXaId?: number;
    duongPhoId?: number;
    duAnId?: number;
}

import { HttpClient } from '@angular/common/http';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';

export class AddressTree {
    public idSelected: FormGroup;
    public list: any;
    public selected: any;
    public lastSelected: Area;
    public listObservable: Observable<any>[] = [];
    constructor(
        private http: HttpClient,
        private fb: FormBuilder,
        private initParams: AddressInitParams
    ) {
        this.list = {};
        ['tinhThanh', 'quanHuyen', 'duongPho', 'phuongXa', 'duAn'].forEach(level => {
            this.list[level] = [];
        });
        this.idSelected = this.fb.group({
            tinhThanhId: this.fb.control(initParams.tinhThanhId),
            quanHuyenId: this.fb.control(initParams.quanHuyenId),
            phuongXaId: this.fb.control(initParams.phuongXaId),
            duongPhoId: this.fb.control(initParams.duongPhoId),
            duAnId: this.fb.control(initParams.duAnId),
        });
        this.load(initParams);
    }
    public changeTinh($event): void {
        this.resetChilds('tinhThanh');
        if ($event) {
            this.setList('tinhThanh', this.list.tinhThanh);
            this.setSelected('tinhThanh', $event.id, this.list.tinhThanh);
            this.httpGet('quan', $event.id).subscribe(res => {
                this.list.quanHuyen = res;
            });
        }
    }
    public changeQuan($event): void {
        this.resetChilds('quanHuyen');
        if ($event) {
            this.setList('quanHuyen', this.list.quanHuyen);
            this.setSelected('quanHuyen', $event.id, this.list.quanHuyen);
            this.httpGet('duong', $event.id).subscribe(res => {
                this.list.duongPho = res;
            });
            this.httpGet('phuong', $event.id).subscribe(res => {
                this.list.phuongXa = res;
            });
            this.httpGet('duan', $event.id).subscribe(res => {
                this.list.duAn = res;
            });
        } else {
            this.resetChild('quanHuyen');
            this.setList('tinhThanh', this.list.tinhThanh);
            this.setSelected('tinhThanh', this.selected.tinhThanh.id, this.list.tinhThanh);
        }
    }
    public changePhuong($event): void {
        this.resetChild('duongPho');
        if ($event) {
            this.setList('phuongXa', this.list.phuongXa);
            this.setSelected('phuongXa', $event.id, this.list.phuongXa);
        } else {
            this.resetChild('phuongXa');
            this.setList('quanHuyen', this.list.quanHuyen);
            this.setSelected('quanHuyen', this.selected.quanHuyen.id, this.list.quanHuyen);
        }
    }
    public changeDuong($event): void {
        this.resetChild('phuongXa');
        if ($event) {
            this.setList('duongPho', this.list.duongPho);
            this.setSelected('duongPho', $event.id, this.list.duongPho);
        } else {
            this.resetChild('duongPho');
            this.setList('quanHuyen', this.list.quanHuyen);
            this.setSelected('quanHuyen', this.selected.quanHuyen.id, this.list.quanHuyen);
        }
    }
    public changeDuAn($event): void {
        if ($event) {
            this.setList('duAn', this.list.duAn);
            this.setSelected('duAn', $event.id, this.list.duAn);
        } else {
            
        }
    }
    private load(initParams: AddressInitParams): void {
        this.selected = {};
        const listSubscribe: any = {
            tinhThanh: this.httpGet('tinh', null),
            quanHuyen: initParams.tinhThanhId ? this.httpGet('quan', initParams.tinhThanhId) : of(null),
            phuongXa: initParams.quanHuyenId ? this.httpGet('phuong', initParams.quanHuyenId) : of(null),
            duongPho: initParams.quanHuyenId ? this.httpGet('duong', initParams.quanHuyenId) : of(null),
            duAn: initParams.quanHuyenId ? this.httpGet('duan', initParams.quanHuyenId) : of(null),
        };
        forkJoin(listSubscribe).subscribe(value => {
            console.log('forkjoin', value)
            const data = value as any;
            (['tinhThanh', 'quanHuyen', 'duongPho', 'phuongXa', 'duAn'] as AddressLevel[]).forEach(level => {
                if (data[level]) {
                    this.setList(level, data[level]);
                }
                if (initParams[`${level}Id`]) {
                    this.setSelected(level, initParams[`${level}Id`], data[level]);
                }
            });
            
            // this.setSelected('quanHuyen', initParams.quanHuyenId, data.quanHuyen);
            // this.setSelected('phuongXa', initParams.phuongXaId, data.phuongXa);
            // this.setSelected('duongPho', initParams.duongPhoId, data.duongPho);
            // this.setSelected('duAn', initParams.duAnId, data.duAn);
        })
        // if (initParams.tinhThanhId) {
        //     listSubscribe.tinhThanh = this.httpGet('tinh', null);
        //     this.httpGet('tinh', null).subscribe(res => {
        //         this.setSelected('tinhThanh', initParams.tinhThanhId, res);
        //     });
        //     this.httpGet('quan', initParams.tinhThanhId).subscribe(res => {
        //         this.setSelected('quanHuyen', initParams.quanHuyenId, res);
        //     });
        // }
        // if (initParams.quanHuyenId) {
        //     this.httpGet('phuong', initParams.quanHuyenId).subscribe(res => {
        //         this.setSelected('phuongXa', initParams.phuongXaId, res);
        //     });
        //     this.httpGet('duong', initParams.quanHuyenId).subscribe(res => {
        //         this.setSelected('duongPho', initParams.duongPhoId, res);
        //     });
        //     this.httpGet('duan', initParams.quanHuyenId).subscribe(res => {
        //         this.setSelected('duAn', initParams.duAnId, res);
        //     });
        // }
        // listParams.forEach(level => {
        //     if (initParams[level.id]) {
        //         level.loads.forEach(load => {
        //             this.httpGet(load.type, initParams[load.idPre]).subscribe(res => {
        //                 this.list[load.name] = res;
        //                 this.selected[load.name] = {
        //                     id: initParams[level.id]
        //                 };
        //             });
        //         });

        //     }
        // });
    }
    private httpGet(type: string, idPre: number): any {
        return this.http.get(`${environment.API.SERVER_ADDRESS}public-dia-chis?type=${type}&idPre=${idPre}`);
    }
    private setSelected(level: AddressLevel, idLevel: number, data: any): void {
        const thongTinKhuVuc = this.list[level].find(a => a.id === idLevel) || {};
        const ten = thongTinKhuVuc.ten || null;
        const tenDayDu = (thongTinKhuVuc.tienTo && thongTinKhuVuc.ten) ? `${thongTinKhuVuc.tienTo} ${thongTinKhuVuc.ten}` : null;
        const duongDan = (thongTinKhuVuc.id && tenDayDu) ? createSlugFromTitle(`${thongTinKhuVuc.tienTo}-${thongTinKhuVuc.ten}-${thongTinKhuVuc.id}`) : null;
        this.selected[level] = {
            id: idLevel,
            name: ten,
            fullName: tenDayDu,
            slug: duongDan
        };
        this.lastSelected = {
            id: idLevel,
            name: ten,
            fullName: tenDayDu,
            slug: duongDan
        };
    }
    private setList(level: AddressLevel, data: any): void {
        this.list[level] = data;
    }
    private resetChild(level: AddressLevel): void {
        this.idSelected.get(`${level}Id`).setValue(null);
        this.selected[level] = {
            id: null,
            name: null,
            fullName: null,
            slug: null
        }
    }
    private resetChilds(level: AddressLevel): void {
        let shouldRemove = false;
        const levelList: AddressLevel[] = ['tinhThanh', 'quanHuyen', 'duongPho', 'phuongXa', 'duAn'];
        levelList.forEach(lv => {
            if (shouldRemove) {
                this.idSelected.get(`${lv}Id`).setValue(null);
                this.list[lv] = [];
                this.selected[lv] = {
                    id: null,
                    name: null,
                    fullName: null,
                    slug: null
                }
            }
            if (lv === level) {
                shouldRemove = true;
            }
        });
    }
}
