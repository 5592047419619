import { Component, OnInit } from '@angular/core';

import { AccountService } from 'src/app/core';
import { PasswordService } from './password.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'app-password',
    styleUrls: ['../account.scss'],
    templateUrl: './password.component.html'
})
export class PasswordComponent implements OnInit {
    doNotMatch: string;
    error: string;
    success: string;
    account: any;
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
    errMsg = '';
    public reactiveForm: FormGroup = new FormGroup({
        recaptchaReactive: new FormControl(null, Validators.required)
    });
    constructor(private passwordService: PasswordService, private accountService: AccountService) {}

    ngOnInit() {
        this.accountService.identity().then(account => {
            this.account = account;
        });
    }

    changePassword() {
        if (this.newPassword !== this.confirmPassword) {
            this.error = null;
            this.success = null;
            this.doNotMatch = 'ERROR';
        } else {
            this.doNotMatch = null;
            this.passwordService.save(this.newPassword, this.currentPassword).subscribe(
                res => {
                    this.error = null;
                    this.success = 'OK';
                },
                err => {
                    if (err.error.title === 'Incorrect password') {
                        this.success = null;
                        this.error = 'ERROR';
                        this.errMsg = 'Mật khẩu cũ không chính xác. Bạn vui lòng thử lại.';
                    } else {
                        this.success = null;
                        this.error = 'ERROR';
                        this.errMsg = 'Đã có lỗi xảy ra. Bạn vui lòng liên hệ với quản trị viên.';
                    }
                }
            );
        }
    }
}
