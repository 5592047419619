import { ThamDinhGiaService } from './../../../../shared/tham-dinh-gia/tham-dinh-gia.service';
import { Component, OnInit } from '@angular/core';
import { DangKyNhanBaoCaoService } from '../../../landing/dang-ky-nhan-bao-cao/dang-ky-nhan-bao-cao.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-main-link-menu',
  templateUrl: './main-link-menu.component.html',
  styleUrls: ['./main-link-menu.component.scss', '../user-menu/user-menu.component.scss']
})
export class MainLinkMenuComponent implements OnInit {
  public environment: any;
  constructor(public thamDinhGiaService: ThamDinhGiaService, public dangKyNhanBaoCaoService: DangKyNhanBaoCaoService) {
    this.environment = environment;
  }

  ngOnInit(): void {
  }

  public dangKyNhanBaoCao() {
    this.dangKyNhanBaoCaoService.formIsOpen = true;
    console.log('aaa')
  }
}
