import { NzNotificationService } from 'ng-zorro-antd/notification';

import { LoginSsoService } from './../../core/login/login-sso.service';
import { InterceptorService } from './interceptor.service';
import { WarningTaoLaiFingerComponent } from './warning-tao-lai-finger/warning-tao-lai-finger.component';
import { CookieService } from 'ngx-cookie-service';
import { WarningBadTimeComponent } from './warning-bad-time/warning-bad-time.component';
import { Injectable, ViewChild, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoginService } from 'src/app/core/login/login.service';
import { LoginModalService } from 'src/app/core';
import { ToasterService } from 'angular2-toaster';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CaptchaComponent } from './captcha.component';
import { ConfirmUpdateVersionComponent } from './confirm-update-version/confirm-update-version.component';
import * as Fingerprint2 from 'fingerprintjs2';
import { environment } from '../../../environments/environment';
import { SeoService } from 'src/app/shared/util/seo.service';
import { Router } from '@angular/router';
import { AccountSsoService } from 'src/app/core/auth/account-sso.service';

@Injectable()

export class AuthExpiredInterceptor implements HttpInterceptor {
  public finger: string;
  public modalRef: NgbModalRef;
  private captchaFormIsOpen: Boolean = false;
  private confirmUpdateVersionIsOpen: boolean = false;
  private warningBadTimeOpen: boolean = false;
  constructor(
    public interceptorService: InterceptorService,
    private loginModalService: LoginModalService,
    private cookieService: CookieService,
    private toaster: ToasterService,
    private modalService: NgbModal,
    private injector: Injector,
    public loginSsoService: LoginSsoService,
    public http: HttpClient,
    public seoService: SeoService,
    private notificationService: NzNotificationService,
    private router: Router,
    private accountSsoService: AccountSsoService
  ) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log('this.captchaFormIsOpen');
    // this.captchaFormIsOpen = false;
    if (this.seoService.onBrowser) {
      const that = this;
      return next.handle(request).pipe(
        tap(
          (event: any) => {
            if (event && event.body && event.body.status === 3) {
              // console.log('this.modalRef');
              // console.log(this.modalRef);
              // console.log('this.captchaFormIsOpen');
              // console.log(this.captchaFormIsOpen);
              // this.toaster.pop('warning', 'Cảnh báo', event.body.description);
              this.interceptorService.limitAccessPopup.open = true;
              // if (!this.captchaFormIsOpen) {
              //   this.modalRef = this.modalService.open(CaptchaComponent, {
              //     size: 'sm',
              //     windowClass: 'largeModalNew',
              //     keyboard: true,
              //     backdrop: 'static'
              //   });
              //   this.modalRef.result.then(_ => {
              //     this.captchaFormIsOpen = false;
              //   });
              //   this.captchaFormIsOpen = true;
              //   /* Would be nice to have feature prevent call serial API after limited */
              // }
            } else if (event && event.body && event.body.status === 2) {
              /* Trường hợp Client gửi token hết hạn lên server */
              // this.interceptorService.setErrorServerTokenHasExpired(); // 07/04/2023 bo di do backend yeu cau
              // this.toaster.pop('warning', 'Cảnh báo', event.body.description);
            } else if (event && event.body && event.body.status === 5) {
              /* Yêu cầu tạo lại finger */
              try {
                console.log('Xoa coookie');
                this.cookieService.delete('g-finger');
              }
              catch (err) {
                console.log('Khong the xoa duoc Cookie', err);
              }
              const localTimeStamp = new Date().getTime() + 7 * 60 * 60 * 1000; // Lấy timestamp của client theo GMT +7
              // Tính độ lệch thời gian giữa sever và client theo phút
              const timeDiff = Math.abs(event.body.timestamp * 1000 - localTimeStamp);
              // console.log(localTimeStamp, timeDiff);
              if (timeDiff / (1000 * 60) > 10) { // Thông báo nếu giờ của client lệch 10 phút so với server
                this.interceptorService.setErrorBadTime(event.body.timestamp);
              } else {
                this.interceptorService.setErrorForceResetFinger();
              }

            } else if (event && event.body && event.body.status === 7) {
              this.interceptorService.setErrorConfirmUpdateVersion();
            } else if (event && event.body && event.body.status === 4) {
              this.toaster.pop('warning', 'Cảnh báo', 'Bạn đã sử dụng hết số lượt định giá, quay lại sau 24h');
            } else if (event && event.body && event.body.status === 104) {
              this.notificationService.error(event.body.description, ''); 
            } else if (event && event.body && event.body.status === 9) {
              this.notificationService.error(event?.body?.description ?? 'Đã có lỗi xảy ra. Vui lòng thử lại!', '');
            }
          },
          (err: any) => {
            console.log('Loi http request', err.status);
            if (err instanceof HttpErrorResponse) {
              if (err.status === 401) {
                if (err.url.includes('/account')) {
                  const loginService: LoginService = this.injector.get(LoginService);
                  // console.log('that.loginService', loginService);
                  loginService.logout();
                  // this.router.navigate(['/']);
                  // this.loginSsoService.logout();
                  // this.loginModalService.open();
                } else {
                  // this.loginSsoService.login();
                  // that.loginModalService.open('Bạn phải đăng nhập để sử dụng chức năng này');
                }
                this.accountSsoService.SID = null;
                this.interceptorService.SID = null;
        
                this.accountSsoService.jwt = null;
                this.accountSsoService.account = null;
        
                this.interceptorService.jwt = null;
        
                this.accountSsoService.account = null;
                this.accountSsoService.setUserIdentity(null);

              } else if (err.status === 0) {
                if (err.url.indexOf('https://app.cenhomes.vn') !== -1) {
                  return;
                } else {
                  this.interceptorService.setErrorNetwork();
                  this.http.post<any>(environment.API.SERVER_ESTIMATE + '/api/dia-chi-cu-the/frontend-exception', {
                        name: 'Loi http',
                        message: err,
                        stack: '',
                        url: ''
                    }
                  );
                }
              }
              else {
                console.error(err);
              }
            }
          }
        )
      );
    } else {
      return next.handle(request);
    }
    
  }

  private encodeFinger(now, finger) {
    const date = now;
    const timestamp = String(date.getTime()); // timestamp with milliseconds
    const chs = [];
    // Copy character by character into array
    for (let i = 0; i < finger.length; i++) {
      chs.push(finger.charAt(i));
    }
    const day = date.getUTCDate();
    let salt = 0;
    if (day % 2 != 0) {
      salt = 1;
    }
    for (let i = 0; i < timestamp.length; i++) {
      chs.splice(i * 2 + salt, 0, timestamp.charAt(i));
    }
    const resultChs = [];
    for (let i = 0; i < chs.length; i++) {
      resultChs.push(this.convertASCII(chs[i], day));
    }

    return btoa(resultChs.join(''));
  }
  private convertASCII<String>(char: any, index: number) {
    const ascii = char.charCodeAt(0);
    return String.fromCharCode(ascii + index);
  }
}
