import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TiemNangTheoNhaDauTuComponent } from './tiem-nang-theo-nha-dau-tu.component';



@NgModule({
  declarations: [TiemNangTheoNhaDauTuComponent],
  imports: [
    CommonModule
  ],
  exports: [TiemNangTheoNhaDauTuComponent]
})
export class TiemNangTheoNhaDauTuModule { }
