import { PopupComponent, PopupHeaderDirective, PopupBodyDirective, PopupFooterDirective } from './popup.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [
    PopupComponent,
    PopupHeaderDirective,
    PopupBodyDirective,
    PopupFooterDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    PopupComponent,
    PopupHeaderDirective,
    PopupBodyDirective,
    PopupFooterDirective
  ]
})
export class PopupModule { }
