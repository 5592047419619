<app-dropdown-menu>
    <ng-template app-dropdown-menu-label>
        <div class="d-flex picker">
            <div class="date-time-string">
                T{{date.monthFrom + 1}}/{{date.yearFrom}} đến T{{date.monthTo + 1}}/{{date.yearTo}}

            </div>
            <div class="calendar">
                <svg style="width: 20px; height: 20px" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="calendar-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-calendar-alt fa-w-14 fa-2x"><path fill="currentColor" d="M400 64h-48V12c0-6.6-5.4-12-12-12h-8c-6.6 0-12 5.4-12 12v52H128V12c0-6.6-5.4-12-12-12h-8c-6.6 0-12 5.4-12 12v52H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM48 96h352c8.8 0 16 7.2 16 16v48H32v-48c0-8.8 7.2-16 16-16zm352 384H48c-8.8 0-16-7.2-16-16V192h384v272c0 8.8-7.2 16-16 16zM148 320h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm-96 96h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm-96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm192 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12z" class=""></path></svg>
            </div>
        </div>
    </ng-template>
    <ng-template app-dropdown-menu-items>
        <div class="row">
            <div class="col-6 date-picker">

                <div class="d-flex justify-content-between year">
                    <div class="next-prev-year" [ngClass]="{'disabled': date.yearFrom === datePickerService.pastYear}" (click)="prevYearFrom()">
                        <svg style="width: 20px; height: 20px" aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-arrow-left fa-w-14 fa-2x"><path fill="currentColor" d="M229.9 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L94.569 282H436c6.627 0 12-5.373 12-12v-28c0-6.627-5.373-12-12-12H94.569l155.13-155.13c4.686-4.686 4.686-12.284 0-16.971L229.9 38.101c-4.686-4.686-12.284-4.686-16.971 0L3.515 247.515c-4.686 4.686-4.686 12.284 0 16.971L212.929 473.9c4.686 4.686 12.284 4.686 16.971-.001z" class=""></path></svg>
                    </div>
                    <div>
                        <div *ngFor="let year of datePickerService.listYear">
                            <span *ngIf="year === date.yearFrom">{{year}}</span>
                        </div>
                    </div>
                    <div class="next-prev-year" [ngClass]="{'disabled': date.yearFrom === datePickerService.currentYear}" (click)="nextYearFrom()">
                        <svg style="width: 20px; height: 20px" aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-arrow-right fa-w-14 fa-2x"><path fill="currentColor" d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z" class=""></path></svg>
                    </div>
                </div>
                <div class="row month-grid-row">
                    <div class="col-4 px-0 month" (click)="changeMonthFrom(month)" [ngClass]="{'selected': month === date.monthFrom}" *ngFor="let month of datePickerService.listMonth">
                        <div>Tháng</div>
                        <div>{{month + 1}}</div>
                    </div>
                </div>
            </div>
            <div class="col-6 px-0 date-picker">
                <!-- <div class="label">Đến</div> -->
                <div class="d-flex justify-content-between year">
                    <div class="next-prev-year" [ngClass]="{'disabled': date.yearTo === datePickerService.pastYear}" (click)="prevYearTo()">
                        <svg style="width: 20px; height: 20px" aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-arrow-left fa-w-14 fa-2x"><path fill="currentColor" d="M229.9 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L94.569 282H436c6.627 0 12-5.373 12-12v-28c0-6.627-5.373-12-12-12H94.569l155.13-155.13c4.686-4.686 4.686-12.284 0-16.971L229.9 38.101c-4.686-4.686-12.284-4.686-16.971 0L3.515 247.515c-4.686 4.686-4.686 12.284 0 16.971L212.929 473.9c4.686 4.686 12.284 4.686 16.971-.001z" class=""></path></svg>
                    </div>
                    <div>
                        <div *ngFor="let year of datePickerService.listYear">
                            <span *ngIf="year === date.yearTo">{{year}}</span>
                        </div>
                    </div>
                    <div class="next-prev-year" [ngClass]="{'disabled': date.yearTo === datePickerService.currentYear}" (click)="nextYearTo()">
                        <svg style="width: 20px; height: 20px" aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-arrow-right fa-w-14 fa-2x"><path fill="currentColor" d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z" class=""></path></svg>
                    </div>
                </div>
                <div class="row month-grid-row">
                    <div class="col-4 px-0 month" (click)="changeMonthTo(month)" [ngClass]="{'selected': month === date.monthTo}" *ngFor="let month of datePickerService.listMonth">
                        <div>Tháng</div>
                        <div>{{month + 1}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="p-2 w-100 text-right">
            Từ tháng {{date.monthFrom + 1}}/{{date.yearFrom}} đến tháng {{date.monthTo + 1}}/{{date.yearTo}} ({{numberOfMonth}} tháng)
            <button app-dropdown-menu-click-close (click)="confirmDateChange()" class="ok-button">Đồng ý</button>
            </div>
        </div>
    </ng-template>
</app-dropdown-menu>
