<div class="footer">
  <div class="wrapper">

    <div class="inner d-flex justify-content-between">

      <div class="left">
        <div class="box">
          <img height="36" class="logo-image" src="/assets/images/img-cenhome.png" alt="CenHomes.vn" />
          <div class="text-16 company-name item">
            <b>Tên đơn vị: </b> <span>Công ty TNHH TMĐT Ngôi nhà Thế Kỷ</span>
          </div>
          <div class="text-16 address item">
            <b>Địa chỉ: </b> <span>Số 137 Nguyễn Ngọc Vũ, P.Trung Hoà, Q.Cầu Giấy, TP.Hà Nội, Việt Nam</span>
          </div>
          <div class="text-16 company-name item">
            <b>Hotline: </b> <a href="tel: 18006268"> 18006268</a>
          </div>
          <div class="text-16 item">
            <b>Email: </b> <a href="mailto: dinhgia@cenhomes.vn">dinhgia@cenhomes.vn</a>
          </div>
          <div class="mt-2 logo">
            <img class="da-thong-bao mr-2" src="content/images/dathongbao.png">
            <img src="/assets/images/img-da-dang-ky-bct.png">
          </div>
        </div>
      </div>
      <div class="d-flex right">
        <div class="item">
          <div class="text-24 text-title">Quận nổi bật</div>
          <div class="box">
            <div class="text" [routerLink]="['/phan-tich-khu-vuc/quan-cau-giay-28']">Quận Cầu Giấy</div>
            <div class="text" [routerLink]="['/phan-tich-khu-vuc/quan-nam-tu-liem-79']">Quận Nam Từ Liêm</div>
            <div class="text" [routerLink]="['/phan-tich-khu-vuc/quan-1-33']">Quận 1</div>
            <div class="text" [routerLink]="['/phan-tich-khu-vuc/quan-3-3']">Quận 3</div>
          </div>
        </div>
        <div class="item">
          <div class="text-24 text-title">Tìm hiểu thêm</div>
          <div class="box">
            <div class="text"><a href="https://cenhomes.vn">Cenhomes</a></div>
            <div class="text"><a href="https://cenhomes.vn/danh-sach-du-an-bat-dong-san">Dự án bất động sản</a></div>
            <div class="text"><a href="https://cenhomes.vn/mua-nha">Nhà bán</a></div>
            <div class="text"><a href="https://cenhomes.vn/thue-nha">Nhà cho thuê</a></div>
          </div>
        </div>
        <div class="item">
          <div class="text-24 text-title">Liên kết</div>
          <div class="box">
            <div class="text"><a href="http://thamdinhgiatheky.vn/">Cenvalue</a></div>
            <div class="text" (click)="thamDinhGiaService.openPopupYeuCauThamDinhGia= true"><a>Thẩm định giá</a></div>
            <div class="text"><a href="http://thamdinhgiatheky.vn/cen-value/44/quy-trinh-tham-dinh-gia">Quy trình thẩm
                định</a></div>
            <div class="text" [routerLink]="['/chinh-sach-bao-mat']">Chính sách bảo mật thông tin</div>
            <div class="text"><a href="https://cenhomes.vn/tin-tuc/quy-che-hoat-dong-69964">Quy chế hoạt động</a></div>
          </div>
        </div>
        <div class="item" style="margin-right: 0px;">
          <div class="text-24 text-title">Tin tức</div>
          <div class="box">
            <div class="text"><a href="https://cenhomes.vn/tin-tuc/tin-tuc-cenhomes" target="_blank">Tin tức
                Cenhomes</a></div>
            <div class="text"><a [routerLink]="['/jobs']">Tuyển dụng</a></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="copy-right">
  Copyright © 2021 by gianhadat.cenhomes.vn
  <!-- <div>Gianhadat.cenhomes.vn là sản phẩm công nghệ của Công ty TNHH TMĐT Ngôi Nhà Thế Kỷ</div>
    <div>(thành viên của Công ty CP Bất động sản Thế Kỷ)</div> -->
</div>

<!-- <div class="footer">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="wrapper-content pl-3 pr-3">
                <div class="row">
                    <div style="margin-bottom: 10px;"><img src="/content/images/cenhome-logo.png" /></div>
                </div>
                <div class="row d-none d-lg-flex">
                    <div class="col-6 pl-0 our-info">
                        <div>
                            <span class="font-weight-500">Tên đơn vị:</span> Công ty TNHH TMĐT Ngôi nhà Thế Kỷ
                        </div>
                        <div>
                            <span class="font-weight-500">Địa chỉ:</span> Số 137 Nguyễn Ngọc Vũ, P.Trung Hoà, Q.Cầu
                            Giấy, TP.Hà Nội, Việt Nam
                        </div>

                    </div>
                    <div class="col-4 pr-0 pl-0">

                        <div>
                            <span class="font-weight-500">Điện thoại:</span> 18006268
                        </div>
                        <div>
                            <span class="font-weight-500">Email:</span> dinhgia@cenhomes.vn
                        </div>
                    </div>
                    <div class="col-2 pr-0">
                        <a target="_blank"
                            href="http://online.gov.vn/HomePage/CustomWebsiteDisplay.aspx?DocId=62006"><img
                                style="width: 150px" src="content/images/dathongbao.png" /></a>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 col-sm-3 px-0 footer-sub-link">
                        <h5>Quận nổi bật</h5>
                        <ul>
                            <li><a [routerLink]="['/phan-tich-khu-vuc/quan-cau-giay-28']">Quận Cầu Giấy</a></li>
                            <li><a [routerLink]="['/phan-tich-khu-vuc/quan-nam-tu-liem-79']">Quận Nam Từ Liêm</a></li>
                            <li><a [routerLink]="['/phan-tich-khu-vuc/quan-1-33']">Quận 1</a></li>
                            <li><a [routerLink]="['/phan-tich-khu-vuc/quan-3-3']">Quận 3</a></li>
                        </ul>
                    </div>
                    <div class="col-6 col-sm-3 px-0 footer-sub-link">
                        <h5>Tìm hiểu thêm</h5>
                        <ul>
                            <li><a href="https://cenhomes.vn">Cenhomes</a></li>
                            <li><a href="https://cenhomes.vn/danh-sach-du-an-bat-dong-san">Dự án bất động sản</a></li>
                            <li><a href="https://cenhomes.vn/mua-nha">Nhà bán</a></li>
                            <li><a href="https://cenhomes.vn/thue-nha">Nhà cho thuê</a></li>
                        </ul>
                    </div>
                    <div class="col-6 col-sm-3 px-0 footer-sub-link">
                        <h5>Liên kết</h5>
                        <ul>
                            <li><a href="http://thamdinhgiatheky.vn/">Cenvalue</a></li>
                            <li><a href="https://gianhadat.cenhomes.vn/yeu-cau-tham-dinh">Thẩm định giá</a></li>
                            <li><a href="http://thamdinhgiatheky.vn/cen-value/44/quy-trinh-tham-dinh-gia">Quy trình thẩm
                                    định</a></li>
                            <li><a [routerLink]="['/chinh-sach-bao-mat']">Chính sách bảo mật thông tin</a></li>
                        </ul>
                    </div>
                    <div class="col-6 col-sm-3 px-0 footer-sub-link">
                        <h5>Tin tức</h5>
                        <ul>
                            <li><a href="https://cenhomes.vn/tin-tuc/tin-tuc-cenhomes" target="_blank">Tin tức
                                    Cenhomes</a></li>
                            <li><a href="https://cenhomes.vn/tin-tuc/kien-truc-nha-dep" target="_blank">Kiến trúc - Nhà
                                    đẹp</a></li>
                            <li><a href="https://cenhomes.vn/tin-tuc/noi-ngoai-that" target="_blank">Nội ngoại thất</a>
                            </li>
                            <li><a href="https://cenhomes.vn/tin-tuc/phong-thuy" target="_blank">Phong thủy</a></li>
                        </ul>
                    </div>
                </div>
                <div class="row line-top">
                    <div class="col-12 col-sm-6 pl-2 pl-sm-0">
                        Copyright © 2018 - 2020 by gianhadat.cenhomes.vn
                    </div>
                    <div class="col-12 col-sm-6 pr-0 d-none d-sm-block text-right">
                        <ul class="social-icon">
                            <li><a href="#"><span appIconW="20" appIconH="20" appIcon="fa_bold_facebook"></span></a>
                            </li>
                            <li><a href="#"><span appIconW="20" appIconH="20"
                                        appIcon="fa_bold_facebook_messenger"></span></a></li>
                            <li><a href="#"><span appIconW="20" appIconH="20" appIcon="fa_bold_youtube"></span></a></li>
                            <li><a href="#"><span appIconW="20" appIconH="20" appIcon="fa_bold_twitter"></span></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->