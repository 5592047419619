import { take, delay, tap } from 'rxjs/operators';
import { AddressHttpService } from '../http/address-http.service';
import { Observable, forkJoin, Subscriber, of, interval, BehaviorSubject } from 'rxjs';
import { ajax } from 'rxjs/ajax';

export class DiaChi {
  id: number;
  phuongId?: number;
  quanId?: number;
  tinhId?: number;
  ten?: string;
  tenDayDu?: string;
  tenKhongDau?: string;
  tienTo?: string;
  updateFromSourceEs(source: any): void {
    this.id = source.id;
    this.ten = source.ten;
    this.tienTo = source.tien_to;
  }
}

class AddressItem<T> {
  list: T[];
  choose: T;
  constructor() {
    this.list = [];
    this.choose = null;
  }
}

export class Address2 {
  tinh: AddressItem<DiaChi>;
  quan: AddressItem<DiaChi>;
  duong: AddressItem<DiaChi>;
  phuong: AddressItem<DiaChi>;
  duan: AddressItem<DiaChi>;
  thonxom: AddressItem<DiaChi>;
  ngo: AddressItem<string>;
  ngachhem: AddressItem<string>;
  initFinish: BehaviorSubject<boolean>;
  onChangeAddress: Observable<string>;
  private callBack: Subscriber<string>;
  constructor(
    private addressHttpService: AddressHttpService,
    private tinhId: number,
    private quanId: number,
    private duongId: number,
    private phuongId: number,
    private thonXomId: number = null,
    private duanId: number = null,
    private ngoStr: string = null,
    private ngachStr: string = null,
    public soNha: string = null
  ) {
    this.initFinish = new BehaviorSubject(false);
    this.init(tinhId, quanId, duongId, phuongId, thonXomId, duanId, ngoStr, ngachStr, soNha);
    const that = this;
    this.onChangeAddress = new Observable(subcriber => {
      that.callBack = subcriber;
    });
  }

  init(
    tinhId: number,
    quanId: number,
    duongId: number,
    phuongId: number,
    thonXomId: number = null,
    duanId: number = null,
    ngoStr: string = null,
    ngachStr: string = null,
    soNha: string = null
  ): any {
    // init dia chi
    const params: any = arguments;

    const addressStructure = [
      { type: 'tinh', id: tinhId, idPre: null },
      { type: 'quan', id: quanId, idPre: tinhId },
      { type: 'duong', id: duongId, idPre: quanId },
      { type: 'phuong', id: phuongId, idPre: quanId },
      { type: 'thonxom', id: thonXomId, idPre: phuongId },
      { type: 'duan', id: duanId, idPre: quanId },
      { type: 'ngo', id: ngoStr, idPre: duongId },
      { type: 'ngachhem', id: ngachStr, idPre: duongId },
      // {type: 'duan', idPre: duanId},
    ];

    this.soNha = soNha;

    const listObservable = [];

    addressStructure.forEach(item => {
      this[item.type] = {
        list: [],
        choose: null
      };
      // requestParams['ver2'] = 'true';
      if (item.id) {
        const requestParams = {};
        if (item.type) { requestParams['type'] = item.type }
        if (item.idPre) { requestParams['idPre'] = item.idPre }
        listObservable.push(new Promise((resolve, reject) => {
          this.addressHttpService.queryAddress<DiaChi>(requestParams).pipe(take(1)).subscribe(res => {
            resolve(res);
          });
        }));
      }
    });
    Promise.all(listObservable).then(values => {
      addressStructure.forEach((item, index) => {
        if (item.id) {
          if (item.type === 'ngo' || item.type === 'ngachhem') {
            this[item.type].list = values[index];
            this[item.type].choose = String(item.id);
          } else {
            this[item.type].list = values[index];
            this[item.type].choose = this[item.type].list.find(element => element.id === item.id) || { id: params[index] };
          }
        }
      });
      // console.log(this);
      this.initFinish.next(true);
    });

  }

  getTitle(): string {
    let tieuDe = '';
    if (this.soNha) {
      tieuDe += 'Số nhà ' + this.soNha + ', ';
    }
    if (this.ngo.choose) {
      tieuDe += 'ngõ ' + this.ngo.choose + ', ';
    }
    if (this.ngachhem.choose) {
      tieuDe += 'ngách ' + this.ngachhem.choose + ', ';
    }
    ['duong', 'phuong', 'thonxom', 'quan', 'tinh'].forEach(item => {
      if (this[item].choose) {
        tieuDe += '' + (this[item].choose.tienTo || '') + ' ' + (this[item].choose.ten && this[item].choose.ten + (item === 'tinh' ? '' : ', ') || '');
      }
    });
    console.log('tieuDe1', this.soNha,)
    return tieuDe;
  }
}
