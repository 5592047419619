// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  version: 'product:1.8.48',
  production: false,
  ssoType: 'cenhomesSSO',
  cenhomesSSO: {
    issuer: `https://id.cenhomes.vn`,
    clientId: `gianhadat`,
    tokenEndpoint: `https://id.cenhomes.vn/connect/token`,
    mapUser: `https://gapi.cenhomes.vn/g-sso-gateway/v3/api/oauth/map-user`,
    account: `https://gapi.cenhomes.vn/g-sso-gateway/v3/auth/account`,
    redirectUri: `https://gianhadat.cenhomes.vn/index.html`,
    logoutRedirectUri: `https://gianhadat.cenhomes.vn/logout.html`,
    loginLink: `https://id.cenhomes.vn/account/login`,
    logoutLink: `https://id.cenhomes.vn/connect/endsession`,
    refreshToken: `https://gapi.cenhomes.vn/g-auth/v3/api/oauth/refresh-token`,
    scope: `openid offline_access profile web`
  },
  buildFunctions: {
    tienIch: true,
    heatmap: true,
    phanTichGia: true,
    uocLuongGiaGuiEmail: true,
    uocLuongGiaGiaNhaNuoc: true,
    uocLuongGiaBaoGiaSai: true,
    thamDinhGiaCenValue: true,
    chiTietBienDongTinRao: true,
    chiTietTienIchXungQuanh: true,
    landing: true,
    banDo: true,
    dinhGia: true,
    lienHe: true,
    userActivity: true,
    favouriteBDS: true,
    chinhSachBaoMat: true,
    huongDanSuDung: true,
    huongDanSuDungThanhToan: true,
    userProfile: true,
    thongTinMoiGioi: true,
    thongTinNguoiDung: true,
    admin: true,
    jobs: true,
    jobDetail: true,
    indexRouter: '/index.html'
  },
  base: 'https://gianhadat.cenhomes.vn',
  sso: {
    realms: 'gianhadat',
    cookieDomain: '.cenhomes.vn',
    ssoLoginDomain: 'https://id.cenhomes.vn/',
    ssoHelperLink: 'https://gianhadat.cenhomes.vn/assets/sso.html'
  },
  API: {
    KONG_SERVER: 'https://gapi.cenhomes.vn',
    SERVER_API_GEOSERVER: 'https://gapi.cenhomes.vn/geoserver/dcs/wms?',
    SERVER_API_CENHOMES_PROJECT: 'https://app.cenhomes.vn/project?apikey=1c17999fb4d3c3b86d6e104a710f1dd8&',
    SERVER_IMG: 'https://d13dxp8d6go1v2.cloudfront.net',
    SERVER_GOOGLE_CACHE: 'https://gapi.cenhomes.vn/g-google-cache/v1',
    SERVER_API_DATA_COLLECTION: 'https://gapi.cenhomes.vn/g-data-collection/v1',
    SERVER_API_G_AUTH: 'https://gapi.cenhomes.vn/g-sso-gateway/v3',
    SERVER_API_THAM_DINH: 'https://gapi.cenhomes.vn/g-evaluation/v1/api/v1/',
    SERVICE_ADDRESS_NORMALIZE: 'https://gapi.cenhomes.vn/g-address-normalize',
    SERVER_SEARCH: 'https://gapi.cenhomes.vn/c-search/',
    SERVER_ADDRESS: 'https://gapi.cenhomes.vn/c-address/v1/',
    SERVER_ESTIMATE: 'https://gapi.cenhomes.vn/g-data-estimate/v1',
    SERVER_ANALYST: 'https://gapi.cenhomes.vn/g-data-statistic/v2',
    SERVER_SCORE: 'https://gapi.cenhomes.vn/c-utility-scoring/v1',
    HTTP_GET_ALL_WITH_TINH: 'https://gapi.cenhomes.vn/g-data-collection/v1/api/static-info/get-all-with-tinh',
    SERVER_SSO_BUSSINESS: 'https://gapi.cenhomes.vn/g-sso-gateway/v3',
    SERVER_G_AUTH_BUSSINESS: 'https://gapi.cenhomes.vn/g-auth/v3',
    GET_MANY_INFO_SAME_LAND: 'https://gapi.cenhomes.vn/g-data-collection/v1/api/dia-chi-cu-the/get-many-info-same-land',
    SERVER_GRAPHQL_VER_2: `https://gapi.cenhomes.vn/app-dcs-graphql/v2`,
    SERVER_GUI_YEU_CAU_THAM_DINH: `https://gapi.cenhomes.vn/g-auth/v2/api/chi-nhanhs/get-all`,
    SERVER_COMMON_DETAIL: `https://gapi.cenhomes.vn/gianhadat-api-gateway/v1/api/detail`,
    // Thanh toan
    SERVER_THANH_TOAN_BASE_URL: 'https://gapi.cenhomes.vn/payment-system/v1',
    SERVER_THANH_TOAN_MY_PLAN: `https://gapi.cenhomes.vn/payment-system/v1/api/plan/my-plan`,
    SERVER_THANH_TOAN_CREATE_PLAN: `https://gapi.cenhomes.vn/payment-system/v1/api/plan/create-plan`,
    SERVER_THANH_TOAN_DELETE_PLAN: `https://gapi.cenhomes.vn/payment-system/v1/api/plan/delete-plan`,
    SERVER_THANH_TOAN_MY_BALANCE: `https://gapi.cenhomes.vn/payment-system/v1/account/my-balance`,
    SERVER_THANH_TOAN_BASE_PRICE: `https://gapi.cenhomes.vn/payment-system/v1/production/base-price`,
    SERVER_THANH_TOAN_PRODUCTION_LIST: `https://gapi.cenhomes.vn/payment-system/v1/production/production-list`,
    SERVER_THANH_TOAN_ALL_COMBO: `https://gapi.cenhomes.vn/payment-system/v1/production/all-combo`,
    SERVER_THANH_TOAN_EXECUTE_CENPOINT: `https://gapi.cenhomes.vn/payment-system/v1/payment/common/cen-point/execute-v2`,
    SERVER_THANH_TOAN_ONE_TOUCH_CENPOINT: `https://gapi.cenhomes.vn/payment-system/v1/payment/common/cen-point/one-touch`,
    SERVER_THANH_TOAN_ONE_TOUCH_VNPAY: `https://gapi.cenhomes.vn/payment-system/v1/payment/vnp/pay/one-touch`,
    SERVER_THANH_TOAN_ONE_TOUCH_ONEPAY: `https://gapi.cenhomes.vn/payment-system/v1/payment/vpc/pay/one-touch`,
    SERVER_THANH_TOAN_EXECUTE_VNPAY: `https://gapi.cenhomes.vn/payment-system/v1/payment/vnp/pay/execute-v2`,
    SERVER_THANH_TOAN_EXECUTE_ONEPAY: `https://gapi.cenhomes.vn/payment-system/v1/payment/vpc/pay/execute-v2`,
    SERVER_THANH_TOAN_MY_COUPON: `https://gapi.cenhomes.vn/payment-system/v1/coupon/my-coupon`,
    SERVER_THANH_TOAN_PRIVATE_COUPON: `https://gapi.cenhomes.vn/payment-system/v1/coupon/private-coupon`,
    SERVER_THANH_TOAN_CHECK_LOOKUP_CONDITION: `https://gapi.cenhomes.vn/payment-system/v1/account/check-lookup-condition`,
    SERVER_THANH_TOAN_ADD_COUPON: `https://gapi.cenhomes.vn/payment-system/v1/subscription/add-coupon`,
    SERVER_THANH_TOAN_TEMP_PRICE: `https://gapi.cenhomes.vn/payment-system/v1/subscription/temp-price`,
    SERVER_THANH_TOAN_MY_SUBSCRIPTIONS: `https://gapi.cenhomes.vn/payment-system/v1/subscription/my-subscriptions`,
    SERVER_THANH_TOAN_MY_RECENTLY_PURCHASED_COMBO: `https://gapi.cenhomes.vn/payment-system/v1/subscription/recently-purchased-combo`,
    SERVER_THANH_TOAN_VALIDATE_PAYMENT: `https://gapi.cenhomes.vn/payment-system/v1/payment/vnp/validate-payment`,
    SERVER_THANH_TOAN_VALIDATE_PAYMENT_ONEPAY: `https://gapi.cenhomes.vn/payment-system/v1/payment/vpc/pay/validate-payment`,
    SERVER_THANH_TOAN_MY_TRANSACTION: `https://gapi.cenhomes.vn/payment-system/v1/transaction/my-transaction`,
    SERVER_THANH_TOAN_MY_COIN: `https://gapi.cenhomes.vn/payment-system/v1/account/my-coin`,
    SERVER_THANH_TOAN_CLAIM_LIST: `https://gapi.cenhomes.vn/payment-system/v1/claim/list`,
    SERVER_THANH_TOAN_CLAIM_DETAIL: `https://gapi.cenhomes.vn/payment-system/v1/claim/`,
    SERVER_THANH_TOAN_CLAIM_CREATE: `https://gapi.cenhomes.vn/payment-system/v1/claim/create`,
    SERVER_THANH_TOAN_CLAIM_RESPONSE: `https://gapi.cenhomes.vn/payment-system/v1/claim/respond-claim`,
    SERVER_RETURN_URL_VNPAY: `https://gianhadat.cenhomes.vn/thanh-toan`,
    SERVER_WALLET: `https://cenpay.cenhomes.vn/recharge`,
  }
};

/*
  * For easier debugging in development mode, you can import the following file
  * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
  *
  * This import should be commented out in production mode because it will have a negative impact
  * on performance if an error is thrown.
  */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
