import { LandingComponent } from './layouts/landing/landing.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PreloadAllModules } from '@angular/router';
import { LoginGuard } from './shared';
import { environment } from 'src/environments/environment';
import { UserRouteAccessService, RouteAccessService } from './core';

const routes: Routes = [
  // environment.buildFunctions.landing ? { path: 'landing', loadChildren: () => import('./layouts/landing/landing.module').then(m => m.LandingModule) } : { path: 'landing', pathMatch: 'full', redirectTo: '' },
  // environment.buildFunctions.banDo ? { path: 'ban-do', loadChildren: () => import('./layouts/ban-do/ban-do.module').then(m => m.BanDoModule) } : { path: 'ban-do', pathMatch: 'full', redirectTo: '' },
  environment.buildFunctions.dinhGia ? { path: 'dinh-gia', loadChildren: () => import('./layouts/dinh-gia/dinh-gia.module').then(m => m.DinhGiaModule) } : { path: 'dinh-gia', pathMatch: 'full', redirectTo: '' },
  environment.buildFunctions.phanTichGia ? { path: 'phan-tich-du-an', loadChildren: () => import('./layouts/phan-tich-du-an/phan-tich-du-an.module').then(m => m.PhanTichDuAnModule) } : { path: 'phan-tich-du-an', pathMatch: 'full', redirectTo: '' },
  environment.buildFunctions.phanTichGia ? { path: 'phan-tich-khu-vuc', loadChildren: () => import('./layouts/phan-tich-khu-vuc/phan-tich-khu-vuc.module').then(m => m.PhanTichKhuVucModule) } : { path: 'phan-tich-khu-vuc', pathMatch: 'full', redirectTo: '' },
  environment.buildFunctions.phanTichGia ? { path: 'phan-tich-khu-vuc-nang-cao', loadChildren: () => import('./layouts/phan-tich-tong-quan-khu-vuc-nang-cao/phan-tich-tong-quan.module').then(m => m.PhanTichTongQuanModule) } : { path: 'phan-tich-khu-vuc-nang-cao', pathMatch: 'full', redirectTo: '' },
  environment.buildFunctions.thamDinhGiaCenValue ? { path: 'yeu-cau-tham-dinh', loadChildren: () => import('./layouts/appraisal-request/appraisal-request.module').then(m => m.AppraisalRequestModule) } : { path: 'yeu-cau-tham-dinh', pathMatch: 'full', redirectTo: '' },
  environment.buildFunctions.lienHe ? { path: 'lien-he', loadChildren: () => import('./layouts/contact/contact.module').then(m => m.ContactModule) } : { path: 'lien-he', pathMatch: 'full', redirectTo: '' },
  environment.buildFunctions.huongDanSuDung ? { path: 'huong-dan-su-dung', loadChildren: () => import('./layouts/huong-dan-su-dung/huong-dan-su-dung.module').then(m => m.GuildModule) } : { path: 'huong-dan-su-dung', pathMatch: 'full', redirectTo: '' },
  environment.buildFunctions.huongDanSuDungThanhToan ? { path: 'huong-dan-su-dung-thanh-toan', loadChildren: () => import('./layouts/huong-dan-su-dung-thanh-toan/huong-dan-su-dung-thanh-toan.module').then(m => m.HuongDanSuDungThanhToanModule) } : { path: 'huong-dan-su-dung-thanh-toan', pathMatch: 'full', redirectTo: '' },
  environment.buildFunctions.favouriteBDS ? { path: 'favourite-BDS', loadChildren: './layouts/favourite-BDS/favourite-BDS.module#FavouriteBDSModule' } : { path: 'favourite-BDS', pathMatch: 'full', redirectTo: '' },
  environment.buildFunctions.userProfile ? { path: 'user-profile', loadChildren: () => import('./layouts/user-profile/user-profile.module').then(m => m.UserprofileModule) } : { path: 'user-profile', pathMatch: 'full', redirectTo: '' },
  environment.buildFunctions.userActivity ? { path: 'user-activity', loadChildren: () => import('./layouts/user-activity/user-activity.module').then(m => m.UserActivityModules) } : { path: 'user-activity', pathMatch: 'full', redirectTo: '' },
  environment.buildFunctions.chinhSachBaoMat ? { path: 'privacy-policy', loadChildren: () => import('./layouts/chinh-sach-bao-mat/chinh-sach-bao-mat.module').then(m => m.SecureModule) } : { path: 'chinh-sach-bao-mat', pathMatch: 'full', redirectTo: '' },
  environment.buildFunctions.thongTinMoiGioi ? { path: 'thong-tin-moi-gioi', loadChildren: () => import('./layouts/thong-tin-moi-gioi/thong-tin-moi-gioi.module').then(m => m.ThongTinMoiGioiModules) } : { path: 'thong-tin-nguoi-dung', pathMatch: 'full', redirectTo: '' },
  environment.buildFunctions.thongTinNguoiDung ? { path: 'thong-tin-nguoi-dung', loadChildren: () => import('./layouts/thong-tin-nguoi-dung/thong-tin- nguoi-dung.module').then(m => m.ThongTinNguoiDungModule) } : { path: 'thong-tin-nguoi-dung', pathMatch: 'full', redirectTo: '' },
  { path: 'giay-phep-kinh-doanh', loadChildren: () => import('./layouts/giay-phep-kinh-doanh/giay-phep-kinh-doanh.module').then(m => m.GiayPhepKinhDoanhModule) },
  
  environment.buildFunctions.phanTichGia ? { path: 'huydangky', loadChildren: () => import('./layouts/landing/huy-bao-cao/huy-bao-cao.module').then(m => m.HuyBaoCaoModule) } : { path: 'huydangky', pathMatch: 'full', redirectTo: '' },
  environment.buildFunctions.admin ? { path: 'admin', loadChildren: () => import('./layouts/admin/admin.module').then(m => m.AdminModule) } : { path: 'admin', pathMatch: 'full', redirectTo: '' },
  { path: '', redirectTo: environment.buildFunctions.indexRouter, pathMatch: 'full' },
  { path: 'logout.html', loadChildren: () => import('./layouts/logout/logout.module').then(m => m.LogoutModule) },
  { path: 'index.html', loadChildren: () => import('./layouts/landing/landing.module').then(m => m.LandingModule) },
  { path: 'ban-do', loadChildren: () => import('./layouts/map-two/map-two.module').then(m => m.MapTwoModule)},
  environment.buildFunctions.jobs ? { path: 'jobs', loadChildren: () => import('./layouts/jobs/jobs.module').then(m => m.JobsModule) } : { path: 'jobs', pathMatch: 'full', redirectTo: '' },
  environment.buildFunctions.jobs ? { path: 'job-detail', loadChildren: () => import('./layouts/jobs/job-detail/job-detail.module').then(m => m.JobDetailModule) } : { path: 'job-detail', pathMatch: 'full', redirectTo: '' },
  { path: 'cong-cu', loadChildren: () => import('./layouts/tools/tools.module').then(m => m.ToolsModule) },
  { path: 'thanh-toan', loadChildren: () => import('./layouts/thanh-toan/thanh-toan.module').then(m => m.ThanhToanModule) },
  { path: 'quan-ly-thanh-toan', loadChildren: () => import('./layouts/quan-ly-thanh-toan/quan-ly-thanh-toan.module').then(m => m.QuanLyThanhToanModule), canActivate: [RouteAccessService], data: {role: 'ROLE_ADMIN'} },
  { path: 'doi-soat-giao-dich', loadChildren: () => import('./layouts/doi-soat-thanh-toan/doi-soat-thanh-toan.module').then(m => m.DoiSoatThanhToanModule), canActivate: [RouteAccessService], data: {role: 'ROLE_ADMIN'}}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    // preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
