import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { RoadWidthValidator, LandTypeValidator, SoTangValidator, EmailValidator, MatTienValidator, DienTichSuDungValidator } from './validator.const';

export function formEstimateCreate(fb: FormBuilder): FormGroup {
    return fb.group({
        chatLuongConLai: fb.control(100),
        coNhieuLoaiDat: fb.control(false),
        namXayDung: fb.control(new Date().getFullYear()),
        dongYNhanEmail: fb.control(false),
        email: fb.control(''),
        idBatDongSanNhapLieu: fb.control(''),
        loaiNhaId: fb.control(''),
        ngachHem: fb.control(''),
        ngo: fb.control(''),
        soNha: fb.control('', [Validators.required]),
        soTang: fb.control(''),
  
        dienTichDatCLN: fb.control(null),
        dienTichDatHNK: fb.control(null),
        dienTichDatODT: fb.control(null),
        dienTichDatSKC: fb.control(null),
        dienTichDatTMD: fb.control(null),
        dienTichDatVLK: fb.control(null),
        /* Set bởi patchThongTinChung() */
        idThuaDat: fb.control(''),
        dienTich: fb.control(''),
        dienTichSuDung: fb.control(''),
        chieuRongMatTien: fb.control('', [Validators.required, Validators.min(0.1), Validators.max(999)]),
        lat: fb.control('', [Validators.required]),
        lon: fb.control('', [Validators.required]),
        hinhDangLoDatId: fb.control('', [Validators.required]),
        huongDatId: fb.control('', [Validators.required]),
        /* Set bởi patchThongTinMatTien() */
        soMatTien: fb.control('', [Validators.required]),
        chieuRongMatDuong: fb.control('', [Validators.required, Validators.min(0.1)]),
        chieuRongMatDuongBa: fb.control(''),
        chieuRongMatDuongHai: fb.control('', [Validators.required]),
        viTriId: fb.control(''),
        viTriIdBa: fb.control(''),
        viTriIdHai: fb.control(''),
        /* Set bởi patchThongTinHanhChinh() */
        tinhId: fb.control('', [Validators.required]),
        quanHuyenId: fb.control('',[Validators.required]),
        phuongXaId: fb.control('',[Validators.required]),
        duongId: fb.control('',[Validators.required]),
        tinh: fb.control(''),
        quanHuyen: fb.control(''),
        phuongXa: fb.control(''),
        duong: fb.control(''),
      },
      {
        validators: [
          // RoadWidthValidator('chieuRongMatDuong', 'viTriId'),
          // RoadWidthValidator('chieuRongMatDuongHai', 'viTriIdHai'),
          // RoadWidthValidator('chieuRongMatDuongBa', 'viTriIdBa'),
          LandTypeValidator(),
          // SoTangValidator(),
          DienTichSuDungValidator(),
          EmailValidator(),
          MatTienValidator()
        ]
    });
}

export const formEstimateReset = {
    chatLuongConLai: 100,
    coNhieuLoaiDat: null,
    namXayDung: new Date().getFullYear(),
    dongYNhanEmail: null,
    email: '',
    idBatDongSanNhapLieu: null,
    loaiNhaId: null,
    ngachHem: null,
    ngo: null,
    soNha: null,
    soTang: null,

    dienTichDatCLN: null,
    dienTichDatHNK: null,
    dienTichDatODT: null,
    dienTichDatSKC: null,
    dienTichDatTMD: null,
    dienTichDatVLK: null,
    /* Set bởi patchThongTinChung() */
    idThuaDat: null,
    dienTich: null,
    dienTichSuDung: null,
    chieuRongMatTien: null,
    lat: null,
    lon: null,
    hinhDangLoDatId: null,
    huongDatId: null,
    /* Set bởi patchThongTinMatTien() */
    soMatTien: null,
    chieuRongMatDuong: null,
    chieuRongMatDuongBa: null,
    chieuRongMatDuongHai: null,
    viTriId: null,
    viTriIdBa: null,
    viTriIdHai: null,
    /* Set bởi patchThongTinHanhChinh() */
    tinhId: null,
    quanHuyenId: null,
    phuongXaId: null,
    duongId: null,
    tinh: null,
    quanHuyen: null,
    phuongXa: null,
    duong: null,
};