import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavbarMobileService {
  public show: boolean;
  constructor() {
    this.show = false ;
  }
}
