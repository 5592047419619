<div class="d-flex">
    <div class="navbar-link-padding" *ngIf="environment.buildFunctions.thamDinhGiaCenValue">
        <div (click)="thamDinhGiaService.openPopupYeuCauThamDinhGia= true" class="navbar-link">Thẩm định giá Cenvalue
        </div>
    </div>
    <div class="navbar-link-padding">
        <div [routerLink]="['/ban-do']" class="navbar-link">Xem giá</div>
    </div>
    <div class="navbar-link-padding" *ngIf="environment.buildFunctions.phanTichGia">
        <app-dropdown-menu>
            <ng-template app-dropdown-menu-label>
                <div class="navbar-link d-flex items-center">
                    <div>Xem phân tích</div>
                    <div class="ml-1">
                        <svg width="12px" height="12px" viewBox="0 0 16 16" class="bi bi-caret-down-fill"
                            fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                        </svg>
                    </div>
                </div>
            </ng-template>
            <ng-template app-dropdown-menu-items>
                <div class="menu">
                    <h3 class="title text-[1.2rem] mb-2">Xem phân tích</h3>
                    <a class="menu-item d-flex flex-row" app-dropdown-menu-click-close
                        [routerLink]="['/phan-tich-khu-vuc']">
                        <div class="icon">
                            <svg width="20px" height="20px" viewBox="0 0 16 16" class="bi bi-bar-chart-fill"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <rect width="4" height="5" x="1" y="10" rx="1" />
                                <rect width="4" height="9" x="6" y="6" rx="1" />
                                <rect width="4" height="14" x="11" y="1" rx="1" />
                            </svg>
                        </div>
                        <div class="d-flex flex-column">
                            <div>Phân tích khu vực</div>
                            <div class="description">Xem phân tích giá của một khu vực</div>
                        </div>
                    </a>
                    <a class="menu-item d-flex flex-row" app-dropdown-menu-click-close
                        [routerLink]="['/phan-tich-du-an']">
                        <div class="icon">
                            <svg width="20px" height="20px" viewBox="0 0 16 16" class="bi bi-pie-chart-fill"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M15.985 8.5H8.207l-5.5 5.5a8 8 0 0 0 13.277-5.5zM2 13.292A8 8 0 0 1 7.5.015v7.778l-5.5 5.5zM8.5.015V7.5h7.485A8.001 8.001 0 0 0 8.5.015z" />
                            </svg>
                        </div>
                        <div class="d-flex flex-column">
                            <div class="item">Phân tích dự án</div>
                            <div class="description">Xem phân tích giá của một dự án</div>
                        </div>
                    </a>
                    <hr />
                    <a class="menu-item d-flex flex-row" app-dropdown-menu-click-close>
                        <div class="icon">
                            <svg width="20px" height="20px" viewBox="0 0 16 16" class="bi bi-bell-fill"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
                            </svg>
                        </div>
                        <div (click)="dangKyNhanBaoCao()" class="d-flex flex-column">
                            <div class="item">Đăng ký nhận báo cáo</div>
                            <div class="description">Đăng ký nhận báo cáo thị trường BĐS</div>
                        </div>
                    </a>
                </div>
            </ng-template>
        </app-dropdown-menu>
    </div>
    <div class="navbar-link-padding">
        <div [routerLink]="['/huong-dan-su-dung-thanh-toan']" class="navbar-link">Hướng dẫn</div>
    </div>
</div>


