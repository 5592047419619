import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appPreventEnterNegativeNumber]',
})
export class PreventEnterNegativeNumberDirective {

  constructor(private control : NgControl) { }

  @HostListener('input',['$event']) onEvent(event){
    if (event.target.value && +event.target.value <= 0) {
      this.control.control.setValue(1);
    }

  }

}
