import { EstimateHttpService } from './../../shared/http/estimate-http.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AccountService {
    userIdentity: any;
    private authenticated: boolean = false;
    public authenticationState = new Subject<any>();

    constructor(
        private http: HttpClient,
        private estimateHttpService: EstimateHttpService,
        ) {
    }

    fetch(): Observable<HttpResponse<Account>> {
        return this.http.get<Account>(environment.API.SERVER_API_G_AUTH + '/auth/account', { observe: 'response' });
    }

    save(account: any): Observable<HttpResponse<any>> {
        return this.http.post(environment.API.SERVER_API_G_AUTH + '/auth/account', account, { observe: 'response' });
    }

    authenticate(identity) {
        this.userIdentity = identity;
        this.authenticated = identity !== null;
        this.authenticationState.next(this.userIdentity);
    }

    hasAnyAuthority(authorities: string[]): boolean {
        if (!this.authenticated || !this.userIdentity || !this.userIdentity.authorities) {
            return false;
        }

        for (let i = 0; i < authorities.length; i++) {
            if (this.userIdentity.authorities.includes(authorities[i])) {
                return true;
            }
        }

        return false;
    }

    hasAuthority(authority: string): Promise<boolean> {
        if (!this.authenticated) {
            return Promise.resolve(false);
        }

        return this.identity().then(
            id => {
                return Promise.resolve(id.authorities && id.authorities.includes(authority));
            },
            () => {
                // console.log('Is authenticated');
                return Promise.resolve(false);
            }
        );
    }

    identity(force?: boolean): Promise<any> {
        // debugger;
        if (force) {
            this.userIdentity = undefined;
        }

        // check and see if we have retrieved the userIdentity data from the server.
        // if we have, reuse it by immediately resolving
        if (this.userIdentity) {
            return Promise.resolve(this.userIdentity);
        }

        // retrieve the userIdentity data from the server, update the identity object, and then resolve.
        return this.fetch()
            .toPromise()
            .then(response => {
                const account = response.body;
                if (account) {
                    this.userIdentity = account;
                    this.authenticated = true;

                } else {
                    this.userIdentity = null;
                    this.authenticated = false;
                }
                this.authenticationState.next(this.userIdentity);
                return this.userIdentity;
            })
            .catch(err => {
                this.userIdentity = null;
                this.authenticated = false;
                this.authenticationState.next(this.userIdentity);
                return null;
            });
    }

    setIdentity(response: any, force?: boolean): any {
        if (force) {
            this.userIdentity = undefined;
        }

        // check and see if we have retrieved the userIdentity data from the server.
        // if we have, reuse it by immediately resolving
        if (this.userIdentity) {
            return Promise.resolve(this.userIdentity);
        }
        // console.log(response);
        const account = response;
        if (account) {
            this.userIdentity = account;
            this.authenticated = true;

        } else {
            this.userIdentity = null;
            this.authenticated = false;
        }
        this.authenticationState.next(this.userIdentity);
        return Promise.resolve(this.userIdentity);
    }

    isAuthenticated(): boolean {
        return this.authenticated;
    }

    isIdentityResolved(): boolean {
        return this.userIdentity !== undefined;
    }

    getAuthenticationState(): Observable<any> {
        return this.authenticationState.asObservable();
    }
    getUserIdentity() {
        return this.userIdentity;
    }

    getImageUrl(): string {
        return null;
        return this.isIdentityResolved() ? this.userIdentity.imageUrl : null;
    }
}
