import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TiemNangTheoNhaDauTuService } from 'src/app/layouts/phan-tich-khu-vuc/phan-tich-nang-cao/block/tiem-nang-theo-nha-dau-tu/tiem-nang-theo-nha-dau-tu.service';
import { CommonService } from 'src/app/layouts/phan-tich-khu-vuc/phan-tich-nang-cao/common.service';

@Component({
  selector: 'app-tiem-nang-theo-nha-dau-tu',
  templateUrl: './tiem-nang-theo-nha-dau-tu.component.html',
  styleUrls: ['./tiem-nang-theo-nha-dau-tu.component.scss']
})
export class TiemNangTheoNhaDauTuComponent implements OnInit {

  isOpen = false;
  @Input() criteriaList;
  @Input() loading: boolean;
  @Input() type: string; // check khach hang xem tong quan hay la khu vuc tiem nang, neu xem-tong-quan thi tat button xoa + noti email
  @Input() selectedId;
  // @Output() getDetailCriteriaEvent = new EventEmitter();
  @Output() selectItemEvent = new EventEmitter();
  @Output() xemChiTietEvent = new EventEmitter();
  @Output() removeCriteriaEvent = new EventEmitter();
  @Output() toggleNotiEmailEvent = new EventEmitter();
  constructor(
    public tiemNangTheoNhaDauTuService: TiemNangTheoNhaDauTuService,
    public commonService: CommonService,
    ) { 
    }

  ngOnInit(): void {}

  allowNotiEmail(e, id: string) {
    this.toggleNotiEmailEvent.emit({isEnable: e.target.checked, id});
    
  }
  selectItemToDrawChart(e, isTongQuan) {
    this.selectItemEvent.emit({...e, isTongQuan});
  }

  xemChiTiet(event, item) {
    event.stopPropagation();
    this.selectItemEvent.emit({...item, isTongQuan: false});
  }
}
