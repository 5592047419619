<div class="modal-header">
    <h5 class="header-title mb-0">Truy cập bị giới hạn</h5>
    <a (click)="close()" class="header-close">
        <span appIconW="45" appIconH="45" appIcon="fa_bold_time"></span>
    </a>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-12">
            <span>Bạn tạm thời bị giới hạn truy cập do thực hiện quá nhiều yêu cầu trong thời gian ngắn. Hãy chứng tỏ
                bạn không phải là robot.</span>
            <re-captcha (resolved)="resolved($event)" siteKey="6LeB0bcUAAAAABSKoSEZU17Z34DtfnHr_e7wqdYA"></re-captcha>
        </div>
    </div>
</div>
