import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { AppShareModule } from './../../app-share.modules';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchSuggestComponent } from './search-suggest.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchSuggestSmallComponent } from './search-suggest-small/search-suggest-small.component';
import { SearchSuggestMobileComponent } from './search-suggest-mobile/search-suggest-mobile.component';
import { SearchSuggestSmallNewComponent } from './search-suggest-small-new/search-suggest-small-new.component';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AppShareModule,
    NzInputModule,
    NzIconModule,
    NzAutocompleteModule
  ],
  declarations: [
    SearchSuggestComponent,
    SearchSuggestSmallComponent,
    SearchSuggestMobileComponent,
    SearchSuggestSmallNewComponent
  ],
  exports: [
    SearchSuggestComponent,
    SearchSuggestSmallComponent,
    SearchSuggestMobileComponent,
    SearchSuggestSmallNewComponent
  ]
})
export class SearchSuggestModule { }
