import { InterceptorService } from './../../../blocks/interceptor/interceptor.service';
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { WindowRef, ISnowplowWindow } from './window-ref';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import * as Fingerprint2 from 'fingerprintjs2';
import { AccountService, LoginModalService, LoginService } from 'src/app/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { SnowPlowTrackerType } from 'src/app/shared/util/interface';
import { SeoService } from 'src/app/shared/util/seo.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SnowplowService {

  public finger: string;
  private _window: ISnowplowWindow;
  private userid: number;
  private username: string;
  private fullName: string;
  public history: any = [];
  public lastTwoHistory: any = [];

  constructor(
    window: WindowRef,
    private router: Router,
    private accountService: AccountService,
    private loginService: LoginService,
    private seoService: SeoService,
    private cookieService: CookieService,
    public interceptorService: InterceptorService
  ) {
    this._window = window.nativeWindow;
    if (this._window.snowplow) {
      this._window.snowplow('newTracker', 'cf', '172.16.100.119:8080', {
        appId: 'ngSnowplow',
        platform: 'web',
        contexts: {
          webPage: true,
          performanceTiming: true
        }
      });
      this._window.snowplow('enableActivityTracking', 10, 10);
      this._window.snowplow('enableLinkClickTracking', null, true, true);
    }

    if (this.seoService.onBrowser) {
      // this.finger = this.localStorage.retrieve('g-finger');
      // this.finger = this.cookieService.get('g-finger');
      this.finger = this.interceptorService.finger;
      // if (!this.finger) {
      //     Fingerprint2.get(components => {
      //         const values = components.map(component => {
      //             return component.value;
      //         });
      //         this.finger = Fingerprint2.x64hash128(values.join(''), 31);
      //         this.localStorage.store('g-finger', this.finger);
      //     });
      // }
    }

    
    const account = this.loginService.getAccountService().getUserIdentity();
    if (account && account.email) {
        this.userid = account.id;
    } else {
      this.userid = null;
    }
    this.username = account && account.login;
    this.fullName = account && account.fullName;

    this.accountService.getAuthenticationState().subscribe(account => {
      if (account && account.email) {
          this.userid = account.id;
      } else {
        this.userid = null;
      }
      this.username = account && account.login;
      this.fullName = account && account.fullName;
    });
    

    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe(({urlAfterRedirects}: NavigationEnd) => {
      this.history = [...this.history, urlAfterRedirects];
    //     this.history.push(urlAfterRedirects);
      if (this.history.length > 2) {
        this.lastTwoHistory = this.history.slice(this.history.length-2);
        // console.log('urlAfterRedirects', this.history.slice(this.history.length-2));
      }
      
    });
  }

  public getFinger(): string {
    return this.finger;
  }
  public getUserId(): number {
    return this.userid;
  }
  public getUsername(): string {
    return this.username;
  }
  public getFullname(): string {
    return this.fullName;
  }
  public getRefererUrl(): string {
    return this.lastTwoHistory.length > 0 ? this.lastTwoHistory[0] : null;
  }
  public trackPageView(): void {
    if (this._window.snowplow) {
      this._window.snowplow('trackPageView');
    }
  }

  public trackOnSelect(data): void {
    if (this._window.snowplow) {
      if (this.interceptorService.finger) {
        data.finger = this.interceptorService.finger;
        data.page_url = environment.base + data.page_url;
        this._window.snowplow('trackSelfDescribingEvent', {
          schema: 'iglu:com.gianhadat/gianhadat_tracker/jsonschema/1-0-0',
          data: data
        });
        console.log('INSP:', data)
      } else {
        this.interceptorService.jwtReadySubject.pipe(take(1)).subscribe(finger => {
          data.finger = this.interceptorService.finger;
          data.page_url = environment.API.KONG_SERVER + data.page_url;
          this._window.snowplow('trackSelfDescribingEvent', {
            schema: 'iglu:com.gianhadat/gianhadat_tracker/jsonschema/1-0-0',
            data: data
          });
          console.log('INSP:', data)
        });
      }


    }
  }
}
