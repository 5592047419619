import { NavbarMobileService } from './navbar-mobile.service';
import { Component, OnInit, Input } from '@angular/core';
import { NavbarComponent } from './../navbar.component';
import { DangKyNhanBaoCaoService } from '../../landing/dang-ky-nhan-bao-cao/dang-ky-nhan-bao-cao.service';

@Component({
  selector: 'app-navbar-mobile',
  templateUrl: './navbar-mobile.component.html',
  styleUrls: ['./navbar-mobile.component.scss']
})
export class NavbarMobileComponent extends NavbarComponent implements OnInit {
  public show: boolean = false;
  public innerHeight: any;
  @Input() stylebar: number;
  // constructor(
  //   public navbarMobileService: NavbarMobileService
  // ) {
  // }
  ngOnInit(): void {
    if (this.seoService.onBrowser) {
      this.innerHeight = `${window.innerHeight}px`;
      this.show = false;
    }
  }



}
