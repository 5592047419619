<app-dropdown-menu>
    <ng-template app-dropdown-menu-label>
        <div class="d-flex picker">
            <div class="date-time-string d-flex justify-content-between">
                Ngày {{chooseDay}}
                <!-- <input type="text" [(ngModel)]="datePickerService.selectedString" /> -->
                <div (click)="$event.stopPropagation();isViewTooltip = true" (mouseover)="isViewTooltip = true"
                    (mouseout)="isViewTooltip = false">
                    <svg style="width: 20px; height: 20px;" aria-hidden="true" focusable="false" data-prefix="fal"
                        data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                        class="svg-inline--fa fa-info-circle fa-w-16 fa-2x">
                        <path fill="currentColor"
                            d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-36 344h12V232h-12c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h48c6.627 0 12 5.373 12 12v140h12c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12h-72c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12zm36-240c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32z"
                            class=""></path>
                    </svg>
                </div>
            </div>

            <div class="calendar">
                <svg style="width: 20px; height: 20px" aria-hidden="true" focusable="false" data-prefix="fal"
                    data-icon="calendar-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                    class="svg-inline--fa fa-calendar-alt fa-w-14 fa-2x">
                    <path fill="currentColor"
                        d="M400 64h-48V12c0-6.6-5.4-12-12-12h-8c-6.6 0-12 5.4-12 12v52H128V12c0-6.6-5.4-12-12-12h-8c-6.6 0-12 5.4-12 12v52H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM48 96h352c8.8 0 16 7.2 16 16v48H32v-48c0-8.8 7.2-16 16-16zm352 384H48c-8.8 0-16-7.2-16-16V192h384v272c0 8.8-7.2 16-16 16zM148 320h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm-96 96h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm-96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm192 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12z"
                        class=""></path>
                </svg>
            </div>
        </div>
        <div *ngIf="isViewTooltip" class="toolip">
            Báo cáo sẽ được gửi vào ngày {{chooseDay}} đầu tiên của quý.
        </div>
    </ng-template>
    <ng-template app-dropdown-menu-items>
        <div class="row" style="z-index: 1000">
            <div class=" col-12 date-picker">
                <!-- <div class="label">Từ</div> -->
                <div class="d-flex justify-content-between year">
                    <div class="next-prev-year"></div>
                    <div class="next-prev-year"></div>
                </div>
                <div class="row month-grid-row">
                    <div app-dropdown-menu-click-close class="col-3 col-sm-2 px-0 month"
                        *ngFor="let day of datePickerService.listDay" (click)="changeDate(day)">
                        <div>Ngày</div>
                        <div>{{day + 1}}</div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</app-dropdown-menu>
