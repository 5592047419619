<div class="flex justify-between  rounded relative">
   <form class="w-full" [formGroup]="searchService.form" (ngSubmit)="searchService.onSubmit()">
      <nz-input-group nzSize="large" [nzSuffix]="inputClearTpl">
         <input nz-input nzSize="large" style="font-size: 14px" formControlName="text" class="" type="text" [placeholder]="placeholder" [nzAutocomplete]="auto"  />
       </nz-input-group>
       <ng-template #inputClearTpl>
            <i nz-icon class="ant-input-clear-icon" nzTheme="fill" nzType="close-circle" *ngIf="searchService.form.value.text" (click)="clearSearchInput()" ></i>
       </ng-template>
   </form>
   <div class="status"  *ngIf="searchService.suggestResults.loading">
       <div class="lds-ring">
           <div></div>
           <div></div>
           <div></div>
           <div></div>
       </div>
   </div>
   <nz-autocomplete #auto >
        <nz-auto-option *ngFor="let item of searchService.suggestResults.items">
            <div (click)="selectDuAn(item)" class="link">
                <span [innerHTML]="item.title"></span>
            </div>
        </nz-auto-option>
    </nz-autocomplete>

       <!-- <ul  class="results bg-white shadow absolute top-[40px] right-0 left-0" *ngIf="searchService.suggestResults && searchService.suggestResults.items?.length">
           <li class="result" *ngFor="let item of searchService.suggestResults.items">
                <a (click)="selectDuAn()" class="link" [routerLink]="item.routerLink" app-dropdown-menu-click-close [queryParams]="item.queryParams">
                    <span [innerHTML]="item.title"></span>
                </a>
            </li>
        </ul> -->
</div>

<!-- <div class="warning" *ngIf="searchService.suggestResults.warning" [innerHTML]="searchService.suggestResults.warning"></div> -->
