import { UserMenuMobileModule } from './part/user-menu-mobile/user-menu-mobile.module';
import { MainLinkMenuModule } from './part/main-link-menu/main-link-menu.module';
import { UserMenuModule } from './part/user-menu/user-menu.module';
import { AppShareModule } from './../../app-share.modules';
import { AppRoutingModule } from './../../app-routing.module';
import { NavbarCsrComponent } from './navbar-csr/navbar-csr.component';
import { NavbarComponent } from './navbar.component';
import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";


@NgModule({
    imports: [
        CommonModule,
        AppShareModule,
        AppRoutingModule,
        UserMenuModule,
        UserMenuMobileModule,
        MainLinkMenuModule
    ],
    declarations: [
        NavbarComponent,
        NavbarCsrComponent,
    ],
    exports: [
        NavbarComponent,
        NavbarCsrComponent,
    ]
})

export class NavbarModule {

}
