export interface Analysis {
  idDiaChi: number;
  loaiDiaChi: string;
  typeData: string;
  typeBdsId: number;
  useFor: string;
  year: number;
  month: number;
  getAll?: boolean;
}


export function createAnalysisObject(params: Partial<Analysis>) {
    return {
        idDiaChi: params.idDiaChi,
        loaiDiaChi: params.loaiDiaChi ?? 'duong',
        typeData: params.typeData ?? 'QUARTER',
        typeBdsId: params.typeBdsId ?? 5,
        useFor: params.useFor ?? 'SALE',
        year: params.year ?? new Date().getFullYear() - 4,
        month: params.month ?? 12,
        getAll: true
    } as Analysis;
}