import { CommonService } from './../../common.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TiemNangTheoNhaDauTuService {
  public investorQueries: any;
  constructor(
    public commonService: CommonService
  ) {
    this.investorQueries = [
      {
        loaiBDSId: 7,
        loaiKhuVucId: 1,
        khoangGiaId: 2,
        capHanhChinhId: 0,
        loaiTangTruongId: 0,
        huongTangTruongId: 0,
        kieuBienDongId: 0,
        soKetQua: 2,
        batNhanThongbao: true,
        giaTriTangTruong: 3,
        keoDaiTrong: 3
      },
      {
        loaiBDSId: 5,
        loaiKhuVucId: 1,
        khoangGiaId: 1,
        capHanhChinhId: 0,
        loaiTangTruongId: 0,
        huongTangTruongId: 0,
        kieuBienDongId: 1,
        soKetQua: 0,
        batNhanThongbao: true,
        giaTriTangTruong: 3,
        keoDaiTrong: 3
      },
      {
        loaiBDSId: 5,
        loaiKhuVucId: 1,
        khoangGiaId: 5,
        capHanhChinhId: 0,
        loaiTangTruongId: 0,
        huongTangTruongId: 1,
        kieuBienDongId: 1,
        soKetQua: 0,
        batNhanThongbao: false,
        giaTriTangTruong: 3,
        keoDaiTrong: 3
      },
      {
        loaiBDSId: 5,
        loaiKhuVucId: 0,
        khoangGiaId: 5,
        capHanhChinhId: 0,
        loaiTangTruongId: 1,
        huongTangTruongId: 0,
        kieuBienDongId: 1,
        soKetQua: 0,
        batNhanThongbao: false,
        giaTriTangTruong: 3,
        keoDaiTrong: 3
      },
      {
        loaiBDSId: 5,
        loaiKhuVucId: 0,
        khoangGiaId: 5,
        capHanhChinhId: 0,
        loaiTangTruongId: 1,
        huongTangTruongId: 0,
        kieuBienDongId: 1,
        soKetQua: 11,
        batNhanThongbao: false,
        giaTriTangTruong: 3,
        keoDaiTrong: 3
      }
    ];
  }
}
