import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root'
})
export class RouteAccessService implements CanActivate {

  constructor(
    private router: Router,
    private http: HttpClient,
    private accountService: AccountService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
    // const authorities = route.data['authorities'];
    // We need to call the checkLogin / and so the principal.identity() function, to ensure,
    // that the client has a principal too, if they already logged in by the server.
    // This could happen on a page refresh.
    return this.checkLogin(route.data['role'] ? [route.data['role']] : []);
  }
  checkLogin(authorities: string[]): Promise<boolean> {
    /* Không cấu hình quyền thì tính là không có quyền */
    if (!authorities || authorities.length === 0) {
      this.router.navigate(['index.html']);
      return Promise.resolve(false);
    }
    const token = localStorage.getItem('access_token');
    const requestOptions = {
        headers: {
        Authorization: 'Bearer ' + token,
        }
    };

    return Promise.resolve<any>(
      this.http.get<any>(`${environment.cenhomesSSO.account}`, requestOptions).pipe(
          map(account => {
              const userInfo = (account.payload && account.payload.data) || account;
              if (userInfo) {
                  this.accountService.userIdentity = userInfo;
              } else {
                  this.accountService.userIdentity = null;
              }
              if (this.hasAnyAuthority(authorities)) {
                  return true;
              } else {
                  this.router.navigate(['index.html']);
                  return false;
              }
          })
      ).toPromise()
    );
  }

  hasAnyAuthority(authorities: string[]): boolean {
    for (let i = 0; i < authorities.length; i++) {
        if (this.accountService.userIdentity.authorities.includes(authorities[i])) {
            return true;
        }
    }

    return false;
}
}
