import { BanDoService } from 'src/app/layouts/ban-do/generic/ban-do.service';
import { Component, OnInit, Input } from '@angular/core';
import { NavbarMobileService } from '../navbar-mobile.service';

@Component({
  selector: 'app-menu-button',
  templateUrl: './menu-button.component.html',
  styleUrls: ['./menu-button.component.scss']
})
export class MenuButtonComponent implements OnInit {
  @Input() stylebar: number;
  constructor(
    public navbarMobileService: NavbarMobileService,
  ) { }

  ngOnInit(): void {
  }

}
