import { Component, HostListener, OnInit } from '@angular/core';
import { SearchSuggestComponent } from '../search-suggest.component';

@Component({
  selector: 'app-search-suggest-small-new',
  templateUrl: './search-suggest-small-new.component.html',
  styleUrls: ['./search-suggest-small-new.component.scss']
})
export class SearchSuggestSmallNewComponent extends SearchSuggestComponent {

  selectDuAn(item) {
    this.searchService.suggestResults.items = [];
    this.searchService.form.get('text').patchValue(item.tieuDe ?? null, {emitEvent: false});
    this.router.navigate(item.routerLink, {queryParams: item.queryParams})
  }

  clearSearchInput() {
    this.searchService.form.get('text').patchValue(null, {emitEvent: false});
  }
}