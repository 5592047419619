import { InterceptorService } from './interceptor.service';
import { Component, OnDestroy, OnInit, NgModule, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { EstimateHttpService } from 'src/app/shared/http/estimate-http.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-captcha',
    templateUrl: './captcha.component.html',
})
export class CaptchaComponent implements OnInit {
    public resolvedMessage: string;
    constructor(
      private interceptorService: InterceptorService,
      private estimateHttpService: EstimateHttpService,
      private location: Location,
      private router: Router,
      public activeModal: NgbActiveModal) {}

    ngOnInit() {
        // this.recaptchaV3Service.execute('importantAction')
        // .subscribe((token) => this.handleToken(token));
    }
    public resolved(captchaResponse: string) {
      // console.log(`Resolved captcha with response: ${captchaResponse}`);
      if (null === captchaResponse) {
        this.resolvedMessage = 'Captcha không hợp lệ';
      } else {
        this.estimateHttpService.doVerifyCaptcha(captchaResponse).subscribe(data => {
            // console.log(data.payload.data);
            if (data.payload.data === true) {
                /* Reload lại router sau khi verify xong captcha. Cách này không official lắm, cần imprrove khi có thời gian */
                this.activeModal.close();
                window.location.reload();
                this.router.navigateByUrl(this.router.url + '&reload=true', { skipLocationChange: true });
            } else {
                this.resolvedMessage = `Không thể xác minh. Vui lòng liên hệ nhân viên hỗ trợ. ${data.description}`;
                this.interceptorService.setErrorCustomMessage('Lỗi Captcha', data.description + '<br />Vui lòng liên hệ nhân viên hỗ trợ');
                // window.location.reload();
            }
        });
      }
    }

    public close() {
      this.activeModal.close();
    }

}
