
import { PopupModule } from './../../../shared/popup/popup.module';
import { NavbarMobileComponent } from './navbar-mobile.component';
import { NgModule } from "@angular/core";
import { UserMenuMobileModule } from '../part/user-menu-mobile/user-menu-mobile.module';
import { AppShareModule } from 'src/app/app-share.modules';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MenuButtonComponent } from './menu-button/menu-button.component';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        AppShareModule,
        UserMenuMobileModule,
        PopupModule
    ],
    exports: [
        NavbarMobileComponent,
        MenuButtonComponent
    ],
    declarations: [
        NavbarMobileComponent,
        MenuButtonComponent
    ]
})

export class NavbarMobileModule {}
