import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AppShareModule } from './../../../../app-share.modules';
import { UserMenuMobileComponent } from './user-menu-mobile.component';
import { NgModule } from "@angular/core";
import { PopupModule } from '../../../../shared/popup/popup.module';
import { FormsModule } from '@angular/forms';
import { NzIconModule } from 'ng-zorro-antd/icon';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        AppShareModule,
        PopupModule,
        FormsModule,
        NzIconModule
    ],
    exports: [
        UserMenuMobileComponent
    ],
    declarations: [
        UserMenuMobileComponent
    ]
})

export class UserMenuMobileModule {}
