import { DangKyNhanBaoCaoService } from './dang-ky-nhan-bao-cao.service';
import { Helpers } from 'src/app/shared/util/helpers';
import { DiaChi } from './../../../shared/util/address';
import { Component, OnInit, NgModule, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { AnalystHttpService } from 'src/app/shared/http/analyst-http.service';
import { Address, createAddressFromParams } from 'src/app/shared/util/address';
import { AccountService, LoginModalService, LoginService } from 'src/app/core';
import { AddressHttpService } from 'src/app/shared/http/address-http.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';


@Component({
  selector: 'app-dang-ky-nhan-bao-cao',
  templateUrl: './dang-ky-nhan-bao-cao.component.html',
  styleUrls: ['./dang-ky-nhan-bao-cao.component.scss']
})

export class DangKyNhanBaoCaoComponent implements OnInit, AfterViewInit {

  public popupOpen: boolean = false;
  public ketQuaDangKy: string;
  public emailNhanKetQua: string;
  // public dangKyNhanBaoCaoService.formSearch: Address;
  public listBieuDo: any;
  public listloaiTaiSan: any;
  public bieuDoChon: any;
  public listphanKhucBds: any;
  // public dangKyNhanBaoCaoService.phanKhucBds: any;
  public dangKyNhanBaoCaoForm = {
    tinh: new FormControl('', [Validators.required]),
  }
  public today: any;
  dayOfDate: number;

  constructor(
    private addressHttpService: AddressHttpService,
    private analystHttpService: AnalystHttpService,
    public accountService: AccountService,
    public dangKyNhanBaoCaoService: DangKyNhanBaoCaoService
  ) {
    const today = new Date();
    this.dayOfDate = today.getDate();
    this.listBieuDo = [
      {
        ten: 'Lịch sử thị trường',
        id: 'LICH_SU_THI_TRUONG'
      },
      {
        ten: 'Tăng trưởng nổi bật',
        id: 'TANG_TRUONG_NOI_BAT'
      },
      {
        ten: 'Phân tích nguồn cung',
        id: 'PHAN_TICH_NGUON_CUNG'
      },
    ];
    this.listloaiTaiSan = [
      {
        ten: 'Nhà Phố',
        id: 5
      },
      {
        ten: 'Nhà Ngõ',
        id: 7
      },
    ];
    this.listphanKhucBds = [
      {
        ten: 'Nhà bán',
        id: 'SALE'
      },
      {
        ten: 'Nhà thuê',
        id: 'RENT'
      }
    ]
  }

  ngOnInit(): void {
    // this.dangKyNhanBaoCaoService.formSearch = new Address(this.addressHttpService, null, null, null, null, null, null, null, null, null);
  }

  ngAfterViewInit(): void {
    // console.log('ngAfterViewInit', this.accountService);
    // this.accountService.getAuthenticationState().subscribe(account => {
    //     console.log('accountService', account);
    //     if (account) {
    //         this.emailNhanKetQua = account.email;
    //     }
    // });
    const account = this.accountService.getUserIdentity();
    this.emailNhanKetQua = account && account.email || '';
  }
  closeDangKyNhanBaoCao(): void {
    this.dangKyNhanBaoCaoService.step = 0;
    // this.popupData.title = 'Đăng ký nhận báo cáo';
    // this.popupDataChange.emit(this.popupData);
    this.dangKyNhanBaoCaoService.formIsOpen = false;
  }
  dongYNhanEmailHangThang(): void {
    this.emailNhanKetQua = this.emailNhanKetQua.trim();
    this.dangKyNhanBaoCaoService.step = 1;
    const data = {
      khuVucQuanTamDTO:
      {
        "tinhId": (this.dangKyNhanBaoCaoService.formSearch && this.dangKyNhanBaoCaoService.formSearch.tinh && this.dangKyNhanBaoCaoService.formSearch.tinh.choose && this.dangKyNhanBaoCaoService.formSearch.tinh.choose.id) ? this.dangKyNhanBaoCaoService.formSearch.tinh.choose.id : null,
        "quanId": (this.dangKyNhanBaoCaoService.formSearch && this.dangKyNhanBaoCaoService.formSearch.quan && this.dangKyNhanBaoCaoService.formSearch.quan.choose && this.dangKyNhanBaoCaoService.formSearch.quan.choose.id) ? this.dangKyNhanBaoCaoService.formSearch.quan.choose.id : null,
        "phuongId": (this.dangKyNhanBaoCaoService.formSearch && this.dangKyNhanBaoCaoService.formSearch.phuong && this.dangKyNhanBaoCaoService.formSearch.phuong.choose && this.dangKyNhanBaoCaoService.formSearch.phuong.choose.id) ? this.dangKyNhanBaoCaoService.formSearch.phuong.choose.id : null,
        "duongId": (this.dangKyNhanBaoCaoService.formSearch && this.dangKyNhanBaoCaoService.formSearch.duong && this.dangKyNhanBaoCaoService.formSearch.duong.choose && this.dangKyNhanBaoCaoService.formSearch.duong.choose.id) ? this.dangKyNhanBaoCaoService.formSearch.duong.choose.id : null
      }
      ,
      email: this.emailNhanKetQua
    };
    if (!this.dangKyNhanBaoCaoService.phanKhucBds || this.dangKyNhanBaoCaoService.phanKhucBds.length === 0 || this.dangKyNhanBaoCaoService.phanKhucBds.length === this.listloaiTaiSan.length) {

    } else if (this.dangKyNhanBaoCaoService.phanKhucBds && this.dangKyNhanBaoCaoService.phanKhucBds.length === 1) {
      data.khuVucQuanTamDTO['useFor'] = this.dangKyNhanBaoCaoService.phanKhucBds[0];
    } else {
      data.khuVucQuanTamDTO['useFor'] = this.dangKyNhanBaoCaoService.phanKhucBds;
    }
    if (!this.dangKyNhanBaoCaoService.loaiTaiSan || this.dangKyNhanBaoCaoService.loaiTaiSan.length === 0 || this.dangKyNhanBaoCaoService.loaiTaiSan.length === this.listphanKhucBds.length) {

    } else if (this.dangKyNhanBaoCaoService.loaiTaiSan && this.dangKyNhanBaoCaoService.loaiTaiSan.length === 1) {
      data.khuVucQuanTamDTO['typeBdsId'] = this.dangKyNhanBaoCaoService.loaiTaiSan[0];
    } else {
      data.khuVucQuanTamDTO['typeBdsId'] = this.dangKyNhanBaoCaoService.loaiTaiSan
    }
    if (this.dangKyNhanBaoCaoService.bieuDoChon && this.dangKyNhanBaoCaoService.bieuDoChon.length > 0) {
      data.khuVucQuanTamDTO['listBieuDo'] = this.dangKyNhanBaoCaoService.bieuDoChon;
    }
    if (this.dayOfDate) {
      data.khuVucQuanTamDTO['day_send'] = this.dayOfDate;
    }
    this.analystHttpService.nhanEmailHangThang(data).subscribe(res => {
      this.dangKyNhanBaoCaoService.step = 2;
      if (res.status === 0) {
        this.dangKyNhanBaoCaoService.title = 'Đăng ký thành công!';
        this.ketQuaDangKy = res.description + ' ' + this.emailNhanKetQua + '.'
      } else {
        this.dangKyNhanBaoCaoService.title = 'Đăng ký thất bại!';
        this.ketQuaDangKy = res.description;
      }
    }, error => {
      this.dangKyNhanBaoCaoService.step = 2;
      this.dangKyNhanBaoCaoService.title = 'Đăng ký thất bại!';
      this.ketQuaDangKy = 'Đã có lỗi xảy ra';
    });
  }
  dateChange($event) {
    this.dayOfDate = $event;
  }
}

