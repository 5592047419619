import { NavbarComponent } from './../navbar.component';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar-csr',
  templateUrl: './navbar-csr.component.html',
  styleUrls: ['./navbar-csr.component.scss']
})
export class NavbarCsrComponent extends NavbarComponent implements OnInit {
  public hasScrolled: boolean;

  ngOnInit(): void {
    
    this.hasScrolled = false;
    if (this.seoService.onBrowser) {
      /* Navbar on scroll */
      document.onscroll = () => {
        const top = (window.pageYOffset || document.body.scrollTop) - (document.body.clientTop || 0);
        if (top < 50) {
          this.scrollDown = true;
          this.hasScrolled = false;
        } else {
          this.scrollDown = false;
          this.hasScrolled = true;
        }

      };
    }
  }
  inPageNeedFixed(): boolean {
    const pageNeedFixed = ['/landing', '/phan-tich-khu-vuc', '/phan-tich-du-an'];
    if (pageNeedFixed.includes(this.router.url)) {
      return true;
    } else {
      return false;
    }
  }
}
