import { gql } from 'apollo-angular';

export const QUERY = gql`
query getChart(
  $idDiaChi: Int!,
  $loaiDiaChi: LoaiDiaChi!,
  $loaiDiaChiChild: LoaiDiaChi!,
  $loadParent: Boolean!,
  $loadSiblings: Boolean!,
  $typeBdsId: Int!,
  $useFor: UseFor!) {
  address(idDiaChi: $idDiaChi, loaiDiaChi: $loaiDiaChi) {
    id
    idDayDu
    idCha
    loaiDiaChi
    loaiDiaChiCha
    ...historyChartData
    parent @include(if: $loadParent) {
      ...historyChartData
      parent {
        ...historyChartData
      }
    }
    childs(loaiDiaChi: $loaiDiaChiChild) @skip(if: $loadSiblings) { # Nếu khu vực là tỉnh/thành phố hoặc quận/huyện
      ...topInCreaseChartData
    }
    parent: parent @include(if: $loadSiblings) { # Nếu khu vực là đường hoặc phường/xã
      childs(loaiDiaChi: $loaiDiaChi) {
        ...topInCreaseChartData
      }
    }
    nguonCungPhanKhuc: nguonCung (typeData: QUARTER, useFor: $useFor, year: 2020, month: 10) {
      soTin
      typeBds {
        id
        name
      }
      tyLeTangTruongSoTinCungKyNamTruoc
    }
  }
}

fragment historyChartData on Address {
  tienTo
  id
  ten
  tenKhongDau
  tenDayDu
  typeBds(typeBdsId: $typeBdsId) {
    id
    name
  }
  useFor(useFor: $useFor)
  analysts(typeData: QUARTER, typeBdsId: $typeBdsId, useFor: $useFor, year: 2017, month: 10, size: 24, sort: "timeOrder,asc") {
    month
    year
    soTin
    tyLeTangTruongSoTin
    giaTrenDtTrungBinh
    tyLeTangTruongGia
    checkBatThuong
  }
}
fragment topInCreaseChartData on Address {
  id
  ten
  tenKhongDau
  tienTo
  loaiDiaChi
  analysts(typeData: QUARTER, typeBdsId: $typeBdsId, useFor: $useFor, year: 2017, month: 10, size: 1, sort: "timeOrder,desc") {
    giaTrenDtTrungBinh
    tangTruongGia
    tyLeTangTruongGia
    soTin
    tangTruongSoTin
    tyLeTangTruongSoTin
    tangTruongGiaCungKyNamTruoc
    tyLeTangTruongGiaCungKyNamTruoc
    tangTruongSoTinCungKyNamTruoc
    tyLeTangTruongSoTinCungKyNamTruoc
    timeOrder
    year
    month
  }
}

`;

export const GET_CHART_DU_AN = gql`
query getChartDuAn(
  $idDiaChi: Int!,
  $loaiDiaChi: LoaiDiaChi!,
  $typeBdsId: Int!,
  $useFor: UseFor!) {
  address(idDiaChi: $idDiaChi, loaiDiaChi: $loaiDiaChi) {
    id
    idDayDu
    idCha
    loaiDiaChi
    loaiDiaChiCha
    ...historyChartData
    parent {
      ...historyChartData
      
    }
    
  }
}
fragment historyChartData on Address {
  tienTo
  id
  ten
  tenKhongDau
  tenDayDu
  typeBds(typeBdsId: $typeBdsId) {
    id
    name
  }
  useFor(useFor: $useFor)
  analysts(typeData: QUARTER, typeBdsId: $typeBdsId, useFor: $useFor, year: 2017, month: 10, size: 24, sort: "timeOrder,asc") {
    month
    year
    soTin
    tyLeTangTruongSoTin
    giaTrenDtTrungBinh
    tyLeTangTruongGia
    checkBatThuong
  }
}
`

export const getAnalysisByAddressId = gql `
  query getAnalysisByAddressId($idDiaChi: Int!, $loaiDiaChi: LoaiDiaChi!, $typeData: TypeData!, $typeBdsId: Int!, $useFor: UseFor!, $year: Int!, $month: Int!, $getAll: Boolean) {
    address(idDiaChi: $idDiaChi, loaiDiaChi: $loaiDiaChi) {
      analysts(typeData: $typeData, typeBdsId: $typeBdsId, useFor: $useFor, year: $year, month: $month, getAll: $getAll) {
        giaTrenDtTrungBinh
        year
        month
        idDiaChi
        quarter
        loaiDiaChi
        typeBdsId
        useFor
        soTin
        checkBatThuong
      }
      id
      idCha
      ten
      tenDayDu
      tienTo
    }
  }
`
