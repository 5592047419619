import { CongTrinh } from 'src/app/shared/model/dcs/cong-trinh.model';
import { AnhTaiSan } from './../../shared/model/dcs/static-info';
import { ThuaDat } from './../../shared/model/dcs/thua-dat.model';
import { BatDongSan } from './../../shared/model/dcs/bat-dong-san.model';
import { Injectable } from '@angular/core';
import { StaticInfo } from '../../shared/model/dcs/static-info';

@Injectable({
  providedIn: 'root'
})
export class InfoBdsService {
  public batDongSan: BatDongSan;
  public congTrinh?: CongTrinh;
  public thuaDat: ThuaDat;
  staticInfo?: StaticInfo;
  avatar?: string;
  title?: string;
  slug?: string; /* Đường dẫn đầy đủ cho SEO */
  area?: string;
  viTri?: string;
  loaiNha?: string;
  anNinhTratTu?: string;
  tinhTrangHeThongDien?: string;
  tinhTrangCapThoatNuoc?: string;
  matDoDanCu?: string;
  loaiCongTrinh?: string;
  huongCua?: string;
  hinhDangThuaDat?: string;
  huongDat?: string;
  huongDat2?: string;
  huongDat3?: string;
  images?: AnhTaiSan[];
  // anhBoSung?: AnhTaiSan;
  hasLand?: boolean;
  hasModel?: boolean;
  giaUocTinh?: boolean;
  doChinhXac?: string;
  scores?: {
    name: string;
    category: string;
    score: number;
    sub_score: {
      sub_category: string;
      name: string;
      score: number;
    }[];
    top: {
      [key: string]: {
        dis: string;
        lat: number;
        lon: number;
        title: string;
      }[];
    };
  }[];
  population?: {
    population_2015?: number;
    population_2020?: number;
  };
  from?: string
  constructor() { }
}
