import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoadExternalScriptService } from './../../layouts/footer/load-external-script';
import { InterceptorService } from './../../blocks/interceptor/interceptor.service';
import { Injectable } from '@angular/core';
import { SeoService } from '../../shared/util/seo.service';
import { map, mergeMap } from 'rxjs/operators';
import { NzNotificationService } from 'ng-zorro-antd/notification';
export interface Balance {
  value: number;
  show: boolean;
}
export interface UserIdentityType {
  id: number;
  name: string;
  authorities: string[];
}
@Injectable({
  providedIn: 'root'
})
export class AccountSsoService {
  public jwt: string;
  public account: any;
  public SID: string;
  public balance: Balance = {
    value: 0,
    show: false
  };
  public myCoin: any;
  public numberOfItem: number = 0;
  public userIdentity: UserIdentityType;

  constructor(
    public seoService: SeoService,
    public interceptorService: InterceptorService,
    private http: HttpClient,
    private notification: NzNotificationService
  ) {

    if (this.seoService.onBrowser) {

    }
  }

  public setUserIdentity(userInfo: any): void {
    if (userInfo) {
      this.userIdentity = {
        id: userInfo.id,
        name: userInfo.fullName,
        authorities: userInfo.authorities
      };
    } else {
      this.userIdentity = null;
    }
  }

  hasAnyAuthority(authorities: string[]): boolean {
    
    if (!this.userIdentity || !this.userIdentity.authorities) {
        return false;
    }
    // console.log('setUserIdentity', authorities, this.userIdentity.authorities);
    for (let i = 0; i < authorities.length; i++) {
        if (this.userIdentity.authorities.includes(authorities[i])) {
            return true;
        }
    }

    return false;
  }

  public isAuthenticated(): boolean {
    if (this.SID) {
      return true;
    } else {
      return false;
    }
  }


  public getCookieByName(cname): string {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }
  public getLargeCookie(prefix): string {
      let cookie = '';
      const cookieField = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'K', 'L', 'M'];
      const numberOfParts = parseInt(this.getCookieByName(prefix), 10);
      for (let i = 0; i < numberOfParts; i++) {
        cookie += this.getCookieByName(cookieField[i] + prefix);
      }
      return cookie;
  }
  getBalance(): any {
    return this.http.get<any>(`${environment.API.SERVER_THANH_TOAN_MY_BALANCE}`)
    .subscribe(res => {
      if (res.status === 0) {
        this.balance.value = res.payload.amount;
      } else {
        this.notification.error('Không thành công', res.description);
      }
    }, err => {
      this.notification.error('không thành công', err.message);
    });
  }
  getMyCoin(): void {
    this.http.get<any>(`${environment.API.SERVER_THANH_TOAN_MY_COIN}`).subscribe(res => {
      if (res.status === 0) {
        this.myCoin = res.payload;
      } else {
        this.notification.error('Không thành công', res.description);
      }
    }, err => {
      this.notification.error('không thành công', err.message);
    });
  }
  getMyPlan(): void {
    this.http.get<any>(`${environment.API.SERVER_THANH_TOAN_MY_PLAN}?page=1&size=1&sort=id,desc`).subscribe(res => {

      this.numberOfItem = res.payload.total;
  
    }, err => {

    });
  }

}
