import { AnalystHttpService } from './../../../shared/http/analyst-http.service';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class FavouriteBdsService {
    private sourceApi = environment.API.SERVER_ANALYST;
    public listBdsYeuThich: Subject<any> = new Subject();
    public listBdsYeuThichData: any;
    danhSachBdsYeuThich = [];
    private countValue = new Subject();
    idBdsYeuThich = false;
    responeBds: any;
    isBdsYeuThich: boolean;
    constructor(private http: HttpClient, private analystHttpService: AnalystHttpService) { }

    getListBdsFavourite() {
        this.http.get<any>(this.sourceApi + '/api/get-bds-yeu-thich').subscribe(response => {
            this.listBdsYeuThich.next(response);
            this.listBdsYeuThichData = response;
        });
    }
    getListBdsFavorite() {
        this.http.get<any>(this.sourceApi + '/api/get-bds-yeu-thich').pipe(
            map(res => {
                if (res && res.payload && res.payload.data && res.payload.data.length) {
                    return res.payload.data.map(item => item.id);
                }
                return [];
            })
        ).subscribe(response => {
            this.danhSachBdsYeuThich = response;
        });
    }

  saveBdsYeuThich(bdsId) {
    this.analystHttpService.saveBdsFavourite({id:  bdsId}).subscribe(res => {
      if (res.status === 0) {
        this.isBdsYeuThich = true;
        this.danhSachBdsYeuThich = [...this.danhSachBdsYeuThich, bdsId];
        // this.favouriteBdsService.getListBdsFavorite();
      } else {
        alert('Vui lòng đăng nhập để sử dụng chức năng này!');
      }
    });
  }

  removeBdsYeuThich(bdsId) {
    this.analystHttpService.removeBdsFavourite({id: bdsId}).subscribe(res => {
      if (res.status === 0) {
        this.isBdsYeuThich = false;
        this.danhSachBdsYeuThich = this.danhSachBdsYeuThich.filter(id => id !== bdsId);
      } else if (res.status === 1) {
        alert('Vui lòng đăng nhập để sử dụng chức năng này!');
      }
    });;
  }


  isBdsFavorite(bdsId) {
    if (this.danhSachBdsYeuThich && this.danhSachBdsYeuThich.length) {
      return this.danhSachBdsYeuThich.includes(+bdsId);
    }
    return false;
  }
}
