import { SeoService } from './../../../../shared/util/seo.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError, debounceTime, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable, of, Subscription } from 'rxjs';
import { Helpers } from 'src/app/shared/util/helpers';
import { Router } from '@angular/router';
import { SnowPlowTrackerType } from '../../../../shared/util/interface';
import { SnowplowService } from '../../../../shared/util/tracking/snowplowService';
import { BehaviorSubject } from 'rxjs';
import { LoginService } from 'src/app/core';

export type SearchType = 'nha_dat_collect' | 'du_an' | 'khu_vuc' | 'thong_tin_moi_gioi';
export interface SuggestItem {
  from: string;
  id: string;
  title: string;
  routerLink: any;
  queryParams: any;
  tieuDe?: string
}
export interface SuggestResults {
  loading: boolean;
  error: string;
  warning: string;
  items: SuggestItem[]
}
@Injectable({
  providedIn: 'root'
})
export class SearchService {
  public keepTextString: string;
  public form: FormGroup;
  public subscribeAPI: Observable<any>;
  public suggestResults: SuggestResults;
  public snowPlowTracker: SnowPlowTrackerType;
  public resultsOpen: boolean;

  typeSearch: any = 'nha_dat_collect';
  text: any;
  textSubscribe: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  loaiTimKiem: 'CENHOMES' | 'GOOGLE' = 'CENHOMES';
  loaiTimKiemVisible: boolean = false;

  constructor(
    private snowplowService: SnowplowService,
    private router: Router,
    private http: HttpClient,
    private fb: FormBuilder,
    private helpers: Helpers,
    public seoService: SeoService,
    private loginService: LoginService
  ) {
    this.suggestResults = {
      loading: false,
      error: null,
      warning: null,
      items: []
    };
    this.form = this.fb.group({
      type: this.fb.control(''),
      text: this.fb.control(''),
      limit: this.fb.control(5),
      offset: this.fb.control(0)
    });
    this.form.get('text').valueChanges.pipe(debounceTime(200)).subscribe(value => {
      this.changeText(value);

    });
    this.form.get('type').valueChanges.subscribe(value => {
      this.form.get('text').setValue('', {emitEvent: false});
      this.suggestResults.items = [];
    });
    this.textSubscribe.pipe(debounceTime(500)).subscribe(text => {
      console.log('textSubscribe', text);
      this.changeText(text);
    });
  }

  changeText(value: string): void {
    if (value && value !== '') {
        setTimeout(() => {
          this.resultsOpen = true;
        }, 100);
        this.suggestResults.loading = true;
        this.suggestResults.error = null;
        this.suggestResults.warning = null;
        // const type = this.form.get('type').value;
        const type = this.typeSearch;
        console.log('type', type);
        const limit = 5;
        const offset = 0;
        if (type === 'nha_dat_collect') {
          if (this.loaiTimKiem === 'GOOGLE') {
            if (this.loginService.getAccountService().isAuthenticated()) {
              this.subscribeAPI = this.http.post<any>(`${environment.API.SERVER_SEARCH}v2/online/google-autocomplete`, {data: value});
            } else {
              this.subscribeAPI = of({hits: {hits: []}});
            }
          } else if (this.loaiTimKiem = 'CENHOMES') {
            this.subscribeAPI = this.http.post<any>(`${environment.API.SERVER_SEARCH}v2/dinhgia-suggest-v2`, {
              cat_type: type,
              data: value
            });
          }
        } else if (type === 'du_an') {
          this.subscribeAPI = this.http.post<any>(`${environment.API.SERVER_SEARCH}v2/dinhgia-suggest-v2`, {
            cat_type: type,
            data: value
          });
        } else if (type === 'khu_vuc') {
          this.subscribeAPI = this.http.post<any>(`${environment.API.SERVER_SEARCH}v2/dinhgia-suggest-v2`, {
            cat_type: type,
            data: value
          });
        } else if (type === 'thong_tin_moi_gioi') {
          this.subscribeAPI = this.http.get<any>(
            environment.API.KONG_SERVER + '/g-data-statistic/v1/api/profile-broker-months/list-broker?search=' + value +
            '&offset=' + offset +
            '&limit=' + limit);
        } else {
          console.error('Khong co type')
        }
        if (this.subscribeAPI) {
          this.subscribeAPI
          // .pipe(catchError(val => {
          //   this.suggestResults.loading = false;
          //   this.suggestResults.error = 'Lỗi';
          //   return of([]);
          // }))
          .pipe(take(1))
          .subscribe(response => {
            this.suggestResults.loading = false;
            this.suggestResults.items = this.getSuggestResults(type, value, response);
            console.log('response', response);
          });
        }
     
    } else {
      this.suggestResults.warning = 'Vui lòng nhập để tìm kiếm';
      this.suggestResults.error = null;
      this.suggestResults.items = [];
    }
  }

  textOnKeyup($event): void {
    const stupidKey = ['Alt', 'Shift', 'Control', 'CapsLock', 'ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight'];
    if (stupidKey.includes($event.key)) {
      return;
    }
    const text = this.text.trim(); //.replace(/ /, '');
    this.textSubscribe.next(text);
  }

  public onSubmit(): any {
    console.log('onSubmit')
    const type: SearchType = this.form.get('type').value;
    const value: string = this.form.get('text').value;
    this.keepTextString = value;
    if (type === 'nha_dat_collect') {
      this.suggestResults.loading = true;
      this.http.post<any>(environment.API.SERVICE_ADDRESS_NORMALIZE + '/v2/address/enter-search', value).subscribe(response => {
        const params = {};
  
        if (response && response.city && response.city.id) {
          params['tinh-id'] = response.city.id;
        }
        if (response && response.district && response.district.id) {
          params['quan-id'] = response.district.id;
        }
        if (response && response.street && response.street.id) {
          params['duong-id'] = response.street.id;
        }
        if (response && response.ward && response.ward.id) {
          params['phuong-id'] = response.ward.id;
        }
        if (response && response.ngo) {
          params['ngo'] = response.ngo;
        }
        if (response && response['ngach-hem']) {
          params['ngach-hem'] = response.ngach;
        }
        if (response && response['so_nha']) {
          params['so-nha'] = response.so_nha;
        }
        params['mode'] = 'home';
        this.suggestResults.loading = false;
        this.router.navigate(['/ban-do'], {
          queryParams: params
        })
      });
      // console.log('searach service value', this.form.value);
      // this.router.navigate(['/ban-do'], {
      //   queryParams: {
      //     query: value
      //   }
      // });
    } else if (type === 'du_an') {
      this.suggestResults.warning = 'Bạn vui lòng chọn một trong các dự án đã được gợi ý.';
    } else if (type === 'khu_vuc') {
      this.suggestResults.warning = 'Bạn vui lòng chọn một trong các khu vực đã được gợi ý.';
    } else if (type === 'thong_tin_moi_gioi') {
    } else {

    }
    /* Dich vu snow plow */
    if (type === 'nha_dat_collect') {
      this.snowPlowTracker = {
        schema_type: 'log_search_tracker',
        page_url: this.router.url,
        timestamp: '' + new Date().getTime(),
        finger: this.snowplowService.getFinger(),
        referer_url: this.snowplowService.getRefererUrl(),
        user_id: this.snowplowService.getUserId(),
        user_name: this.snowplowService.getUsername(),
        full_name: this.snowplowService.getFullname(),
        property_search_content: value,
        property_search_type: type,
      };
      this.snowplowService.trackOnSelect(this.snowPlowTracker);
    }
  }

  public setDefaultType(type: SearchType): void {
    this.form.get('type').setValue(type);
  }
  public getSuggestResults(type: SearchType, text: string, response): SuggestItem[] {
    const suggestItem: SuggestItem[] = [];
    const listResults = response?.hits?.hits || response?.payload || [];
    if (listResults.length === 0) {
      this.suggestResults.warning = 'Không tìm thấy kết quả nào. Vui lòng thử một địa chỉ khác.'
    }
    listResults.forEach(element => {
      let item;
      let routerLink = [];
      let queryParams = null;
      if (type === 'nha_dat_collect') {
        if (this.loaiTimKiem === 'GOOGLE') {
          item = element;
          routerLink = ['/ban-do'];
          queryParams = {
            'gg-id': item.place_id,
          };
        } else if (this.loaiTimKiem === 'CENHOMES') {
          item = element && element._source;
          routerLink = ['/ban-do'];
          queryParams = {
            'tinh-id': item.tinh.id,
            'quan-id': item.quan_huyen.id,
            'phuong-id': item.phuong_xa.id,
            // 'duong-id': item.duong.id,
            'bds-id': item.id,
            'from': item.from
          };
        }

      } else if (type === 'du_an') {
        item = element && element._source;
        routerLink = ['/phan-tich-du-an', this.helpers.createSlugFromTitle(`${item.tieu_de}-${item.id.replace('_analytics_du_an', '')}`)];
        queryParams = null;
      } else if (type === 'khu_vuc') {
        item = element && element._source;
        routerLink = ['/phan-tich-khu-vuc', this.helpers.createSlugFromTitle(`${item.tieu_de}-${item[item.address_type.type].id}`)];
        queryParams = null;
      }
      suggestItem.push({
        from: item.from,
        id: item.id,
        title: highLight(text, item?.tieu_de || item?.address),
        routerLink: routerLink,
        queryParams: queryParams,
        tieuDe: item.tieu_de
      })
    });
    return suggestItem;
  }
}

// convert ASCII to wildcard
function addAccents(input): string {
  let retval = input;
  retval = retval.replace(/a/ig, '[aàáạảãâầấậẩẫăằắặẳẵ]');
  retval = retval.replace(/e/ig, '[eèéẹẻẽêềếệểễ]');
  retval = retval.replace(/o/ig, '[oòóọỏõôồốộổỗơờớợởỡ]');
  retval = retval.replace(/u/ig, '[uùúụủũưừứựửữ]');
  retval = retval.replace(/d/ig, '[dđ]');
  retval = retval.replace(/i/ig, '[iìíịỉĩ]');
  retval = retval.replace(/y/ig, '[yỳýỵỷỹ]');
  retval = retval.replace(/ /ig, '|');
  return retval;
}
export function highLight(text, result): any {
  const regex = new RegExp(`(${addAccents(text)})`, 'gi');
  return (result).replace(regex, '<b>$1</b>');
}