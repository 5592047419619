import { Injectable, Inject, OnInit, HostListener } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class SeoService {
  public onBrowser: boolean;
  public onMobile: boolean;
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      console.log('onBrowser');
      this.onBrowser = true;
      console.log('this.onBrowser12', this.onBrowser);
      if (window && window.innerWidth < 576) {
        this.onMobile = true;
      console.log('this.onMobile', this.onMobile);
      } else {
        this.onMobile = false;
      }
    } else {
      console.log('onServer');
      this.onBrowser = false;
    }
  }

  getMobileHeight(difference) {
    if (this.onBrowser) {
      if (window && window.innerWidth < 576) {
        return {
          'height': `${window.innerHeight - difference}px`
        }
      } else {
        return {}
      }
    } else {
      return {}
    }
  }
  getMobileStyle() {
    if (this.onBrowser) {
      if (window && window.innerWidth < 576) {
        return {
          'overflow-y': 'scroll',
          'height': `${window.innerHeight - 1}px`,
          'scroll-behavior': 'smooth',
          'position': 'fixed',
          'top': '0',
          'left': '0',
          'width': '100%',
          'padding': 0,
          'margin': 0
        }
      } else {
        return {}
      }
    } else {
      return {}
    }
    
  }

  setMobileScreen() {
    if (this.onBrowser) {
      if (window && window.innerWidth < 576) {
        document.body.style.position = 'fixed';
        document.body.style.top = '0';
        document.body.style.bottom = '0';
        document.body.style.left = '0';
        document.body.style.right = '0';
        document.body.style.margin = '0';
        document.body.style.padding = '0';
        document.body.style.overflow = 'hidden';
        document.body.style.width = '100%';
        document.body.style.height = `${window.innerHeight}px`;
      }
    } else {
      return {}
    }
  }
  unSetMobileScreen() {
    if (this.onBrowser) {
      if (window && window.innerWidth < 576) {
        document.body.style.position = 'unset';
        document.body.style.top = 'initial';
        document.body.style.bottom = 'initial';
        document.body.style.left = 'initial';
        document.body.style.right = 'initial';
        document.body.style.margin = 'margin: unset;';
        document.body.style.padding = 'margin: unset;';
        document.body.style.overflow = 'auto';
        document.body.style.width = 'initial';
        document.body.style.height = 'initial';
      }
    } else {
      return {}
    }
  }
}
