import { AfterViewInit } from '@angular/core';
import { OnInit } from '@angular/core';
import { LoadExternalScriptService } from './load-external-script';
import { Component, NgModule } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AccountService, LoginModalService, LoginService } from 'src/app/core';
// import { Address, createAddressFromParams } from 'src/app/shared/util/address';
// import { RealEstateModule } from 'src/app/shared/real-estate/real-estate.module';
import { from } from 'rxjs';
import { SeoService } from '../../shared/util/seo.service';
import { ThamDinhGiaService } from '../../shared/tham-dinh-gia/tham-dinh-gia.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit, AfterViewInit {
    modalRef: NgbModalRef;

    constructor(
        // private accountService: AccountService,
        private loginModalService: LoginModalService,
        public loadExternalScriptService: LoadExternalScriptService,
        public seoService: SeoService,
        public thamDinhGiaService: ThamDinhGiaService
    ) {

    }

    login() {
        this.modalRef = this.loginModalService.open();
    }

    ngOnInit() {

    }
    ngAfterViewInit(): void {
      if (this.seoService.onBrowser) {
        setTimeout(() => {
          window['fbAsyncInit'] = function() {
              window['FB'].init({
                  appId            : '784036721642385',
                  autoLogAppEvents : true,
                  xfbml            : true,
                  version          : 'v4.0'
              });
          };
          this.loadExternalScriptService.loadDynamicScript('https://connect.facebook.net/en_US/sdk.js').then(() => {

          });

          this.loadExternalScriptService.loadDynamicScript('https://www.googletagmanager.com/gtag/js?id=UA-147969788-2').then(() => {
              let dataLayer = (window as any).dataLayer;
              (window as any).dataLayer = (window as any).dataLayer || [];

              this.gtag('js', new Date());
              this.gtag('config', 'UA-147969788-2');
          });
          
        }, 200);
      }
    }
    gtag(param1, param2) {
        (window as any).dataLayer.push(arguments);
    }

}

