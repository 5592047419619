import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

export class DatePickerService {
  public rangeYears: number = 5;
  public currentMonth: number;
  public currentYear: number;

  public pastMonth: number;
  public pastYear: number;

  // public selectedMonth: number;
  // public selectedYear: number;
  public selectedString: string;

  public listYear: number[];
  public listMonth: number[];
  constructor() {
    const date = new Date();
    this.currentMonth = date.getMonth();
    this.currentYear = date.getFullYear();

    // this.selectedMonth = this.currentMonth;
    // this.selectedYear = this.currentYear;

    this.pastMonth = 1;
    this.pastYear = this.currentYear - this.rangeYears;
    this.listYear = [];
    for (let i = this.pastYear; i <= this.currentYear; i++) {
      this.listYear.push(i);
    }
    this.listMonth = [];
    for (let i = 0; i < 12; i++) {
      this.listMonth.push(i);
    }
    // this.selectedString =
    // String(this.selectedMonth) + '/' +
    // String(this.selectedYear);
  }

}

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [DatePickerService]
})
export class DatePickerComponent implements OnInit {
  // @Input() monthFrom: any;
  // @Input() monthTo: any;
  // @Input() yearFrom: any;
  // @Input() yearTo: any;
  public numberOfMonth: number;

  @Input() date: any;
  @Output() dateChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() dateChangeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() confirmDateChangeEvent: EventEmitter<any> = new EventEmitter<any>();
  constructor(public datePickerService: DatePickerService) { }

  ngOnInit(): void {
    // this.changeAnyThing();
    console.log('anything')
  }
  changeMonthFrom(month): void {
    this.date.monthFrom = month;
    this.changeAnyThing();
  }
  changeMonthTo(month): void {
    this.date.monthTo = month;
    this.changeAnyThing();
  }
  nextYearFrom(): void {
    this.date.yearFrom++;
    this.changeAnyThing();
  }
  nextYearTo(): void {
    this.date.yearTo++;
    this.changeAnyThing();
  }
  prevYearFrom(): void {
    this.date.yearFrom--;
    this.changeAnyThing();
  }
  prevYearTo(): void {
    this.date.yearTo--;
    this.changeAnyThing();
  }
  changeAnyThing(): void {
    const before = new Date(this.date.yearFrom, this.date.monthFrom);
    const after = new Date(this.date.yearTo, this.date.monthTo);
    this.numberOfMonth = (after.getTime() - before.getTime()) / 1000;

    this.numberOfMonth = (after.getFullYear() - before.getFullYear()) * 12;
    this.numberOfMonth -= before.getMonth();
    this.numberOfMonth += after.getMonth();

    // this.datePickerService.selectedString = this.datePickerService.selectedMonth + '/' + this.datePickerService.selectedYear;
    this.dateChange.emit(this.date);
    this.dateChangeEvent.emit(this.date);
  }
  confirmDateChange(): void {
    this.confirmDateChangeEvent.emit(this.date);
  }
}
