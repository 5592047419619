import { PhanTichNangCaoMobileService } from './../http/phan-tich-nang-cao-mobile.service';
import { createFilterFormObject, createCriteriaObject } from 'src/app/shared';
import { PhanTichNangCaoService } from '../http/phan-tich-nang-cao.service';
import { LoginService } from './../../core/login/login.service';
import { LoginSsoService } from './../../core/login/login-sso.service';
import { ToasterService } from 'angular2-toaster';
import { catchError, delay, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { dataInitKetQuaTimKiemKhuVucTiemNang } from './../chart/data-chart-init';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ChangeDetectorRef, Directive, Injectable } from '@angular/core';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SeoService } from '../util/seo.service';
import { Subject } from 'rxjs';
import { Helpers } from '../util/helpers';
import { SearchSuggestService } from '../search-suggest/search-suggest.service';
import { Address } from '../util/address';
import { AddressHttpService } from '../http/address-http.service';

@Injectable({ providedIn: 'root' })
@Directive()
export class PhanTichNangCaoLogic {

  public selectedTab: any;

  /* Loại bất động sản */
  public loaiBatDongSan: any;
  public loaiBatDongSanSelected: any;

  /* Khu vực */
  public khuVuc: any;
  public khuVucSelected: any;

  /* Khoảng giá mong muốn */
  public khoangGiaQuanTam: any;
  public khoangGiaQuanTamSelected: any;

  // Gia min
  giaMin: any;
  //gia Max
  giaMax: any;

  /* Loại tăng trưởng */
  public loaiTangTruong: any;

  /* Hướng tăng trưởng */
  public huongTangTruong: any;
  public huongTangTruongSelected: any;

  public quanHe: any;
  public quanHeSelected: any;

  /* Biến động so với */
  public kieuBienDong: any;
  public kieuBienDongSelected: any;

  /* Tăng trưởng ít nhất */
  public tyLeTangTruong: any;
  public giaTriTangTruongSelected: any;

  tenLoaiBds: any;
  listPhanKhuc: any;
  /* Kéo dài trong ít nhất */
  public keoDaiTrong: any;
  public keoDaiTrongSelected: any;

  /* Cấp hành chính */
  public capHanhChinh: any;
  public capHanhChinhSelected: any;

  public dataChartInitKhuVucNangCao: any;
  listQuickNavigate
  dataChartCriteriaDetailInit;
  loadingChart = false;
  showLoadingWhenGetCriteriaDetail = false;
  showPopupCriteriaDetail = false;
  showPopupDuLieuDangCapNhatKhiChonDuongTrongPopup = false;
  dataCriteriaDetailLocal;
  selectedAddressIds: string[];
  selectedBdsId;
  tenBdsSelected;
  loadingWhenGetDataToDrawChart = false;
  loadingWhenGetDataToDrawChartIntoDetail = false;
  loadingCriteriaList = false;
  loadingSaveCriteria = false;
  addressIdSelectedUI;
  readyUIOverview: boolean;
  shouldShowBtnSaveCriteria = false;
  shouldShowBtnSeeCriteriaDetail = false;
  tabIndex;
  mucTimKiemSelected: any;
  loadingChartKVTiemNang = false;
  loadingChartKVThoaManTieuChiNguoiDung = false;
  loadingChartKhiChonTieuChi = false;
  diaChiDeLuuTieuChi: { ten: any; tienTo: any; tenDayDu: any; };
  criteriaSelected: any;
  dataChartKhuVucQuanTamCuaBan;

  loadingChartKhuVucTiemNhatCuaToi = false;
  listDienGiai = [];
  page;
  id;
  thongtinkhuvuc;
  formSearch;
  areaSelect;
  showPopupConfirmRemove = false;
  idCriteriaSelectedRemove: number;
  loadingRemove = false;
  loaiDiaChiKVTiemNangNhatSelected = 'duong';
  loaiDiaChiXuhuongTimKiemSelected = 'duong';
  duongDangChonTrongPopup: string;
  donViHienThiTrongPopup;
  showPopupDuLieuDangCapNhatMobile = false;
  // loadingLogin = false;
  constructor(public addressHttpService: AddressHttpService, public searchSuggestService: SearchSuggestService, public helpers: Helpers, public cd: ChangeDetectorRef, public router: Router, public fb: FormBuilder, public phanTichNangCaoMobileService: PhanTichNangCaoMobileService, public toasterService: ToasterService, public seoService: SeoService, public loginSsoService: LoginSsoService, public loginService: LoginService, public phanTichNangCaoService: PhanTichNangCaoService, public activatedRoute: ActivatedRoute) {

    this.loaiBatDongSan = [
      { id: 5, name: "Nhà mặt đường" },
      { id: 7, name: "Nhà trong ngõ" },
      // { id: 8, name: "Căn hộ, chung cư" },
    ];

    this.listPhanKhuc = [
      { name: 'Nhà bán', id: 'SALE' },
      { name: 'Nhà thuê', id: 'RENT' },
    ]

    this.khuVuc = [
      { name: 'Cả nước', id: 0 },
      { name: 'Hà Nội', id: 1 },
      { name: 'Tp. Hồ Chí Minh', id: 2 },
      { name: 'Hải Phòng', id: 3 },
      { name: 'Đà Nẵng', id: 4 }
    ];

    this.loaiTangTruong = [
      { name: 'Giá', id: 'gia' },
      { name: 'Tin rao', id: 'sotin' }
    ];

    this.huongTangTruong = [
      { name: 'Tăng', id: 'tang' },
      { name: 'Giảm', id: 'giam' },
    ];

    this.kieuBienDong = [
      { name: 'Tháng trước', id: 1 },
      { name: 'Khu vực xung quanh', id: 2 }
    ];

    this.capHanhChinh = [
      { name: 'Tỉnh/Thành Phố', id: 'tinh' },
      { name: 'Quận/Huyện', id: 'quan_huyen' },
      { name: 'Phường/Xã', id: 'phuong_xa' },
      { name: 'Đường', id: 'duong' }
    ];

    this.quanHe = [
      { name: 'Lớn hơn', id: 1 },
      { name: 'Nhỏ hơn', id: 2 }
    ];

    this.khoangGiaQuanTam = [
      { name: 'Bất kỳ giá nào', id: 1 },
      { name: 'Dưới 20 triệu/m2', id: 1 },
      { name: '20 triệu/m2 đến 50 triệu/m2', id: 1 },
      { name: '50 triệu/m2 đến 100 triệu/m2', id: 1 },
      { name: '100 triệu/m2 đến 200 triệu/m2', id: 1 },
      { name: '200 triệu/m2 đến 300 triệu/m2', id: 1 },
      { name: 'Trên 300 triệu/m2', id: 1 },
    ];

    this.tyLeTangTruong = [];
    for (let i = 1; i < 100; i++) {
      this.tyLeTangTruong.push({ name: i + '%', id: i });
    }

    this.keoDaiTrong = [
      { name: '2 tháng', id: 1 },
      { name: '3 tháng', id: 1 },
      { name: '6 tháng', id: 1 },
      { name: '9 tháng', id: 1 },
      { name: '12 tháng', id: 1 }
    ];

    this.listQuickNavigate = [
      { label: 'LICH_SU_THI_TRUONG', name: 'Lịch sử thị trường', hover: false },
      { label: 'TANG_TRUONG_NOI_BAT', name: 'Tăng trưởng nổi bật', hover: false },
      { label: 'PHAN_TICH_NGUON_CUNG', name: 'Phân tích nguồn cung', hover: false },
      { label: 'BAN_DO_GIA_TRONG_KHU_VUC', name: 'Bản đồ giá trong khu vực', hover: false },
      { label: 'TIEN_ICH_XUNG_QUANH', name: 'Tiện ích xung quanh', hover: false }
    ];
    // this.setDummyFilter();
    this.dataChartCriteriaDetailInit = { ...dataInitKetQuaTimKiemKhuVucTiemNang, data: dataInitKetQuaTimKiemKhuVucTiemNang['series'], title: null };
    this.dataChartCriteria = { ...dataInitKetQuaTimKiemKhuVucTiemNang, data: dataInitKetQuaTimKiemKhuVucTiemNang['series'], title: null };
    this.dataChartKhuVucTiemNang = { ...dataInitKetQuaTimKiemKhuVucTiemNang, data: dataInitKetQuaTimKiemKhuVucTiemNang['series'], title: null };
    this.dataChartXuHuongTimKiem = { ...dataInitKetQuaTimKiemKhuVucTiemNang, data: dataInitKetQuaTimKiemKhuVucTiemNang['series'], title: null };
    this.dataChartInitKhuVucNangCao = { ...dataInitKetQuaTimKiemKhuVucTiemNang, data: dataInitKetQuaTimKiemKhuVucTiemNang['series'], title: null };
    this.dataChartKhuVucQuanTamCuaBan = { ...dataInitKetQuaTimKiemKhuVucTiemNang, data: dataInitKetQuaTimKiemKhuVucTiemNang['series'], title: null };
    // this.dataInitKetQuaTimKiemKhuVucTiemNang = {...dataInitKetQuaTimKiemKhuVucTiemNang, data: [], series: [], title: this.titleChart};
    this.page = {
      area: {
        tinh: {
          id: null,
          ten: ''
        },
        quanHuyen: {
          id: null,
          ten: ''
        },
        phuongXa: {
          id: null,
          ten: ''
        },
        duong: {
          id: null,
          ten: ''
        }
      },
      type: null,
      title: {
        thiTruong: '',
        bds: '',
        text: ''
      },
      description: '',
      images: []
    };
    this.areaSelect = {
      level0: null,
      level1: null,
      level2: null
    };
    this.formSearch = new Address(
      this.addressHttpService,
      this.page.area.tinh.id,
      this.page.area.quanHuyen.id,
      this.page.area.duong.id,
      this.page.area.phuongXa.id,
      null, null, null, null, null);
    this.formSearch.initFinish.subscribe(data => {
      this.areaSelect.level0 = this.formSearch.tinh.choose;
      this.areaSelect.level1 = this.formSearch.quan.choose;
      this.areaSelect.level2 = this.formSearch.duong.choose || this.formSearch.phuong.choose;
    });

  }
  titleChart = 'Biểu đồ lịch sử giá của các đường';
  loaiNha = [
    { name: 'Nhà mặt phố', id: 5, selected: true },
    { name: 'Nhà trong ngõ', id: 7, selected: false }
  ];
  useFor = [
    { name: 'Bán', id: 'SALE' },
    { name: 'Cho thuê', id: 'RENT' }
  ];
  donVi = 'triệu/m2';
  typeBdsIdselected = 5;
  useForSelected = 'SALE';
  dataChartCriteria;
  dataChartKhuVucTiemNang;
  dataChartXuHuongTimKiem;
  filterForm: FormGroup;
  provinceForm = new FormControl(null, Validators.required);
  districtForm = new FormControl();
  allProvince = [];
  allDistrict = [];
  allCriteria$: Observable<any>;
  allCriteriaData = [];
  allCriteriaByKhuVucId = [];
  allDistrictWhenFilterCriteria = [];
  capHanhChinhList = [];
  listResultFilter;
  provinceIdSelected;
  districtIdSelected;
  giaMaxError = false;
  pageSelected = 1;
  // pageSelectedKhuVucTiemNang = 1;
  pageSize = 4;
  idDiaChiSelected: number;
  typeDiaChiSelected: string;
  isNoDataFilter = false;
  totalPageCriteria = 0;
  totalPageCriteriaKhuVucTiemNang = 0;
  titlePopup = 'Thông tin';
  showErrorMessageSoTin = false;
  allowSaveCriteria = false;


  selectItemInPopupDetail(ele: HTMLInputElement, item) {
    console.log('checked: ', ele.checked);
    ele.checked = !ele.checked;
    if (ele.checked) {
      this.selectAddressIntoCriteriaDetail(item);
    } else {
      this.removeAddressIntoCriteriaDetail(item);
    }
  }


  checkBoxEvent(ele, item) {
    ele.checked = !ele.checked;
    if (ele.checked) {
      this.selectAddressIntoCriteriaDetail(item);
    } else {
      this.showPopupDuLieuDangCapNhatKhiChonDuongTrongPopup = false;
      this.removeAddressIntoCriteriaDetail(item);
    }
  }

  hightlightTab() {
    this.activatedRoute.queryParams.pipe(
      takeUntil(this.destroy$)
    ).subscribe(params => {
      this.selectedTab = params['type'] ?? 'tim-kiem';
      // this.tabSelectedMobile = 'xem-tong-quan';
    });
  }

  public validateForm() {
    this.filterForm.get('useFor').valueChanges.subscribe(useFor => {
      if (useFor === 'SALE') {
        this.donVi = 'triệu/m2';
      } else {
        this.donVi = 'nghìn/m2/tháng';
      }
    });
  }

  loadingChartOverview = false;
  selectedId;

  submitted = false;
  get f() { return this.filterForm.controls; }


  tabValueChange(tab) {
    this.dataChartCriteria = { ...this.dataChartCriteria, title: this.titleChart };
    if (tab === 'xem-tong-quan') {
      this.tabIndex = 0;
    } else if (tab === 'tim-kiem') {
      this.tabIndex = 2;
    } else {
      this.tabIndex = 1;

    }
  }

  toggleTypeBds(id: number, tinhId, quanId) {
    const useFor = this.useForSelected;
    const typeBdsId = id;
    const loaiDiaChiKVTiemNangNhatSelected = this.loaiDiaChiKVTiemNangNhatSelected;
    const loaiDiaChiXuhuongTimKiemSelected = this.loaiDiaChiXuhuongTimKiemSelected;
    this.typeBdsIdselected = id;
    this.getTopKhuVucTiemNangNhatTongQuan(tinhId, quanId, loaiDiaChiKVTiemNangNhatSelected, typeBdsId, useFor);
    this.getTopXuHuong(tinhId, quanId, loaiDiaChiXuhuongTimKiemSelected, typeBdsId, useFor);
  }

  toggleUseFor(id: string, tinhId, quanId) {
    const useFor = id;
    const typeBdsId = this.typeBdsIdselected;
    const loaiDiaChiKVTiemNangNhatSelected = this.loaiDiaChiKVTiemNangNhatSelected;
    const loaiDiaChiXuhuongTimKiemSelected = this.loaiDiaChiXuhuongTimKiemSelected;
    this.useForSelected = id;
    this.getTopKhuVucTiemNangNhatTongQuan(tinhId, quanId, loaiDiaChiKVTiemNangNhatSelected, typeBdsId, useFor);
    this.getTopXuHuong(tinhId, quanId, loaiDiaChiXuhuongTimKiemSelected, typeBdsId, useFor);
  }

  selectItemToDrawChart(item) {
    this.selectedId = item.id;
    this.shouldShowBtnSeeCriteriaDetail = false;
    this.allowSaveCriteria = false;
    // this.donVi = item.useFor === 'SALE' ? 'triệu/m2' : 'nghìn/m2/tháng';
    this.getDetailCriteria(item, item.isTongQuan);
  }

  xemChiTietCriteria(isMobile = false, criteria = this.criteriaSelected) {
    console.log("this.criteriaSelected: ", this.criteriaSelected);
    this.allowSaveCriteria = false;
    // this.donVi = criteria.useFor === 'SALE' ? 'triệu/m2' : 'nghìn/m2/tháng';
    this.getDetailCriteria(criteria, false, isMobile);
  }

  changeMucTimKiemTiemNangNhat(e, tinhId = null, quanId = null) {
    const useFor = this.useForSelected;
    const typeBdsId = this.typeBdsIdselected;
    const loaiDiaChi = e.target.value;
    this.loaiDiaChiKVTiemNangNhatSelected = e.target.value;
    this.getTopKhuVucTiemNangNhatTongQuan(tinhId, quanId, loaiDiaChi, typeBdsId, useFor);
  }

  changeMucTimKiemXuHuong(e, tinhId = null, quanId) {
    const useFor = this.useForSelected;
    const typeBdsId = this.typeBdsIdselected;
    const loaiDiaChi = e.target.value;
    this.loaiDiaChiXuhuongTimKiemSelected = e.target.value;
    this.getTopXuHuong(tinhId, quanId, loaiDiaChi, typeBdsId, useFor);
  }


  refreshDataKhiThayDoiXemPhanTichKhuVuc() {
    this.phanTichNangCaoService.khuVucDangXem$.asObservable().pipe(
      delay(300)
    ).subscribe(data => {
      if (data) {
        const { tinhId, quanId } = data;
        this.districtIdSelected = quanId ? +quanId : null;
        this.filterForm.patchValue({ tinhId: null });
        if (tinhId && quanId) {
          this.idDiaChiSelected = quanId;
          this.typeDiaChiSelected = 'quan_huyen';
          this.filterForm.patchValue({ tinhId });
          this.provinceForm.patchValue(tinhId);
          // this.districtForm.patchValue(quanId);
          this.getAllCriteriaBaseAreaId(tinhId, quanId);
        } else {
          this.filterForm.patchValue({ tinhId, quanId: null });
          this.provinceForm.patchValue(tinhId);
          this.getAllCriteriaBaseAreaId(tinhId, null);
        }
      } else {
        this.idDiaChiSelected = -1;
        this.typeDiaChiSelected = 'ca_nuoc';
        this.filterForm.patchValue({ tinhId: -1, quanId: null });
        this.provinceForm.patchValue(-1);
        this.districtForm.patchValue(null);
        this.getAllCriteriaBaseAreaId(null, null);
      }
    });
  }


  getTopKhuVucTiemNangNhatTongQuan(tinhId, quanId, loaiDiaChi = 'duong', typeBdsId = 5, useFor = 'SALE', isMobile = false) {
    this.loadingChartKVTiemNang = true;
    return this.phanTichNangCaoService.getTopKhuVucTiemNangNhat(tinhId, quanId, loaiDiaChi, typeBdsId, useFor, true).pipe(
      catchError(err => {
        this.loadingChartKVTiemNang = false;
        return of(err);
      })
    ).subscribe(result => {
      this.loadingChartKVTiemNang = false;
      if (result['series'].length && result['years'].length) {
      const title = this.getTitleChart(loaiDiaChi);
        this.dataChartKhuVucTiemNang = { ...dataInitKetQuaTimKiemKhuVucTiemNang, data: result['series'], series: result['series'], xAxis: { categories: result['years'] }, title, yAxis: { title: { text: isMobile ? null : `Giá chào ${useFor === 'SALE' ? 'bán' : 'thuê'} trung bình` } } };
      } else {
        this.dataChartKhuVucTiemNang = { ...dataInitKetQuaTimKiemKhuVucTiemNang, data: [], title: null };
      }
    });
  }

  getTopXuHuong(tinhId, quanId, loaiDiaChi = 'duong', typeBdsId = 5, useFor = 'SALE', isMobile = false) {
    this.loadingChartKVThoaManTieuChiNguoiDung = true;
    this.phanTichNangCaoService.getTopXuHuong(tinhId, quanId, loaiDiaChi, typeBdsId, useFor).pipe(
      catchError(err => {
        this.loadingChartKVThoaManTieuChiNguoiDung = false;
        return of(err);
      })
    ).subscribe(result => {
      this.loadingChartKVThoaManTieuChiNguoiDung = false;
      this.dataChartXuHuongTimKiem = { ...dataInitKetQuaTimKiemKhuVucTiemNang, data: [], title: null };
      if (result['series'].length && result['years'].length) {
        const title = this.getTitleChart(loaiDiaChi);
        this.dataChartXuHuongTimKiem = { ...this.dataChartXuHuongTimKiem, data: result['series'], series: result['series'], xAxis: { categories: result['years'] }, title, yAxis: { title: { text: isMobile ? null : `Giá chào ${useFor === 'SALE' ? 'bán' : 'thuê'} trung bình` } } };
      } else {
        this.dataChartXuHuongTimKiem = { ...dataInitKetQuaTimKiemKhuVucTiemNang, data: [], title: null };
      }
    });
  }

  public trackFilterFormValueChange() {
    this.filterForm.valueChanges.subscribe(formValue => {
      console.log("form value: ", formValue);
      if (formValue.tinhId && formValue.quanId) {
        this.capHanhChinhList = this.capHanhChinh.filter(item => item.id !== 'tinh' && item.id !== 'quan_huyen');
      } else if (!formValue.tinhId || formValue.tinhId === -1) {
        this.capHanhChinhList = this.capHanhChinh;
      } else if (formValue.tinhId) {
        this.capHanhChinhList = this.capHanhChinh.filter(item => item.id !== 'tinh');
      } else {
        this.capHanhChinhList = this.capHanhChinh;
      }
    });
  }

  navigateTo(segment: string) {
    this.showPopupCriteriaDetail = false;
    this.router.navigate(['/phan-tich-khu-vuc/' + segment]);
  }

  getDanhSachTieuChiDuaVaoKhuVucDaChon() {
    const provinceId = this.provinceForm.value;
    const districtId = this.districtForm.value;
    this.addressIdSelectedUI = provinceId;
    if (!provinceId && !districtId) {
      alert("Vui lòng chọn một khu vực để xem!");
      return;
    }
    this.idDiaChiSelected = provinceId;
    this.pageSelected = 1;
    if (provinceId === -1) {
      this.getAllCriteriaBaseAreaId(null, null);
    } else if (provinceId && districtId) {
      this.getAllCriteriaBaseAreaId(provinceId, districtId);
    } else {
      this.getAllCriteriaBaseAreaId(provinceId, null);
    }
  }

  // chon address de ve chart khi xem chi tiet tieu chi
  selectAddressIntoCriteriaDetail(criteria) {
    console.log("selectAddress: ", criteria);
    const addressItemChart = this.dataCriteriaDetailLocal['series'].find(item => item.idDiaChi === criteria.idDiaChi);
    if (addressItemChart) {
      // series dung de update vao highchar component de ve bieu do
      // check trong local da co du lieu ko, neu ko thi goi api lay ket qua ve
      const series = [...this.dataChartCriteriaDetailInit['data'], addressItemChart];
      this.dataChartCriteriaDetailInit = { ...this.dataChartCriteriaDetailInit, data: series, series, title: this.titleChart };
    } else {
      this.loadingWhenGetDataToDrawChartIntoDetail = true;
      const {idDiaChi, loaiDiaChi} = criteria;
      const {useFor, typeBdsId, loaiTangTruong} = this.dataCriteriaDetailLocal['queryNangCaoDTO'];
      const body = {
        idDiaChi,
        loaiDiaChi,
        useFor,
        typeBdsId
      };
      this.phanTichNangCaoService.getAnalysisByAddressId(body, loaiTangTruong).subscribe(result => {
        console.log("getAnalysisByAddressId: ", result);
        if (result) {
          // add line chart cho address da chon
          this.showPopupDuLieuDangCapNhatKhiChonDuongTrongPopup = false;
          const series = [...this.dataChartCriteriaDetailInit['data'], ...result['series']];
          // ađ ket qua tra ve toi datalocal de ko phai goi len server lay tiep
          this.dataCriteriaDetailLocal = { ...this.dataCriteriaDetailLocal, series: [...this.dataCriteriaDetailLocal['series'], ...result['series']], title: this.titleChart };
          this.dataChartCriteriaDetailInit = { ...this.dataChartCriteriaDetailInit, data: series, series, xAxis: { categories: result['years'] }, title: this.titleChart, };
        } else {
          this.duongDangChonTrongPopup = criteria.tenDayDu;
          this.showPopupDuLieuDangCapNhatKhiChonDuongTrongPopup = true;
        }
        this.loadingWhenGetDataToDrawChartIntoDetail = false;
      });
    }
  }
  // xoa chart khi xem chi tiet tieu chi

  removeAddressIntoCriteriaDetail(event) {
    // remove item bieu do
    if (event) {
      const series = this.dataChartCriteriaDetailInit['data'].filter(item => item.idDiaChi !== event.idDiaChi);
      this.dataChartCriteriaDetailInit = { ...this.dataChartCriteriaDetailInit, data: series, series, title: this.titleChart };
      console.log("new data chart: ", this.dataCriteriaDetailLocal);
    }
  }
  // selectAddressToDrawChart(event) {
  //   console.log("selectAddressToDrawChart: ", event);
  //   const addressItemChart = this.listResultFilter['series'].find(item => item.idDiaChi === event.idDiaChi);
  //   console.log('addressItemChart: ', addressItemChart);
  //   if (addressItemChart) {
  //     // series dung de update vao highchar component de ve bieu do
  //     // check trong local da co du lieu ko, neu ko thi goi api lay ket qua ve
  //     const series = [...this.dataChartInitKhuVucNangCao['data'], addressItemChart];
  //     this.dataChartInitKhuVucNangCao = { ...this.dataChartInitKhuVucNangCao, data: series, series, title: this.titleChart };
  //   } else {
  //     const tenLoaiBds = this.selectedBdsId === 5 ? 'Nhà mặt phố' : 'Nhà trong ngõ';
  //     const queryNangCaoDTO = { typeBdsId: this.selectedBdsId, tenLoaiBds };
  //     const analyticsAddressResDTO = event;
  //     this.loadingWhenGetDataToDrawChart = true;
  //     this.phanTichNangCaoService.getAnalysisByAddressId(queryNangCaoDTO, analyticsAddressResDTO).subscribe(result => {
  //       console.log("getAnalysisByAddressId: ", result);
  //       if (result) {
  //         // add line chart cho address da chon
  //         const series = [...this.dataChartInitKhuVucNangCao['data'], ...result['series']];
  //         // ađ ket qua tra ve toi datalocal de ko phai goi len server lay tiep
  //         this.listResultFilter = { ...this.listResultFilter, series: [...this.listResultFilter['series'], ...result['series']] };
  //         this.dataChartInitKhuVucNangCao = { ...this.dataChartInitKhuVucNangCao, data: series, series };
  //       } else {
  //         alert('Dữ liệu đường này đang cập nhật');
  //       }
  //       this.loadingWhenGetDataToDrawChart = false;
  //     });
  //   }
  // }
  removeAddressToRemoveChart(event) {
    console.log("removeAddressToRemoveChart: ", event);
    const series = this.dataChartInitKhuVucNangCao['data'].filter(item => item.idDiaChi !== event.value.idDiaChi);
    this.dataChartInitKhuVucNangCao = { ...this.dataChartInitKhuVucNangCao, data: series, series };
  }
  isSearchAfterSaveCriteria = false;
  // search tieu chi
  filterDataBaseCriteria(isMobile = false) {
    this.isSearchAfterSaveCriteria = true;
    const formValue = createFilterFormObject({ ...this.filterForm.value });
    this.isNoDataFilter = false;
    this.selectedBdsId = formValue.typeBdsId;
    this.tenBdsSelected = formValue.useFor;
    this.allowSaveCriteria = false;

    this.submitted = true;
    if (this.filterForm.invalid) {
      return;
    }
    if (this.filterForm.value.giaMax <= this.filterForm.value.giaMin) {
      this.giaMaxError = true;
      return;
    }
    this.filterFormValueBefore = this.filterForm.value;


    this.getInfoAddress(formValue).subscribe();

    this.shouldShowBtnSaveCriteria = false;
    this.loadingChart = true;
    this.showErrorMessageSoTin = false;
    this.giaMaxError = false;
    this.dataChartInitKhuVucNangCao = dataInitKetQuaTimKiemKhuVucTiemNang;
    this.listResultFilter = null;
    this.loadingWhenGetDataToDrawChartIntoDetail = true;
    this.phanTichNangCaoService.filterDataBaseCriteria(formValue).subscribe(res => {
      this.allowSaveCriteria = true;
      if (res.series.length && res.years.length) {
        console.log('filter res: ', res);
        this.isNoDataFilter = false;
        const data = { ...res, queryNangCaoDTO: formValue };
        this.listResultFilter = data;
        this.shouldShowBtnSaveCriteria = true;
        this.showPopupSearchResult(data, isMobile);
      } else {
        this.isNoDataFilter = true;
      }
      this.loadingChart = false;
    });
  }
  public getInfoAddress(formValue) {
    const idFull = this.getIdToGetInfoAddress(formValue);
    if (idFull) {
      return this.phanTichNangCaoService.getProvinceBaseDistrictId(idFull).pipe(
        tap((res: any) => {
          if (res && res._source) {
            if (res._source.quan_huyen) {
              const address = res._source.quan_huyen;
              this.diaChiDeLuuTieuChi = { ten: address.ten, tienTo: address.tien_to, tenDayDu: res._source.tieu_de };
            } else {
              const address = res._source.tinh;
              this.diaChiDeLuuTieuChi = { ten: address.ten, tienTo: address.tien_to, tenDayDu: res._source.tieu_de };
            }
          } else {
            this.diaChiDeLuuTieuChi = null;
          }
          console.log("address info result: ", res);
        })
      );
    }
    return of(null);
  }


  public getIdToGetInfoAddress(formValue) {
    let idFull = '';
    if (formValue.quanId) {
      idFull = `${formValue.quanId}_analytics_quan_huyen`;
    } else if (formValue.tinhId && formValue.tinhId !== -1) {
      idFull = `${formValue.tinhId}_analytics_tinh`;
    } else {
      idFull = null;
    }
    return idFull;
  }

  seeCriteriaDetailAfterSearch(isMobile = false) {
    // this.shouldShowBtnSaveCriteria = false;
    this.loadingWhenGetDataToDrawChartIntoDetail = true;
    this.showPopupSearchResult(this.listResultFilter, isMobile);
  }
  getTopKhuVucTiemNangNhatCuaBan(tinhId, quanId, loaiDiaChi = 'duong', typeBdsId = 5, useFor = 'SALE', isTongQuan, isMobile) {
    this.loadingChartKhuVucTiemNhatCuaToi = true;
    return this.phanTichNangCaoService.getTopKhuVucTiemNangNhat(tinhId, quanId, loaiDiaChi, typeBdsId, useFor, isTongQuan, 5).pipe(
      catchError(err => {
        this.loadingChartKhuVucTiemNhatCuaToi = false;
        return of(err);
      })
    ).subscribe(result => {
      this.loadingChartKhuVucTiemNhatCuaToi = false;
      if (result['series'].length && result['years'].length) {
        const groupByKhuVucId = result['listDienGiai'].groupBy('idDG');
        this.listDienGiai = Object.keys(groupByKhuVucId).map(key => {
          return {
            idDG: key,
            diaChi: groupByKhuVucId[key][0].diaChi,
            mucTimKiem: groupByKhuVucId[key][0].mucTimKiem,
            data: groupByKhuVucId[key].map(item => {
              return {
                ...item,
                tenDayDu: item.tenDayDu ? item.tenDayDu : 'Cả nước'
              }
            })
          }
        });

        const title = this.getTitleChart(loaiDiaChi);
        this.dataChartKhuVucQuanTamCuaBan = { ...this.dataChartKhuVucQuanTamCuaBan, data: result['series'], series: result['series'], xAxis: { categories: result['years'] }, title, yAxis: { title: { text: isMobile ? null : `Giá chào ${useFor === 'SALE' ? 'bán' : 'thuê'} trung bình` } } };
      } else {
        this.listDienGiai = [];
        this.dataChartKhuVucQuanTamCuaBan = { ...dataInitKetQuaTimKiemKhuVucTiemNang, data: [], title: null };
      }
    });
  }


  public showPopupSearchResult(result, isMobile = false) {
    this.titlePopup = 'Kết quả tìm kiếm tiêu chí của bạn';
    this.showPopupCriteriaDetail = false;
    const mucTimKiem = this.filterForm.get('mucTimKiem').value ?? 'duong';
    const loaiTangTruong = this.filterForm.get('loaiTangTruong').value ?? 'gia';
    const useFor = this.filterForm.get('useFor').value === 'SALE' ? 'bán' : 'thuê';
    this.donViHienThiTrongPopup = this.filterForm.get('useFor').value === 'SALE' ? 'triệu/m2' : 'nghìn/m2/tháng';
    this.titleChart = this.getTitleChart(mucTimKiem, loaiTangTruong);
    this.dataChartCriteriaDetailInit = { ...dataInitKetQuaTimKiemKhuVucTiemNang, data: [], years: [], selectedIds: [], title: null };
    setTimeout(() => {
      this.loadingWhenGetDataToDrawChartIntoDetail = false;

      this.dataChartCriteriaDetailInit = { ...this.dataChartCriteriaDetailInit, data: result['series'], series: result['series'], xAxis: { categories: result['years'] }, title: this.titleChart, yAxis: { title: { text: isMobile ? null : `${loaiTangTruong === 'gia' ? 'Giá' : 'Số lượng'} chào ${useFor} trung bình` } } };

    }, 400);
    this.dataCriteriaDetailLocal = {
      ...result,
      queryNangCaoDTO: { ...result['queryNangCaoDTO'], ...this.diaChiDeLuuTieuChi },
      enableNotiEmail: result['queryNangCaoDTO'].trangThai === 'bat_thong_bao' ? true : false
    };
    console.log("dataCriteriaDetailLocal show ui: ", this.dataCriteriaDetailLocal);
    this.showPopupCriteriaDetail = true;
  }

  getTitleChart(capHanhChinh: string, loaiTangTruong = 'gia') {

    return this.phanTichNangCaoService.getTitleChart(capHanhChinh, loaiTangTruong);
  }
  pageChange(pageNumber: number, isMobile = false, isTongQuan, tinhId, quanId) {
    console.log("event: ", pageNumber);
    if (pageNumber) {
      this.getAllCriteria(tinhId, quanId, isMobile, isTongQuan, pageNumber - 1);
    }
  }

  pageChangeKhuVucTiemNang(pageNumber: number) {
    this.pageSelected = pageNumber;
    if (pageNumber) {
      const tinhId = this.provinceForm.value === -1 ? null : this.provinceForm.value;
      const quanId = this.districtForm.value;
      this.getAllCriteriaBaseAreaId(tinhId, quanId, pageNumber - 1);
    }
  }
  getAllCriteria(tinhId, quanId, isMobile = false, isTongQuan = true, page = 0, pageSize = this.pageSize) {
    this.loadingCriteriaList = true;
    return this.phanTichNangCaoService.getAllCriteria(tinhId, quanId, page, pageSize, isTongQuan).pipe(
      tap(res => {
        if (res['payload']['totalData']) {
          this.totalPageCriteria = res['payload']['totalData'];
        }
      }),
      map((res: any) => {
        return this.transformDataCriteria(res);
      }),
      tap((result: any) => {
        this.readyUIOverview = false;
        if (result.length) {
          this.allCriteriaData = result;
        } else {
          this.allCriteriaData = [];
        }
      }),
      switchMap((result: any) => {
        if (result.length) {
          this.selectedId = result[0].id;
          this.criteriaSelected = result[0];
          return this.phanTichNangCaoService.getDetailCriteriaWithChartGetFromGraphql(result[0].id, result[0].loaiTangTruong).pipe(tap(res => {
            if (res['series'].length && res['years'].length) {
              this.shouldShowBtnSeeCriteriaDetail = true;
            } else {
              this.shouldShowBtnSeeCriteriaDetail = false;
            }
          }));
        }
        return of([]);

      }),
      tap(result => {
        console.log("data chart default tong quan: ", result);

      }),
      catchError(err => {
        this.loadingCriteriaList = false;
        return of(err);
      })
    ).subscribe(result => {
      this.loadingCriteriaList = false;
      if (result['series']?.length && result['years']?.length) {
        const mucTimKiem = result['queryNangCaoDTO'].mucTimKiem ?? 'duong';
        const loaiTangTruong = result['queryNangCaoDTO'].loaiTangTruong ?? 'gia';
        const useFor = result['queryNangCaoDTO'].useFor === 'SALE' ? 'bán' : 'thuê';
        const title = this.getTitleChart(mucTimKiem, loaiTangTruong);
        this.dataChartCriteria = { ...this.dataChartCriteria, data: result['series'], series: result['series'], xAxis: { categories: result['years'] }, title, yAxis: { title: { text: isMobile ? null : `${loaiTangTruong === 'gia' ? 'Giá' : 'Số lượng'} chào ${useFor} trung bình` } } };
      } else {
        this.dataChartCriteria = { ...dataInitKetQuaTimKiemKhuVucTiemNang, data: [], series: [], title: null };
      }


      this.readyUIOverview = true;
    });
  }
  getAllCriteriaBaseAreaId(tinhId, quanId, page = 0, pageSize = this.pageSize) {
    this.loadingCriteriaList = true;
    return this.phanTichNangCaoService.getAllCriteria(tinhId, quanId, page, pageSize, false).pipe(
      tap(res => {
        if (res['payload'] && res['payload']['totalData']) {
          this.totalPageCriteriaKhuVucTiemNang = res['payload']['totalData'];
        } else {
          this.totalPageCriteriaKhuVucTiemNang = 1;
        }
      }),
      map(res => {
        return this.transformDataCriteria(res);
      }),
      catchError(err => throwError(err))
    ).subscribe(result => {
      this.loadingCriteriaList = false;
      if (result.length) {
        this.allCriteriaByKhuVucId = result;
      } else {
        this.allCriteriaByKhuVucId = [];
      }
    });
  }

  public transformDataCriteria(res) {
    if (res && res.payload && res.payload.data.length) {
      return res.payload.data.map(item => {
        const tenKhuVuc = this.getTenKhuVuc(item['queryNangCaoDTO']);
        console.log("tenKhuVuc: ", tenKhuVuc);
        return {
          ...item.queryNangCaoDTO,
          countResults: item.countResults,
          // tenLoaiBds: this.loaiBatDongSan.find(i => i.id === item['queryNangCaoDTO'].typeBdsId)?.name ?? '',
          enableNotiEmail: item['queryNangCaoDTO'].trangThai === 'bat_thong_bao' ? true : false,
          tenKhuVuc
        }
      })
    }
    return [];
  }
  getTenKhuVuc(criteria) {
    switch (criteria.typeDiaChi) {
      case "tinh":
        return this.allProvince.find(province => province.id === criteria.khuVucId)?.name ?? '';
      case "quan_huyen":
        return this.allDistrictWhenFilterCriteria.find(district => district.id === criteria.khuVucId)?.name ?? '';
      case "phuong_xa":
        return '';
      case "duong":
        return '';
      default:
        return '';
    }
  }

  getDetailCriteria(item, isTongQuan = false, isMobile = false) {
    this.donViHienThiTrongPopup = item.useFor === 'SALE' ? 'triệu/m2' : 'nghìn/m2/tháng';
    // this.donVi = item.useFor === 'SALE' ? 'triệu/m2' : 'nghìn/m2/tháng';
    this.shouldShowBtnSaveCriteria = false;
    this.loadingChartKhiChonTieuChi = true;
    this.criteriaSelected = item;
    if (isTongQuan) {
      this.loadingChartOverview = true;
      this.dataChartCriteria = { ...dataInitKetQuaTimKiemKhuVucTiemNang, data: [], series: [], xAxis: { categories: [] }, title: null };
    } else {
      this.loadingWhenGetDataToDrawChartIntoDetail = true;
      this.showPopupCriteriaDetail = false;
      this.showLoadingWhenGetCriteriaDetail = true;
      this.dataChartCriteriaDetailInit = { ...dataInitKetQuaTimKiemKhuVucTiemNang, data: [], year: [], selectedIds: [], xAxis: { categories: [] }, title: null, };

    }
    this.phanTichNangCaoService.getDetailCriteriaWithChartGetFromGraphql(item.id, item.loaiTangTruong).pipe(
      catchError(err => {
        this.loadingChartKhiChonTieuChi = false;
        return of(err);
      })
    ).subscribe(result => {
      console.log("detail: ", result);
      this.loadingChartKhiChonTieuChi = false;
      if (result['series'].length && result['years'].length) {

        const mucTimKiem = result['queryNangCaoDTO'].mucTimKiem ?? 'duong';
        const loaiTangTruong = result['queryNangCaoDTO'].loaiTangTruong ?? 'gia';
        const useFor = result['queryNangCaoDTO'].useFor === 'SALE' ? 'bán' : 'thuê';
        this.titleChart = this.getTitleChart(mucTimKiem, loaiTangTruong);
        if (isTongQuan) {
          // this.dataChartDefaultIntoTongQuan = { ...dataInitKetQuaTimKiemKhuVucTiemNang, data: [], year: [], selectedIds: [], xAxis: { categories: [] }, title: null };
          this.dataChartCriteria = { ...this.dataChartCriteria, data: result['series'], series: result['series'], xAxis: { categories: result['years'] }, title: this.titleChart, yAxis: { title: { text: isMobile ? null : `${loaiTangTruong === 'gia' ? 'Giá' : 'Số lượng'} chào ${useFor} trung bình` } } };
          this.shouldShowBtnSeeCriteriaDetail = true;
        } else {
          this.dataCriteriaDetailLocal = {
            ...result,
            enableNotiEmail: result['queryNangCaoDTO'].trangThai === 'bat_thong_bao' ? true : false
          };
          setTimeout(() => {
            this.loadingWhenGetDataToDrawChartIntoDetail = false;
            this.dataChartCriteriaDetailInit = { ...this.dataChartCriteriaDetailInit, data: result['series'], series: result['series'], xAxis: { categories: result['years'] }, title: this.titleChart, yAxis: { title: { text: isMobile ? null : `${loaiTangTruong === 'gia' ? 'Giá' : 'Số lượng'} chào ${useFor} trung bình` } } };
          }, 400);

          this.titlePopup = 'Thông tin tiêu chí';
          this.showPopupCriteriaDetail = true;
          // this.showPopupDuLieuDangCapNhatKhiChonDuongTrongPopup = false;
          this.showPopupDuLieuDangCapNhatMobile = false;
        }
      } else {
        // this.showPopupDuLieuDangCapNhatKhiChonDuongTrongPopup = true;
        this.showPopupDuLieuDangCapNhatMobile = true;
        this.duongDangChonTrongPopup = item.tenDayDu;
        this.dataCriteriaDetailLocal = null;
        this.shouldShowBtnSeeCriteriaDetail = false;
        this.dataChartCriteria = { ...dataInitKetQuaTimKiemKhuVucTiemNang, data: [], series: [], xAxis: { categories: [] }, title: null };
      }
      this.loadingChartOverview = false;
      this.showLoadingWhenGetCriteriaDetail = false;
    });
  }

  openPopupConfirmRemoveCriteria(id: number) {
    this.idCriteriaSelectedRemove = id;
    this.showPopupConfirmRemove = true;
  }

  removeCriteria(tinhId, quanId) {
    this.loadingRemove = true;
    this.phanTichNangCaoService.removeCriteria(this.idCriteriaSelectedRemove).subscribe(res => {
      console.log("remove res: ", res);
      this.showPopupCriteriaDetail = false;
      if (this.allCriteriaByKhuVucId.length === 1) {
        this.getAllCriteriaBaseAreaId(tinhId, quanId);
      } else {
        this.allCriteriaByKhuVucId = this.allCriteriaByKhuVucId.filter(item => item.id !== this.idCriteriaSelectedRemove);

      }
      this.showPopupConfirmRemove = false;
      this.loadingRemove = false;
    });
  }

  toggleNotiEmail(data: { isEnable: boolean; id: string }) {
    console.log('toggleNotiEmail', data);
    if (data.isEnable) {
      this.phanTichNangCaoService.allowNotiToEmail(data.id, data.isEnable).subscribe(res => console.log("allowNotiToEmail res: ", res));;
    } else {
      this.phanTichNangCaoService.disabledNotiToEmail(data.id, data.isEnable).subscribe(res => console.log("disabledNotiToEmail res: ", res));;
    }
  }
  filterFormValueBefore;
  saveCriteria() {
    this.submitted = true;
    if (this.filterForm.invalid) {
      return;
    }
    if (this.filterForm.value.giaMax <= this.filterForm.value.giaMin) {
      this.giaMaxError = true;
      return;
    }
    this.shouldShowBtnSaveCriteria = false;
    const formVal = this.filterForm.value;
    const tenLoaiBds = formVal.typeBdsId === 5 ? 'Nhà mặt đường' : 'Nhà trong ngõ';

    const tinhId = formVal.tinhId ?? -1;

    const check = JSON.stringify(this.filterFormValueBefore) === JSON.stringify(this.filterForm.value);
    this.phanTichNangCaoService.currentProvinceIdSelectedToFilterCriteria$.next(+tinhId);
    this.loadingSaveCriteria = true;
    if (!this.isSearchAfterSaveCriteria || !check) {
      this.listResultFilter = null;
      const formValue = createFilterFormObject({ ...this.filterForm.value });
      this.getInfoAddress(formValue).subscribe(() => {
        const filterFormValue = { ...formVal, tenLoaiBds, tinhId, ...this.diaChiDeLuuTieuChi };
        this.saveCriateriaToServer(filterFormValue);
      });
    } else {
      const filterFormValue = { ...formVal, tenLoaiBds, tinhId, ...this.diaChiDeLuuTieuChi };
      this.saveCriateriaToServer(filterFormValue);
    }
  }
  public saveCriateriaToServer(data) {
    this.phanTichNangCaoService.saveCriteria(data).subscribe(res => {
      console.log('save res: ', res);
      this.loadingSaveCriteria = false;
      this.allowSaveCriteria = false;
      // this.diaChiDeLuuTieuChi = null;
      const creiteriaId = res['payload']?.data ?? null;
      if (res.description && res.description === 'Cập nhật thành công tiêu chí!') {
        this.toasterService.pop({
          type: 'success', title: 'Cập nhật tiêu chí thành công!',
          showCloseButton: false
        });
        this.getAllCriteriaAfterSaveIfAddressChange(creiteriaId);
      } else {

        this.toasterService.pop({
          type: 'success', title: 'Lưu tiêu chí thành công!',
          showCloseButton: false
        });
        if (!creiteriaId) {
          const criteria = {
            ...this.filterForm.value,
            id: -1,
            countResults: this.listResultFilter?.allData?.length ?? 0,
            // tenLoaiBds: this.loaiBatDongSan.find(i => i.id === this.filterForm.get('typeBdsId').value)?.name ?? '',
            enableNotiEmail: true,
            // tenKhuVuc: this.allProvince.find(province => province.id === this.filterForm.get('tinhId').value)?.name ?? ''
            ...this.diaChiDeLuuTieuChi
          };
          const criteriaNew = createCriteriaObject(criteria);
          this.allCriteriaByKhuVucId = [...this.allCriteriaByKhuVucId, criteriaNew];
        } else {
          this.getAllCriteriaAfterSaveIfAddressChange(creiteriaId);
        }
      }
    });
  }

  public getAllCriteriaAfterSaveIfAddressChange(creiteriaId: number) {
    this.dataChartInitKhuVucNangCao = dataInitKetQuaTimKiemKhuVucTiemNang;
    const provinceId = this.filterForm.get('tinhId').value || -1;
    const districtId = this.filterForm.get('quanId').value;
    if (provinceId === -1) {
      this.getAllCriteriaBaseAreaId(null, null);
    } else if (provinceId && districtId) {
      this.getAllCriteriaBaseAreaId(provinceId, districtId);
    } else {
      this.getAllCriteriaBaseAreaId(provinceId, null);
    }
    this.provinceForm.patchValue(provinceId);
    this.districtForm.patchValue(districtId);
    // if (check) {
    //   this.allCriteriaByKhuVucId = [...this.allCriteriaByKhuVucId, criteriaNew];
    //   this.districtForm.patchValue(this.filterForm.get('quanId')?.value ?? null);
    // } else {
    // const districtId = this.filterForm.get('quanId').value;
    // if (provinceId === -1) {
    //   this.idDiaChiSelected = -1;
    //   this.typeDiaChiSelected = 'ca_nuoc';
    //   this.getAllCriteriaBaseAreaId();
    // } else if (provinceId && districtId) {
    //   this.idDiaChiSelected = districtId;
    //   this.typeDiaChiSelected = 'quan_huyen';
    //   this.getAllCriteriaBaseAreaId();
    // } else {
    //   this.idDiaChiSelected = provinceId;
    //   this.typeDiaChiSelected = 'tinh';
    //   this.getAllCriteriaBaseAreaId();
    // }
    // this.provinceForm.patchValue(provinceId);
    // this.districtForm.patchValue(districtId);
    // }
  }

  destroy$ = new Subject();

  getAllProvince() {
    this.phanTichNangCaoService.getAllProvince().pipe(
      takeUntil(this.destroy$),
    ).subscribe(data => {
      if (data && data.length) {
        this.allProvince = data;
      } else {
        this.allProvince = [];
      }
    });
  }

  // trackParamMapChange(tinhId, quanId) {
  //   if (tinhId) {
  //     this.activatedRoute.paramMap.pipe(
  //       takeUntil(this.destroy$),
  //       switchMap(() => {
  //         if (quanId) {
  //           return this.phanTichNangCaoService.getAllDistrictByProvinceId();
  //         }
  //         return of([]);
  //       })
  //     ).subscribe(districts => {
  //       this.filterForm.patchValue({ tinhId: tinhId ?? -1 });
  //       this.provinceForm.patchValue(tinhId ?? -1);
  //       let idDiaChi;
  //       let typeDiaChi;
  //       if (districts.length) {
  //         idDiaChi = quanId;
  //         typeDiaChi = 'quan_huyen'
  //         this.filterForm.patchValue({ quanId: quanId });
  //         this.districtForm.patchValue(quanId);
  //       } else {
  //         idDiaChi = tinhId;
  //         typeDiaChi = 'tinh'
  //       }
  //       this.getAllCriteriaBaseAreaId(idDiaChi, typeDiaChi);
  //     });
  //   } else {
  //     this.filterForm.patchValue({ tinhId: -1 });
  //     this.provinceForm.patchValue(-1);
  //     this.getAllCriteriaBaseAreaId(-1, 'ca_nuoc');
  //   }
  // }
  // public getAllProvince2() {
  //   return combineLatest([this.activatedRoute.paramMap, this.activatedRoute.queryParams, this.phanTichNangCaoService.getAllProvince()]).pipe(
  //     takeUntil(this.destroy$),
  //     tap(([paramsMap, queryParams, data]) => {
  //       if (data && data.length) {
  //         // console.log("params: ", params['type']);
  //         // this.loadingCriteriaList = false;
  //         this.allProvince = data;
  //         if (queryParams['type'] === 'xem-tong-quan') {
  //           this.tabIndex = 0;
  //           this.provinceForm.patchValue(-1, { emitEvent: false });
  //           this.filterForm.patchValue({ tinhId: -1 }, { emitEvent: false });
  //           this.idDiaChiSelected = -1;
  //           this.typeDiaChiSelected = 'ca_nuoc';
  //           this.getAllCriteriaBaseAreaId();
  //         } else {
  //           this.tabIndex = 2;
  //           this.updateFilterFormBaseAreaSelected(paramsMap);
  //         }
  //       }
  //     }));
  // }


  // public updateFilterFormBaseAreaSelected(params) {
  //   if (params.get("segment")) {
  //     const areaSplit = params.get("segment").split('-');
  //     const typeDiaChi = areaSplit[0];
  //     this.addressIdSelectedUI = areaSplit[areaSplit.length - 1];
  //     this.idDiaChiSelected = areaSplit[areaSplit.length - 1];
  //     console.log("type: ", typeDiaChi);
  //     switch (typeDiaChi) {
  //       case 'quan':
  //       case 'huyen':
  //         this.getProvinceBaseDistrictId(+this.idDiaChiSelected, 'quan_huyen');
  //         break;
  //       case 'phuong':
  //       case 'xa':
  //         // this.filterForm.patchValue({ tinhId: 1 });
  //         // this.provinceForm.patchValue(1);
  //         this.typeDiaChiSelected = 'phuong_xa';
  //         this.getProvinceBaseDistrictId(+this.idDiaChiSelected, 'phuong_xa');
  //         // this.getAllCriteriaBaseAreaId(1, 'tinh');
  //         break;
  //       case 'duong':
  //       case 'pho':
  //         // this.filterForm.patchValue({ tinhId: 1 });
  //         // this.provinceForm.patchValue(1);
  //         this.typeDiaChiSelected = 'duong';
  //         this.getProvinceBaseDistrictId(+this.idDiaChiSelected, 'duong');
  //         break;
  //       default:
  //         this.typeDiaChiSelected = 'tinh';
  //         this.getAllCriteriaBaseAreaId();
  //         this.provinceForm.patchValue(+this.idDiaChiSelected);
  //         this.filterForm.patchValue({ tinhId: +this.idDiaChiSelected });
  //         break;
  //     }
  //   }
  // }

  // public getProvinceBaseDistrictId(id: number, typeDiaChi: string) {
  //   const idFull = `${id}_analytics_${typeDiaChi}`;
  //   this.phanTichNangCaoService.getProvinceBaseDistrictId(idFull).subscribe(result => {
  //     console.log("province result base district: ", result);
  //     if (result && result['_source']) {
  //       this.filterForm.patchValue({ tinhId: result['_source']['tinh']?.id ?? -1 }, { emitEvent: false });
  //       this.provinceIdSelected = +result['_source']['tinh']?.id ?? null;
  //       this.provinceForm.patchValue(this.provinceIdSelected, { emitEvent: false });
  //       if (result['_source']['quan_huyen']) {
  //         this.districtIdSelected = +result['_source']['quan_huyen']?.id ?? null;
  //         console.log('allDistrict: ', this.allDistrict);
  //         this.filterForm.patchValue({ quanId: this.districtIdSelected }, { emitEvent: false });
  //         this.districtForm.patchValue(this.districtIdSelected, { emitEvent: false });
  //         this.idDiaChiSelected = +result['_source']['quan_huyen']?.id ?? null;
  //         this.typeDiaChiSelected = 'quan_huyen';
  //         this.getAllCriteriaBaseAreaId();
  //       } else {
  //         this.idDiaChiSelected = +result['_source']['tinh']?.id ?? null;
  //         this.typeDiaChiSelected = 'tinh';
  //         this.getAllCriteriaBaseAreaId();
  //       }
  //       this.phanTichNangCaoService.currentProvinceIdSelected$.next(result['_source']['tinh']?.id ?? -1);
  //       this.phanTichNangCaoService.currentProvinceIdSelectedToFilterCriteria$.next(result['_source']['tinh']?.id ?? -1);
  //       // this.allDistrict = [...this.allDistrict, { name: result['_source'] }]
  //     }
  //   })
  // }
  public getAllDistrict() {
    return this.phanTichNangCaoService.getAllDistrictByProvinceId().pipe(tap(data => {
      if (data && data.length) {
        this.allDistrict = data;
        this.districtForm.patchValue(+this.districtIdSelected || null, { emitEvent: false });
        this.filterForm.patchValue({ quanId: +this.districtIdSelected || null });
        // this.filterForm.patchValue({ quanId: data[0].id })
      } else {
        this.allDistrict = [];
      }
    }));
  }

  public getAllDistrictWhenChangeProvinceToFilterCriteria() {
    return this.phanTichNangCaoService.getAllDistrictWhenChangeProvinceToFilterCriteria().pipe(tap((data: any) => {
      if (data && data.length) {
        this.allDistrictWhenFilterCriteria = data;
        // this.filterForm.patchValue({ quanId: +this.districtIdSelected || null })
      } else {
        this.allDistrictWhenFilterCriteria = [];
      }
    }));
  }

  public trackProvinceFormWhenFilterCriteria() {
    this.provinceForm.valueChanges.subscribe(provinceId => {
      if (!provinceId || provinceId === -1) {
        this.districtForm.disable({ emitEvent: false });
      } else {
        this.districtForm.enable({ emitEvent: false });
        this.phanTichNangCaoService.currentProvinceIdSelectedToFilterCriteria$.next(provinceId);
      }
      // this.addressIdSelectedUI = provinceId;
      this.districtForm.patchValue(null, { emitEvent: false });
    })
  }

  public checkDistrictFormChange() {
    this.filterForm.get('quanId').valueChanges.subscribe(quanId => {
      this.filterForm.patchValue({ mucTimKiem: 'duong' });
    });
  }

  public getAllDistrictWhenChangeProvince() {
    this.filterForm.get('tinhId').valueChanges.subscribe(provinceId => {
      this.filterForm.patchValue({ quanId: null });
      this.phanTichNangCaoService.currentProvinceIdSelected$.next(provinceId ?? null);
      if (!provinceId || provinceId === -1) {
        this.filterForm.get('quanId').disable({ emitEvent: false });
      } else {
        this.filterForm.get('quanId').enable({ emitEvent: false });
      }
    })
  }

  public initForm() {
    this.filterForm = this.fb.group({
      typeBdsId: [5, Validators.required],
      // tenLoaiBds: [5],
      tinhId: [-1],
      quanId: [null],
      mucTimKiem: ['duong', Validators.required],
      giaMin: [10, [Validators.required, Validators.min(0)]],
      giaMax: [500, [Validators.required, Validators.min(0)]],
      soTinMin: [1],
      // soTinMax: [null],
      loaiTangTruong: ['gia', Validators.required],
      huongTangTruong: ['tang', Validators.required],
      tyLeTangTruong: [10, Validators.required],
      khoangTangTruong: [1, Validators.required],
      useFor: ['SALE', Validators.required],
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}