import { NgModule } from '@angular/core';
import { DropdownMenuComponent, DropdownMenuLabelDirective, DropdownMenuItemsDirective, ClickToCloseDirective } from './dropdown-menu.component';
import { CommonModule } from '@angular/common';
import { ClickOutsideModule } from 'ng-click-outside';

@NgModule({
    imports: [
        CommonModule,
        ClickOutsideModule
    ],
    declarations: [
        DropdownMenuComponent,
        DropdownMenuLabelDirective,
        DropdownMenuItemsDirective,
        ClickToCloseDirective
    ],
    exports: [
        DropdownMenuComponent,
        DropdownMenuLabelDirective,
        DropdownMenuItemsDirective,
        ClickToCloseDirective
    ]
})
export class DropdownMenuModule {}
