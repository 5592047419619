<div class="container">
        <div class="pwd-content-card">
        <!-- <div class="col-md-4"> -->
            <h3 class="pwd-title" >Đặt lại mật khẩu</h3>

            <div class="alert alert-danger" *ngIf="keyMissing">
                <strong>Không tìm thấy mã đổi mật khẩu.</strong>
            </div>

            <!-- <div class="" style="font-style: italic;" *ngIf="!success && !keyMissing">
                <p >Nhâp vào mật khẩu mới</p>
            </div> -->
            <!-- <div class="alert alert-info" *ngIf="!success && !keyMissing">
                <p >Choose a new password</p>
            </div> -->

            <div class="alert alert-danger" *ngIf="error">
                <p >Đường link tương ứng với mã đổi mật khẩu không còn hiệu lực.</p>
            </div>

            <p class="alert alert-success" *ngIf="success">
                <span ><strong>Đổi mật khẩu thành công.</strong> Bạn có thể ấn vào </span>
                <a class="alert-link" (click)="login()" >đây</a> để đăng nhập lại.
            </p>

            <div class="alert alert-danger" *ngIf="doNotMatch" >
                The password and its confirmation do not match!
            </div>

            <div *ngIf="!keyMissing">
                <form *ngIf="!success" name="form" role="form" (ngSubmit)="finishReset()" #passwordForm="ngForm">
                    <div class="form-group">
                        <label class="form-control-label" for="password" >Mật khẩu mới</label>
                        <input type="password" class="form-control" id="password" name="password" #passwordInput="ngModel"
                               placeholder="Nhập mật khẩu mới"
                               [(ngModel)]="resetAccount.password" minlength=4 maxlength=50 required>
                        <div *ngIf="passwordInput.dirty && passwordInput.invalid">
                            <small class="form-text text-danger"
                               *ngIf="passwordInput.errors.required" >
                                Your password is required.
                            </small>
                            <small class="form-text text-danger"
                               *ngIf="passwordInput.errors.minlength" >
                                Your password is required to be at least 4 characters.
                            </small>
                            <small class="form-text text-danger"
                               *ngIf="passwordInput.errors.maxlength" >
                                Your password cannot be longer than 50 characters.
                            </small>
                        </div>
                        <app-password-strength-bar [passwordToCheck]="resetAccount.password"></app-password-strength-bar>
                    </div>

                    <div class="form-group">
                        <label class="form-control-label" for="confirmPassword" >Xác nhận mật khẩu mới</label>
                        <input type="password" class="form-control" id="confirmPassword" name="confirmPassword" #confirmPasswordInput="ngModel"
                               placeholder="Nhập lại mật khẩu mới"
                               [(ngModel)]="confirmPassword" minlength=4 maxlength=50 required>
                        <div *ngIf="confirmPasswordInput.dirty && confirmPasswordInput.invalid">
                            <small class="form-text text-danger"
                               *ngIf="confirmPasswordInput.errors.required">
                                Your password confirmation is required.
                            </small>
                            <small class="form-text text-danger"
                               *ngIf="confirmPasswordInput.errors.minlength">
                                Your password confirmation is required to be at least 4 characters.
                            </small>
                            <small class="form-text text-danger"
                               *ngIf="confirmPasswordInput.errors.maxlength">
                                Your password confirmation cannot be longer than 50 characters.
                            </small>
                        </div>
                    </div>
                    <button type="submit" [disabled]="passwordForm.form.invalid" class="btn appraisal-btn" >Xác nhận</button>
                    <!-- <button type="submit" [disabled]="passwordForm.form.invalid" class="btn appraisal-btn">Reset Password</button> -->
                </form>
            </div>

        <!-- </div> -->
    </div>
</div>
