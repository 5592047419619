
import { RouteStateService } from './../../shared/util/routeState.service';
import { LoginService } from 'src/app/core';
import { HttpClient } from '@angular/common/http';
import { LoadExternalScriptService } from './../../layouts/footer/load-external-script';
import { InterceptorService } from './../../blocks/interceptor/interceptor.service';
import { Subject } from 'rxjs';
import { AccountSsoService } from './../auth/account-sso.service';
import { SeoService } from './../../shared/util/seo.service';
import { AccountService } from './../auth/account.service';
import { AuthServerProvider } from './../auth/auth-jwt.service';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginSsoService {
  public popupWindow: any;
  public ssoHelperLink: string;
  public ssoLoginDomain: string;

  public alreadyLogin: boolean;
  public waitForLogedIn: Subject<boolean> = new Subject();

  public cenhomesSSO: any;
  public environment: any;

  constructor(
    public accountService: AccountService,
    public seoService: SeoService,
    public accountSsoService: AccountSsoService,
    public interceptorService: InterceptorService,
    public loadExternalScriptService: LoadExternalScriptService,
    public http: HttpClient,
    private loginService: LoginService,
    private routeStateService: RouteStateService,
  ) {
    this.alreadyLogin = false;
    this.waitForLogedIn.next(false);

    if (this.seoService.onBrowser) {
      if (environment.ssoType === 'sso') {
        this.loadExternalScriptService.loadDynamicScript(environment.sso.ssoLoginDomain + 'assets/sso.js').then(() => {
          console.log('loadExternalScriptService sso', (window as any).CenhomesSSO);
          this.cenhomesSSO = new (window as any).CenhomesSSO({
            cookieDomain: environment.sso.cookieDomain
          });
  
          // let dataReceived = null;
          // try {
          //   dataReceived = JSON.parse(decodeURIComponent(escape(window.atob(this.cenhomesSSO.getLargeCookie('TK')))));
          // } catch {
          //   dataReceived = {
          //     jwt: null,
          //     data: null
          //   };
          // }
          // dataReceived = JSON.parse(decodeURIComponent(escape(window.atob(this.cenhomesSSO.getLargeCookie('TK')))));
  
          // this.accountSsoService.jwt = dataReceived.jwt;
          // this.accountSsoService.SID = dataReceived.SID;
          // this.interceptorService.jwt = dataReceived.jwt;
          // this.interceptorService.SID = dataReceived.SID;
  
          // this.accountSsoService.account = dataReceived.data;
          // this.accountService.setIdentity(dataReceived.data);
          // console.log('JWT from cookie', this.interceptorService.jwt, dataReceived.data);
          // if (this.interceptorService.jwt) {
          //   this.interceptorService.jwtBehaviorSubject.next(this.interceptorService.jwt);
          // }
          this.cenhomesSSO.cookieChanged('SID', cookie => {
            console.log('Received TK', cookie);
            this.accountSsoService.SID = cookie;
            this.interceptorService.SID = cookie;
            // console.log('cookie nhan duoc realtime', cookie, dataReceived);
            const requestJWTUrl = `${environment.API.SERVER_API_G_AUTH}/auth/api/jwt-storages/${this.interceptorService.SID}`;
            const requestInfoUrl = `${environment.API.SERVER_API_G_AUTH}/auth/account`;
            /* Nhan duoc cookie tai khoan moi */
            console.log('Goi trong login service');
            this.interceptorService.gettingJWT = true;
            this.http.get<any>(requestJWTUrl).subscribe(res => {
              this.interceptorService.jwt = res && res.payload && res.payload.data && res.payload.data.jwt || null;
              this.http.get<any>(requestInfoUrl,
                {
                  headers: {
                    Authorization: 'Bearer ' + this.interceptorService.jwt
                  }
                }
              ).subscribe(info => {
                /* Gọi account khi truyền JWT của tài khoản thường và tài khoản đăng nhập bằng social khác nhau :() */
                const userInfo = (info.payload && info.payload.data) || info;
                this.accountSsoService.setUserIdentity(userInfo);
  
                this.accountSsoService.account = userInfo;
                this.accountService.setIdentity(userInfo);
                this.waitForLogedIn.next(true);
                this.interceptorService.jwtReady = true;
                this.interceptorService.jwtReadySubject.next(true);
              }, err => {
                if (err.status === 401) {
                  /* Thủ tục Logout */
                  this.logout();
                  /* Hết thủ tục logout */
                } else {
  
                }
              });
            });
          });
  
        });
      } else if (environment.ssoType === 'cenhomesSSO') {
      }

      if (this.seoService.onBrowser) {
        this.ssoHelperLink = environment.sso.ssoHelperLink;
        this.ssoLoginDomain = environment.sso.ssoLoginDomain;
        console.log('Init login sso service');

      }
    }
  }

  isLogged() {
    return this.interceptorService.jwt
  }

  verifyUserLogin(callback, afterLogin?: Function) {
    if (!this.loginService.getAccountService().hasAnyAuthority(['ROLE_USER'])) {
      this.routeStateService.appendParamFromUrl('login', 'open');
      this.login();
      this.waitingForLogedIn(() => {
        afterLogin();
      });
      // this.modalRef = this.loginModalService.open('Bạn cần đăng nhập để có thể định giá.', result => {
      //     if (result === 'login success') {
      //         afterLogin();
      //     }
      // });
    } else {
      callback();
    }
  }

  public login(): void {
    this.alreadyLogin = false;
    this.waitForLogedIn.next(false);
    if (this.seoService.onBrowser) {
      const params = {
        realms: environment.sso.realms,
        type: 'POST_POPUP_IFRAME',
        next: environment.sso.ssoLoginDomain
      };

      this.popupWindow = window.open(environment.sso.ssoLoginDomain + 'dang-nhap?t=' + btoa(JSON.stringify(params)), '_blank', 'location=yes,height=680,width=520,scrollbars=yes,status=yes');

      // loginSsoService
      // setTimeout(() => {
      //   // popupLogin.close();
      //   const data = (popupLogin as any).doLogin();
      //   // const data = popupLogin.opener.localStorage.getItem('jhi-auth-jwt');
      //   console.log(data, 'data');
      // }, 2000);
    }
  }
  public waitingForLogedIn(callback): void {
    if (this.alreadyLogin) {
      callback();
    } else {
      this.waitForLogedIn.subscribe(value => {
        if (value) {
          callback();
        }
      });
    }
  }
  public logout(logoutAll?: boolean): void {
    // logoutAll: gui request logout sang account.cenhomes.vn hay ko?
    if (this.seoService.onBrowser) {
      if (environment.ssoType === 'sso') {
        // window.localStorage.removeItem('jhi-g-auth-jwt');
        // window.localStorage.removeItem('jhi-account');
        // window.localStorage.removeItem('jhi-full_name');
        // window.localStorage.removeItem('jhi-user_id');

        // window.sessionStorage.removeItem('jhi-g-auth-jwt');
        // window.sessionStorage.removeItem('jhi-account');
        // window.sessionStorage.removeItem('jhi-full_name');
        // window.sessionStorage.removeItem('jhi-user_id');
        // this.interceptorService.jwt = null;

        this.accountSsoService.SID = null;
        this.interceptorService.SID = null;

        this.accountSsoService.jwt = null;
        this.accountSsoService.account = null;

        this.interceptorService.jwt = null;

        this.accountSsoService.account = null;
        this.accountSsoService.setUserIdentity(null);
        this.accountService.setIdentity(null, true);

        this.cenhomesSSO.removeLargeCookie('TK');
        this.cenhomesSSO.removeCookie('SID');

        this.interceptorService.jwtReady = false;
        this.interceptorService.jwtReadySubject.next(null);
        this.interceptorService.gettingJWT = false;
        // const ifrm = document.createElement('iframe');
        // ifrm.setAttribute('src', this.ssoLoginDomain + 'dang-xuat');
        // ifrm.setAttribute('id', 'gia-nha-dat');
        // ifrm.style.display = 'none';
        // ifrm.style.width = '0px';
        // ifrm.style.height = '0px';
        // document.body.appendChild(ifrm);
        console.log('Thong tin account sau khi thoat', this.accountService.getUserIdentity());
      } else if (environment.ssoType === 'cenhomesSSO') {

        try {
          console.log('logout:removeItem:access_token');
          localStorage.removeItem(`access_token`);
          localStorage.removeItem(`refresh_token`);
        } catch {
          console.log('logout:removeItem:loi')
        }
        
        this.accountSsoService.SID = null;
        this.interceptorService.SID = null;

        this.accountSsoService.jwt = null;
        this.accountSsoService.account = null;

        this.interceptorService.jwt = null;

        this.accountSsoService.account = null;
        this.accountSsoService.setUserIdentity(null);
        this.accountService.setIdentity(null, true);

        // this.oauthService.logOut(logoutAll);
        // if (localStorage.get(`access_token`)) {
          localStorage.setItem('afterLogin', location.href);
          const logoutLink = `${environment.cenhomesSSO.logoutLink}?post_logout_redirect_uri=${environment.cenhomesSSO.logoutRedirectUri}`;
          location.href = logoutLink;
        // }

        localStorage.setItem('loaiBanDoDangChon', '["layerBanDoDiaChinh"]');

      }
    }
  }
}
