
export interface Criteria {
    giaMax:           number;
    giaMin:           number;
    huongTangTruong:  string;
    id:               number;
    khoangTangTruong: number;
    khuVucId:         number;
    loaiTangTruong:   string;
    mucTimKiem:       string;
    tenLoaiBds:       string;
    trangThai:        string;
    tyLeTangTruong:   number;
    typeBdsId:        number;
    typeDiaChi:       string;
    enableNotiEmail: boolean;
    countResults: number;
    tenKhuVuc: string;
}

export function createCriteriaObject(params: Partial<Criteria>) {
    return {
        giaMax: params.giaMax ?? 0,
        giaMin: params.giaMin ?? 0,
        huongTangTruong: params.huongTangTruong ?? '',
        id:  params.id ?? 0,
        khoangTangTruong: params.khoangTangTruong ?? 0,
        khuVucId: params.khuVucId ?? null,
        loaiTangTruong: params.loaiTangTruong ?? '',
        mucTimKiem: params.mucTimKiem ?? '',
        tenLoaiBds: params.tenLoaiBds ?? '',
        tyLeTangTruong: params.tyLeTangTruong ?? 0,
        typeBdsId: params.typeBdsId ?? null,
        typeDiaChi: params.typeDiaChi ?? '',
        countResults: params.countResults ?? 0,
        enableNotiEmail: params.enableNotiEmail ?? true,
        tenKhuVuc: params.tenKhuVuc ?? ''
    } as Criteria;
}

export function createFilterFormObject(params) {
    return {
        typeBdsId: params.typeBdsId,
      tenLoaiBds: params.tenLoaiBds ?? 'SALE',
      tinhId: params.tinhId,
      quanId: params.quanId,
      mucTimKiem: params.mucTimKiem ?? 'phuong_xa',
      giaMin: params.giaMin ?? 10,
      giaMax: params.giaMax ?? 500,
      soTinMin: params.soTinMin ?? null,
    //   soTinMax: params.soTinMax ?? null,
      loaiTangTruong: params.loaiTangTruong ?? 'gia',
      huongTangTruong: params.huongTangTruong ?? 'tang',
      tyLeTangTruong: params.tyLeTangTruong ?? 3,
      khoangTangTruong: params.khoangTangTruong ?? 3,
      useFor: params.useFor ?? 'SALE',
    }
}
