export const dataInitKetQuaTimKiemKhuVucTiemNang = {
    chart: {
        type: 'spline',
    },
    title: 'Biểu đồ lịch sử giá của các đường',

    // subtitle: {
    //     text: null,
    //     align: null,
    //     verticalAlign: null,
    //     y:  null
    // },

    yAxis: {
        title: {
            text: 'Giá trị chào bán trung bình'
        }
    },

    xAxis: {
        categories: []
    },
    tooltip: {
        //valueSuffix: '°C',
        enabled: true,
        useHTML: true,
        headerFormat: '<b >{point.key}</b><br/>',
        pointFormat: '<span >{point.ten}</span><br/><table style="width: 100%"><tr><td >Giá: </td>' + '<td style="text-align: right"><b style="color: {series.color}">{point.gia: ,.2f} {point.donvi} <span>{point.isBatThuong}</span></b></td></tr>' + '<tr><td >Số tin: </td>' + '<td style="text-align: right"><b style="color: {series.color}">{point.soTin}</b></td></tr>',
        footerFormat: '</table>',
        // pointFormat: '<tr><td style="color: {series.color}">{point.ten}: </td>' +
        //     '<td style="text-align: right"><b>{point.gia} EUR</b></td></tr>',
    },
    // 'Giá: {point.gia: ,.2f} {point.donvi}<br/>Số tin: {point.soTin}'
    // legend: {
    //     layout: 'vertical',
    //     align: 'right',
    //     verticalAlign: 'middle'
    // },

    plotOptions: {
        line: {
            dataLabels: {
                enabled: false
            },
            enableMouseTracking: true
        },
        series: {
            label: {
                enabled: false,
            }
        }
    },

    series: [],
    credits: {
        enabled: false
    },
    // [{
    //     name: 'Quận Nam Từ Liêm',
    //     data: [232, 231, 242, 242, 242, 246, 248, 250]
    // }, {
    //     name: 'Quận Hà Đông',
    //     data: [160, 161, 158, 156, 162, 163, 165, 166]
    // }, {
    //     name: 'Huyện Hoài Đức',
    //     data: [120, 122, 120, 118, 116, 119, 123, 125]
    // }],

    responsive: {
        rules: [{
            condition: {
                maxWidth: 500
            },
            chartOptions: {
                legend: {
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom'
                }
            }
        }]
    }

};
// export const dataChartKhuVucTiemNangInit = {
//     chart: {
//         type: 'spline',
//     },
//     title: 'Biểu đồ lịch sử giá của các đường',

//     // subtitle: {
//     //     text: null,
//     //     align: null,
//     //     verticalAlign: null,
//     //     y:  null
//     // },

//     yAxis: {
//         title: {
//             text: 'Giá trị chào bán trung bình'
//         }
//     },

//     xAxis: {
//         categories: []
//     },
//     tooltip: {
//         //valueSuffix: '°C',
//         enabled: true,
//         useHTML: true,
//         headerFormat: '<b >{point.key}</b><br/>',
//         pointFormat: '<span >{point.ten}</span><br/><table style="width: 100%"><tr><td >Giá: </td>' + '<td style="text-align: right"><b style="color: {series.color}">{point.gia: ,.2f} {point.donvi}</b></td></tr>' + '<tr><td >Số tin: </td>' + '<td style="text-align: right"><b style="color: {series.color}">{point.soTin}</b></td></tr>',
//         footerFormat: '</table>',
//     },

//     // legend: {
//     //     layout: 'vertical',
//     //     align: 'right',
//     //     verticalAlign: 'middle'
//     // },

//     plotOptions: {
//         line: {
//             dataLabels: {
//                 enabled: false
//             },
//             enableMouseTracking: true
//         },
//         series: {
//             label: {
//                 enabled: false,
//             }
//         }
//     },

//     series: [],
//     credits: {
//         enabled: false
//     },
//     // [{
//     //     name: 'Quận Nam Từ Liêm',
//     //     data: [232, 231, 242, 242, 242, 246, 248, 250]
//     // }, {
//     //     name: 'Quận Hà Đông',
//     //     data: [160, 161, 158, 156, 162, 163, 165, 166]
//     // }, {
//     //     name: 'Huyện Hoài Đức',
//     //     data: [120, 122, 120, 118, 116, 119, 123, 125]
//     // }],

//     responsive: {
//         rules: [{
//             condition: {
//                 maxWidth: 500
//             },
//             chartOptions: {
//                 legend: {
//                     layout: 'horizontal',
//                     align: 'center',
//                     verticalAlign: 'bottom'
//                 }
//             }
//         }]
//     }
// }

// export const dataChartXuHuongTimKiemInit = {
//     chart: {
//         type: 'spline',
//     },
//     title: 'Biểu đồ lịch sử giá của các đường',

//     // subtitle: {
//     //     text: null,
//     //     align: null,
//     //     verticalAlign: null,
//     //     y:  null
//     // },

//     yAxis: {
//         title: {
//             text: 'Giá trị chào bán trung bình'
//         }
//     },

//     xAxis: {
//         categories: []
//     },
//     tooltip: {
//         //valueSuffix: '°C',
//         enabled: true,
//         useHTML: true,
//         headerFormat: '<b >{point.key}</b><br/>',
//         pointFormat: '<span >{point.ten}</span><br/><table style="width: 100%"><tr><td >Giá: </td>' + '<td style="text-align: right"><b style="color: {series.color}">{point.gia: ,.2f} {point.donvi}</b></td></tr>' + '<tr><td >Số tin: </td>' + '<td style="text-align: right"><b style="color: {series.color}">{point.soTin}</b></td></tr>',
//         footerFormat: '</table>',
//     },
//     // legend: {
//     //     layout: 'vertical',
//     //     align: 'right',
//     //     verticalAlign: 'middle'
//     // },

//     plotOptions: {
//         line: {
//             dataLabels: {
//                 enabled: false
//             },
//             enableMouseTracking: true
//         },
//         series: {
//             label: {
//                 enabled: false,
//             }
//         }
//     },

//     series: [],
//     credits: {
//         enabled: false
//     },
//     // [{
//     //     name: 'Quận Nam Từ Liêm',
//     //     data: [232, 231, 242, 242, 242, 246, 248, 250]
//     // }, {
//     //     name: 'Quận Hà Đông',
//     //     data: [160, 161, 158, 156, 162, 163, 165, 166]
//     // }, {
//     //     name: 'Huyện Hoài Đức',
//     //     data: [120, 122, 120, 118, 116, 119, 123, 125]
//     // }],

//     responsive: {
//         rules: [{
//             condition: {
//                 maxWidth: 500
//             },
//             chartOptions: {
//                 legend: {
//                     layout: 'horizontal',
//                     align: 'center',
//                     verticalAlign: 'bottom'
//                 }
//             }
//         }]
//     }
// }

// export const dataChartKhuVucQuanTamCuaBan = {
//     chart: {
//         type: 'spline',
//     },
//     title: 'Biểu đồ lịch sử giá của các đường',

//     // subtitle: {
//     //     text: null,
//     //     align: null,
//     //     verticalAlign: null,
//     //     y:  null
//     // },

//     yAxis: {
//         title: {
//             text: 'Giá trị chào bán trung bình'
//         }
//     },

//     xAxis: {
//         categories: []
//     },
//     tooltip: {
//         //valueSuffix: '°C',
//         enabled: true,
//         useHTML: true,
//         headerFormat: '<b >{point.key}</b><br/>',
//         pointFormat: '<span >{point.ten}</span><br/><table style="width: 100%"><tr><td >Giá: </td>' + '<td style="text-align: right"><b style="color: {series.color}">{point.gia: ,.2f} {point.donvi}</b></td></tr>' + '<tr><td >Số tin: </td>' + '<td style="text-align: right"><b style="color: {series.color}">{point.soTin}</b></td></tr>',
//         footerFormat: '</table>',
//     },
//     // legend: {
//     //     layout: 'vertical',
//     //     align: 'right',
//     //     verticalAlign: 'middle'
//     // },

//     plotOptions: {
//         line: {
//             dataLabels: {
//                 enabled: false
//             },
//             enableMouseTracking: true
//         }
//     },

//     series: [],
//     credits: {
//         enabled: false
//     },
//     // [{
//     //     name: 'Quận Nam Từ Liêm',
//     //     data: [232, 231, 242, 242, 242, 246, 248, 250]
//     // }, {
//     //     name: 'Quận Hà Đông',
//     //     data: [160, 161, 158, 156, 162, 163, 165, 166]
//     // }, {
//     //     name: 'Huyện Hoài Đức',
//     //     data: [120, 122, 120, 118, 116, 119, 123, 125]
//     // }],

//     responsive: {
//         rules: [{
//             condition: {
//                 maxWidth: 500
//             },
//             chartOptions: {
//                 legend: {
//                     layout: 'horizontal',
//                     align: 'center',
//                     verticalAlign: 'bottom'
//                 }
//             }
//         }]
//     }
// }


// export const dataChartPhanTichNangCaoInit = {
//     chart: {
//       type: "spline",
//     },
//     title: {
//       style: {
//         fontSize: '13px'
//       },
//       text: "Biểu đồ lịch sử giá các đường",
//     },
//     xAxis: {
//       categories: [],
//       // crosshair: true,
//     },
//     yAxis: {
//       title: {
//         text: null,
//       },
//     },
//     tooltip: {
//       //valueSuffix: '°C',
//       enabled: true,
//       useHTML: true,
//       headerFormat: "<b >{point.key}</b><br/>",
//       pointFormat:
//         '<span >{point.ten}</span><br/><table style="width: 100%"><tr><td >Giá: </td>' +
//         '<td style="text-align: right"><b style="color: {series.color}">{point.y: ,.2f} triệu/m2</b></td></tr>' +
//         "<tr><td >Số tin: </td>" +
//         '<td style="text-align: right"><b style="color: {series.color}">{point.soTin}</b></td></tr>',
//       footerFormat: "</table>",
//       // pointFormat: '<tr><td style="color: {series.color}">{point.ten}: </td>' +
//       //     '<td style="text-align: right"><b>{point.y} EUR</b></td></tr>',
//     },
//     plotOptions: {
//       line: {
//         dataLabels: {
//           enabled: false,
//         },
//         enableMouseTracking: true,
//       },
//       series: {
//         label: {
//           enabled: false,
//         },
//         marker: {
//             fillColor: 'red'
//         }
//       },
//     },
//     series: [
//       { name: '', data: []},
//       { name: '', data: []},
//       { name: '', data: []},
//     ],
//   };