import { Injectable } from '@angular/core';
import { Address } from '../../../shared/util/address';
import { AddressHttpService } from '../../../shared/http/address-http.service';

@Injectable({
  providedIn: 'root'
})
export class DangKyNhanBaoCaoService {
  public formIsOpen: boolean;
  public step: number;
  public title: string;
  public formSearch: Address;
  public phanKhucBds: any;
  public loaiTaiSan: any;
  public bieuDoChon: any;
  constructor(
    public addressHttpService: AddressHttpService
  ) {
    this.loaiTaiSan = [];
    this.phanKhucBds = [];
    this.bieuDoChon = [];
    this.formIsOpen = false;
    this.step = 0;
    this.title = 'Đăng ký nhận báo cáo';
    this.formSearch = new Address(this.addressHttpService, null, null, null, null, null, null, null, null, null);
  }
  dangKyNhanBaoCao(loaiBieuDo?: any): void {
    this.formIsOpen = true;
    this.bieuDoChon = [];
    if (loaiBieuDo) {
      this.bieuDoChon.push(loaiBieuDo);
    }
  }
}
