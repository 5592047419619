import { Component, OnInit, Injectable, Input, Output, EventEmitter } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class DatePickerMobileService {
  public listMonth: any;
  public currentMonth: number;
  public currentYear: number;
  public pastMonth: number;
  public pastYear: number;
  public rangeYears: number;
  public listYear: number[];
  constructor() {
    const date = new Date();
    this.rangeYears = 5
    this.currentMonth = date.getMonth();
    this.currentYear = date.getFullYear();
    this.pastMonth = 1;
    this.pastYear = this.currentYear - this.rangeYears;
    this.listYear = [];
    for (let i = this.pastYear; i <= this.currentYear; i++) {
      this.listYear.push(i);
    }
    console.log('this.listYear', this.listYear)
    this.listMonth = [];
    for (let i = 0; i < 12; i++) {
      this.listMonth.push(i);
    }

  }

}
@Component({
  selector: 'app-date-picker-mobile',
  templateUrl: './date-picker-mobile.component.html',
  styleUrls: ['./date-picker-mobile.component.scss']
})
export class DatePickerMobileComponent implements OnInit {
  @Input() date: any;
  @Output() confirmDateChangeEvent: EventEmitter<any> = new EventEmitter<any>();
  constructor(public datepickerMobileService: DatePickerMobileService) {
    // this.date = {
    //   dateMonth: undefined,
    //   dateTo: undefined,
    //   yearMonth: undefined,
    //   yearTo: 2020
    // }

  }

  ngOnInit(): void {
  }
  changeMonthTo(month) {
    // console.log('month', month)
    this.date.monthTo = month;
    this.confirmDateChange()
  }
  changeMonthFrom(month) {
    this.date.monthFrom = month;
    this.confirmDateChange()
  }
  prevYearTo(): void {
    this.date.yearTo--;
  }
  prevYearFrom(): void {
    this.date.yearFrom--;
  }
  nextYearTo(): void {
    this.date.yearTo++;
  }
  nextYearFrom(): void {
    this.date.yearFrom++
  }
  confirmDateChange(): void {
    console.log('this.date', this.date)
    this.confirmDateChangeEvent.emit(this.date);
  }
}
