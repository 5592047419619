<div class="button-bar-mobile" [ngClass]="{'map-button': stylebar === 1}" (click)="navbarMobileService.show = true">
    <!-- <svg style="color: black" width="24px" height="24px" viewBox="0 0 16 16" class="bi bi-justify" fill="currentColor"
        xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
            d="M2 12.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
    </svg> -->
    <div>
        <!-- <img src="/assets/images/img-menu.png" width="24" height="24" alt=""> -->
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 6H21" stroke="#222B45" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17 12H3" stroke="#222B45" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3 18H13" stroke="#222B45" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            
    </div>
</div>