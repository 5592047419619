import { forkJoin, Observable, of } from 'rxjs';
import { switchMap, tap, map } from 'rxjs/operators';

export function tranFormDataChart() {
    return function<T>(source$: Observable<any>) {
      return source$.pipe(
        switchMap(apis => {
            if (apis.length) {
              return forkJoin(apis).pipe(tap(res => console.log("getTopKhuVucTiemNang grapql: ", res)));
            }
            return of([]);
          }),
          map(res => {
            if (res.length) {
              return res.map(item => {
                const address = item['data']['address'];
                const analysis = address.analysts.map( i => ({...i, giaTrenDTTrungBinh: i.giaTrenDtTrungBinh}))
                return {...item['data']['address'], idDiaChi: address.id, analysis};
              });
            }
            return [];
          }),
      )
    }
}

