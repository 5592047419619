<div class="navbar-padding-fixed">

</div>
<div>
   

    <div class="row justify-content-center">
        <div class="col-md-8">
            <h2 *ngIf="settingsAccount">Cài đặt cá nhân cho <b>{{settingsAccount.login}}</b></h2>

            <div class="alert alert-success" *ngIf="success">
                <strong>Lưu cài đặt!</strong>
            </div>

            <app-alert-error></app-alert-error>

            <form name="form" role="form" (ngSubmit)="save()" #settingsForm="ngForm" *ngIf="settingsAccount" novalidate>

                <div class="form-group">
                    <input type="hidden" class="form-control" id="fullname" name="fullname"
                        [(ngModel)]="settingsAccount.fullName" minlength=1 maxlength=50
                        [value]="settingsAccount.firstName">
                </div>

                <div class="form-group">
                    <label class="form-control-label" for="firstName">Họ</label>
                    <input type="text" class="form-control" id="firstName" name="firstName"
                        placeholder="Nhập tên của bạn" [(ngModel)]="settingsAccount.firstName" minlength=1 maxlength=50
                        #firstNameInput="ngModel" required>
                    <div *ngIf="firstNameInput.dirty && firstNameInput.invalid">
                        <small class="form-text text-danger" *ngIf="firstNameInput.errors.required">
                            Tên của bạn không thể bỏ trống.
                        </small>
                        <small class="form-text text-danger" *ngIf="firstNameInput.errors.minlength">
                            Tên của bạn phải nhiều hơn 1 kí tự.
                        </small>
                        <small class="form-text text-danger" *ngIf="firstNameInput.errors.maxlength">
                            Tên của bạn không thể quá 50 kí tự.
                        </small>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="lastName">Tên</label>
                    <input type="text" class="form-control" id="lastName" name="lastName" placeholder="Nhập họ của bạn"
                        [(ngModel)]="settingsAccount.lastName" minlength=1 maxlength=50 #lastNameInput="ngModel"
                        required>
                    <div *ngIf="lastNameInput.dirty && lastNameInput.invalid">
                        <small class="form-text text-danger" *ngIf="lastNameInput.errors.required">
                            Họ của bạn không thể bỏ trống.
                        </small>
                        <small class="form-text text-danger" *ngIf="lastNameInput.errors.minlength">
                            Họ của bạn phải nhiều hơn 1 kí tự.
                        </small>
                        <small class="form-text text-danger" *ngIf="lastNameInput.errors.maxlength"
                            >
                            Họ của bạn không thể quá 50 kí tự.
                        </small>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="email">Email</label>
                    <input type="email" class="form-control" id="email" name="email" placeholder="Nhập email của bạn"
                        [(ngModel)]="settingsAccount.email" minlength="5" maxlength="254" #emailInput="ngModel" email
                        required>
                    <div *ngIf="emailInput.dirty && emailInput.invalid">
                        <small class="form-text text-danger" *ngIf="emailInput.errors.required">
                            Email của bạn không thể bỏ trống.
                        </small>
                        <small class="form-text text-danger" *ngIf="emailInput.errors.email">
                            Email của bạn chưa đúng định dạng.
                        </small>
                        <small class="form-text text-danger" *ngIf="emailInput.errors.minlength">
                            Email của bạn phải nhiều hơn 5 kí tự.
                        </small>
                        <small class="form-text text-danger" *ngIf="emailInput.errors.maxlength"
                            >
                            Email của bạn không thể quá 100 kí tự.
                        </small>
                    </div>
                </div>
                
                <button type="submit" [disabled]="settingsForm.form.invalid" class="btn btn-primary">Lưu thông
                    tin</button>
            </form>
        </div>
    </div>
</div>