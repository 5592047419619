import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../../environments/environment';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MarkerType, BDSType, BoundsType, MarkersEvent, MarkersEventType, GPSType, AddressType, FormSearchRequest, GPSRequestType } from './models/search-form.model';
import { Injectable } from '@angular/core';
import { PopupType } from '../../../shared/popup/popup.model';
import { BdsDetailRequestType } from '../ban-do.model';
import { UocLuongGiaService } from '../../map/uoc-luong-gia/uoc-luong-gia.service';
import { SnowplowService } from 'src/app/shared/util/tracking/snowplowService';
import { FormSearchService } from '../../map/form-search/form-search.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { first, map, pairwise, startWith } from 'rxjs/operators';
import { SearchFormService } from './search-form.service';
import { of } from 'zen-observable';



@Injectable({
  providedIn: 'root'
})
export class BanDoService {
  public popup: PopupType;
  public bounds: BoundsType;
  public addressTitle: string;
  public gps: any;
  public markersEvent: MarkersEventType;
  public danhSachBDS: BDSType[] = [];
  public staticInfo: any;
  public mapview: GPSType;

  public dinhGiaFormHouseLoading: boolean = false;
  public dinhGiaFormGpsLoading: boolean = false;
  
  public bdsDetailRequest: BdsDetailRequestType;
  public navigateBoundsWhenSearch: boolean;
  public fitBoundsWhenSearch: boolean;

  public modeCreateNew: boolean = false;

  public formParams: FormGroup;
  public address: FormSearchRequest;
  public prevAddress: FormSearchRequest;
  public bdsId: string;

  firstTimeLoadPage = true;
  diaChi: AddressType;
  openPopupSearchMobile = false;
  openPopupChiaSe = false;
  dataShareNetwork;
  constructor(
    private http: HttpClient,
    public service: UocLuongGiaService,
    private snowplowService: SnowplowService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private formSearchService: FormSearchService,
    private searchFormService: SearchFormService,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.popup = {
      open: false
    };
    this.address = {
      city: null,
      district: null,
      lane: null,
      alley: null,
      street: null,
      village: null,
      ward: null,
      address: null,
      so_to_ban_do: null,
      so_thua: null,
      top_left: null,
      bottom_right: null
    };
    this.prevAddress = {
      city: null,
      district: null,
      lane: null,
      alley: null,
      street: null,
      village: null,
      ward: null,
      address: null,
      so_to_ban_do: null,
      so_thua: null,
      top_left: null,
      bottom_right: null
    };

    // this.formParams = this.fb.group({
    //   bdsId: this.fb.control(null),
    //   from: this.fb.control(null),
    //   topLeft: this.fb.control(null),
    //   bottomRight: this.fb.control(null),
    //   tinhId: this.fb.control(null),
    //   address: this.fb.group({
    //     tinhId: [null],
    //     quanId: [null],
    //     phuongId: [null],
    //     duongId: [null],
    //     thonXomId: [null],
    //     ngoId: [null],
    //     ngachHemId: [null],
    //   })
    // });

    // this.formParams.get('bdsId').valueChanges.subscribe(bdsId => {
      // console.log('viewSearchResultDetail', `${this.bdsDetailRequest && this.bdsDetailRequest.id}`, bdsId)
      // if (bdsId !== `${this.bdsDetailRequest && this.bdsDetailRequest.id}`) {
      //   this.viewSearchResultDetail({
      //     'bds-id': bdsId,
      //     from: this.formParams.value.from
      //   });
      // }
    // });
    // this.formParams.get('topLeft').valueChanges.subscribe(topLeft => {
      // if (topLeft !== `${this.bounds && this.bounds.topLeft.lat},${this.bounds && this.bounds.topLeft.lng}`) {
      //   const tl = topLeft && topLeft.split(',');
      //   const br = this.formParams.value.bottomRight && this.formParams.value.bottomRight.split(',');
      //   this.bounds = {
      //     topLeft: {
      //       lat: Number(tl[0]),
      //       lng: Number(tl[1]),
      //     },
      //     bottomRight: {
      //       lat: Number(br[0]),
      //       lng: Number(br[1]),
      //     }
      //   };
      //   this.dinhGiaFormGPS(this.address, 'changedBySearching');
      // }
      // console.log('Topleft change single', value);
    // });
    // this.formAddress.valueChanges.subscribe(form => {
    //   console.log('watchAddressAndBoundsChange', JSON.stringify(form), this.address)
    //   if (form.city !== this.address.city) {
    //     this.address.city = form.city;
    //   }
    //   if (form.district !== this.address.district) {
    //     this.address.district = form.district;
    //   }
    //   this.dinhGiaFormGPS(this.address, 'changedBySearching');


    // });
    // this.formParams.get('topLeft').valueChanges.pipe(startWith(null), pairwise()).subscribe(([prevTopLeft, nextTopLeft]: [any, any]) => {
    //   console.log('Topleft change', nextTopLeft, this.formParams.value.bottomRight)
    //   if (nextTopLeft !== prevTopLeft) {

    //     const tl = nextTopLeft && nextTopLeft.split(',');
    //     const br = this.formParams.value.bottomRight && this.formParams.value.bottomRight.split(',');
    //     this.bounds = {
    //       topLeft: {
    //         lat: Number(tl[0]),
    //         lng: Number(tl[1]),
    //       },
    //       bottomRight: {
    //         lat: Number(br[0]),
    //         lng: Number(br[1]),
    //       }
    //     };
    //   }
    // });
  }


  openShare(url: string): void {
    this.openPopupChiaSe = true;
    this.dataShareNetwork = { url: encodeURIComponent('https://gianhadat.cenhomes.vn' + url) };

    const a = document.getElementsByClassName('zalo-share-button');
    if (a[0]) {
      a[0].setAttribute('data-href', encodeURIComponent('https://gianhadat.cenhomes.vn' + url));
    }

  }


  markerSelected($event): void {
    console.log('markerSelected', $event);
    if ($event) {
      console.log($event);
      this.popup = {
        open: true
      };
      this.bdsId = $event;

    }
  }
  setBounds($event): void {
    console.log('bounds triggers', this.bounds);
    // console.log('onmoveend', $event, JSON.stringify($event));
    this.bounds = $event;

  }
  searchGPSDone($event: MarkersEventType): void {
    this.markersEvent = $event;
  }
  searchBdsDone($event): void {
    this.danhSachBDS = $event;
  }
  traCuuToaDoEvent($event): void {
    console.log('$event', $event);
    this.mapview = $event;
  }
  viewSearchResultDetail(params: Params) {
    if (params['bds-id']) {
      this.popup = {
        open: true
      };
      if (params['from']) {
        this.bdsDetailRequest = {
          id: params['bds-id'],
          src: params['from']
        }
      } else {
        this.bdsDetailRequest = {
          id: params['bds-id'],
          src: null
        }
      }
      // this.bdsId = params['bds-id'];

    } else {
      this.popup = {
        open: false
      };
    }
    console.log('this.bdsDetailRequest', this.bdsDetailRequest);
  }

  modeCreateNewChange($event): void {
    if (!$event) {
      this.service.stepEstimateStatus.requestShow = false;
      this.service.stepEstimateStatus.resultShow = false;
    }

  }

  queryParamsHandle(params) {
    this.navigateBoundsWhenSearch = false;
    console.log('anyParamsHasChange', params);
    const key_so_to = +params.isSearchSoToSoThuaCu ? 'so_to_ban_do_cu' : 'so_to_ban_do';
    const key_so_thua = +params.isSearchSoToSoThuaCu ? 'so_thua_cu' : 'so_thua';
    this.address = {
      city: Number(params['tinh-id']) || null,
      district: Number(params['quan-id']) || null,
      ward: Number(params['phuong-id']) || null,
      village: Number(params['thon-xom-id']) || null,
      street: Number(params['duong-id']) || null,
      lane: params['ngo'],
      alley: params['ngach-hem'],
      address: params['so-nha'],
      [key_so_to]: params['so-to-ban-do'],
      [key_so_thua]: params['so-thua'],
      top_left: this.getBoundsFromParams(params, 'top-left'),
      bottom_right: this.getBoundsFromParams(params, 'bottom-right'),
    };

    if (Object.keys(params).length === 0) {
      this.address.city = 1;
    }

    this.searchFormService.initForm(this.address, initDone => {
      this.addressTitle = initDone.title;
    });

    this.bounds = {
      topLeft: {
        lat: this.address.top_left && this.address.top_left.lat,
        lng: this.address.top_left && this.address.top_left.lon,
      },
      bottomRight: {
        lat: this.address.bottom_right && this.address.bottom_right.lat,
        lng: this.address.bottom_right && this.address.bottom_right.lon,
      },
    }

    if (this.firstTimeLoadPage) { // Lần đầu vào trang có gì load đấy, ko phải check
      
      if (params['bds-id']) {
        if (params['tinh-id']) {
          this.navigateBoundsWhenSearch = false; // force fit bounds
          
          this.dinhGiaForm(this.address);
        } else {
          /* Nếu link chỉ có bds-id mà không có tinh-id, quan-id,... thì chỉ load bđs,
          sau đó sẽ tỉnh quận huyện sẽ đc navgate khi bds load xong (ở trong search-result-detail.service) */
        }
        this.viewSearchResultDetail({
          'bds-id': params['bds-id'],
          from: params['from'] || null
        });
      } else {
        this.navigateBoundsWhenSearch = false; // force fit bounds

        this.dinhGiaForm(this.address);
      }
      this.firstTimeLoadPage = false;
    } else {

      // if (this.bdsIdJustAppear(params)) { // Nếu bất động sản được bật lên
      //   this.viewSearchResultDetail({
      //     'bds-id': params['bds-id'],
      //     from: params['from'] || null
      //   });
      // } else if (this.bdsIdJustDisappear(params)) { // Nếu bất động sản bị tắt đi
        
      // } else if (this.bdsIdJustChange(params)) { // Khi đang xem bất động sản này chuyển qua bất động sản khác
      //   this.viewSearchResultDetail({
      //     'bds-id': params['bds-id'],
      //     from: params['from'] || null
      //   });
      // }

      if (params['bds-id']) {
        this.viewSearchResultDetail({
          'bds-id': params['bds-id'],
          from: params['from'] || null
        });
      }

      // if (
      //   this.anyParamsHasChange(params, ['tinh-id', 'quan-id', 'phuong-id', 'thon-xom-id', 'duong-id', 'ngo', 'ngach-hem', 'top-left', 'bottom-right', 'so-to-ban-do', 'so-thua', 't'])

      //   ) {
        if (params['mode'] === 'toa-do') {
          this.navigateBoundsWhenSearch = false;
        } else if (params['mode'] === 'home') {
          this.navigateBoundsWhenSearch = false;
        } else if (params['mode'] === 'dia-chi') {
          this.navigateBoundsWhenSearch = false;
        } else if (params['mode'] === 'thua-dat') {
          this.navigateBoundsWhenSearch = false;
        } else if (params['mode'] === 'bounds') {
          this.navigateBoundsWhenSearch = true;
        } else {
          this.checkBounds();
        }
        console.log('navigateBoundsWhenSearch 1', this.navigateBoundsWhenSearch);
        this.dinhGiaForm(this.address);
      // }

    }
    this.bdsId = params['bds-id'] || null;
    this.prevAddress = this.address;

  }

  checkBounds(): void {
    if (this.address.top_left) {
      this.navigateBoundsWhenSearch = true;
    } else {
      this.navigateBoundsWhenSearch = false;
    }
  }

  getBoundsFromParams(params: Params, bound: 'top-left' | 'bottom-right'): GPSRequestType {
    const tl = params[bound] && params[bound].split(',');
    if (tl && tl[0]) {
      return {
        lat: Number(tl && tl[0]),
        lon: Number(tl && tl[1])
      }
    } else {
      return null;
    }
  }

  anyParamsHasChange(params: Params, param: string[]): boolean {
    const map = {
      'tinh-id': 'city',
      'quan-id': 'district',
      'phuong-id': 'ward',
      'thon-xom-id': 'village',
      'duong-id': 'street',
      'ngo': 'lane',
      'ngach-hem': 'alley',
      'so-nha': 'address',
      'top-left': 'top_left',
      'bottom-right': 'bottom_right',
    };
    for (let item of param) {
      if ((item === 'top-left' || item === 'bottom-right') && this.prevAddress[map[item]]) {
        if (`${(params[item] || null)}` !== `${this.prevAddress[map[item]].lat  || null},${this.prevAddress[map[item]].lon  || null}`) {
          console.log(`anyParamsHasChange: ${item}`, `${(params[item] || null)}`, `${this.prevAddress[map[item]].lat  || null},${this.prevAddress[map[item]].lon  || null}`)
          return true;
        }
      } else {
        if (`${(params[item] || null)}` !== `${this.prevAddress[map[item]]  || null}`) {
          console.log(`anyParamsHasChange: ${item}`, `${(params[item] || null)}`, `${this.prevAddress[map[item]]  || null}`)
          return true;
        }
      }
    }
    return false;
  }

  bdsIdJustAppear(params: Params): boolean {
    // console.log(`paramJustAappear :`, `${this.bdsId}`, `${(params['bds-id'] || null)}`, `${this.bdsId}` === 'null');
    if (
      (`${(params['bds-id'] || null)}` !== `${this.bdsId}`)
      && (`${this.bdsId}` === 'null')
    ) {
      // console.log(`anyParamsHasChange: ${item}`, `${(params[item] || null)}`, `${this.address[map[item]]  || null}`)
      return true;
    } else {
      return false;
    }
  }
  bdsIdJustDisappear(params: Params): boolean {
    // console.log(`bdsIdJustDisappear :`, `${this.bdsId}`, `${(params['bds-id'] || null)}`, `${(params['bds-id'] || null)}` === 'null');
    if (
      (`${(params['bds-id'] || null)}` !== `${this.bdsId}`)
      && (`${(params['bds-id'] || null)}` === 'null')
    ) {
      
      return true;
    } else {
      return false;
    }
  }
  bdsIdJustChange(params: Params): boolean {
    if (`${(params['bds-id'] || null)}` !== `${this.bdsId  || null}`) {
      return true;
    } else {
      return false;
    }
  }

  timKiemNangCaoEvent(address) {
    const { tinhId, quanId, phuongId, duongId, thonXomId, ngoId, ngachHemId, soNha, lat, lng, url } = address;
    const snowPlowTracker = {
      schema_type: 'log_search_tracker',
      page_url: url,
      timestamp: '' + new Date().getTime(),
      finger: this.snowplowService.getFinger(),
      referer_url: this.snowplowService.getRefererUrl(),
      user_id: this.snowplowService.getUserId(),
      user_name: this.snowplowService.getUsername(),
      full_name: this.snowplowService.getFullname(),
      advanced_search_city: tinhId,
      advanced_search_district: quanId,
      advanced_search_ward: phuongId,
      advanced_search_group: duongId,
      advanced_search_street: thonXomId,
      advanced_search_lane: ngoId,
      advanced_search_alley: ngachHemId,
      advanced_search_house_number: soNha,
      advanced_search_lat: lat,
      advanced_search_lon: lng,
    }

    this.snowplowService.trackOnSelect(snowPlowTracker);

  }


  dinhGiaForm(body) {
    const newBody = {};
    for (let item in body) {
      if (body[item]) {
        newBody[item] = body[item];
      }
    }
    this.dinhGiaFormHouseLoading = true;
    this.formSearchService.dinhGiaFormHouse(newBody, 0, 50)
    .pipe(
      // catchError(err => {
      //   return of({
      //     status: -1,
      //     description: 'Không kết nối được đến máy chủ'
      //   });
      // })
    )
    .subscribe((bds) => {
      this.dinhGiaFormHouseLoading = false;
      this.danhSachBDS = bds;
      // this.openPopupSearchMobile = false;
    });
    this.dinhGiaFormGpsLoading = true;
    this.formSearchService.dinhGiaFormGps(newBody, 0, 50)
    .pipe(
      // catchError(err => {
      //   return of({
      //     status: -1,
      //     description: 'Không kết nối được đến máy chủ'
      //   });
      // })
    )
    .subscribe((gps: MarkerType[]) => {
      this.dinhGiaFormGpsLoading = false;
      const emitData: any = {
        event: null,
        markers: gps
      }
      this.markersEvent = emitData;
    });
  }

  destroy(): void {
    this.markersEvent = null;
  }

}
