<div class="popup" *ngIf="open">
    <div class="content" *ngIf="mode === 'NORMAL'"
    [ngStyle]="{'transform': top ? 'translateY(' + -top + ')' : auto}"
    [style.width.%]="popupWidth"
    [style.left.%]="50 - (popupWidth/2)"
    [style.borderRadius.px]="borderRadius"
    [style.maxHeight.vh]="popupHeight"
    [style.top.vh]="50 - (popupHeight/2)">
        <div *ngIf="closeButton" class="close">
            <a class="close-button" (click)="close()">
                <svg style="width: 18px; height: 18px; color: #616770" aria-hidden="true" focusable="false"
                    data-prefix="fal" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10 fa-2x">
                    <path fill="currentColor"
                        d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
                        class=""></path>
                </svg>
            </a>
        </div>
        <div *ngIf="popupHeader" class="popup-header d-flex">
            <div class="title w-100">
                <ng-template [ngTemplateOutlet]="popupHeader?.templateRef"></ng-template>
            </div>
        </div>
        <div class="popup-body" [style.padding.px]="padding" [style.paddingTop.px]="paddingTop" [style.paddingBottom.px]="paddingBottom">
            <ng-template [ngTemplateOutlet]="popupBody?.templateRef"></ng-template>
        </div>
        <div *ngIf="popupFooter" class="popup-footer">
            <div class="text-center">
                <ng-template [ngTemplateOutlet]="popupFooter?.templateRef"></ng-template>
            </div>
        </div>
    </div>



    <div class="content" *ngIf="mode === 'FULLHEIGHT'"
    [ngStyle]="{'transform': top ? 'translateY(' + -top + ')' : auto}"
    [style.width.%]="popupWidth"
    [style.left.%]="50 - (popupWidth/2)"
    [style.borderRadius.px]="borderRadius"
    [style.height.px]="popupHeight"
    [style.top.px]="16">
        <div *ngIf="closeButton" class="close">
            <a class="close-button" (click)="close()">
                <svg style="width: 18px; height: 18px; color: #616770" aria-hidden="true" focusable="false"
                    data-prefix="fal" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10 fa-2x">
                    <path fill="currentColor"
                        d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
                        class=""></path>
                </svg>
            </a>
        </div>
        <div *ngIf="popupHeader" class="popup-header d-flex">
            <div class="title w-100">
                <ng-template [ngTemplateOutlet]="popupHeader?.templateRef"></ng-template>
            </div>
        </div>
        <div class="popup-body" [style.padding.px]="padding" [style.paddingTop.px]="paddingTop" [style.paddingBottom.px]="paddingBottom">
            <ng-template [ngTemplateOutlet]="popupBody?.templateRef"></ng-template>
        </div>
        <div *ngIf="popupFooter" class="popup-footer">
            <div class="text-center">
                <ng-template [ngTemplateOutlet]="popupFooter?.templateRef"></ng-template>
            </div>
        </div>
    </div>
</div>

