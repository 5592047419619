import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { AccountSsoService } from './../../../core/auth/account-sso.service';
import { LoginSsoService } from './../../../core/login/login-sso.service';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import {
  StepEstimate,
  KetQuaUocLuongGia,
  ketQuaUocLuongGiaDefault,
  makeStaticInfo,
  suggestSoTangFromLoaiNha,
  diaChiDefault,
  scrollToTop,
  TabPrice,
  PopupType,
  popupDefault,
  Status,
  statusDefault,
  thuaDatDefault,
  CoKhungGia,
  StepEstimateStatus,
  ChiTietGias
} from './uoc-luong-gia.const';
import { RoadWidthValidator, LandTypeValidator, SoTangValidator, EmailValidator, MatTienValidator } from './validator.const';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { formEstimateCreate, formEstimateReset } from './uoc-luong-gia.form';
import { environment } from '../../../../environments/environment';
import { of } from 'zen-observable';
import { round } from '../../phan-tich-khu-vuc/helper.chart';
import { BehaviorSubject } from 'rxjs';
import { StaticInfoService } from '../../../shared/static-info.service';
import { AppErrorService } from 'src/app/app-error.service';
import { SnowPlowTrackerType } from '../../../shared/util/interface';
import { SnowplowService } from '../../../shared/util/tracking/snowplowService';
import { SearchResultDetailService } from '../../ban-do/generic/search-result-detail.service';

declare var L: any;

@Injectable({
  providedIn: 'root'
})
export class UocLuongGiaService {
  public open: boolean;
  public form: FormGroup;
  public status: Status;
  public formSendEmail: FormGroup;
  public step: StepEstimate;
  public stepEstimateStatus: StepEstimateStatus;
  public tabprice: TabPrice;
  public result: KetQuaUocLuongGia;
  public currentRawResult: any;
  public xacNhanUocLuongGia: PopupType;
  public baoDuLieuChuaChinhXac: PopupType;
  public soLuotUocLuongGia: number;
  public soLuotTraCuuQuyHoach = 0;
  public mapping: any;

  public thuaDat: any;
  public batDongSan: any;
  public diaChi: any;
  public coKhungGia: CoKhungGia;

  public staticInfo: any;
  public coNhieuLoaiDat: boolean;
  public nhieuLoaiDat: any;

  public thuaDatXungQuanh: any;
  public thuaDatDangChon: any; // Đánh dấu thửa đất đang chọn vừa đc load
  public thuaDatGanNhatId: any; // Id thửa đất gần nhất lấy từ thửa đất xung quanh

  snowPlowTracker: SnowPlowTrackerType;

  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    public loginSsoService: LoginSsoService,
    public accountSsoService: AccountSsoService,
    public staticInfoService: StaticInfoService,
    public appErrorService: AppErrorService,
    private toasterService: ToasterService,
    private snowplowService: SnowplowService,
    public router: Router,
    private nzNotificationService: NzNotificationService,
    public searchResultDetailService: SearchResultDetailService,
  ) {
    this.xacNhanUocLuongGia = popupDefault;
    this.baoDuLieuChuaChinhXac = {
      open: false,
      header: '',
      body: ''
    };
    this.status = statusDefault;
    this.diaChi = diaChiDefault;
    this.open = false;
    this.step = 'HIDE_ESTIMATE';
    this.stepEstimateStatus = {
      requestShow: false,
      resultShow: false,
    };
    this.mapping = {
      mapInDetail: null,
      duongBoundLayerGroup: null,
      makerTamThuaLayerGroup: null,
      // mapInited: new BehaviorSubject<boolean>(false)
    }
    this.tabprice = 'GIA_THAM_DINH';
    this.form = formEstimateCreate(this.fb);
    this.formSendEmail = this.fb.group({
      dongYNhanEmail: this.fb.control(true),
      email: this.fb.control('')
    },
      {
        validators: [
          EmailValidator()
        ]
      });
    this.disableForm();
    this.form.get('loaiNhaId').valueChanges.subscribe(loaiNhaId => {
      this.form.patchValue({ loaiNhaId: Number(loaiNhaId) }, { emitEvent: false });
      const soTangSuggest = suggestSoTangFromLoaiNha(loaiNhaId);
      if (soTangSuggest === -1) {
        // this.form.get('soTang').patchValue(null);
        this.form.get('dienTichSuDung').patchValue(null);
      } else {
        const soTang =
          this.batDongSan &&
          this.batDongSan.congTrinhSubmitDTOSet &&
          this.batDongSan.congTrinhSubmitDTOSet[0] &&
          this.batDongSan.congTrinhSubmitDTOSet[0].congTrinhDTO &&
          this.batDongSan.congTrinhSubmitDTOSet[0].congTrinhDTO.soTangNha || 0;
        // this.form.get('soTang').patchValue(soTangSuggest || soTang || null);
      }
    });
    this.form.get('tinhId').valueChanges.subscribe(tinhId => {

      this.getStaticInfo(parseInt(tinhId, 10));
    });

    /* Casting some field */
    // this.form.get('chieuRongMatDuongHai').valueChanges.subscribe(chieuRongMatDuongHai => {
    //   this.form.patchValue({chieuRongMatDuongHai: Number(chieuRongMatDuongHai)}, {emitEvent: false});
    // });
    // this.form.get('chieuRongMatDuongBa').valueChanges.subscribe(chieuRongMatDuongBa => {
    //   this.form.patchValue({chieuRongMatDuongBa: Number(chieuRongMatDuongBa)}, {emitEvent: false});
    // });
    this.form.get('quanHuyenId').valueChanges.subscribe(quanHuyenId => {
      this.form.patchValue({ quanHuyenId: quanHuyenId }, { emitEvent: false });
    });
    this.form.get('phuongXaId').valueChanges.subscribe(phuongXaId => {
      this.form.patchValue({ phuongXaId: phuongXaId }, { emitEvent: false });
    });


    this.form.get('dienTich').valueChanges.subscribe(dienTich => {
      this.form.patchValue({ dienTichSuDung: Number(round(dienTich * this.form.value.soTang)) })
    });
    this.form.get('soTang').valueChanges.subscribe(soTang => {
      this.form.patchValue({ dienTichSuDung: Number(round(soTang * this.form.value.dienTich)) })
    });
    this.form.get('soMatTien').valueChanges.subscribe(soMatTien => {
      if (Number(soMatTien) === 1) {
        this.form.patchValue({
          chieuRongMatDuongHai: null,
          chieuRongMatDuongBa: null,
          viTriIdHai: null,
          viTriIdBa: null
        })

      } else if (Number(soMatTien) === 2) {
        this.form.patchValue({
          chieuRongMatDuongBa: null,
          viTriIdBa: null
        })
      }
    })
  }

  checkingEstimateable(): boolean {
    return this.status.getThuaDat.loading || this.status.getThuaDatXungQuanh.loading || this.status.checkKhungGia.loading;
  }
  khuVucBiQuyHoach(): boolean {
    return !this.checkingEstimateable()
      && this.thuaDat && !this.thuaDat.hasPrice && this.thuaDat.canhBao;
  }
  khuVucChuaDuocDinhGia(): boolean {
    return !this.checkingEstimateable()
      && this.thuaDat && !this.thuaDat.hasPrice && !this.thuaDat.canhBao;
  }
  khuVucChuaCoKhungGia(): boolean {
    return !this.checkingEstimateable()
      && !(this.thuaDatXungQuanh && this.thuaDatXungQuanh.length > 0)
      && !(this.coKhungGia && this.coKhungGia.co)
  }
  showButtonUocLuongGia(): boolean {
    return !this.checkingEstimateable() &&
      ((this.thuaDatXungQuanh && this.thuaDatXungQuanh.length > 0) &&
        !(this.thuaDat && !this.thuaDat.hasPrice && this.thuaDat.canhBao) &&
        !(this.thuaDat && !this.thuaDat.hasPrice && !this.thuaDat.canhBao)) ||
      (this.coKhungGia && this.coKhungGia.co);
  }
  doYouWantEstimate(): void {
    this.xacNhanUocLuongGia.open = true;
    this.xacNhanUocLuongGia.header = `Xác nhận ước lượng giá`;
    this.xacNhanUocLuongGia.body = `Bạn muốn định giá tài sản ` +
      (this.form.value.soNha ? `Số ${this.form.value.soNha}, ` : '') +
      (this.form.value.ngachHem ? `ngách ${this.form.value.ngachHem}, ` : '') +
      (this.form.value.ngo ? `ngõ ${this.form.value.ngo}, ` : '') +
      (this.form.value.duong ? `${this.form.value.duong}, ` : '') +
      (this.form.value.phuongXa ? `${this.form.value.phuongXa}, ` : '') +
      (this.form.value.quanHuyen ? `${this.form.value.quanHuyen}, ` : '') +
      (this.form.value.tinh ? `${this.form.value.tinh}` : '') +
      `?`;
  }
  doYouWantReportTheResult(): void {
    this.baoDuLieuChuaChinhXac.open = true;
    this.baoDuLieuChuaChinhXac.header = `Báo Dữ Liệu Chưa Chính Xác`;
  }
  gotoResult(): void {
    this.step = 'RESULT_ESTIMATE';
    this.stepEstimateStatus.requestShow = false;
    this.stepEstimateStatus.resultShow = false;
    this.tabprice = 'GIA_THAM_DINH';
    this.result = ketQuaUocLuongGiaDefault;
    this.status.estimate.loading = true;
    this.status.estimate.error = false;
    this.currentRawResult = null;
    this.http.post<any>(`${environment.API.SERVER_ESTIMATE}/api/dia-chi-cu-the/estimate`, this.form.value)
      .pipe(
        catchError(error => {
          console.log('gotoResult', error);
          this.status.estimate.error = true;
          this.status.estimate.message = `${error.status}`;
          return of([]);
        })
      ).subscribe(result => {
        if (result?.status === 1) {
          this.nzNotificationService.error('', result?.description);
        } else {
          this.stepEstimateStatus.resultShow = true;
          this.status.estimate.loading = false;
          this.currentRawResult = result.payload.data;
          const data = result.payload.data;
          // const donGiaTextHighlight = (data.giaDatMoHinh.canhBaoQuyHoach && data.giaDatMoHinh.canhBaoQuyHoach !== '')
          //   ? `
          //   <div class="d-flex justify-content-center">${round((data.giaDatMoHinh.giaTri * 1000) / Number(this.form.value.dienTich))}tr/m<sup>2</sup></div>
          //   <div class="d-flex justify-content-center mt-2 notice">(${data.giaDatMoHinh.canhBaoQuyHoach})</div>`
          //   : `
          //   <div class="d-flex justify-content-center">
          //     ${round(data.giaDatMoHinh.donGia)}tr/m<sup>2</sup>
          //   </div>`;
  
          const donGiaTextHighlight = `<div class="d-flex justify-content-center">${round(data.giaDatMoHinh.donGia)}tr/m<sup>2</sup></div><div class="d-flex justify-content-center mt-2 notice">${data.giaDatMoHinh.canhBao || ''}</div>`;
  
          // const donGiaText = (data.giaDatMoHinh.canhBaoQuyHoach && data.giaDatMoHinh.canhBaoQuyHoach !== '')
          //   ? `<div>${round((data.giaDatMoHinh.giaTri * 1000) / Number(this.form.value.dienTich))}</div>
          //   <div>(${data.giaDatMoHinh.canhBaoQuyHoach})</div>`
          //   : `${round(data.giaDatMoHinh.donGia)}`;
  
          const donGiaText = `<div>${round(data.giaDatMoHinh.donGia)}</div><div>${data.giaDatMoHinh.canhBao || ''}</div>`;
  
          const chiTietGias: ChiTietGias[] = [];
          if (data.giaDatMoHinh.chiTietGias && data.giaDatMoHinh.chiTietGias.length) {
            data.giaDatMoHinh.chiTietGias.forEach(element => {
              chiTietGias.push({
                donGiaText: `${round(element.donGiaPhuHopQuyHoach || null) || '---'}`,
                dienTich: `${round(element.dienTichPhuHopQuyHoach || null) || '---'}`,
                giaTri: `${round(element.giaTriPhuHopQuyHoach || null) || '---'}`,
                loaiGiaTri: `Giá trị quyền sử dụng đất ${element.dienGiaiLoaiDat || ''} phù hợp quy hoạch`,
              });
              chiTietGias.push({
                donGiaText: `${round(element.donGiaQuyHoachLoGioi || null) || '---'}`,
                dienTich: `${round(element.dienTichQuyHoachLoGioi || null) || '---'}`,
                giaTri: `${round(element.giaTriQuyHoachLoGioi || null) || '---'}`,
                loaiGiaTri: `Giá trị quyền sử dụng đất ${element.dienGiaiLoaiDat || ''} quy hoạch lộ giới`,
              });
              chiTietGias.push({
                donGiaText: `${round(element.donGiaKhongPhuHopQuyHoachKhac || null) || '---'}`,
                dienTich: `${round(element.dienTichKhongPhuHopQuyHoachKhac || null) || '---'}`,
                giaTri: `${round(element.giaTriKhongPhuHopQuyHoachKhac || null) || '---'}`,
                loaiGiaTri: `Giá trị quyền sử dụng đất ${element.dienGiaiLoaiDat || ''} không phù hợp quy hoạch khác`,
              });
            });
          }
  
          this.result = {
            giaDatThamDinh: {
              dienTich: this.form.value.dienTich,
              donGia: `${round(data.giaDatMoHinh.donGia) || '---'}`,
              donGiaTextHighlight,
              donGiaText,
              CLCL: '---',
              giaTri: `${round(data.giaDatMoHinh.giaTri) || '---'}`,
              dienGiai: `${data.giaDatMoHinh.dienGiai}`
            },
            chiTietGias: chiTietGias ?? [],
            // phuHopQuyHoach: {
            //   dienTich: (data.giaDatMoHinh.chiTietGia && data.giaDatMoHinh.chiTietGia.dienTichPhuHopQuyHoach) ? `${round(data.giaDatMoHinh.chiTietGia.dienTichPhuHopQuyHoach)}` : '---',
            //   donGia: '---',
            //   donGiaText: (data.giaDatMoHinh.chiTietGia && data.giaDatMoHinh.chiTietGia.donGiaPhuHopQuyHoach) ? `${round(data.giaDatMoHinh.chiTietGia.donGiaPhuHopQuyHoach)}` : '---',
            //   CLCL: '---',
            //   giaTri: (data.giaDatMoHinh.chiTietGia && data.giaDatMoHinh.chiTietGia.giaTriPhuHopQuyHoach) ? `${round(data.giaDatMoHinh.chiTietGia.giaTriPhuHopQuyHoach)}` : '---',
            //   dienGiai: ''
            // },
            // quyHoachLoGioi: {
            //   dienTich: (data.giaDatMoHinh.chiTietGia && data.giaDatMoHinh.chiTietGia.dienTichQuyHoachLoGioi) ? `${round(data.giaDatMoHinh.chiTietGia.dienTichQuyHoachLoGioi)}` : '---',
            //   donGia: '---',
            //   donGiaText: (data.giaDatMoHinh.chiTietGia && data.giaDatMoHinh.chiTietGia.donGiaQuyHoachLoGioi) ? `${round(data.giaDatMoHinh.chiTietGia.donGiaQuyHoachLoGioi)}` : '---',
            //   CLCL: '---',
            //   giaTri: (data.giaDatMoHinh.chiTietGia && data.giaDatMoHinh.chiTietGia.giaTriQuyHoachLoGioi) ? `${round(data.giaDatMoHinh.chiTietGia.giaTriQuyHoachLoGioi)}` : '---',
            //   dienGiai: ''
            // },
            // khongPhuHopQuyHoachKhac: {
            //   dienTich: (data.giaDatMoHinh.chiTietGia && data.giaDatMoHinh.chiTietGia.dienTichKhongPhuHopQuyHoachKhac) ? `${round(data.giaDatMoHinh.chiTietGia.dienTichKhongPhuHopQuyHoachKhac)}` : '---',
            //   donGia: '---',
            //   donGiaText: (data.giaDatMoHinh.chiTietGia && data.giaDatMoHinh.chiTietGia.donGiaKhongPhuHopQuyHoachKhac) ? `${round(data.giaDatMoHinh.chiTietGia.donGiaKhongPhuHopQuyHoachKhac)}` : '---',
            //   CLCL: '---',
            //   giaTri: (data.giaDatMoHinh.chiTietGia && data.giaDatMoHinh.chiTietGia.giaTriKhongPhuHopQuyHoachKhac) ? `${round(data.giaDatMoHinh.chiTietGia.giaTriKhongPhuHopQuyHoachKhac)}` : '---',
            //   dienGiai: ''
            // },
            giaNha: {
              dienTich: this.form.value.dienTichSuDung,
              donGia: `${round(data.giaCTXD.donGia) || '---'}`,
              donGiaText: `${round(data.giaCTXD.donGia) || '---'}`,
              CLCL: this.form.value.chatLuongConLai,
              giaTri: `${round(data.giaCTXD.giaTri) || '---'}`,
              dienGiai: data.giaCTXD.dienGiai
            },
            other: data.other,
            tongGiaThamDinh: `${round((data.giaDatMoHinh.giaTri || 0) + (data.giaCTXD.giaTri || 0))}`
          };
          if (this.accountSsoService.hasAnyAuthority(['MENTOR'])) {
            this.getDonGiaDatNhaNuoc(data.giaCTXD, data.giaCTXDTinhThue, this.form.value.duongId);
          }
          this.updateSoLuotUocLuongGia();
          this.snowPlowTrackerPush();
          this.sendEmailEstimate(this.form.value.dongYNhanEmail, this.form.value.email);
        }
      });
    scrollToTop('#popup-uoc-luong-gia .popup .content');
  }
  getDonGiaDatNhaNuoc(giaCTXD, giaCTXDTinhThue, duongId): void {
    this.http.get<any>(`${environment.API.SERVER_ESTIMATE}/api/dia-chi-cu-the/chi-tiet-don-gia-dat?id-duong=${duongId}`)
      .pipe(
        catchError(error => {
          return of([]);
        })
      ).subscribe(result => {
        const tenVTs = ['donGiaVT4', 'donGiaVT1', 'donGiaVT2', 'donGiaVT3'];
        const tenVT = tenVTs[this.form.value.viTriId % 4];
        let sumNhaNuoc = 0;
        const data = result.payload.data;
        console.log('getDonGiaDat', result);
        if (data.donGiaDatTheoNhaNuocDTOS.length > 0) {
          data.donGiaDatTheoNhaNuocDTOS.forEach(bg => {
            sumNhaNuoc += bg[tenVT];
          });
          const donGiaDatNhaNuoc = sumNhaNuoc / data.donGiaDatTheoNhaNuocDTOS.length;
          const giaTriGiaNhaNuoc = (donGiaDatNhaNuoc * parseInt(this.form.value.dienTich, 10)) / 1000;
          const quyetDinh = data.donGiaDatTheoNhaNuocDTOS[0].quyetDinh;
          this.result.giaDatNhaNuoc = {
            dienTich: this.form.value.dienTich,
            donGia: `${round(donGiaDatNhaNuoc)}`,
            donGiaText: `${round(donGiaDatNhaNuoc)}`,
            CLCL: '---',
            giaTri: `${round(giaTriGiaNhaNuoc)}`,
            quyetDinh
          };
          this.result.tongGiaNhaNuoc = `${(round(giaTriGiaNhaNuoc) || 0) + (round(giaCTXD.giaTri) || 0)}`;

          const giaTriTinhThue = (giaCTXDTinhThue.donGia * parseInt(this.form.value.dienTichSuDung, 10)) / 1000;
          this.result.giaTinhThue = {
            donGia: `${round(giaCTXDTinhThue.donGia)}`,
            donGiaText: `${round(giaCTXDTinhThue.donGia)}`,
            dienTich: `${round(this.form.value.dienTichSuDung)}`,
            giaTri: `${round(giaTriTinhThue)}`,
            dienGiai: giaCTXDTinhThue.dienGiai
          };
          const tongGiaTinhThue = round((giaTriGiaNhaNuoc || 0) + (giaTriTinhThue || 0));
          this.result.tongGiaTinhThue = tongGiaTinhThue;

          if (tongGiaTinhThue) {
            this.result.thueTNCN = round(tongGiaTinhThue * 2 * 10); // / 100 * 1000
            this.result.lePhiTruocBa = round(tongGiaTinhThue * 0.5 * 10);
            this.result.lePhiThamDinh = round(tongGiaTinhThue * 0.15 * 10);
            if (this.result.lePhiThamDinh > 5) {
              this.result.lePhiThamDinh = 5;
            }
            if (tongGiaTinhThue < 0.05) {
              this.result.phiCongChung = 0.05; // 50 nghin dong
              this.result.cachTinhPhiCongChung = 'Áp dụng cho tài sản < 50 triệu';
            } else if (tongGiaTinhThue < 0.1) {
              // duoi 100 trieu dong
              this.result.phiCongChung = 0.1;
              this.result.cachTinhPhiCongChung = 'Áp dụng cho tài sản từ 50-100 triệu';
            } else if (tongGiaTinhThue < 1) {
              this.result.phiCongChung = tongGiaTinhThue;
              this.result.cachTinhPhiCongChung = '0.1% giá trị tái sản';
            } else if (tongGiaTinhThue < 3) {
              this.result.phiCongChung = 1 + (tongGiaTinhThue - 1) * 0.6;
              this.result.cachTinhPhiCongChung = '1 triệu + 0.06% phần giá trị > 1 tỷ';
            } else if (tongGiaTinhThue < 5) {
              this.result.phiCongChung = 2.2 + (tongGiaTinhThue - 3) * 0.5;
              this.result.cachTinhPhiCongChung = '2.2 triệu + 0.05% phần giá trị > 3 tỷ';
            } else if (tongGiaTinhThue < 10) {
              this.result.phiCongChung = 3.2 + (tongGiaTinhThue - 5) * 0.4;
              this.result.cachTinhPhiCongChung = '3.2 triệu + 0.04% phần giá trị > 5 tỷ';
            } else if (tongGiaTinhThue < 100) {
              this.result.phiCongChung = 5.2 + (tongGiaTinhThue - 10) * 0.3;
              this.result.cachTinhPhiCongChung = '5.2 triệu + 0.03% phần giá trị > 10 tỷ';
            } else {
              this.result.phiCongChung = 32.2 + (tongGiaTinhThue - 100) * 0.2;
              this.result.cachTinhPhiCongChung = '32.2 triệu + 0.02% phần giá trị > 100 tỷ';
            }
            if (this.result.phiCongChung > 70) {
              this.result.phiCongChung = 70;
              this.result.cachTinhPhiCongChung = 'Tối đa là 70 triệu đồng';
            }
            this.result.tongThueVaPhi = round(this.result.thueTNCN +
              this.result.lePhiTruocBa + this.result.lePhiThamDinh + this.result.phiCongChung);
          }
        }
      });
  }
  sendEmailEstimate(dongYNhanEmail: boolean, email: string): void {
    console.log('sendEmailEstimate', this.currentRawResult, this.form.value.email);
    /* Nếu user tích chọn đôngf ý nhận Email và giá ước lượng khác 0 */
    if (dongYNhanEmail && this.currentRawResult.giaDatMoHinh.giaTri !== 0) {
      this.status.sendMail.loading = true;
      const body = {
        estimateId: this.currentRawResult.idEstimate,
        email,
        url: ''
      };
      this.http.post<any>(`${environment.API.SERVER_ESTIMATE}/api/dia-chi-cu-the/send-email-estimate`, body)
        .pipe(catchError(err => {
          console.log('sendEmailEstimate', err);
          this.status.sendMail.loading = false;
          return of({
            description: 'Lỗi ket noi máy chủ',
            payload: null,
            status: -1
          });
        }))
        .subscribe(res => {
          console.log('Response gui email', res);
          this.status.sendMail.loading = false;
          if (res.status === 0) {
            this.appErrorService.popup = {
              open: true,
              header: 'Thông báo',
              body: `<center>Gửi Email thành công.</center>`
            };
          } else {
            // this.appErrorService.popup = {
            //   open: true,
            //   header: 'Gửi Email thất bại',
            //   body: res.description
            // };
            this.toasterService.pop({
              type: "error",
              title: "Gửi Email thất bại!",
              showCloseButton: true,
            });
          }

        });
    } else {
      console.log('Khong dong y nhan email hoac khong dinh gia duoc');
    }
  }
  exitEstimate(): void {
    this.step = 'HIDE_ESTIMATE';
    this.stepEstimateStatus.requestShow = false;
    this.stepEstimateStatus.resultShow = false;
  }
  backtoRequest(): void {
    this.step = 'REQUEST_ESTIMATE';
    this.stepEstimateStatus.requestShow = true;
    this.stepEstimateStatus.resultShow = false;
  }
  disableForm(): void {
    // this.form.controls['chieuRongMatTien'].disable();
  }

  formEstimateReset(): void {
    this.form.patchValue(formEstimateReset);
  }
  bdsChanged(batDongSan): void {
    console.log('bdsChanged', batDongSan);
    this.thuaDat = null;
    // this.thuaDatXungQuanh = null;
    this.thuaDatDangChon = null;
    this.thuaDatGanNhatId = null;
    this.updateSoLuotUocLuongGia();
    if (batDongSan ?.batDongSanDTO && batDongSan ?.congTrinhSubmitDTOSet) {
      this.resetThongTinThuaDat();
      /* Lưu lại thông tin bất động s hiện tại để khi cần thông tin ở ngoài form ước lượng giá thì có th lấy ra */
      this.batDongSan = batDongSan;
      const idBatDongSanNhapLieu = batDongSan.batDongSanDTO.id;
      const loaiNhaId = batDongSan.congTrinhSubmitDTOSet[0].congTrinhDTO.loaiCongTrinhId || 14; // Nếu không có loại công trình thì set thành đất trống (id14)
      const ngo = batDongSan.batDongSanDTO.dlNgo;
      const ngachHem = batDongSan.batDongSanDTO.dlNgachHem;
      const soNha = batDongSan.congTrinhSubmitDTOSet[0].congTrinhDTO.soNha;
      const soTang = this.getSoTang(loaiNhaId);
      console.log('suggestSoTangFromLoaiNha', soTang);
      this.form.patchValue({
        idBatDongSanNhapLieu,
        loaiNhaId,
        ngo,
        ngachHem,
        soNha,
        soTang,
      });
      this.getStaticInfo(parseInt(batDongSan.batDongSanDTO.tinh.id, 10));
    } else {
      console.error('Thiếu thông tin BDS batDongSanDTO hoặc congTrinhSubmitDTOSet');
    }
  }
  landChanged($event): void {
    console.log('landChanged', $event);
    this.thuaDat = $event;
    this.patchThongTinChung(this.thuaDat);
    this.patchThongTinMatTien(this.thuaDat);
    this.patchThongTinHanhChinh(this.thuaDat);
  }

  private patchThongTinChung(thuaDat): void {
    if (thuaDat) {
      this.form.patchValue({
        idThuaDat: thuaDat.id,
        dienTich: thuaDat.dienTichThuaDat,
        dienTichSuDung: round(this.form.value.soTang * thuaDat.dienTichThuaDat),
        chieuRongMatTien: thuaDat.kichThuocMatTien,
        lon: thuaDat.tungDoGps,
        lat: thuaDat.hoanhDoGps,
        huongDatId: thuaDat.huongDatId,
        hinhDangLoDatId: thuaDat.hinhDangLoDatId,
      });
    }

  }
  private patchThongTinMatTien(thuaDat): void {
    let soMatTien;
    let chieuRongMatDuong;
    let chieuRongMatDuongHai;
    let chieuRongMatDuongBa;
    let viTriId;
    let viTriIdHai;
    let viTriIdBa;
    soMatTien = 1;
    chieuRongMatDuongHai = null;
    chieuRongMatDuongBa = null;
    viTriId = thuaDat && thuaDat.viTri || viTriId;
    /* Việt Anh confirm tất cả các thửa đất đều không có viTriHai, viTriBa nên set bằng viTri + 1 */
    chieuRongMatDuong = thuaDat && thuaDat.chieuRongMatDuong;
    if (thuaDat && thuaDat.chieuRongMatDuong2 && thuaDat.chieuRongMatDuong2 !== 0) {
      soMatTien = 2;
      chieuRongMatDuongHai = thuaDat.chieuRongMatDuong2;
      viTriIdHai = thuaDat.viTri2;
    }
    if (thuaDat && thuaDat.chieuRongMatDuong3 && thuaDat.chieuRongMatDuong3 !== 0) {
      soMatTien = 3;
      chieuRongMatDuongBa = thuaDat.chieuRongMatDuong3;
      viTriIdBa = thuaDat.viTri3;
    }
    this.form.patchValue({
      soMatTien,
      chieuRongMatDuong: (chieuRongMatDuong),
      chieuRongMatDuongHai: (chieuRongMatDuongHai),
      chieuRongMatDuongBa: (chieuRongMatDuongBa),
      viTriId,
      viTriIdHai,
      viTriIdBa
    });
  }
  private patchThongTinHanhChinh(thuaDat): void {
    console.log('patchThongTinHanhChinh', this.batDongSan);
    if (!thuaDat) {
      thuaDat = {
        tinhId: this.batDongSan.batDongSanDTO.tinhId,
        quanId: this.batDongSan.batDongSanDTO.quanHuyenId,
        phuongId: this.batDongSan.batDongSanDTO.phuongXaId,
        duongId: this.batDongSan.batDongSanDTO.duongId,
      }
    }
    this.form.patchValue({
      tinhId: thuaDat.tinhId,
      quanHuyenId: thuaDat.quanId,
      phuongXaId: thuaDat.phuongId,
      duongId: thuaDat.duongId,
    });
    /* Lấy TÊN tỉnh quận đường phường theo bất động sản (ID vẫn là của thửa đất) */
    this.form.patchValue({
      tinh: `${this.batDongSan ?.batDongSanDTO ?.tinh ?.tienTo || ''} ${this.batDongSan ?.batDongSanDTO ?.tinh ?.ten || ''}`,
      quanHuyen: `${this.batDongSan ?.batDongSanDTO ?.quanHuyen ?.tienTo || ''} ${this.batDongSan ?.batDongSanDTO ?.quanHuyen ?.ten || ''}`,
      phuongXa: `${this.batDongSan ?.batDongSanDTO ?.phuongXa ?.tienTo || ''} ${this.batDongSan ?.batDongSanDTO ?.phuongXa ?.ten || ''}`,
      duong: `${this.batDongSan ?.batDongSanDTO ?.duong ?.tienTo || ''} ${this.batDongSan ?.batDongSanDTO ?.duong ?.ten || ''}`,
    });
    /*
    Lấy tỉnh quận đường phường theo thửa đất
    [
      { id: 'tinhId', name: 'tinh', query: '_analytics_tinh' },
      { id: 'quanId', name: 'quanHuyen', query: '_analytics_quan_huyen' },
      { id: 'phuongId', name: 'phuongXa', query: '_analytics_phuong_xa' },
      { id: 'duongId', name: 'duong', query: '_analytics_duong' }
    ].forEach(item => {
      if (thuaDat[item.id]) {
        const key = `area:${thuaDat[item.id]}:${item.query}`;
        const data = sessionStorage.getItem(key);
        if (data) {
          const dataSplit = data.split(':');
          const name = dataSplit[1] || '';
          const tienTo = dataSplit[1] || '';
          this.diaChi[item.name] = tienTo && `${tienTo} ${name}` || null;
          this.form.get(item.name).setValue(this.diaChi[item.name]);
        } else {

        }
        this.http.get<any>(`${environment.API.SERVER_SEARCH}v2/dinhgia-get?id=${thuaDat[item.id]}${item.query}`)
          .pipe(
            catchError(error => {
              return of(null);
            })
          )
          .subscribe(res => {
            const type = res && res._source && res._source.address_type && res._source.address_type.type;
            const name = res && res._source && res._source[type] && res._source[type].ten || '';
            const tienTo = res && res._source && res._source[type] && res._source[type].tien_to || '';
            const data = `${type}:${name}:${tienTo}`
            this.diaChi[item.name] = tienTo && `${tienTo} ${name}` || null;
            this.form.get(item.name).setValue(this.diaChi[item.name]);
            sessionStorage.setItem(key, data);
          });
      }
    });
    */

  }
  private resetThongTinThuaDat(): void {
    this.patchThongTinChung(thuaDatDefault);
    this.patchThongTinMatTien(thuaDatDefault);
    this.patchThongTinHanhChinh(thuaDatDefault);
  }
  private getStaticInfo(tinhId: number): void {
    this.staticInfoService.getById(tinhId || null);
    if (tinhId === 59) {
      this.nhieuLoaiDat = [
        { type: 'ODT', value: '', selected: false, input: 'dienTichDatODT', reference: 'dienTichDatODT', text: 'Đất ở tại đô thị (ODT)' },
        { type: 'SKC', value: '', selected: false, input: 'dienTichDatSKC', reference: 'dienTichDatSKC', text: 'Đất cơ sở sản xuất, kinh doanh (SKC)' },
        { type: 'TMD', value: '', selected: false, input: 'dienTichDatTMD', reference: 'dienTichDatTMD', text: 'Đất thương mại dịch vụ (TMD)' },
        { type: 'NNP', value: '', selected: false, input: 'dienTichDatNNP', reference: 'dienTichDatNNP', text: 'Đất nông nghiệp (NNP)' },
      ];
    } else {
      this.nhieuLoaiDat = [
        { type: 'ODT', value: '', selected: false, input: 'dienTichDatODT', reference: 'dienTichDatODT', text: 'Đất ở tại đô thị (ODT)' },
        { type: 'SKC', value: '', selected: false, input: 'dienTichDatSKC', reference: 'dienTichDatSKC', text: 'Đất cơ sở sản xuất, kinh doanh (SKC)' },
        { type: 'VLK', value: '', selected: false, input: 'dienTichDatVLK', reference: 'dienTichDatSKC', text: 'Đất vườn liền kề (VLK)' },
        { type: 'TMD', value: '', selected: false, input: 'dienTichDatTMD', reference: 'dienTichDatSKC', text: 'Đất thương mại dịch vụ (TMD)' },
        { type: 'CLN', value: '', selected: false, input: 'dienTichDatCLN', reference: 'dienTichDatCLN', text: 'Đất trồng cây lâu năm (CLN)' },
        { type: 'HNK', value: '', selected: false, input: 'dienTichDatHNK', reference: 'dienTichDatHNK', text: 'Đất trồng cây hàng năm (HNK)' },
      ];
    }
  }
  public dienTichLoaiDatChanged(reference, value, $event?, index?): void {
    // this.form.get(reference).setValue(value);
    if ($event) {
      this.nhieuLoaiDat[index].value = $event.target.value;
    }
    this.clearAllLoaiDat();
    this.calcAllLoaiDat();

  }
  public chonDienTichLoaiDatChanged(item): void {
    // if (item.selected) {
    //   this.form.get(item.reference).setValue(item.value);
    // } else {
    //   this.form.get(item.reference).setValue(null);
    // }
    console.log('ass')
    this.clearAllLoaiDat();
    this.calcAllLoaiDat();
  }
  public clearAllLoaiDat(): void {
    ['dienTichDatODT', 'dienTichDatSKC', 'dienTichDatCLN', 'dienTichDatHNK', 'dienTichDatTMD', 'dienTichDatVLK'].forEach(item => {
      this.form.get(item).setValue(null);
    });
    console.log('this.form', this.form.value)
  }
  public calcAllLoaiDat(): void {
    console.log('this.nhieuLoaiDat', this.nhieuLoaiDat)
    this.nhieuLoaiDat.forEach(element => {
      if (element.selected) {
        const currentValue = Number(this.form.value[element.reference]);
        this.form.get(element.reference).setValue((currentValue + Number(element.value)) || null);
        console.log('currentValue', currentValue)
      }
    });
    console.log('this.form123', this.form.value)
  }
  public coNhieuLoaiDatChanged(selected): void {
    if (selected) {
      this.clearAllLoaiDat();
      this.calcAllLoaiDat();
    } else {
      this.clearAllLoaiDat();
    }
  }

  getAllErrors(form: FormGroup | FormArray): { [key: string]: any; } | null {
    let hasError = false;
    const result = Object.keys(form.controls).reduce((acc, key) => {
      const control = form.get(key);
      const errors = (control instanceof FormGroup || control instanceof FormArray)
        ? this.getAllErrors(control)
        : control.errors;
      if (errors) {
        acc[key] = errors;
        hasError = true;
      }
      return acc;
    }, {} as { [key: string]: any; });
    return hasError ? result : null;
  }

  updateSoLuotUocLuongGia(): void {
    if (this.accountSsoService.hasAnyAuthority(['ROLE_USER'])) {
      this.http.get<any>(`${environment.API.SERVER_ESTIMATE}/api/number-request-remain-estimate`)
        .pipe(
          catchError(err => {
            this.soLuotUocLuongGia = 0;
            this.soLuotTraCuuQuyHoach = 0;
            return of({ payload: { data: 0 } });
          })
        )
        .subscribe(remain => {
          this.soLuotUocLuongGia = remain.payload?.data?.soLuotTraCuuGia ?? 0;
          this.soLuotTraCuuQuyHoach = remain.payload?.data?.soLuotTraCuuQuyHoach ?? 0;
        });
    }


  }






  public getThuaDatXungQuanhMarkers(dataThuaDat): any {
    const cacThuaDat = [];
    if (dataThuaDat && dataThuaDat.length > 0) {
      /* Tìm thấy ít nhất một thửa đất xung quanh */
      dataThuaDat.forEach((thuaDat, index) => {
        cacThuaDat.push(
          {
            lat: thuaDat._source.location.lat,
            lng: thuaDat._source.location.lon,
            phuongXaId: thuaDat._source.phuong_id,
            id: thuaDat._source.id,
            square: thuaDat._source.dienTichThuaDat,
            quyHoach: (thuaDat._source.display === 'quy hoạch') ? 'Thửa đất bị quy hoạch' : '',
            selected: false
          }
        );
      });
    } else {
      /* Không tìm thấy thửa đất xung quanh nào */
    }
    return cacThuaDat;
  }
  public getPhuongXaIdList(cacThuaDat): any {
    const phuongXaIdList = [];
    cacThuaDat.forEach(item => {
      if (!phuongXaIdList.includes(item.phuongXaId)) {
        phuongXaIdList.push(item.phuongXaId);
      }
    });
    return phuongXaIdList;
  }
  public unHighlightSelectedLand(): void {
    Array.from(document.getElementsByClassName('marker-html-round')).forEach(element => {
      element.classList.remove('marker-selected');
    });
  }
  public highlightSelectedLand(id): void {
    console.log('highlightSelectedLand', id);
    this.thuaDatGanNhatId = id;
    /* Đánh dấu màu vàng trên bản đồ và emit id thủa đất vừa chọn */
    // this.idThuaDatChanged.emit(parseInt(id, 10));
    if (this.accountSsoService.hasAnyAuthority(['ROLE_USER'])) {
      this.status.getThuaDat.loading = true;
      this.http.get<any>(`${environment.API.SERVER_ESTIMATE}/api/dia-chi-cu-the/thua-dat/${id}`)
        .pipe(
          catchError(val => {
            return of(null);
          })
        ).subscribe(res => {
          this.status.getThuaDat.loading = false;
          const thongTinThuaDat = res && res.payload && res.payload.data && res.payload.data[0];
          /* Lưu lại thông tin thửa đất hiện tại để khi cần thông tin ở ngoài form ước lượng giá thì có th lấy ra */
          // this.thuaDatDangChon.next(thongTinThuaDat);
          this.thuaDatDangChon = thongTinThuaDat;
          this.landChanged(this.thuaDatDangChon);
          // this.thuaDatChanged.emit(this.thuaDatDangChon);
        });
    } else {
      // this.thuaDatDangChon.next(null);
      this.thuaDatDangChon = null;
    }
    const cacMarkerThuaDatGanNhat = document.querySelectorAll('[data-thuadatgannhatid]');
    if (cacMarkerThuaDatGanNhat && cacMarkerThuaDatGanNhat.length > 0) {
      cacMarkerThuaDatGanNhat.forEach(item => {
        if (parseInt(item.getAttribute('data-thuadatgannhatid'), 10) === parseInt(id, 10)) {
          item.classList.add('marker-selected');

        }
      });
    }

    // Tìm các bđs đang gắn với thửa đất
    // Tìm các bđs gắn với thửa đất này
    let params = '';
    if (this.thuaDatGanNhatId) {
      params = `id_thua_dat=${this.thuaDatGanNhatId}`;
    } else {
      params = `id_bds=${this.batDongSan.id}`;
    }
    const manyAddress = [];
    this.searchResultDetailService.loadingManyInfo = true;
    this.http.get<any>(`${environment.API.GET_MANY_INFO_SAME_LAND}?${params}`).subscribe(res => {
      console.log('res', res, this.batDongSan.id);
      res.payload.data.forEach(element => {
        const bds = element.batDongSanDTO;
        const congTrinh = element.congTrinhSubmitDTOSet[0].congTrinhDTO;
        const diaChi = this.searchResultDetailService.generateDiaChi(bds, congTrinh);
        if (Number(this.batDongSan.id) !== bds.id) {
          manyAddress.push({
            id: bds.id,
            diaChi: diaChi
          });
        }
      });
      this.searchResultDetailService.loadingManyInfo = false;
      console.log('manyAddress', manyAddress);
      this.searchResultDetailService.infoBds.manyAddress = manyAddress;
    }, err => {
      // this.loadingManyInfo = false;
    });
  }
  addPopupToSuggestedLand(id) {

    const cacMarkerThuaDatGanNhat = document.querySelectorAll('[data-thuadatgannhatid]');
    console.log('cacMarkerThuaDatGanNhat', '[data-thuadatgannhatid]', cacMarkerThuaDatGanNhat);
    if (cacMarkerThuaDatGanNhat && cacMarkerThuaDatGanNhat.length > 0) {
      cacMarkerThuaDatGanNhat.forEach(item => {

        if (parseInt(item.getAttribute('data-thuadatgannhatid')) === parseInt(id)) {
          item.innerHTML = `<div class="marker-popup">Tài sản bạn đang xem được hệ thống gợi ý ở vị trí hiện tại. Nếu vị trí này chưa đúng, bạn có thể chọn lại các vị trí xung quanh để kết quả ước lượng giá được chính xác nhất</div>`;
        }
      })
    }
  }
  public addWMSAndMarker(cacThuaDat: any): void {
    const phuongXaIdList = this.getPhuongXaIdList(cacThuaDat);
    
    phuongXaIdList.forEach(item => {
      // L.tileLayer.wms(environment.API.SERVER_API_GEOSERVER, {
      //   maxZoom: 22,
      //   layers: 'dcs:new_thua_dat_' + item,
      //   version: '1.1.0',
      //   format: 'image/svg',
      //   transparent: true,
      //   style: '',
      //   zIndex: 10
      // }).addTo(this.mapping.duongBoundLayerGroup);
      L.gridLayer.googlemapcache(environment.API.SERVER_IMG, environment.API.SERVER_GOOGLE_CACHE, `dcs:new_thua_dat_${item}`)
        .addTo(this.mapping.duongBoundLayerGroup);
    });

    cacThuaDat.forEach((item, index) => {
      const icon = new L.DivIcon({
        className: 'my-div-icon',
        html: '<div title="' + item.quyHoach + '" data-thuadatgannhatid="' + item.id + '" class="marker-html-round"></div>'
      });
      L.marker([item.lat, item.lng], { icon }).addTo(this.mapping.makerTamThuaLayerGroup).on('click', e => {
        this.unHighlightSelectedLand();
        const id = e.originalEvent &&
          e.originalEvent.srcElement &&
          e.originalEvent.srcElement.dataset &&
          e.originalEvent.srcElement.dataset.thuadatgannhatid;
        this.highlightSelectedLand(id);

      }).on('mouseover', e => {

      });
    });
    if (this.thuaDatDangChon) {
      this.highlightSelectedLand(this.thuaDatDangChon.id);
      this.addPopupToSuggestedLand(this.thuaDatDangChon.id);
    } else {
      console.log('highlightSelectedLand thua dat', this.batDongSan.thuaDatDinhGiaId);
      const thuaDatDuocChonId = (this.batDongSan && this.batDongSan.thuaDatDinhGiaId) || (cacThuaDat && cacThuaDat[0] && cacThuaDat[0].id);
      this.highlightSelectedLand(thuaDatDuocChonId);
      this.addPopupToSuggestedLand(thuaDatDuocChonId);
    }
    if (cacThuaDat[0]) {

    }
  }
  checkKhungGia(tinhId: number, quanHuyenId: number, duongId: number): void {
    this.status.checkKhungGia.loading = true;
    this.coKhungGia = {
      co: false,
      loading: true,
      error: false
    };
    this.http.get<any>(`${environment.API.SERVER_ESTIMATE}/api/khung-gia-dat-cenvalues/co-khung-gia?tinh-id=${tinhId}&quan-id=${quanHuyenId}&duong-id=${duongId}`).subscribe(coKhungGia => {
      this.coKhungGia.loading = false;
      this.status.checkKhungGia.loading = false;
      this.coKhungGia.co = coKhungGia && coKhungGia.payload && coKhungGia.payload.data;
    })
  }
  chonLaiThuaDat(id: number): void {
    /* Sử dụng khi click chấm tròn trên bản đồ mapping hoặc lấy thửa đất gần nhất */
    // this.ssoService.waitingForLogedIn(() => {
    /* Trường hợp có id thửa đất */

    if (this.accountSsoService.hasAnyAuthority(['ROLE_USER'])) {
      this.http.get<any>(`${environment.API.SERVER_ESTIMATE}/api/dia-chi-cu-the/thua-dat/${id}`)
        .pipe(
          catchError(val => {
            return of(null);
          })
        ).subscribe(res => {
          const thongTinThuaDat = res && res.payload && res.payload.data && res.payload.data[0];
          /* Lưu lại thông tin thửa đất hiện tại để khi cần thông tin ở ngoài form ước lượng giá thì có th lấy ra */
          // this.thuaDatDangChon.next(thongTinThuaDat);
          this.thuaDatDangChon = thongTinThuaDat;
        });
    } else {
      // this.thuaDatDangChon.next(null);
      this.thuaDatDangChon = null;
    }

    // });
  }
  getSoTang(loaiNhaId) {
    return this.batDongSan &&
    this.batDongSan.congTrinhSubmitDTOSet &&
    this.batDongSan.congTrinhSubmitDTOSet[0] &&
    this.batDongSan.congTrinhSubmitDTOSet[0].congTrinhDTO &&
    this.batDongSan.congTrinhSubmitDTOSet[0].congTrinhDTO.soTangNha || 0;
    // const soTangSuggest = suggestSoTangFromLoaiNha(loaiNhaId);
    // if (soTangSuggest === -1) {
    //   return null;
    // } else {
    //   const soTang =
    //     this.batDongSan &&
    //     this.batDongSan.congTrinhSubmitDTOSet &&
    //     this.batDongSan.congTrinhSubmitDTOSet[0] &&
    //     this.batDongSan.congTrinhSubmitDTOSet[0].congTrinhDTO &&
    //     this.batDongSan.congTrinhSubmitDTOSet[0].congTrinhDTO.soTangNha || 0;
    //   return soTangSuggest || soTang || null;
    // }
  }
  clickUocLuongGia(): void {
    if (this.accountSsoService.hasAnyAuthority(['ROLE_USER'])) {
      this.step = 'REQUEST_ESTIMATE';
      this.stepEstimateStatus.requestShow = true;
      this.open = true;
    } else {
      if (environment.ssoType === 'sso') {
        this.loginSsoService.login();
        this.loginSsoService.waitingForLogedIn(() => {
          console.log('afterLogin');
          // this.selectLand
          // this.thuaDatGanNhatId
          this.highlightSelectedLand(this.thuaDatGanNhatId);
          this.updateSoLuotUocLuongGia();
        });
      } else if (environment.ssoType === 'cenhomesSSO') {

      }
    }

  }

  snowPlowTrackerPush() {
    // console.log('snowPlowTrackerPush', this.form.requestEstimate, this.snowPlowAddress);
    this.snowPlowTracker = {
      schema_type: 'bds_cen_estimate_tracker',
      page_url: this.router.url,
      timestamp: '' + new Date().getTime(),
      finger: this.snowplowService.getFinger(),
      referer_url: this.snowplowService.getRefererUrl(),
      user_id: this.snowplowService.getUserId(),
      user_name: this.snowplowService.getUsername(),
      full_name: this.snowplowService.getFullname(),

      bds_id: this.form.value.idBatDongSanNhapLieu,
      thua_dat_id: this.form.value.idThuaDat,

      street_id: this.form.value.duongId,
      ward_id: this.form.value.phuongXaId,
      district_id: this.form.value.quanHuyenId,
      city_id: this.form.value.tinhId,

      street_name: this.form.value.duong,
      ward_name: this.form.value.phuongXa,
      district_name: this.form.value.quanHuyen,
      city_name: this.form.value.tinh,

      dien_tich: this.form.value.dienTich,
      mat_tien: this.form.value.chieuRongMatTien,
      huong_nha_id: this.form.value.huongDatId,
      hinh_dang_lo_dat_id: this.form.value.hinhDangLoDatId,
      loai_nha_id: this.form.value.loaiNhaId,
      loai_bat_dong_san_id: '' + this.form.value.loaiBatDongSanId,
      so_tang: this.form.value.soTang,
      nam_xay_dung: this.form.value.namXayDung,
      vi_tri: this.form.value.viTriId,
      // cach_duong_oto: this.form.value.,
      // be_rong_via_he: this.form.value.,
      chieu_rong_mat_duong: this.form.value.chieuRongMatDuong,
      price_per_meter: Number(this.result.giaDatThamDinh.donGia),
      gia_estimate_ctxd: Number(this.result.giaNha.giaTri),
      gia_estimate_dat: Number(this.result.giaDatThamDinh.giaTri),
      // gia_khach_hang_nhap: this.form.value.,
      // so_to_ban_do: this.form.value.,
      // so_thua_ban_do: this.form.value.,

    }
    this.snowplowService.trackOnSelect(this.snowPlowTracker);
  }
}
