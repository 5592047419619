import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { PhanTichNangCaoService } from './phan-tich-nang-cao.service';
import { of } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PhanTichNangCaoMobileService {
  BASE_URL = `${environment.API.KONG_SERVER}/g-data-statistic/v2/api/query-nang-cao`;
  loading: boolean;
  selectedId: any;
  criteriaList: any;
  shouldShowBtnSeeCriteria = false;
  totalPage: number;
  pageSelected = 1;
  pageSize = 4;
  criterialSelected: any;
  dataChart;
  constructor(private http: HttpClient, private phanTichNangCaoService: PhanTichNangCaoService) { }

  // getAllCriteria(page: number, size = this.pageSize, isTongQuan = false) {
  //   let url: string;
  //   this.loading = true;
  //   if (isTongQuan) {
  //     url = `${this.BASE_URL}/get-ket-qua?page=${page}&size=${size}&option=default`;
  //   } else {
  //     url = `${this.BASE_URL}/get-ket-qua?page=${page}&size=${size}`;
  //   }
  //   return this.http.get(url).pipe(
  //     tap(res => {
  //       if (res['payload']['totalData']) {
  //         this.totalPage = res['payload']['totalData'];
  //       }
  //     }),
  //     map((res: any) => {
  //       return this.transformDataCriteria(res);
  //     }),
  //     tap(result => {
  //       if (result.length) {
  //         this.criteriaList = result;
  //       } else {
  //         this.criteriaList = [];
  //       }
  //     }),
  //     switchMap(result => {
  //       if (result.length) {
  //         this.selectedId = result[0].id;
  //         this.criterialSelected = result[0];
  //         return this.phanTichNangCaoService.getDetailCriteriaWithChartGetFromGraphql(result[0].id, result[0].loaiTangTruong).pipe(tap(res => {
  //           if (res['series'].length && res['years'].length) {
  //             this.shouldShowBtnSeeCriteria = true;
  //           } else {
  //             this.shouldShowBtnSeeCriteria = false;
  //           }
  //         }));
  //       }
  //       return of([]);

  //     }),
  //     tap(result => {
  //       console.log("data chart default tong quan: ", result);

  //     }),
  //     catchError(err => {
  //       this.loading = false;
  //       return of(err);
  //     })
  //   ).subscribe((res: any) => {
  //     this.loading = false;
  //     if (res['series'].length) {

  //       this.dataChart = this.phanTichNangCaoService.getDataChart(res, this.criterialSelected.mucTimKiem)
  //     } else {
  //       this.dataChart = {data: []}
  //     }

  //   });
  // }
  // private transformDataCriteria(res) {
  //   if (res && res.payload && res.payload.data.length) {
  //     return res.payload.data.map(item => {
  //       return {
  //         ...item.queryNangCaoDTO,
  //         countResults: item.countResults,
  //         enableNotiEmail: item['queryNangCaoDTO'].trangThai === 'bat_thong_bao' ? true : false,
  //       }
  //     })
  //   }
  //   return [];
  // }

  // selectItemCriterial(item) {
  //   this.selectedId = item.id;
  //   this.shouldShowBtnSeeCriteria = false;
  //   this.phanTichNangCaoService.getDetailCriteriaWithChartGetFromGraphql(item.id, this.criterialSelected.loaiTangTruong).subscribe(res => {
  //     if (res['series'].length) {
  //       this.dataChart = this.phanTichNangCaoService.getDataChart(res, this.criterialSelected.mucTimKiem)
  //       this.shouldShowBtnSeeCriteria = true;
  //     } else {
  //       this.dataChart = {data: []}
  //     }
  //   });
  
  // }
}
