<app-dropdown-menu>
    <ng-template app-dropdown-menu-label>
        <div class="d-flex search rounded-[4px] py-[0.85px]">
            <form class="flex" [formGroup]="searchService.form" (ngSubmit)="searchService.onSubmit()">
                <input formControlName="text" class="input-search" type="text" [placeholder]="placeholder" />
            </form>
            <div class="status">
                <div *ngIf="searchService.suggestResults.loading" class="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <button>
                <svg _ngcontent-serverApp-c115="" aria-hidden="true" focusable="false" data-prefix="fal"
                    data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                    class="svg-inline--fa fa-search fa-w-16 fa-2x ng-star-inserted">
                    <path _ngcontent-serverApp-c115="" fill="currentColor"
                        d="M508.5 481.6l-129-129c-2.3-2.3-5.3-3.5-8.5-3.5h-10.3C395 312 416 262.5 416 208 416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c54.5 0 104-21 141.1-55.2V371c0 3.2 1.3 6.2 3.5 8.5l129 129c4.7 4.7 12.3 4.7 17 0l9.9-9.9c4.7-4.7 4.7-12.3 0-17zM208 384c-97.3 0-176-78.7-176-176S110.7 32 208 32s176 78.7 176 176-78.7 176-176 176z">
                    </path>
                </svg>
            </button>
        </div>
    </ng-template>
    <ng-template app-dropdown-menu-items>
        <div class="warning" *ngIf="searchService.suggestResults.warning" [innerHTML]="searchService.suggestResults.warning"></div>
        <ul class="results">
            <li class="result" *ngFor="let item of searchService.suggestResults.items">
                <a class="link" [routerLink]="item.routerLink" app-dropdown-menu-click-close [queryParams]="item.queryParams">
                    <span [innerHTML]="item.title"></span>
                </a>
            </li>
        </ul>
    </ng-template>
</app-dropdown-menu>
