import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { OnlyDayDatePickerComponent } from "./only-day-date-picker.component";
import { AppShareModule } from "../../../app-share.modules"
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    AppShareModule
  ],
  declarations: [
    OnlyDayDatePickerComponent,
  ],
  exports: [
    OnlyDayDatePickerComponent
  ]
})
export class OnlyDayDatePickerModule { }
