import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

import { JhiLoginModalComponent } from 'src/app/shared/login/login.component';
import { RouteStateService } from 'src/app/shared/util/routeState.service';

@Injectable({ providedIn: 'root' })
export class LoginModalService {
    private isOpen = false;
    private modalRef: NgbModalRef;
    constructor(public routeStateService: RouteStateService, private modalService: NgbModal, private config: NgbModalConfig) {
        config.backdrop = 'static';
        config.keyboard = false;
        this.routeStateService.routeAction.subscribe(action => {
            console.log('action', action);
            if ('login' === action) {
                this.close();
            }
        });
    }

    open(message?: string, callback?: Function): NgbModalRef {
        console.log('this.isOpen', this.isOpen);
        if (this.isOpen) {
            return;
        }
        this.isOpen = true;
        this.routeStateService.appendParamFromUrl('login', 'open');
        
        const modalRef = this.modalService.open(JhiLoginModalComponent, {
            windowClass: 'loginModalNew',
            // keyboard: true,
            // backdrop: true
        });
        modalRef.componentInstance.message = message;
        modalRef.result.then(
            result => {
                this.isOpen = false;
            },
            reason => {
                if (callback) {
                    callback(reason);
                }
                this.isOpen = false;
            }
        );
        this.modalRef = modalRef;
        return modalRef;
    }

    close() {
        this.isOpen = false;
        this.modalRef.close();
        this.routeStateService.removeParamFromUrl('login');
    }
}
