import { environment } from './../../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThamDinhGiaService {
  openPopupYeuCauThamDinhGia = false;
  danhSachMucDichThamDinh = [];
  danhSachChiNhanhThamDinh = [];
  listTypeTD = [
    { ten: 'Bất động sản', value: 'BAT_DONG_SAN' }, { ten: 'Bất động sản đặc thù', value: 'TAI_SAN_DAC_THU' },
    { ten: 'BDS_CB', value: 'BDS_CB' }, { ten: 'Gói bất động sản', value: 'GOI_BDS' },
    { ten: 'Căn hộ đơn lẻ', value: 'CAN_HO_DON_LE' }, { ten: 'Gói căn hộ chung cư', value: 'GOI_CAN_HO_CHUNG_CU' },
    { ten: 'Khách sạn, resort', value: 'KHACH_SAN_RESORT' }, { ten: 'Dự án', value: 'DU_AN' },
  ];
  constructor(private http: HttpClient) { }

  getChiNhanhThamDinh() {
    return this.http.get('https://gapi.cenhomes.vn/g-auth/v2/api/chi-nhanhs/get-all').pipe(
      map((res: any) => {
        if (res.payload && res.payload.length) {
          return res.payload;
        }
        return [];
      })
    ).subscribe(data => {
      this.danhSachChiNhanhThamDinh = data;
    })
  }

  getAllMucDichThamDinh() {
    return this.http.get(`${environment.API.SERVER_API_THAM_DINH}/other/muc-dich-tham-dinh`).pipe(
      map((res: any) => {
        if (res.payload && res.payload.data.length) {
          return res.payload.data;
        }
        return [];
      })
    ).subscribe(data => {
      this.danhSachMucDichThamDinh = data;
    });
  }

  submitYeuCauThamDinh(body) {
    return this.http.post(`${environment.API.SERVER_API_THAM_DINH}/submit-yeu-cau-tham-dinh`, body);
  }
}
