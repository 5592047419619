import { Component, AfterViewInit, ElementRef, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/core/login/login.service';
import { StateStorageService } from 'src/app/core/auth/state-storage.service';
import { FacebookLoginProvider, GoogleLoginProvider, SocialUser, SocialAuthService } from 'angularx-social-login';
import { LoginModalService } from 'src/app/core';
import { PasswordResetInitService, Register } from 'src/app/account';
import { EMAIL_ALREADY_USED_TYPE, EMAIL_NOT_FOUND_TYPE, LOGIN_ALREADY_USED_TYPE } from 'src/app/shared';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthHttpService } from '../http/auth-http.service';

@Component({
    selector: 'app-login-modal',
    templateUrl: './login.component.html',
    styleUrls: ['login.component.scss'],
    // encapsulation: ViewEncapsulation.None
})
export class JhiLoginModalComponent implements AfterViewInit, OnInit {
    @ViewChild('captchaRef') reCaptcha;
    submitted = false;
    usernameEmpty = false;
    passwordEmpty = false;
    authenticationError: boolean;
    password: string;
    rememberMe: boolean = true;
    username: string;
    credentials: any;
    isSignUp: any;
    /* forget */
    emailForget: any;
    error = null;
    errorEmailNotExists = null;
    isforget: any;
    success: string;
    message?: string;

    /* register */
    confirmPassword: string;
    doNotMatch: string;
    errorEmailExists: string;
    errorUserExists: string;
    successRegister: boolean;
    public captchaModel: string;
    public userSocial: any = SocialUser;

    public logingin: boolean = false;

    public registerAccount: any = {
        login: undefined,
        email: undefined,
        phone: undefined,
        password: undefined,
        tokenCaptcha: '',
        fullName: ''
    };

    constructor(
        // private eventManager: JhiEventManager,
        private loginService: LoginService,
        private stateStorageService: StateStorageService,
        private router: Router,
        public activeModal: NgbActiveModal,
        private socialAuthService: SocialAuthService,
        private modalService: LoginModalService,
        private passwordResetInitService: PasswordResetInitService,
        private registerService: Register,
        private authHttpService: AuthHttpService
    ) {
        this.credentials = {};
    }

    ngAfterViewInit() {
        this.submitted = false;
    }

    ngOnInit(): void {}

    close() {
        this.modalService.close();
    }

    googleLogIn() {
        this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(userData => {
            this.userSocial = userData;
            const body = {
                rememberMe: true,
                idToken: this.userSocial.idToken
            };
            this.authHttpService.authGoogle(body).subscribe(res => {
                this.loginService
                    .loginWithToken(res.id_token, true)
                    .then(() => {
                        this.authenticationError = false;
                        this.activeModal.dismiss('login success');
                        if (
                            this.router.url === '/register' ||
                            /^\/activate\//.test(this.router.url) ||
                            /^\/reset\//.test(this.router.url)
                        ) {
                            this.router.navigate(['']);
                        }

                        // this.eventManager.broadcast({
                        //     name: 'authenticationSuccess',
                        //     content: 'Sending Authentication Success'
                        // });
                        // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                        // since login is successful, go to stored previousState and clear previousState
                        this.close();
                    })
                    .catch(() => {
                        this.authenticationError = true;
                    });
                const redirect = this.stateStorageService.getUrl();
            });
        });
    }

    facebookLogin() {
        this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(userData => {
            this.userSocial = userData;
            const body = {
                rememberMe: true,
                idToken: this.userSocial.authToken,
                idUser: this.userSocial.id
            };
            this.authHttpService.authFaceBook(body).subscribe(res => {
                this.loginService
                    .loginWithToken(res.id_token, true)
                    .then(() => {
                        this.authenticationError = false;
                        this.activeModal.dismiss('login success');
                        if (
                            this.router.url === '/register' ||
                            /^\/activate\//.test(this.router.url) ||
                            /^\/reset\//.test(this.router.url)
                        ) {
                            this.router.navigate(['']);
                        }

                        // this.eventManager.broadcast({
                        //     name: 'authenticationSuccess',
                        //     content: 'Sending Authentication Success'
                        // });
                        // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                        // since login is successful, go to stored previousState and clear previousState
                        this.close();
                    })
                    .catch(() => {
                        this.authenticationError = true;
                    });
                const redirect = this.stateStorageService.getUrl();
            });
        });
    }

    cancel() {
        this.credentials = {
            username: null,
            password: null,
            rememberMe: true
        };
        this.authenticationError = false;
        this.activeModal.dismiss('cancel');
    }

    login() {
        this.usernameEmpty = false;
        this.passwordEmpty = false;
        this.submitted = true;
        if (!this.validForm()) {
            return 0;
        }
        this.logingin = true;
        this.loginService
            .login({
                username: this.username,
                password: this.password,
                rememberMe: this.rememberMe
            })
            .then(() => {
                this.logingin = false;
                this.authenticationError = false;
                this.activeModal.dismiss('login success');
                if (this.router.url === '/register' || /^\/activate\//.test(this.router.url) || /^\/reset\//.test(this.router.url)) {
                    this.router.navigate(['']);
                }

                // this.eventManager.broadcast({
                //     name: 'authenticationSuccess',
                //     content: 'Sending Authentication Success'
                // });

                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is successful, go to stored previousState and clear previousState
                this.close();
            })
            .catch(() => {
                this.logingin = false;
                this.authenticationError = true;
            });
    }
    requestResetPassword() {
        this.activeModal.dismiss('to state requestReset');
        this.router.navigate(['/reset', 'request']);
    }

    validForm(): Boolean {
        if (this.username === '' || this.username === null || this.username === undefined) {
            this.usernameEmpty = true;
        }
        if (this.password === '' || this.password === null || this.password === undefined) {
            this.passwordEmpty = true;
        }
        if (this.usernameEmpty === true || this.passwordEmpty === true) {
            return false;
        } else {
            return true;
        }
    }

    keyDownFunction(event) {
        if (event.keyCode === 13) {
            this.login();
        }
    }

    isForGet() {
        this.isforget = !this.isforget;
    }

    isChange() {
        this.successRegister = false;
        this.isSignUp = !this.isSignUp;
    }

    requestReset() {
        this.error = null;
        this.errorEmailNotExists = null;
        // this.submitCount = this.submitCount + 1;
        if (this.emailForget === undefined || this.emailForget.trim() === '') {
          return;
        }
        this.passwordResetInitService.save(this.emailForget).subscribe(
            () => {
                this.success = 'OK';
            },
            response => {
                this.success = null;
                if (response.status === 400 && response.error.type === EMAIL_NOT_FOUND_TYPE) {
                    this.errorEmailNotExists = 'Email không tồn tại trong hệ thống';
                } else {
                    this.error = 'ERROR';
                }
            }
        );
    }

    register() {
        if (this.registerAccount.tokenCaptcha === '') {
            return;
        }
        if (this.registerAccount.password !== this.confirmPassword) {
            this.doNotMatch = 'ERROR';
        } else {
          if (this.registerAccount.password === undefined || this.registerAccount.password.trim() === '' ||
            this.registerAccount.email === undefined || this.registerAccount.email.trim() === '') {
            return;
          }
            this.registerAccount.login = this.registerAccount.email;
            this.doNotMatch = null;
            this.error = null;
            this.errorUserExists = null;
            this.errorEmailExists = null;

                this.registerService.save(this.registerAccount).subscribe(
                    res => {
                        if (res.status === 0) {
                            this.successRegister = true;
                            this.refreshFormSignUp();
                            /*this.isChange();*/
                        }
                    },
                    response => this.processError(response)
                );

        }
        this.reCaptcha.reset();
    }

    refreshFormSignUp() {
        this.registerAccount = {
            login: undefined,
            email: undefined,
            phone: undefined,
            password: undefined,
            tokenCaptcha: '',
            fullName: ''
        };
        this.confirmPassword = undefined;
    }

    private processError(response: HttpErrorResponse) {
        this.successRegister = null;
        if (response.status === 400 && response.error.type === LOGIN_ALREADY_USED_TYPE) {
            this.errorUserExists = 'Email đã tồn tại';
        } else if (response.status === 400 && response.error.type === EMAIL_ALREADY_USED_TYPE) {
            this.errorEmailExists = response.error.title;
        } else {
            this.error = 'Hệ thống bị lỗi hãy báo với quản trị viên';
        }
    }

    public resolved(captchaResponse: string) {
        // console.log(`Resolved captcha with response: ${captchaResponse}`);
        if (null === captchaResponse) {
        //   this.resolvedMessage = 'Captcha không hợp lệ';
        } else {
            this.registerAccount.tokenCaptcha = captchaResponse;
        //   this.estimateHttpService.doVerifyCaptcha(captchaResponse).subscribe(data => {
        //       // console.log(data.payload.data);
        //       if (data.payload.data === true) {
        //           /* Reload lại router sau khi verify xong captcha. Cách này không official lắm, cần imprrove khi có thời gian */
        //           this.activeModal.close();
        //           this.router.navigateByUrl(this.router.url + '&reload=true', { skipLocationChange: true });
        //       } else {
        //           this.resolvedMessage = 'Không thể xác minh. Vui lòng liên hệ nhân viên hỗ trợ.';
        //           this.router.navigateByUrl(this.router.url + '&reload=true', { skipLocationChange: true });
        //       }
        //   });
        }
    }
}
