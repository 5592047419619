import { LoginSsoService } from './../../core/login/login-sso.service';
import { BehaviorSubject, of } from 'rxjs';
import { AccountSsoService } from './../../core/auth/account-sso.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Route, Router, RouterStateSnapshot } from '@angular/router';
import { FormCenEstimateV2Service } from '../cen-estimate/cen-estimate-v2/form-cen-estimate-v2.service';
import { tap } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class LoginGuard implements CanActivate {
    constructor(private router: Router, private accountSsoService: AccountSsoService, private loginSsoService: LoginSsoService, private formCenEstimateV2Service: FormCenEstimateV2Service) { }
    isLogged$ = new BehaviorSubject(false);
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isLogged = this.loginSsoService.isLogged();
        // if (isLogged) {
        //     // this.isLogged$.next(true);
        //     return of(true);
        // } else {
        //     this.loginSsoService.verifyUserLogin(() => {
        //         // this.router.navigateByUrl('/phan-tich-khu-vuc-nang-cao/tong-quan');
        //     }, (res) => {
        //         this.router.navigateByUrl('/phan-tich-khu-vuc-nang-cao/tong-quan');
        //     });
        //     return false;
        // }
        return true;
    }

    canLoad(route: Route) {
        return this.isLogged$.asObservable();;
    }
}