import { AccountService } from './../../core/auth/account.service';
import { AccountSsoService } from './../../core/auth/account-sso.service';
import { InterceptorService } from './interceptor.service';
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { Observable, Subscriber, Subject, pipe, of } from 'rxjs';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpClient } from '@angular/common/http';
import * as Fingerprint2 from 'fingerprintjs2';

import { VERSION } from 'src/app/app.constants';
import { SeoService } from 'src/app/shared/util/seo.service';
import { map, take, mergeMap, takeWhile, skipWhile, switchMap, delay, filter, find, first } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  public finger: string;
  private subFinger: Subject<string>;
  public environment: any;

  constructor(
    private seoService: SeoService,
    private interceptorService: InterceptorService,
    public accountSsoService: AccountSsoService,
    public accountService: AccountService,
    private http: HttpClient,
  ) {
    this.environment = environment;
    this.interceptorService.gettingJWT = false;
    if (this.seoService.onBrowser) {
      
      // this.interceptorService.finger = this.localStorage.retrieve('g-finger');
      // this.interceptorService.finger = this.cookieService.get('g-finger');

      /*
      Độ dài finger qua các vesion
      Version 1: 32 character. Ex: 6d5317668df960d45198aacd35fa7d3d
      Version 2: 33 or 34 character. Ex: 6d531710668df960d45198aacd35fa7d3d
      Version 3: 60 character
      */
      // if (true) {
      // if (this.fingerInvalid()) {
      //     this.interceptorService.fingerSubcribe = new Subject();
      //     Fingerprint2.get(components => {
      //         const values = components.map(component => {
      //             return component.value;
      //         });
      //         this.interceptorService.finger = Fingerprint2.x64hash128(values.join(''), 31);

      //         const now = new Date();
      //         const expire = new Date();
      //         expire.setUTCFullYear(now.getFullYear());
      //         expire.setUTCMonth(now.getMonth());
      //         expire.setUTCDate(now.getDate() + 1 );
      //         expire.setUTCHours(0);
      //         expire.setUTCMinutes(0);
      //         expire.setUTCSeconds(0);

      //         this.interceptorService.finger = this.encodeFinger(now, this.interceptorService.finger);

      //         this.cookieService.set('g-finger', this.interceptorService.finger, expire);
      //         // this.localStorage.store('g-finger', this.interceptorService.finger);
      //         this.interceptorService.fingerSubcribe.next(this.interceptorService.finger);
      //     });
      // }
    } else {

    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (environment.ssoType === 'sso') {
      // console.log('request.url', request.url)
      if (request.url.indexOf('https://app.cenhomes.vn') !== -1) {
        return next.handle(request);
      }
      // if (request.url.includes(`${environment.API.SERVER_API_G_AUTH}/auth/api/jwt-storages/`) || (request.url === `${environment.API.SERVER_API_G_AUTH}/auth/account`)) {
      //     console.log('Found', request.url, request.url.includes(`${environment.API.SERVER_API_G_AUTH}/auth/api/jwt-storages/`) || (request.url === `${environment.API.SERVER_API_G_AUTH}/auth/account`));
      //   return next.handle(request);
      // }
      if (!this.seoService.onBrowser) {
        request = request.clone({
          setHeaders: {
            Authorization: 'Finger 6d5317668df960d45198aacd35fa7d3d',
            Finger: '6d5317668df960d45198aacd35fa7d3d'
          }
        });
        return next.handle(request);
      } else {
        /* Lấy MD5 (gọi là SID) từ trong cookie */
        let cookieTK;

        if (!this.interceptorService.SID) {
          try {
            cookieTK = this.getCookieByName('SID');
            
            this.interceptorService.SID = cookieTK;
            // console.log('SID from TK', this.interceptorService.SID);
          } catch {
            this.interceptorService.SID = null;
            // console.log('Cannot get SID from Tk');
          }
          this.accountSsoService.SID = this.interceptorService.SID;
          // console.log('cookieTK', cookieTK);
        } else {

        }
        if (this.interceptorService.SID) {
          /* URL sẽ truyền SID lên để lấy token  */
          const requestJWTUrl = `${environment.API.SERVER_API_G_AUTH}/auth/api/jwt-storages/${this.interceptorService.SID}`;
          const requestInfoUrl = `${environment.API.SERVER_API_G_AUTH}/auth/account`;
          /* Gọi API lấy  ngay trước khi API đầu tiên được gọi và khi API này chưa được gọi trong lần tải trang hiện tại */
          if (!this.interceptorService.gettingJWT) {
            this.interceptorService.gettingJWT = true;
            console.log('Goi trong interceptor');
            this.http.get<any>(requestJWTUrl).subscribe(res => {
              this.interceptorService.jwt = res && res.payload && res.payload.data && res.payload.data.jwt || null;
              console.log('jwt from md5', res);
              this.http.get<any>(requestInfoUrl,
                {
                  headers: {
                    Authorization: 'Bearer ' + this.interceptorService.jwt
                  }
                }
              ).subscribe(info => {
                console.log('Info from md5', info);
                /* Gọi account khi truyền JWT của tài khoản thường và tài khoản đăng nhập bằng social khác nhau :() */
                const userInfo = (info.payload && info.payload.data) || info;
                this.accountSsoService.setUserIdentity(userInfo);
                this.accountSsoService.account = userInfo;
                this.accountService.setIdentity(userInfo);

                this.interceptorService.jwtReady = true;
                this.interceptorService.jwtReadySubject.next(true);
                return next.handle(request);
              }, errorInfo => {
                console.log('Authen error', errorInfo);
                if (errorInfo.status === 401) {
                  /* Thủ tục Logout */
                  this.accountSsoService.SID = null;
                  this.interceptorService.SID = null;
                  this.accountSsoService.jwt = null;
                  this.accountSsoService.account = null;
                  this.interceptorService.jwt = null;
                  this.accountSsoService.account = null;
                  this.accountService.setIdentity(null, true);
                  this.removeLargeCookie('TK');
                  this.removeCookie('SID');
                  this.interceptorService.jwtReady = false;
                  this.interceptorService.jwtReadySubject.next(null);
                  this.interceptorService.gettingJWT = false;
                  /* Hết thủ tục logout */
                  return next.handle(request);

                } else {
                  this.interceptorService.canNotGetAPI(errorInfo.status, errorInfo.message);
                  /* Thủ tục Logout */
                  this.accountSsoService.SID = null;
                  this.interceptorService.SID = null;
                  this.accountSsoService.jwt = null;
                  this.accountSsoService.account = null;
                  this.interceptorService.jwt = null;
                  this.accountSsoService.account = null;
                  this.accountService.setIdentity(null, true);
                  this.removeLargeCookie('TK');
                  this.removeCookie('SID');
                  this.interceptorService.jwtReady = false;
                  this.interceptorService.jwtReadySubject.next(null);
                  this.interceptorService.gettingJWT = false;
                  /* Hết thủ tục logout */
                  return next.handle(request);
                }
              });

            }, err => {
              this.interceptorService.canNotGetAPI(err.status, err.message);
              /* Thủ tục Logout */
              this.accountSsoService.SID = null;
              this.interceptorService.SID = null;
              this.accountSsoService.jwt = null;
              this.accountSsoService.account = null;
              this.interceptorService.jwt = null;
              this.accountSsoService.account = null;
              this.accountService.setIdentity(null, true);
              this.removeLargeCookie('TK');
              this.removeCookie('SID');
              this.interceptorService.jwtReady = false;
              this.interceptorService.jwtReadySubject.next(null);
              this.interceptorService.gettingJWT = false;
              /* Hết thủ tục logout */
              return next.handle(request);
            });
          } else {
            // return next.handle(request);
          }
          /* Nếu url hiện tại là url gọi để lấy token thì không làm gì */
          if (requestJWTUrl === request.url || requestInfoUrl === request.url) {
            return next.handle(request);
          } else {
            /* Tất cả các url khác đều đợi API lấy token xong mới tiếp tục gọi */
            // this.interceptorService.jwtReadySubject.subscribe(value => {
            //     console.log('value', value);
            // });
            if (this.interceptorService.jwtReady) {
              // console.log('Ready', this.interceptorService.jwt);
              const datetime = new Date().toLocaleString();
              const authorization = this.interceptorService.jwt ?
                'Bearer ' + this.interceptorService.jwt :
                'Finger ' + this.interceptorService.finger;
              request = request.clone({
                setHeaders: {
                  Authorization: authorization,
                  Finger: this.interceptorService.finger,
                  Version: VERSION,
                  // DateTime: datetime
                }
              });
              return next.handle(request);
            } else {
              return this.interceptorService.jwtReadySubject.pipe(
                // delay(5000),
                take(1),
                mergeMap(value => {
                  const datetime = new Date().toLocaleString();
                  const authorization = this.interceptorService.jwt ?
                    'Bearer ' + this.interceptorService.jwt :
                    'Finger ' + this.interceptorService.finger;
                  request = request.clone({
                    setHeaders: {
                      Authorization: authorization,
                      Finger: this.interceptorService.finger,
                      Version: VERSION,
                      // DateTime: datetime
                    }
                  });

                  // console.log('End intercept', value);
                  return next.handle(request);
                })
              );
            }
          }
        } else {
          // console.log('vao nhanh');
          // this.interceptorService.fingerBehaviorSubject.subscribe(finger => {
          //   console.log('finger sub', finger);
          // });
          return this.interceptorService.fingerBehaviorSubject.pipe(
            // delay(5000),
            find(finger => finger !== null),
            mergeMap(finger => {
              // console.log('finger sub', finger);
              this.interceptorService.jwtReady = true;
              this.interceptorService.jwtReadySubject.next(true);
              const datetime = new Date().toLocaleString();
              request = request.clone({
                setHeaders: {
                  Authorization: 'Finger ' + this.interceptorService.finger,
                  Finger: this.interceptorService.finger,
                  Version: VERSION,
                  // DateTime: datetime
                }
              });
              return next.handle(request);
            }));
        }
      }
    } else if (environment.ssoType === 'cenhomesSSO') {
      // if (
      // (`https://gapi-sandbox.cenhomes.vn/khangna/g-sso-gateway/v2/api/oauth/map-user` !== request.url)
      // && (`https://gapi-sandbox.cenhomes.vn/khangna/g-sso-gateway/v2/auth/account` !== request.url)
      // ) {
        // const datetime = new Date().toLocaleString();
        // let token = localStorage.access_token || sessionStorage.access_token;
        // let headers = request.headers
        // .set('Authorization', token ? `Bearer ${token}` : `Finger ${this.interceptorService.finger}`)
        // .set('Finger', this.interceptorService.finger)
        // .set('Version', VERSION)
        // .set('DateTime', datetime);
        // request = request.clone({ headers });
        // return next.handle(request);
        if (!this.seoService.onBrowser) {
          request = request.clone({
            setHeaders: {
              Authorization: 'Finger 6d5317668df960d45198aacd35fa7d3d',
              Finger: '6d5317668df960d45198aacd35fa7d3d'
            }
          });
          return next.handle(request);
        } else {
          return this.interceptorService.fingerBehaviorSubject
          .pipe(
            find(finger => finger !== null),
            mergeMap(finger => {
              const datetime = new Date().toLocaleString();
              let token = localStorage.access_token || sessionStorage.access_token;
              let headers = request.headers
              .set('Authorization', token ? `Bearer ${token}` : `Finger ${this.interceptorService.finger}`)
              .set('Finger', this.interceptorService.finger)
              .set('Version', VERSION)
              // .set('DateTime', datetime);
              request = request.clone({ headers });
              return next.handle(request);
            })
          );
        }
        
        // return this.interceptorService.jwtBehaviorSubject
        //   .pipe(
        //     find(jwt => jwt !== null),
        //     mergeMap(jwt => {
        //       console.log('jwt', jwt);
        //       const datetime = new Date().toLocaleString();
        //       request = request.clone({
        //         setHeaders: {
        //           Authorization: jwt === 'null' ? 'Finger ' + this.interceptorService.finger : 'Bearer ' + jwt,
        //           // Authorization: 'Bearer ' + jwt,
        //           Finger: this.interceptorService.finger,
        //           Version: VERSION,
        //           DateTime: datetime
        //         }
        //       });
        //       return next.handle(request);
        //     })
        // );
      // } else {
      //   return next.handle(request);
      // }
      
      
    } else {
      console.error('ssoType không hợp lệ');
    }
  }

  private encodeFinger(now, finger): string {
    const date = now;
    const timestamp = String(date.getTime()); // timestamp with milliseconds
    const chs = [];
    // Copy character by character into array
    for (let i = 0; i < finger.length; i++) {
      chs.push(finger.charAt(i));
    }
    const day = date.getUTCDate();
    let salt = 0;
    if (day % 2 !== 0) {
      salt = 1;
    }
    for (let i = 0; i < timestamp.length; i++) {
      chs.splice(i * 2 + salt, 0, timestamp.charAt(i));
    }
    const resultChs = [];
    for (let i = 0; i < chs.length; i++) {
      resultChs.push(this.convertASCII(chs[i], day));
    }

    return btoa(resultChs.join(''));
  }
  private convertASCII<String>(char: any, index: number): string {
    const ascii = char.charCodeAt(0);
    return String.fromCharCode(ascii + index);
  }
  private fingerInvalid(): boolean {
    return !this.interceptorService.finger || (this.interceptorService.finger.length !== 60);
  }


  // private requestJWT(callback): void {
  //     const requestJWTUrl = 'https://sso.cenhomes.vn/g-sso-gateway/v2/auth/api/jwt-storages/' + this.interceptorService.SID;
  //     if (this.interceptorService.jwt) {
  //         this.http.get<any>(requestJWTUrl).subscribe(res => {
  //             console.log('jwt from md5', res);
  //             this.interceptorService.jwt = res && res.payload && res.payload.data && res.payload.data.jwt || null;
  //             callback(this.interceptorService.jwt);
  //         }, err => {
  //             callback(null);
  //         });
  //     } else {
  //         callback(null);
  //     }
  // }
  public getCookieByName(cname): string {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }
  public getLargeCookie(prefix): string {
    let cookie = '';
    const cookieField = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'K', 'L', 'M'];
    const numberOfParts = parseInt(this.getCookieByName(prefix), 10);
    for (let i = 0; i < numberOfParts; i++) {
      cookie += this.getCookieByName(cookieField[i] + prefix);
    }
    return cookie;
  }
  public removeCookie(cname): void {
    const TKN = [
      cname + '=',
      'expires=Thu, 01 Jan 1970 00:00:01 GMT',
      'domain='      + environment.sso.cookieDomain,
      'path='        + '/',
    ];
    document.cookie = TKN.join(';');
  }
  public removeLargeCookie(prefix): void {
    // let cookie = '';
    const cookieField = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'K', 'L', 'M'];
    const numberOfParts = parseInt(this.getCookieByName(prefix), 10);
    for (let i = 0; i < numberOfParts; i++) {
        const TKN = [
            cookieField[i] + prefix + '=',
            'expires=Thu, 01 Jan 1970 00:00:01 GMT',
            'domain='      + environment.sso.cookieDomain,
            'path='        + '/',
        ];
        document.cookie = TKN.join(';');
    }

    const TK = [
        prefix + '=',
        'expires=Thu, 01 Jan 1970 00:00:01 GMT',
        'domain='      + environment.sso.cookieDomain,
        'path='        + '/',
    ];
    document.cookie = TK.join(';');
  }
}
