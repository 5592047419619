import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { DangKyNhanBaoCaoComponent } from "./dang-ky-nhan-bao-cao.component";
import { OnlyDayDatePickerModule } from '../only-day-date-picker/only-day-date-picker.module'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    OnlyDayDatePickerModule
  ],
  declarations: [
    DangKyNhanBaoCaoComponent,
  ],
  exports: [
    DangKyNhanBaoCaoComponent
  ]
})
export class DangKyNhanBaoCaoModule { }
