import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  public map: any;
  public streetLayer: any;
  public satelliteLayer: any;
  public backgroundLayer: any;
  public markerLayer: any;
  public markerCreateNewLayer: any;
  public wmsLayer: any;
  public utilityLayer: any;
  public heatmapLayer: any;
  public markerPinLayer: any;
  constructor() { }
}
