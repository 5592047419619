import { environment } from './../../../environments/environment';
import { debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { SessionStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class SearchHttpService {
  public httpStatus: any;
  public loading: any = {
    searchFilterRealEstate: false,
    searchThuaDatMap: false,
    getStatusWard: false,
    dinhGiaFormGPS: false
  };
  public error: any = {
    searchFilterRealEstate: false,
    searchThuaDatMap: false,
    getStatusWard: false,
    dinhGiaFormGPS: false
  };
  private sourceApi = environment.API.SERVER_SEARCH + 'v2/';
  private sourceApiV2 = environment.API.SERVER_SEARCH + 'v2/';
  myHeaders: HttpHeaders;

  constructor(private http: HttpClient, private sessionStorageService: SessionStorageService) {
    this.httpStatus = {
      searchFilterRealEstate: false,
      searchThuaDatMap: false,
    };
    this.myHeaders = new HttpHeaders({ 'Content-Type': 'application/json ' });
  }

  // Goi y khi search
  getSuggest(type, tem) {
    // return this.http.post<any>(this.sourceApi + 'dinhgia-suggest', {
    //     cat_type: type,
    //     data: tem
    // });
    return this.http.post<any>(this.sourceApiV2 + 'dinhgia-suggest-v2', {
      cat_type: type,
      data: tem
    });
  }

  searchTerm(type: any, terms: Observable<string>) {
    // console.log(type);
    if (terms) {
      return terms.pipe(debounceTime(200), distinctUntilChanged(),
        switchMap(tem => this.getSuggest(type, tem))
      );
    }
  }

  getStatusWard(idPhuong: number, idQuan: number): Observable<any> {
    this.loading.getStatusWard = true;
    this.error.getStatusWard = false;
    const listPhuongXaQuanHuyenHasMap = this.sessionStorageService.retrieve('status-ward') || {};
    if (listPhuongXaQuanHuyenHasMap[idPhuong + '-' + idQuan]) {
      return of(listPhuongXaQuanHuyenHasMap[idPhuong + '-' + idQuan]);
    }
    // const status = this.sessionStorage.retrieve('status_ward');
    // if (status && status.phuongId === idPhuong) {
    //     return of(status.status);
    // } else {
    return this.http.get<any>(this.sourceApi + 'check-has-map-and-model?ward-id=' + idPhuong + '&district-id=' + idQuan)
      .pipe(
        retry(0),
        switchMap(value => {
          listPhuongXaQuanHuyenHasMap[idPhuong + '-' + idQuan] = value;
          this.sessionStorageService.store('status-ward', listPhuongXaQuanHuyenHasMap);
          // console.log('value from switch map', value);
          this.loading.getStatusWard = false;
          return of(value);
        }),
        catchError(err => {
          // console.log('Co loi xay ra', err);
          this.loading.getStatusWard = false;
          this.error.getStatusWard = true;
          return of('{"hits":{"hits":[]}}');
        })
      );
    // return this.http.get<any>(this.sourceApi + 'check-has-map-and-model?ward-id=' + idPhuong).pipe(
    //         map(statusWard => {
    //             this.sessionStorage.store('status_ward', {
    //                 phuongId: idPhuong,
    //                 status: statusWard
    //             });
    //             return statusWard;
    //         })
    //     );
    // }
  }

  searchRealEstate(query, page) {
    return this.http.post<any>(this.sourceApi + 'dinhgia-search?page=' + page, {
      data: query
    });
  }

  getCrawSimilar(body) {
    return this.http.post<any>(this.sourceApi + 'dinhgia-crawl-similar', body);
  }

  getZoneId(id) {
    return this.http.get<any>(this.sourceApi + 'dinhgia-get?id=' + id);
  }

  searchFilterRealEstate(body, pages, size) {
    this.loading.searchFilterRealEstate = true;
    this.error.searchFilterRealEstate = false;
    // return this.http.post<any>(this.sourceApi + 'dinhgia-form-search?page=' + pages + '&size=' + size, body)
    // return this.http.post<any>(this.sourceApiV2 + 'dinhgia-form-search-v2?page=' + pages + '&size=' + size, body)
    return this.http.post<any>(this.sourceApiV2 + 'dinhgia-form-gps?page=' + pages + '&size=' + size, body)
      .pipe(
        retry(0),
        switchMap(value => {
          // console.log('value from switch map', value);
          this.loading.searchFilterRealEstate = false;
          return of(value);
        }),
        catchError(err => {
          console.log('Co loi xay ra searchFilterRealEstate', err);
          this.loading.searchFilterRealEstate = false;
          this.error.searchFilterRealEstate = true;
          return of('{"hits":{"hits":[]}}');
        })
      );
  }

  searchThuaDatMap(body, limit) {
    this.loading.searchThuaDatMap = true;
    this.error.searchThuaDatMap = false;
    // return this.http.post<any>(this.sourceApi + 'dinhgia-search-land?page=0&size=' + limit, body).pipe(
    return this.http.post<any>(this.sourceApiV2 + 'dinhgia-search-land-v2?page=0&size=' + limit, body).pipe(
      retry(0),
      switchMap(value => {
        // console.log('value from switch map', value);
        this.loading.searchThuaDatMap = false;
        return of(value)
      }),
      catchError(err => {
        // console.log('Co loi xay ra', err);
        this.loading.searchThuaDatMap = false;
        this.error.searchThuaDatMap = true;
        return of('{"hits":{"hits":[]}}');
      })
    );
  }

  dinhgiaAddressDupliceThuaDat(id): Observable<any> {
    const body = { id: id };
    return this.http.post<any>(this.sourceApiV2 + 'dinhgia-address-duplice-thua-dat', body);
  }

  queryHeatMap(body) {
    return this.http.post<any>(this.sourceApi + 'dinhgia-heatmap', body);
  }

  queryUtility(body) {
    return this.http.post<any>(this.sourceApi + 'dinhgia-search-utilities', body);
  }

  queryPopulation(body: any): Observable<any> {
    return this.http.post(this.sourceApi + 'dinhgia-population', body);
  }

  queryAddressByLatLng(lat: number, lng: number): Observable<any> {
    return this.http.get(this.sourceApi + '/dinhgia-address-point?' +
      'lat=' + lat +
      '&lon=' + lng);
  }
  // searchRealEstateInMap(body) {
  //     return this.ht
  // }
  public getHttpStatus() {
    return this.httpStatus;
  }
  private handleError(error: HttpErrorResponse, caught) {
    console.log('error');
    console.log(caught.source.source);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };
  HighlightKV(body: any): Observable<any> {
    return this.http.post<any>(this.sourceApi + 'analysis/address-gps', body);
    // return of({"status":0,"payload":{"data":{"idEstimate":1269,"giaDatMoHinh":{"donGia":254.36,"giaTri":6.893156000000001,"dienGiai":"Mô hình định giá bằng AI"},"giaDatTinRao":null,"giaCTXD":{"donGia":5.393317,"giaTri":0.26966585,"dienGiai":"Quyết định 06/2017/QĐ-UBND của thành phố Hà Nội."},"giaCTXDTinhThue":{"donGia":6.824,"giaTri":0.27296,"dienGiai":"Quyết định số: 3023/QĐ-UBND của thành phố Hà Nội"}}},"description":""})
  }

  InforDC(body: any): Observable<any> {
    return this.http.post<any>(this.sourceApi + 'analysis/address-thong-tin-dia_chinh', body);
  }

  duAnXungQuanh(cityId: number, districtId: number, limit: number): Observable<any> {
    return this.http.get(this.sourceApi + 'dinhgia-du-an-xung-quanh?' +
      'city_id=' + cityId +
      '&district_id=' + districtId +
      '&limit=' + limit);
  }

  bieuDoGiaDuAn(id: number): Observable<any> {
    return this.http.get(this.sourceApi + 'dinhgia-project-statistics?id=' + id);
  }
  //api tien ich quan va duong
  UtilityDistrict(body: any): Observable<any> {
    return this.http.post<any>(this.sourceApi + 'utility/utility_search', body);
  }
  // UtilityDistrict(body:any): Observable<any> {
  //     return this.http.post<any>('http://cendev2:8096/utility/utility_search', body);
  // }
  dataStatistic() {
    return this.http.get<any>(this.sourceApi + 'data-statistic', {});
  }
  queryApiCenhome(body: string): Observable<any> {
    return this.http.get(environment.API.SERVER_API_CENHOMES_PROJECT + 'address=' + body);
  }
  queryadress(type: string, id: number): Observable<any> {
    return this.http.get(environment.API.KONG_SERVER + '/c-address/v1/public-dia-chis?' + 'type=' + type + '&idPre=' + id)
  }
  //api tang truong noi bat
  tangtruongnoibat(body: any): Observable<any> {
    return this.http.post<any>(environment.API.KONG_SERVER + '/g-data-statistic/v1/api/khu-vuc/top-tang-truong', body);
  }
  queryDuanxungquang(body: string): Observable<any> {
    return this.http.get(environment.API.SERVER_API_CENHOMES_PROJECT + 'title=' + body, {
      headers: this.myHeaders
    });
  }
  //api profile user
  analyticsUser(body: string, num: number, site: any): Observable<any> {
    return this.http.get(environment.API.KONG_SERVER + '/g-data-statistic/v1/api/profile-user/anlytics-user-weekly?id=' + body + '&month=' + num + '&site=' + site);
  }

  //api profile-user lấy theo muc thang
  analyticsUserMonthly(body: string, from_month: number, to_month: number, from_year: number, to_year: number, site: any) {
    return this.http.get(environment.API.KONG_SERVER + '/g-data-statistic/v1/api/profile-user/anlytics-user-monthly?id=' + body + '&from_month=' + from_month + '&to_month=' + to_month + '&from_year=' + from_year + '&to_year=' + to_year + '&site=' + site);
  }

  //query user
  getUser(body: string, site?: string): Observable<any> {
    //  return this.http.get<any>( environment.API.KONG_SERVER + '/g-data-statistic/v1/api/profile-user/list-user?site=gianhadat&limit=1000&offset=0');
    return this.http.get<any>(environment.API.KONG_SERVER + '/g-data-statistic/v1/api/profile-user/list-user?site=' + site + '&limit=50&offset=0&search=' + body);
  }

  layThuaDatXungQuanhByGps(lat: number, lng: number, size: number) {
    return this.http.post<any>(this.sourceApi + 'dinhgia-search-land-by-location?size=' + size, { 'lat': lat, 'lon': lng });
  }

  public soTinRaoVaGiaMoiGioi(params): Observable<any> {
    return this.http.get<any>(
      environment.API.KONG_SERVER + '/g-data-statistic/v1/api/profile-broker-months/statistic?key-id=' + params.keyId +
      '&from-month=' + params.fromMonth +
      '&to-month=' + params.toMonth +
      '&from-year=' + params.fromYear +
      '&to-year=' + params.toYear +
      '&use-for=' + params.useFor);
  }
  getTyTrongMoiGioiTheoPhanKhuc(params): Observable<any> {
    let param = '';
    if (params.keyId) {
      param += 'key-id=' + params.keyId + '&'
    }
    return this.http.get<any>(
      environment.API.KONG_SERVER + '/g-data-statistic/v1/api/profile-broker-months/ty-trong?' + param +
      'from-month=' + params.fromMonth +
      '&to-month=' + params.toMonth +
      '&from-year=' + params.fromYear +
      '&to-year=' + params.toYear +
      '&use-for=' + params.useFor);
  }
  getDataKhuVucQuanTam(params): Observable<any> {
    return this.http.get<any>(
      environment.API.KONG_SERVER + '/g-data-statistic/v1/api/profile-broker-months/kvqt?key-id=' + params.keyId +
      '&from-month=' + params.fromMonth +
      '&to-month=' + params.toMonth +
      '&from-year=' + params.fromYear +
      '&to-year=' + params.toYear +
      '&use-for=' + params.useFor);
  }

  public listBroker(params): Observable<any> {
    return this.http.get<any>(
      environment.API.KONG_SERVER + '/g-data-statistic/v1/api/profile-broker-months/list-broker?search=' + params.search +
      '&offset=' + params.offset +
      '&limit=' + params.limit);
  }

  public listTop(params): Observable<any> {
    return this.http.get<any>(
      environment.API.KONG_SERVER + '/g-data-statistic/v1/api/profile-broker-months/list-top?' +
      'from-month=' + params.fromMonth +
      '&to-month=' + params.toMonth +
      '&from-year=' + params.fromYear +
      '&to-year=' + params.toYear +
      '&limit=' + params.limit);
  }
  public listTopCommunities(params): Observable<any> {
    const query = [];
    for (const propt in params) {
      if (params[propt] !== null && params[propt] !== undefined) {
        query.push(propt + '=' + params[propt]);
      }
    }
    return this.http.get<any>(
      environment.API.KONG_SERVER + '/g-data-statistic/v1/api/profile-broker-communities/list-top?' + query.join('&'));
    // profile-broker-communities/list-top?from-month=1&to-month=5&from-year=2020&to-year=2020&limit=100&id-city=1&id-district=28'
  }
  public tiTrongBrokerComunities(params): Observable<any> {
    const query = [];
    for (const propt in params) {
      if (params[propt] !== null && params[propt] !== undefined) {
        query.push(propt + '=' + params[propt]);
      }
    }
    return this.http.get<any>(environment.API.KONG_SERVER + '/g-data-statistic/v1/api/profile-broker-communities/ty-trong?' + query.join('&'));
  }
  public getDataKhuVucQuanTamCommunities(params): Observable<any> {
    const query = [];
    // console.log('getDataKhuVucQuanTamCommunities', params);
    for (const propt in params) {
      if (params[propt] !== null && params[propt] !== undefined) {
        query.push(propt + '=' + params[propt]);
      }
    }
    return this.http.get<any>(environment.API.KONG_SERVER + '/g-data-statistic/v1/api/profile-broker-communities/kvqt?' + query.join('&'));
  }
  // profile-broker-communities/kvqt?from-month=1&to-month=5&from-year=2020&to-year=2020&id-city=1&id-district=28'

}

