import { FormGroup } from '@angular/forms';

export const soThuaDatXungQuanh = 32;
export interface LatLng {
  lat: number;
  lng: number;
}
export type StepEstimate = 'HIDE_ESTIMATE' | 'REQUEST_ESTIMATE' | 'RESULT_ESTIMATE';
export interface StepEstimateStatus {
  requestShow: boolean;
  resultShow: boolean;
}
export type TabPrice = 'GIA_THAM_DINH' | 'GIA_NHA_NUOC' | 'GIA_TINH_THUE_PHI';
export interface KetQuaNha {
  dienTich: string;
  donGia: string;
  donGiaText: string;
  CLCL: string;
  giaTri: string;
  dienGiai: string;
}

export interface PopupType {
  open: boolean;
  header: string;
  body: string;
}
export interface HttpStatus {
  loading: boolean;
  error: boolean;
  message: string;
}
export interface Status {
  estimate: HttpStatus;
  sendMail: HttpStatus;
  getThuaDatXungQuanh: HttpStatus;
  getThuaDat: HttpStatus;
  checkKhungGia: HttpStatus;
}
export interface KetQuaDat {
  dienTich: string;
  donGia: string;
  donGiaText: string;
  donGiaTextHighlight?: string;
  CLCL: string;
  giaTri: string;
  dienGiai: string;
}
export interface GiaDatNhaNuocType {
  donGia: string;
  donGiaText: string;
  dienTich: string;
  giaTri: string;
  CLCL: string;
  quyetDinh: string;
}
export interface GiaTinhThueType {
  donGia: string;
  donGiaText: string;
  dienTich: string;
  giaTri: string;
  dienGiai: string
}
export interface DiaChi {
  tinh: string;
  quanHuyen: string;
  phuongXa: string;
  duong: string;
}
export interface ChiTietGias {
  donGiaText: string;
  dienTich: string;
  giaTri: string;
  loaiGiaTri: string;
}

export interface KetQuaUocLuongGia {
  giaNha: KetQuaNha;
  giaDatThamDinh: KetQuaDat;
  chiTietGias: ChiTietGias[];
  tongGiaThamDinh: string;
  giaDatNhaNuoc?: GiaDatNhaNuocType;
  tongGiaNhaNuoc?: string;
  giaTinhThue?: GiaTinhThueType;
  tongGiaTinhThue?: number;
  thueTNCN?: number;
  lePhiTruocBa?: number;
  lePhiThamDinh?: number;
  phiCongChung?: number;
  cachTinhPhiCongChung?: string;
  tongThueVaPhi?: number;
  other?: string
}

export interface CoKhungGia {
  co: boolean;
  loading: boolean;
  error: boolean;
}

export interface YeuCauUocLuongGia {
  chatLuongConLai: number;
  chieuRongMatDuong: number;
  chieuRongMatTien: number;
  coNhieuThuaDat: boolean;
  dienTich: number;
  dienTichSuDung: number;
  duong: string;
  duongId: number;
  email: string;
  hinhDangLoDatId: number;
  huongDatId: number;
  idBatDongSanNhapLieu: number;
  idThuaDat: number;
  lat: number;
  loaiNhaId: number;
  lon: number;
  namXayDung: number;
  ngachHem: string;
  ngo: string;
  phuongXa: string;
  phuongXaId: string;
  quanHuyen: string;
  quanHuyenId: number;
  soMatTien: number;
  soNha: string;
  soTang: number;
  tinh: string;
  tinhId: number;
  viTriId: number;
  viTriIdBa: number;
  viTriIdHai: number;
}


export const ketQuaUocLuongGiaDefault: KetQuaUocLuongGia = {
  giaDatThamDinh: {
    dienTich: '---',
    donGia: '---',
    donGiaText: '---',
    CLCL: '---',
    giaTri: '---',
    dienGiai: ''
  },
  chiTietGias: [],
  giaNha: {
    dienTich: '---',
    donGia: '---',
    donGiaText: '---',
    CLCL: '---',
    giaTri: '---',
    dienGiai: '---'
  },
  tongGiaThamDinh: '---',
  giaDatNhaNuoc: {
    dienTich: '---',
    donGia: '---',
    donGiaText: '---',
    CLCL: '---',
    giaTri: '---',
    quyetDinh: '---'
  },
  giaTinhThue: {
    donGia: '---',
    donGiaText: '---',
    dienTich: '---',
    giaTri: '---',
    dienGiai: '---',
  },
  tongGiaNhaNuoc: '---',
  tongGiaTinhThue: null,
  thueTNCN: null,
  lePhiTruocBa: null,
  lePhiThamDinh: null,
  phiCongChung: null,
  cachTinhPhiCongChung: '---',
  tongThueVaPhi: null
};

export const thuaDatDefault = {
  canhBao: '',
  chieuRongMatDuong: null,
  chieuRongMatDuong2: null,
  chieuRongMatDuong3: null,
  dienTichNgoaiQuyHoach: null,
  dienTichThuaDat: null,
  dienTichTrongQuyHoach: null,
  donGiaDat: null,
  duongId: null,
  giaDat: null,
  hasPrice: null,
  hinhDangLoDatId: null,
  hoanhDoGps: null,
  huongDatId: null,
  id: null,
  kichThuocMatTien: null,
  kichThuocMatTien2: null,
  kichThuocMatTien3: null,
  phuongId: null,
  quanId: null,
  tinhId: null,
  tungDoGps: null,
  urlAnhBanDoDiaChinh: null,
  viTri: null
};

export const diaChiDefault: DiaChi = {
  tinh: '',
  quanHuyen: '',
  phuongXa: '',
  duong: ''
};
export const popupDefault: PopupType = {
  open: false,
  header: '',
  body: ''
};
export const statusDefault: Status = {
  estimate: {
    loading: false,
    error: false,
    message: ''
  },
  sendMail: {
    loading: false,
    error: false,
    message: ''
  },
  getThuaDatXungQuanh: {
    loading: false,
    error: false,
    message: ''
  },
  getThuaDat: {
    loading: false,
    error: false,
    message: ''
  },
  checkKhungGia: {
    loading: false,
    error: false,
    message: ''
  }
};

export function makeStaticInfo(info) {
  const staticInfo = {};
  /* Lưa lại mảng gốc để có khi cần dung */
  staticInfo['origin'] = info;
  Object.keys(info).forEach(function (key) {
    // console.log(key, info[key]);
    const obj = {};
    info[key].forEach(element => {
      obj[element.id] = element.ten;
    });
    staticInfo[key] = obj;
  });
  return staticInfo;
}

export function suggestSoTangFromLoaiNha(loaiNhaId: number): number {
  if (loaiNhaId === 3 || loaiNhaId === 4 || loaiNhaId === 7 || loaiNhaId === 8 || loaiNhaId === 15) {
    return 2;
  } else if (loaiNhaId === 9 || loaiNhaId === 10) {
    return 3;
  } else if (loaiNhaId === 5 || loaiNhaId === 6 || loaiNhaId === 16 || loaiNhaId === 17) {
    return 1;
  } else if (loaiNhaId === 11 || loaiNhaId === 12) {
    return 4;
  } else if (loaiNhaId === 13) {
    return 5;
  } else if (loaiNhaId === 14) { // đất trống
    return -1;
  } else if (loaiNhaId === 2) { // 6 tầng trở lên
    return null;
  } else if (loaiNhaId === 1) { // từ 3 đến 5 tầng khung cột tường gạch
    return null;
  } else {
    return null;
  }
}

export function scrollToTop(id): void {
  const p = document.querySelector(id);
  console.log('scrollToTop', p);
  if (p) {
    p.scrollTo(0, 0);
  }
}
