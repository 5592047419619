<div class="menu" (clickOutside)="onClickedOutside($event)">
    <div class="label" (click)="openMenu()">
        <ng-template [ngTemplateOutlet]="dropdownMenuLabel?.templateRef"></ng-template>
    </div>
    <div *ngIf="dropdownMenuService.open" class="content">
        <div *ngIf="clickOutsideTechType === 'BACK_DROP'" class="background" (click)="closeMenu()"></div>
        <div class="items" [ngClass]="{'menu-left-align': menuLeftAlign}">
            <ng-template [ngTemplateOutlet]="dropdownMenuItems?.templateRef"></ng-template>
        </div>
    </div>
</div>
