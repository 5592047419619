import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AreaPhanTich, Area, AddressTree } from './../util/address-tree';
import { Component, OnInit, Input } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Address } from "../util/address";
import { Helpers } from "../util/helpers";

@Component({
  selector: 'app-phan-tich-khu-vuc-search',
  templateUrl: './phan-tich-khu-vuc-search.component.html',
  styleUrls: ['./phan-tich-khu-vuc-search.component.scss']
})
export class PhanTichKhuVucSearchComponent implements OnInit {
  public areaSelected: Area;
  @Input() type: string;
  @Input() placeholder: string;
  @Input() formSwitch: FormGroup;
  @Input() set area(value: AreaPhanTich) {
    if (value) {
      this.addressTree = new AddressTree(
        this.http,
        this.fb,
        {
          tinhThanhId: value.tinhThanh && value.tinhThanh.id,
          quanHuyenId: value.quanHuyen && value.quanHuyen.id,
          phuongXaId: value.phuongXa && value.phuongXa.id,
          duongPhoId: value.duongPho && value.duongPho.id,
          duAnId: value.duAn && value.duAn.id
        }
      );
    }
  }
  public formSearch: Address;
  public addressTree: any;
  public idSelected: number;
  public nameSelected: string;
  public slugSelected: string;
  constructor(
    private http: HttpClient,
    public router: Router,
    public helpers: Helpers,
    private fb: FormBuilder
  ) {


  }

  ngOnInit(): void {

  }

  public changeTinh($event): void {
    this.addressTree.changeTinh($event);
  }
  public changeQuan($event): void {
    console.log('Change quan', $event)
    this.addressTree.changeQuan($event);
  }
  public changePhuong($event): void {
    this.addressTree.changePhuong($event);
  }
  public changeDuong($event): void {
    this.addressTree.changeDuong($event);
  }
  public changeDuAn($event): void {
    this.addressTree.changeDuAn($event);
  }
  public goToDesired(): void {
    if (this.type === 'du_an') {
      this.router.navigate(['/phan-tich-du-an/' + this.addressTree.selected.duAn.slug]);
    } else if (this.type === 'khu_vuc') {
      this.router.navigate(['/phan-tich-khu-vuc/' + this.addressTree.lastSelected.slug]);
    } else {
      console.error('toolbar-phan-tich: type không hợp lệ');
    }

  }

}
