export const USEFOR_REF = {
  SALE: ' bán ',
  RENT: ' thuê ',
};
export const TYPEBDS_REF = {
  5: 'mặt đường',
  7: 'trong ngõ',
  6: 'biệt thự',
  8: 'chung cư'
};
export const UNIT_REF = {
  SALE: ' triệu/m2 ',
  RENT: 'k/m<sup>2</sup>/tháng ',
};
export function round(num): number {
  if (num === null) {
    return null;
  } else {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }
}
export class Chart {
    tenDayDu: string;
    data: Array<any>;
    timeSeries: Array<any>;
    series: Array<any>;
    useFor: string;
    typeBds: string;

    public lichSuThiTruong: any;
    public lichSuThiTruongBatThuong: any;

    constructor(public inputData: any) {
      this.huntData();
      this.getTimeSeries();
    }
    public huntData(): any {
      /* Lấy data của cấp cha nếu cấp con không có */
      if (this.inputData.analysts && this.inputData.analysts.length > 0) {
          this.tenDayDu = this.inputData.tenDayDu;
          this.data = this.inputData.analysts;
          this.useFor = this.inputData.useFor;
          this.typeBds = this.inputData.typeBds.id;
      } else {
          if (this.inputData.parent.analysts && this.inputData.parent.analysts.length > 0) {
            this.tenDayDu = this.inputData.parent.tenDayDu;
            this.data = this.inputData.parent.analysts;
            this.useFor = this.inputData.parent.useFor;
            this.typeBds = this.inputData.parent.typeBds.id;
        } else {
            if (this.inputData.parent.parent.analysts && this.inputData.parent.parent.analysts.length > 0) {
              this.tenDayDu = this.inputData.parent.parent.tenDayDu;
              this.data = this.inputData.parent.parent.tenDayDu;
              this.useFor = this.inputData.parent.parent.useFor;
              this.typeBds = this.inputData.parent.parent.typeBds.id;
          } else {
              this.tenDayDu = '',
              this.data = [];
          }
        }
      }
    }
    public getData(): any {
        return this.data;
    }
    public getTenDayDu(): any {
        return this.tenDayDu;
    }
    public getUseFor(): any {
        return this.useFor;
    }
    public getTypeBds(): any {
        return this.typeBds;
    }

    getTimeSeries(): any {
      this.timeSeries = [];
      const dataLength = this.data.length;
      const startDate = new Date(this.data[0].year, this.data[0].month - 1);
      const endDate = new Date(this.data[dataLength - 1].year, this.data[dataLength - 1].month - 1);
      for (let i = startDate; i <= endDate; i.setMonth(startDate.getMonth() + 3)) {
          this.timeSeries.push([i.getMonth() + 1, i.getFullYear()]);
      }
    }
    getSerial(field: string): any {
      /*
      Example:
      inputData:
      [
          { "month": 2, "year": 2016, "soTin": 554, "giaTrenDtTrungBinh": 117.05403891883603 },
          { "month": 3, "year": 2016, "soTin": 1651, "giaTrenDtTrungBinh": 122.1884185194897 },
          { "month": 4, "year": 2016, "soTin": 2430, "giaTrenDtTrungBinh": 118.49141477420007 },
      ]
      field: "giaTrenDtTrungBinh"

      return:
      [117.05403891883603, 122.1884185194897, 118.49141477420007]
      */
      this.series = [];
      this.timeSeries.forEach(time => {
        const correspondingItem = this.data.findIndex(point => point.month === time[0] && point.year === time[1]);
        this.series.push(this.data[correspondingItem] ? this.data[correspondingItem][field] : null);
      });
      return this.series;
    }

    public limitItemPieChart(list: any, mergeField: any, sumField: string, limit: number): any {
      const newList = [];
      if (list.length <= limit - 1) {
          return list;
      } else {
          for (let i = 0; i < limit - 1; i ++) {
              newList.push(list[i]);
          }
          let sum = 0;
          for (let i = limit - 1; i < list.length; i ++) {
              sum = sum + list[i][sumField];
          }
          const lastItem = {};
          lastItem[sumField] = sum;
          for (let key in mergeField) {
              lastItem[key] = mergeField[key];
          }
          newList.push(lastItem);
          return newList;
      }
    }
}

export class ParseFilter {
  public idDiaChi: number;
  public loaiDiaChi: string;
  public use: string;
  public type: string;
  constructor(urlParse: any) {
      const queryParams = urlParse.queryParams;
      this.type = queryParams.nha;
      this.use = queryParams['thi-truong'];
      const segment = urlParse.root.children.primary.segments[1].path;

      const segmentSplit = segment.split('-');
      this.idDiaChi = Number(segmentSplit[segmentSplit.length - 1]);

      let levelReference = '';
      if (segment.indexOf('tinh') === 0) {
        levelReference = 'analytics_tinh';
        this.loaiDiaChi = 'tinh';
      } else if (segment.indexOf('thanh') === 0) {
        if ([1, 3, 5, 16, 59].includes(this.idDiaChi)) {
          levelReference = 'analytics_tinh';
          this.loaiDiaChi = 'tinh';
        } else {
          levelReference = 'analytics_quan_huyen';
          this.loaiDiaChi = 'quan';
        }
      } else if (segment.indexOf('quan') === 0 ||
        segment.indexOf('huyen') === 0 ||
        segment.indexOf('thi-xa') === 0) {
        levelReference = 'analytics_quan_huyen';
        this.loaiDiaChi = 'quan';
      } else if (segment.indexOf('phuong') === 0 ||
        segment.indexOf('xa') === 0 ||
        segment.indexOf('thi-tran') === 0) {
        levelReference = 'analytics_phuong_xa';
        this.loaiDiaChi = 'phuong';
      } else if (segment.indexOf('duong') === 0 ||
        segment.indexOf('pho') === 0) {
        levelReference = 'analytics_duong';
        this.loaiDiaChi = 'duong';
      }
  }

}

export function createSlugFromTitle(alias): string {
  let str = alias;
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, ' ');
  str = str.replace(/\s+/g, ' ');
  str = str.trim();
  str = str.replace(/\s/g, '-');
  // console.log(str);
  return str;
}
