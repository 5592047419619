<div class="mt-3 bao-cao" *ngIf="dangKyNhanBaoCaoService.step === 0">

    <div class="form-wrap">
        <div class="form-input-width-2">
            <span class="label">Email nhận báo cáo</span>
            <input class="input-default-theme" [(ngModel)]="emailNhanKetQua" type="text"
                placeholder="Nhập Email của bạn">
        </div>
        <div class="form-input-width-2">
            <span class="label">Thời gian nhận</span>
            <app-only-day-date-picker [(chooseDay)]="dayOfDate" (dateChange)="dateChange($event)">
            </app-only-day-date-picker>
        </div>
    </div>
    <div class="form-wrap">
        <div class="form-input-width-2">
            <span class="label">Tỉnh/Thành phố </span>
            <ng-select *ngIf="dangKyNhanBaoCaoService.formSearch && dangKyNhanBaoCaoService.formSearch.tinh" required
                [items]="dangKyNhanBaoCaoService.formSearch.tinh.list" bindLabel="ten" dropdownPosition="bottom"
                [(ngModel)]="dangKyNhanBaoCaoService.formSearch.tinh.choose" name="tinh" [clearable]="true"
                placeholder="Tỉnh/Thành phố " (ngModelChange)="dangKyNhanBaoCaoService.formSearch.changeTinh()"
                notFoundText="Không tìm thấy kết quả">
            </ng-select>
        </div>
        <div class="form-input-width-2">
            <span class="label">Quận/Huyện</span>
            <ng-select *ngIf="dangKyNhanBaoCaoService.formSearch && dangKyNhanBaoCaoService.formSearch.quan"
                [items]="dangKyNhanBaoCaoService.formSearch.quan.list" bindLabel="ten" dropdownPosition="bottom"
                [(ngModel)]="dangKyNhanBaoCaoService.formSearch.quan.choose" name="quan" [clearable]="true"
                placeholder="Quận/Huyện" (ngModelChange)="dangKyNhanBaoCaoService.formSearch.changeQuan()"
                notFoundText="Không tìm thấy kết quả">
            </ng-select>
        </div>
    </div>
    <div class="form-wrap">
        <div class="form-input-width-2">
            <span class="label">Phường/Xã</span>
            <ng-select *ngIf="dangKyNhanBaoCaoService.formSearch && dangKyNhanBaoCaoService.formSearch.phuong"
                [items]="dangKyNhanBaoCaoService.formSearch.phuong.list" bindLabel="ten" dropdownPosition="bottom"
                [(ngModel)]="dangKyNhanBaoCaoService.formSearch.phuong.choose" name="phuong" [clearable]="true"
                placeholder="Phường/Xã" (ngModelChange)="dangKyNhanBaoCaoService.formSearch.changePhuong()"
                notFoundText="Không tìm thấy kết quả">
            </ng-select>
        </div>
        <div class="form-input-width-2">
            <span class="label">Đường</span>
            <ng-select *ngIf="dangKyNhanBaoCaoService.formSearch && dangKyNhanBaoCaoService.formSearch.duong"
                [items]="dangKyNhanBaoCaoService.formSearch.duong.list" bindLabel="ten" dropdownPosition="bottom"
                [(ngModel)]="dangKyNhanBaoCaoService.formSearch.duong.choose" name="duong" [clearable]="true"
                placeholder="Đường" (ngModelChange)="dangKyNhanBaoCaoService.formSearch.changeDuong()"
                notFoundText="Không tìm thấy kết quả">
            </ng-select>
        </div>
    </div>
    <div>
        <span class="label-multi">Các loại biểu đồ </span>
        <ng-select required [items]="listBieuDo" bindLabel="ten" bindValue="id" dropdownPosition="bottom"
            [(ngModel)]="dangKyNhanBaoCaoService.bieuDoChon" name="bieuDo" [multiple]="true" [clearable]="true"
            placeholder="Chọn Biểu Đồ" notFoundText="Không tìm thấy kết quả">
        </ng-select>
    </div>
    <div class="row">
        <div class="col-6 pl-0 pr-1">
            <span class="label-multi">Loại tài sản</span>
            <ng-select required [items]="listloaiTaiSan" bindLabel="ten" bindValue="id" dropdownPosition="bottom"
                [(ngModel)]="dangKyNhanBaoCaoService.loaiTaiSan" name="taiSan" [multiple]="true" [clearable]="true"
                placeholder="Chọn Loại Tài Sản" notFoundText="Không tìm thấy kết quả">
            </ng-select>
        </div>
        <div class="col-6 pl-1 pr-0">
            <span class="label-multi">Phân khúc</span>
            <ng-select required [items]="listphanKhucBds" bindLabel="ten" bindValue="id" dropdownPosition="bottom"
                [(ngModel)]="dangKyNhanBaoCaoService.phanKhucBds" name="phanKhucBds" [multiple]="true"
                [clearable]="true" placeholder="Chọn Phân Khúc" notFoundText="Không tìm thấy kết quả">
            </ng-select>
        </div>
    </div>


    <div class="row mt-3" *ngIf="dangKyNhanBaoCaoService.formSearch">
        <div class="col-12 mb-4 text-center">
            <button
                [disabled]="!emailNhanKetQua
            || !(this.dangKyNhanBaoCaoService.formSearch && this.dangKyNhanBaoCaoService.formSearch.tinh && this.dangKyNhanBaoCaoService.formSearch.tinh.choose && this.dangKyNhanBaoCaoService.formSearch.tinh.choose.id)"
                class="btn btn-cenhomes button-cen-dinh-gia" (click)="dongYNhanEmailHangThang()">Xác nhận đăng
                ký</button>
        </div>

    </div>
</div>
<div class="text-center" *ngIf="dangKyNhanBaoCaoService.step === 1">
    <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
    <div>Đang xác nhận</div>
</div>
<div class="text-center" *ngIf="dangKyNhanBaoCaoService.step === 2">
    <div class="py-2">{{ketQuaDangKy}}</div>
    <div><button (click)="closeDangKyNhanBaoCao()">Đóng</button></div>
</div>
