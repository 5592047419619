import { DirectiveModule } from './../directives/directive.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThamDinhGiaComponent } from './tham-dinh-gia.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzRadioModule } from 'ng-zorro-antd/radio';



@NgModule({
  declarations: [ThamDinhGiaComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    DirectiveModule,
    NzIconModule,
    NzCheckboxModule,
    NzInputModule,
    NzButtonModule,
    NzSelectModule,
    NzRadioModule
  ],
  exports: [ThamDinhGiaComponent]
})
export class ThamDinhGiaModule { }
