<!-- <ng-template [ngTemplateOutlet]="contentTabsLabel[0]?.templateRef"></ng-template> -->
<div class="d-flex" [ngClass]="[tabsClass ? tabsClass : '']">
    <div (click)="selectTab(i, item.value)" [ngClass]="[
            item.tabClass ? item.tabClass : '',
            tabValue === item.value ? 'selected' : '',
            item.disabled ? 'disabled' : ''
        ]" *ngFor="let item of contentTabsLabel; index as i">
        <ng-template [ngTemplateOutlet]="item.templateRef"></ng-template>
    </div>
</div>
