import { FormsModule } from '@angular/forms';
import { DropdownMenuModule } from './../dropdown-menu/dropdown-menu.module';
import { DatePickerComponent } from './date-picker.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        DropdownMenuModule
    ],
    declarations: [
        DatePickerComponent
    ],
    exports: [
        DatePickerComponent
    ]
})
export class DatePickerModule {}
