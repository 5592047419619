import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { makeStaticInfo } from '../layouts/map/uoc-luong-gia/uoc-luong-gia.const';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay, filter, first, map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StaticInfoService {
  public currentTinhId: number;
  array: any;
  keyValue: any;
  subject: BehaviorSubject<any>;
  promise: Promise<any>;
  loading: boolean;
  constructor(
    private http: HttpClient
  ) {
    this.subject = new BehaviorSubject(null);
    
  }
  getById(tinhId: number) {
    this.loading = true;
    this.subject.next(null);
    if (tinhId) {
      if (`${this.currentTinhId}` !== `${tinhId}`) {
        this.http.get<any>(`${environment.API.SERVER_API_DATA_COLLECTION}/api/static-info/get-all-with-tinh?tinh-id=${tinhId}`)
        // .pipe(delay(3000))
        .subscribe(staticInfo => {
          this.makeStaticInfo(staticInfo.payload);
          this.loading = false;
          this.subject.next(this.keyValue);
        });
        this.currentTinhId = tinhId;
      } else {
        this.loading = false;
      }
    }
  }
  async getAsyncStatic(): Promise<any> {
    return Promise.resolve(this.keyValue.viTriDTO);
  }

  makeStaticInfo(info): void {
    const staticInfo = {};
    /* Lưa lại mảng gốc để có khi cần dung */
    this.array = info;
    Object.keys(info).forEach(function(key) {
        // console.log(key, info[key]);
        const obj = {};
        info[key].forEach(element => {
            obj[element.id] = element.ten;
        });
        staticInfo[key] = obj;
    });
    this.keyValue = staticInfo;
  }
  
}
