import { Injectable } from '@angular/core';
import { PopupType } from './shared/popup/popup.model';

@Injectable({
  providedIn: 'root'
})
export class AppErrorService {
  public popup: PopupType;
  constructor() {
    this.popup = {
      open: false,
      header: '',
      body: ''
    };
  }
}
