import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhanTichKhuVucSearchComponent } from './phan-tich-khu-vuc-search.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DropdownMenuModule } from '../dropdown-menu/dropdown-menu.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchSuggestModule } from '../search-suggest/search-suggest.module';



@NgModule({
  declarations: [PhanTichKhuVucSearchComponent],
  imports: [
    CommonModule,
    NgSelectModule,
    DropdownMenuModule,
    FormsModule,
    SearchSuggestModule,
    ReactiveFormsModule
  ],
  exports: [PhanTichKhuVucSearchComponent]
})
export class PhanTichKhuVucSearchModule { }
