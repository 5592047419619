import { LoginSsoService } from './../../../core/login/login-sso.service';
import { InfoBdsService } from './../../../layouts/map/info-bds.service';
// import { MARKERS } from './../../../layouts/map/map.defines';
import { MARKERS } from '../../../app.global.constants';
import { LoginModalService } from './../../../core/login/login-modal.service';
import { LoginService } from 'src/app/core/login/login.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AccountService } from './../../../core/auth/account.service';
import { EstimateHttpService } from 'src/app/shared/http/estimate-http.service';
import { Subject, Observable } from 'rxjs';
import { DcsHttpService } from './../../http/dcs-http.service';
import { Helpers } from 'src/app/shared/util/helpers';
import { AddressHttpService } from './../../http/address-http.service';
import { Injectable } from '@angular/core';
import { RequestEstimate, ResponseEstimate } from 'src/app/shared/model/estimate/estimate';
import { Address } from 'src/app/shared/util/address';
import { Address2 } from 'src/app/shared/util/address2';
import { SearchHttpService } from '../../http/search-http.service';
import { RouteStateService } from '../../util/routeState.service';
import { ToasterService } from 'angular2-toaster';
import { callbackify } from 'util';
import { ThuaDat } from '../../model/dcs/thua-dat.model';
import { environment } from 'src/environments/environment';


declare var L: any;

export const timeoutDinhGia = 20000; // (ms)
export const timeoutLayNhaHangXom = 5000; // (ms)
export const timeoutLayThuaDatHangXom = 5000; // (ms)

export interface mapStateType {
  isReady: boolean;
  readySubscribe: Subject<boolean>;
}

export interface formEstimateState {
  initingFormEstimate: boolean;
  initingMappingThuaDat: boolean;
  gettingThuaDatById: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class FormCenEstimateV2Service {
  public inputErrorList: any;
  public address: Address;
  public customLatLng: any;
  // public infoBds: any;
  public loaiTaiSan: string;
  public estimateFrom: string;

  public diaChiTaiSan: Address2;
  public diaChiDayDu: string;
  public emailNhanGia: string;
  public checkboxDongYNhanMail: boolean;
  public emailNhanKetQua: string;
  public tenNguoiGui: string;

  public canhBaoQuyHoach: string;

  public staticInfo: any;
  /* Map để mapping thửa đất trong popup chi tiết tài sản */
  public mapInDetail: any;
  public mapInDetailState: mapStateType;
  public duongBoundLayerGroup: any;
  public makerTamThuaLayerGroup: any;

  /* Map để mapping thửa đất trong form định giá */
  public mapInForm: any;
  public mapInFormState: mapStateType;
  public duongBoundLayerGroupInForm: any;
  public makerTamThuaLayerGroupInForm: any;

  public haveMap: boolean;
  public mapReady: Subject<boolean> = new Subject();

  public form: RequestEstimate = new RequestEstimate();
  public additionEstimateInfo: any = {
    thuaDatXungQuanh: null,
    thuaDatHeThongGoiYId: null
  };
  public thuaDatDangChonSubscribe: Subject<ThuaDat> = new Subject();
  public thuaDatDangChon: ThuaDat;
  public result: ResponseEstimate = new ResponseEstimate();
  public resultSubscibe: Subject<any> = new Subject();
  public dongGiaDatNhaNuoc: any;
  public isEstimating: boolean = false;
  public isDinhGiaLai: boolean = false;
  public showResult: boolean = false;
  public showEstimateForm: boolean = false;
  public landPhuongId: number;
  public currentYear: any;
  public daAnSuaThongTinVaDinhGiaLai: boolean = false;
  public dangLayThongTinGoiY: boolean = false;
  public notResponse: boolean = false;
  public idThuaDatGanNhat: number;
  public cacheThuaDatXungQuanh: any = [];
  public state: formEstimateState;

  public suggestedLandId: number;
  public formValidated: any = {
    valid: {},
    allValid: false
  };

  public optionalLoaiDatStatic: any = {
    '1': [
      { type: 'ODT', checkbox: 'coDatODT', input: 'dienTichDatODT', reference: 'dienTichDatODT', text: 'Đất ở tại đô thị (ODT)' },
      { type: 'SKC', checkbox: 'coDatSKC', input: 'dienTichDatSKC', reference: 'dienTichDatSKC', text: 'Đất cơ sở sản xuất, kinh doanh (SKC)' },
      { type: 'VLK', checkbox: 'coDatVLK', input: 'dienTichDatVLK', reference: 'dienTichDatSKC', text: 'Đất vườn liền kề (VLK)' },
      { type: 'TMD', checkbox: 'coDatTMD', input: 'dienTichDatTMD', reference: 'dienTichDatSKC', text: 'Đất thương mại dịch vụ (TMD)' },
      { type: 'CLN', checkbox: 'coDatCLN', input: 'dienTichDatCLN', reference: 'dienTichDatCLN', text: 'Đất trồng cây lâu năm (CLN)' },
      { type: 'HNK', checkbox: 'coDatHNK', input: 'dienTichDatHNK', reference: 'dienTichDatHNK', text: 'Đất trồng cây hàng năm (HNK)' },
    ],
    '59': [
      { type: 'ODT', checkbox: 'coDatODT', input: 'dienTichDatODT', reference: 'dienTichDatODT', text: 'Đất ở tại đô thị (ODT)' },
      { type: 'SKC', checkbox: 'coDatSKC', input: 'dienTichDatSKC', reference: 'dienTichDatSKC', text: 'Đất cơ sở sản xuất, kinh doanh (SKC)' },
      { type: 'TMD', checkbox: 'coDatTMD', input: 'dienTichDatTMD', reference: 'dienTichDatTMD', text: 'Đất thương mại dịch vụ (TMD)' },
      { type: 'NNP', checkbox: 'coDatNNP', input: 'dienTichDatNNP', reference: 'dienTichDatNNP', text: 'Đất nông nghiệp (NNP)' },
    ]
  };

  public modalRef: NgbModalRef;
  public error: {
    errordienTich: boolean;
    errorchieuRongMatTien: boolean;
    errorchieuRongMatDuong: boolean;
    errorchieuRongMatDuongHai: boolean,
    errorchieuRongMatDuongBa: boolean,
    errorsoTang: boolean,
    errorgiaDeXuat: boolean,
  }
  public errorMessage: any;
  openPopUp: boolean;
  constructor(
    public infoBds: InfoBdsService,
    private addressHttpService: AddressHttpService,
    private dcsHttpService: DcsHttpService,
    private estimateHttpService: EstimateHttpService,
    private accountService: AccountService,
    private searchHttpService: SearchHttpService,
    private helpers: Helpers,
    public routeStateService: RouteStateService,
    public loginService: LoginService,
    public loginModalService: LoginModalService,
    private modalService: NgbModal,
    private toaster: ToasterService,
    public loginSsoService: LoginSsoService
  ) {
    const currentDate = new Date();
    this.currentYear = currentDate.getFullYear();
    this.mapReady.next(false);
    this.mapInDetailState = {
      isReady: false,
      readySubscribe: new Subject()
    };
    this.mapInFormState = {
      isReady: false,
      readySubscribe: new Subject()
    };
    this.state = {
      initingFormEstimate: false,
      initingMappingThuaDat: false,
      gettingThuaDatById: false
    };
    this.error = {
      errordienTich: false,
      errorchieuRongMatTien: false,
      errorchieuRongMatDuong: false,
      errorchieuRongMatDuongHai: false,
      errorchieuRongMatDuongBa: false,
      errorsoTang: false,
      errorgiaDeXuat: false
    }
    this.errorMessage = {
      errordienTich: '',
      errorchieuRongMatTien: '',
      errorchieuRongMatDuong: '',
      errorchieuRongMatDuongHai: '',
      errorchieuRongMatDuongBa: '',
      errorsoTang: '',
      errorgiaDeXuat: '',
    }
    this.openPopUp = false;
  }



  setDataToForm(input) {
    return;
    this.haveMap = false;
    this.notResponse = false;
    this.daAnSuaThongTinVaDinhGiaLai = false; /* Reset cờ đánh dấu rằng người dùng đang thực hiện chức năng sửa thông tin và định giá lại */
    // this.dangLayThongTinGoiY = true; /* Reset cờ đánh dấu rằng hệ thống đang lấy thông tin gợi ý từ thửa đất hàng xóm hoặc nhà hàng xóm */
    // this.loaiTaiSan = input.type;
    this.address = input.address;
    this.customLatLng = input.customLatLng;

  }

  setMiniMap(): void {
  }
  confirmEstimate() {
    this.openPopUp = true;
  }

  dinhGia(): void {
    this.openPopUp = false;
    this.verifyUserLogin(() => {
      this.showResult = true;
      this.routeStateService.appendParamFromUrl('estimate', 'open');
      this.isEstimating = true;
      this.updateCurrentAddressToFormEstimate();
      this.diaChiDayDu = this.diaChiTaiSan.getTitle();
      let received = false;
      // this.form.chieuRongMatTien = parseFloat(this.form.chieuRongMatTien.toString().replace(/,/g, '.'));
      // console.log('form', this.form.chieuRongMatTien.toString().replace(/,/g, '.'));
      this.forMatDataEstimate();
      const subscribeDinhGia = this.estimateHttpService.submitCenEstimate(this.form).subscribe(response => {
        this.result = response && response.payload && response.payload.data;
        console.log('this.result', this.result)
        this.resultSubscibe.next(this.result);
        this.isEstimating = false;
        console.log('other', this.result)
        this.form.other = this.result.other;

        const scrollFormDinhGia = document.querySelector('.popup-cenestimate-v2');
        if (scrollFormDinhGia) { scrollFormDinhGia.scrollTo(0, 0); }

        received = true;
        this.estimateHttpService.getNumberRequest().subscribe(res => {
        });
      });
      setTimeout(() => {
        subscribeDinhGia.unsubscribe();
        if (!received) {
          this.notResponse = true;
          this.isEstimating = false;
        }
      }, timeoutDinhGia);
    });
  }
  forMatDataEstimate() {
    this.form.chieuRongMatTien = this.form.chieuRongMatTien ? parseFloat(this.form.chieuRongMatTien.toString().replace(/,/g, '.')) : undefined;
    this.form.chieuRongMatDuong = this.form.chieuRongMatDuong ? parseFloat(this.form.chieuRongMatDuong.toString().replace(/,/g, '.')) : undefined;
    this.form.dienTich = this.form.dienTich ? parseFloat(this.form.dienTich.toString().replace(/,/g, '.')) : undefined;
    this.form.chieuRongMatDuongHai = this.form.chieuRongMatDuongHai ? parseFloat(this.form.chieuRongMatDuongHai.toString().replace(/,/g, '.')) : undefined;
    this.form.chieuRongMatDuongBa = this.form.chieuRongMatDuongBa ? parseFloat(this.form.chieuRongMatDuongBa.toString().replace(/,/g, '.')) : undefined;
  }

  triggerEstimate(): void {
    setTimeout(() => {
      console.log('triggerEstimate', this.formValidated.allValid);
      if (this.formValidated.allValid) {
        this.dangLayThongTinGoiY = false;
        this.dinhGia();
      } else {
      }
    }, 0);

  }
  suaThongTinVaDinhGiaLai(): void {
    this.daAnSuaThongTinVaDinhGiaLai = true;
    this.showResult = false;
    this.initMappingThuaDat('form-mini-map', () => {
      this.dangLayThongTinGoiY = false;
    }, true);
  }

  // changeDuong() {
  //     this.form.duongId = this.address.duong && this.address.duong.choose && this.address.duong.choose.id || null;
  //     this.form.viTriId = null;
  //     this.getNearestLand({
  //         lat: this.form.lat,
  //         lng: this.form.lon,
  //         viTriId: this.form.viTriId,
  //         duongId: this.form.duongId
  //     });
  // }

  updateCurrentAddressToFormEstimate() {
    console.log(this.diaChiTaiSan);
    this.form.tinhId = this.form.tinhId || (this.diaChiTaiSan.tinh && this.diaChiTaiSan.tinh.choose && this.diaChiTaiSan.tinh.choose.id);
    this.form.tinh = this.form.tinh || (this.diaChiTaiSan.tinh && this.diaChiTaiSan.tinh.choose && this.diaChiTaiSan.tinh.choose.ten);

    this.form.quanHuyen = this.form.quanHuyen || (this.diaChiTaiSan.quan && this.diaChiTaiSan.quan.choose && this.diaChiTaiSan.quan.choose.ten);
    this.form.quanHuyenId = this.form.quanHuyenId || (this.diaChiTaiSan.quan && this.diaChiTaiSan.quan.choose && this.diaChiTaiSan.quan.choose.id);

    this.form.phuongXaId = this.form.phuongXaId || (this.diaChiTaiSan.phuong && this.diaChiTaiSan.phuong.choose && this.diaChiTaiSan.phuong.choose.id);
    this.form.phuongXa = this.form.phuongXa || (this.diaChiTaiSan.phuong && this.diaChiTaiSan.phuong.choose && this.diaChiTaiSan.phuong.choose.ten);

    this.form.duongId = this.form.duongId || (this.diaChiTaiSan.duong && this.diaChiTaiSan.duong.choose && this.diaChiTaiSan.duong.choose.id);
    this.form.duong = this.form.duong || (this.diaChiTaiSan.duong && this.diaChiTaiSan.duong.choose && this.diaChiTaiSan.duong.choose.ten);

    this.form.ngo = this.form.ngo || (this.diaChiTaiSan.ngo && this.diaChiTaiSan.ngo.choose && this.diaChiTaiSan.ngo.choose);
    this.form.ngachHem = this.form.ngachHem || (this.diaChiTaiSan.ngachhem && this.diaChiTaiSan.ngachhem.choose && this.diaChiTaiSan.ngachhem.choose);
    this.form.soNha = this.form.soNha || this.diaChiTaiSan.soNha;
    this.form.email = this.emailNhanKetQua;
  }
  getNearestLand(params, callback) {
    let received = false;
    const layThuaDataHangXomSubscribe = this.estimateHttpService.layThuaDatHangXom({
      'lon': params.lng,
      'lat': params.lat,
      'viTriId': params.viTriId,
      'duongId': params.duongId
    }).subscribe(res => {
      // console.log('getNearestLand', performance.now());
      if (res && res.payload && res.payload.data && res.payload.data[0]) {
        callback(res.payload.data[0]);
      } else {
        callback(null);
      }
      received = true;
    });
    setTimeout(() => {

      layThuaDataHangXomSubscribe.unsubscribe();

      if (!received) {
        // console.log('setTimeout', performance.now());
        console.log('Timed out');
        callback(null);
      }
    }, timeoutLayThuaDatHangXom);
  }
  getNearestHouse(params, callback): void {
    let received = false;
    const layNhaHangXom = this.dcsHttpService.nhaDatNeighbor(params.lat, params.lng, params.duongId).subscribe(infoBds => {
      const bds = infoBds && infoBds.batDongSanDTO || null;
      const ctrinh = infoBds && infoBds.congTrinhSubmitDTOSet && infoBds.congTrinhSubmitDTOSet[0] && infoBds.congTrinhSubmitDTOSet[0].congTrinhDTO || null;
      callback({ bds: bds, ctrinh: ctrinh });
      received = true;
    });
    setTimeout(() => {
      layNhaHangXom.unsubscribe();
      if (!received) {
        callback({ bds: null, ctrinh: null });
      }
    }, timeoutLayNhaHangXom);
  }

  waitForAMapReady(map, callback) {
    if (this[map].isReady) {
      callback();
    } else {
      const mapReady = this[map].readySubscribe.subscribe(res => {
        if (res) {
          callback();
        }
        // setTimeout(() => { this.mapInDetail.invalidateSize()}, 300);
        mapReady.unsubscribe();
      });
    }

  }
  setViewAfterMapReady(mapPosition, wantToSetMarker: boolean, wantToSetBound: boolean, markerHtml?: any) {
    // console.log('setViewAfterMapReady', this.mapState);
    if (mapPosition === 'detail-mini-map') {
      if (this.mapInDetailState.isReady) {
        // this.map.setView([this.form.lat, this.form.lon], 19);
        this.mapSetViewAndMarker(mapPosition, wantToSetMarker, wantToSetBound, markerHtml);
        setTimeout(() => { this.mapInDetail.invalidateSize() }, 200);
      } else {
        const mapReady = this.mapInDetailState.readySubscribe.subscribe(res => {
          if (res) {
            // this.map.setView([this.form.lat, this.form.lon], 19);
            this.mapSetViewAndMarker(mapPosition, wantToSetMarker, wantToSetBound, markerHtml);
          }
          setTimeout(() => { this.mapInDetail.invalidateSize() }, 300);
          mapReady.unsubscribe();
        });
      }
    } else if (mapPosition === 'form-mini-map') {
      if (this.mapInFormState.isReady) {
        // this.map.setView([this.form.lat, this.form.lon], 19);
        this.mapSetViewAndMarker(mapPosition, wantToSetMarker, wantToSetBound, markerHtml);
        setTimeout(() => { this.mapInForm.invalidateSize() }, 200);
      } else {
        const mapReady = this.mapInFormState.readySubscribe.subscribe(res => {
          if (res) {
            // this.map.setView([this.form.lat, this.form.lon], 19);
            this.mapSetViewAndMarker(mapPosition, wantToSetMarker, wantToSetBound, markerHtml);
          }
          setTimeout(() => { this.mapInForm.invalidateSize() }, 300);
          mapReady.unsubscribe();
        });
      }
    } else {

    }


  }
  mapSetViewAndMarker(mapPosition, wantToSetMarker: boolean, wantToSetBound: boolean, markerHtml?: any) {
    if (mapPosition === 'detail-mini-map') {
      this.mapInDetail && this.mapInDetail.setView([this.form.lat, this.form.lon], 19);
      if (wantToSetMarker) {
        this.makerTamThuaLayerGroup && this.makerTamThuaLayerGroup.clearLayers();
        const icon = new L.DivIcon(markerHtml || MARKERS.LAND_POINT);
        L.marker([this.form.lat, this.form.lon], { icon: icon }).addTo(this.makerTamThuaLayerGroup);
      }
      if (wantToSetBound) {
        this.duongBoundLayerGroup && this.duongBoundLayerGroup.clearLayers();
        L.tileLayer.wms(environment.API.SERVER_API_GEOSERVER, {
          maxZoom: 22,
          layers: 'dcs:new_thua_dat_' + this.form.phuongXaId,
          version: '1.1.0',
          format: 'image/svg',
          transparent: true,
          style: '',
          zIndex: 10
        }).addTo(this.duongBoundLayerGroup);
      }
    } else if (mapPosition === 'form-mini-map') {
      this.mapInForm && this.mapInForm.setView([this.form.lat, this.form.lon], 19);
      if (wantToSetMarker) {
        this.makerTamThuaLayerGroupInForm && this.makerTamThuaLayerGroupInForm.clearLayers();
        const icon = new L.DivIcon(markerHtml || MARKERS.LAND_POINT);
        L.marker([this.form.lat, this.form.lon], { icon: icon }).addTo(this.makerTamThuaLayerGroupInForm);
      }
      if (wantToSetBound) {
        this.duongBoundLayerGroupInForm && this.duongBoundLayerGroupInForm.clearLayers();
        L.tileLayer.wms(environment.API.SERVER_API_GEOSERVER, {
          maxZoom: 22,
          layers: 'dcs:new_thua_dat_' + this.form.phuongXaId,
          version: '1.1.0',
          format: 'image/svg',
          transparent: true,
          style: '',
          zIndex: 10
        }).addTo(this.duongBoundLayerGroupInForm);
      }
    } else {

    }
  }
  waitForMapReady(callback) {
    if (this.mapInDetailState.isReady) {
      // console.log('setViewAfterMapReady', [this.form.lat, this.form.lon], this.map);
      callback();
    } else {
      const mapReady = this.mapInDetailState.readySubscribe.subscribe(res => {
        if (res) {
          // console.log('setViewAfterMapReady', [this.form.lat, this.form.lon], this.map);
          callback();
        }
        setTimeout(() => { this.mapInDetail.invalidateSize() }, 300);
        mapReady.unsubscribe();
      });
    }
  }

  layThuaDatXungQuanhByGps(danhSachThuaDat) {
    this.dangLayThongTinGoiY = true;
    if (!this.additionEstimateInfo.thuaDatXungQuanh) {
      this.searchHttpService.layThuaDatXungQuanhByGps(this.form.lat, this.form.lon, 32).subscribe(thuaDatXungQuanh => {
        this.dangLayThongTinGoiY = false;
        this.additionEstimateInfo.thuaDatXungQuanh = thuaDatXungQuanh;
        danhSachThuaDat(thuaDatXungQuanh);
      }, error => { this.toaster.pop('warning', 'Không lấy được thửa đất xung quanh'); this.dangLayThongTinGoiY = false; });
    } else {
      // setTimeout(() => {
      this.dangLayThongTinGoiY = false;
      danhSachThuaDat(this.additionEstimateInfo.thuaDatXungQuanh);
      // }, 2000);

    }

  }

  initMappingThuaDat(mapPostition, callback, check?: any) {
    this.state.initingMappingThuaDat = true;
    if (mapPostition === 'detail-mini-map') {
      this.duongBoundLayerGroup && this.duongBoundLayerGroup.clearLayers();
      this.makerTamThuaLayerGroup && this.makerTamThuaLayerGroup.clearLayers();
    } else if (mapPostition === 'form-mini-map') {
      this.duongBoundLayerGroupInForm && this.duongBoundLayerGroupInForm.clearLayers();
      this.makerTamThuaLayerGroupInForm && this.makerTamThuaLayerGroupInForm.clearLayers();
    }
    if (this.loaiTaiSan === 'tai-san-nha' || this.loaiTaiSan === 'tai-san-chua-co') {

      // additionEstimateInfo
      this.layThuaDatXungQuanhByGps(thuaDatXungQuanh => {
        // this.searchHttpService.layThuaDatXungQuanhByGps(this.form.lat, this.form.lon, 32).subscribe(thuaDatXungQuanh => {
        // console.log('thuaDatXungQuanh', this.form.lat, this.form.lon, thuaDatXungQuanh);
        const dataThuaDat = thuaDatXungQuanh && thuaDatXungQuanh.hits && thuaDatXungQuanh.hits.hits;
        if (dataThuaDat && dataThuaDat.length > 0) {

          const thuaDatXungQuanhMarkers = [];
          if (this.staticInfo.huongNhaDTO && this.staticInfo.hinhDangLoDatDTO) {
            dataThuaDat.forEach((thuaDat, index) => {
              // console.log('thuaDat', thuaDat);
              thuaDatXungQuanhMarkers.push(
                {
                  lat: thuaDat._source.location.lat,
                  lng: thuaDat._source.location.lon,
                  phuongXaId: thuaDat._source.phuong_id,
                  id: thuaDat._source.id,
                  square: thuaDat._source.dienTichThuaDat,
                  quyHoach: (thuaDat._source.display === 'quy hoạch') ? 'Thửa đất bị quy hoạch' : '',
                  selected: false
                }
              );
            });
          }


          let minDistance = 100;
          let indexThuaDatGanNhat = 0;
          thuaDatXungQuanhMarkers.forEach((item, index) => {
            let distance = Math.sqrt((item.lat - this.form.lat) * (item.lat - this.form.lat) + (item.lng - this.form.lon) * (item.lng - this.form.lon));
            if (minDistance > distance) {
              minDistance = distance;
              indexThuaDatGanNhat = index;
            }
          });

          if (!this.additionEstimateInfo.thuaDatHeThongGoiYId) {
            this.additionEstimateInfo.thuaDatHeThongGoiYId = dataThuaDat[indexThuaDatGanNhat]._source.id;
          }
          if (!check) {
            this.addImageFromSelectedLand(
              dataThuaDat[indexThuaDatGanNhat] &&
              dataThuaDat[indexThuaDatGanNhat]._source &&
              dataThuaDat[indexThuaDatGanNhat]._source.url_anh_ban_do_dia_chinh
            );
          }


          console.log('thuaDatDangChon', [thuaDatXungQuanhMarkers[0].lat, thuaDatXungQuanhMarkers[0].lng]);

          let phuongXaIdList = [];
          thuaDatXungQuanhMarkers.forEach(item => {
            if (!phuongXaIdList.includes(item.phuongXaId)) {
              phuongXaIdList.push(item.phuongXaId);
            }
          });

          console.log('phuongXaIdList cu', phuongXaIdList)
          phuongXaIdList.forEach(item => {
            this.waitForAMapReady(
              mapPostition === 'detail-mini-map' ? 'mapInDetailState' : 'mapInFormState',
              () => {
                phuongXaIdList.forEach(item => {
                  L.tileLayer.wms(environment.API.SERVER_API_GEOSERVER, {
                    maxZoom: 22,
                    layers: 'dcs:new_thua_dat_' + item,
                    version: '1.1.0',
                    format: 'image/svg',
                    transparent: true,
                    style: '',
                    zIndex: 10
                  }).addTo(mapPostition === 'detail-mini-map' ? this.duongBoundLayerGroup : this.duongBoundLayerGroupInForm);
                });
              }
            );
          });
          // phuongXaIdList.forEach(item => {
          //     L.tileLayer.wms(environment.API.SERVER_API_GEOSERVER, {
          //         maxZoom: 22,
          //         layers: 'dcs:new_thua_dat_' + item,
          //         version: '1.1.0',
          //         format: 'image/svg',
          //         transparent: true,
          //         style: '',
          //         zIndex: 10
          //     }).addTo(mapPostition === 'detail-mini-map' ? this.duongBoundLayerGroup : this.duongBoundLayerGroupInForm);
          // });

          this.waitForAMapReady(
            mapPostition === 'detail-mini-map' ? 'mapInDetailState' : 'mapInFormState',
            () => {
              thuaDatXungQuanhMarkers.forEach((item, index) => {
                const icon = new L.DivIcon({
                  className: 'my-div-icon',
                  html: '<div title="' + item.quyHoach + '" data-thuadatgannhatid="' + item.id + '" class="marker-html-round"></div>'
                });
                L.marker([item.lat, item.lng], { icon: icon }).addTo(
                  mapPostition === 'detail-mini-map' ? this.makerTamThuaLayerGroup : this.makerTamThuaLayerGroupInForm
                ).on('click', e => {
                  this.unHighlightSelectedLand();
                  const thuaDatDangChon = dataThuaDat.filter((a) => { return a._source.id == e.originalEvent.srcElement.dataset.thuadatgannhatid });
                  // console.log('dataThuaDat', dataThuaDat);


                  this.highlightSelectedLand(thuaDatDangChon[0]._source.id);
                  this.getAndFillInfoThuaDatById(thuaDatDangChon[0]._source.id, thongTinThuaDat => {
                    this.thuaDatDangChonSubscribe.next(thongTinThuaDat);
                  });
                  // this.estimateHttpService.getInfoThuaDatAndPriceById(thuaDatDangChon[0]._source.id).subscribe(res => {
                  //     const thongTinThuaDat = res && res.payload && res.payload.data && res.payload.data[0];
                  //     this.updateDataThuaDatToForm(thongTinThuaDat);

                  // }, error => {
                  //     this.toaster.pop('warning', 'Không lấy được thông tin thửa đất');
                  // });

                  this.form.idThuaDatNguoiDung = e.originalEvent.srcElement.dataset.thuadatgannhatid;

                  // e.originalEvent.srcElement.classList.add("marker-selected");
                  const thuaDatDangChonData = thuaDatDangChon[0] && thuaDatDangChon[0]._source;
                  const dataThuaDatDangChon = {
                    canhBao: thuaDatDangChonData && thuaDatDangChonData.canhBao,
                    // chieuRongMatDuong: 8,
                    // chieuRongMatDuong2: 1.36,
                    // chieuRongMatDuong3: 0,
                    // dienTichDatCLN: null,
                    // dienTichDatHNK: null,
                    // dienTichDatNNP: null,
                    // dienTichDatODT: null,
                    // dienTichDatSKC: null,
                    // dienTichDatTMD: null,
                    // dienTichNgoaiQuyHoach: 0,
                    dienTichThuaDat: thuaDatDangChonData && thuaDatDangChonData.dien_tich_thua_dat,
                    // dienTichTrongQuyHoach: 0,
                    // doRongDuongLoGioi: null,
                    // donGiaDat: null,
                    duongId: parseInt(thuaDatDangChonData && thuaDatDangChonData.duong_id),
                    // giaDat: null,
                    hasPrice: thuaDatDangChonData && thuaDatDangChonData.hasPrice,
                    // hinhDangLoDatId: 4,
                    hoanhDoGps: thuaDatDangChonData && thuaDatDangChonData.location && thuaDatDangChonData.location.lat,
                    // huongDat2Id: 4,
                    // huongDat3Id: null,
                    huongDatId: 1,
                    id: parseInt(thuaDatDangChonData && thuaDatDangChonData.id),
                    // kichThuocMatTien: 2.97,
                    // kichThuocMatTien2: 5.11,
                    // kichThuocMatTien3: 0,
                    // ngach: null,
                    // ngo: null,
                    phuongId: thuaDatDangChonData && thuaDatDangChonData.phuong_id,
                    quanId: thuaDatDangChonData && thuaDatDangChonData.quan_id,
                    // soNha: null,
                    tinhId: thuaDatDangChonData && thuaDatDangChonData.tinh_id,
                    tungDoGps: thuaDatDangChonData && thuaDatDangChonData.location && thuaDatDangChonData.location.lon,
                    urlAnhBanDoDiaChinh: thuaDatDangChonData && thuaDatDangChonData.url_anh_ban_do_dia_chinh,
                    // viTri: 1
                  };


                }).on('mouseover', e => {
                  // console.log('Hover', e);
                });
              });


              this.idThuaDatGanNhat = dataThuaDat[indexThuaDatGanNhat]._source.id;


              // setTimeout(() => {

              // }, 1000);
              this.getAndFillInfoThuaDatById(this.idThuaDatGanNhat, thongTinThuaDat => {
                console.log('thongTinThuaDat', thongTinThuaDat);
                // console.log('Trang thai map', this.mapInFormState.isReady);
                if (thongTinThuaDat === null) { // Chua dang nhap
                  // this.form.lat = thongTinThuaDat.hoanhDoGps;
                  // this.form.lon
                  const toaDo = dataThuaDat[indexThuaDatGanNhat]._source && dataThuaDat[indexThuaDatGanNhat]._source.location;
                  this.form.lat = toaDo && toaDo.lat;
                  this.form.lon = toaDo && toaDo.lon;
                  // console.log('Thua dat gan nhat', dataThuaDat[indexThuaDatGanNhat]._source);
                }

                this.setViewAfterMapReady(mapPostition, false, false);
                this.highlightSelectedLand(this.idThuaDatGanNhat);

                // console.log('mapPostition indexThuaDatGanNhat', [this.form.lat, this.form.lon]);
                if (mapPostition === 'detail-mini-map') {
                  this.addPopupToSuggestedLand('detail-mini-map', 'Tài sản bạn đang xem được hệ thống gợi ý ở vị trí hiện tại. Nếu vị trí này chưa đúng, bạn có thể chọn lại các vị trí xung quanh để kết quả ước lượng giá được chính xác nhất.');
                }
                if (mapPostition === 'form-mini-map') {
                  this.addPopupToSuggestedLand('form-mini-map', 'Nhà của bạn có nằm trên thủa đất này không? Nếu không hãy chọn lại để kết quả ước lượng giá được chính xác nhất!');
                }
                this.state.initingMappingThuaDat = false;
                callback();
              });
            });
        } else {
          /* API lấy thủa đất xung quanh không trả về thửa đất nào */
          this.setViewAfterMapReady(mapPostition, true, true);
          this.state.initingMappingThuaDat = false;
          callback();
        }
      });
    } else {
      this.setViewAfterMapReady(mapPostition, true, true);
      this.state.initingMappingThuaDat = false;
      callback();

    }


  }
  getAndFillInfoThuaDatById(id, callback): void {
    this.state.gettingThuaDatById = true;
    const idNumber = id;
    const thongTinThuaDat = this.cacheThuaDatXungQuanh.find(x => x.id === idNumber);
    if (thongTinThuaDat) {
      this.updateDataThuaDatToForm(thongTinThuaDat);
      this.state.gettingThuaDatById = false;
      callback(thongTinThuaDat);
    } else {
      if (this.loginService.getAccountService().hasAnyAuthority(['ROLE_USER'])) {
        let received = false;
        const getInfoThuaDatAndPriceById = this.estimateHttpService.getInfoThuaDatAndPriceById(idNumber).subscribe(res => {
          console.log('getInfoThuaDatAndPriceById', res);
          const thongTinThuaDat = res && res.payload && res.payload.data && res.payload.data[0];
          this.updateDataThuaDatToForm(thongTinThuaDat);
          this.cacheThuaDatXungQuanh.push(thongTinThuaDat);
          this.state.gettingThuaDatById = false;
          callback(thongTinThuaDat);
          received = true;
          this.dangLayThongTinGoiY = false;

        }, error => {
          this.toaster.pop('warning', 'Không lấy được thông tin thửa đất');
          this.state.gettingThuaDatById = false;
          callback(null);
          this.dangLayThongTinGoiY = false;
        });
        setTimeout(() => {
          getInfoThuaDatAndPriceById.unsubscribe();
          this.dangLayThongTinGoiY = false;
          if (!received) {
            this.state.gettingThuaDatById = false;
            callback({ bds: null, ctrinh: null });
          }
        }, timeoutLayNhaHangXom);
      } else {
        this.state.gettingThuaDatById = false;
        callback(null);
      }
    }
  }
  addImageFromSelectedLand(url): void {
    // this.infoBds.images = this.infoBds && [];
    if (this.infoBds && this.infoBds.images) {
      this.infoBds.images.push({ url: environment.API.SERVER_IMG + url });
      // console.log('this.infoBds12', this.infoBds)
      // console.log('addImageFromSelectedLand', this.infoBds.images);
    }
  }
  highlightSelectedLand(id) {
    const cacMarkerThuaDatGanNhat = document.querySelectorAll('[data-thuadatgannhatid]');
    if (cacMarkerThuaDatGanNhat && cacMarkerThuaDatGanNhat.length > 0) {
      cacMarkerThuaDatGanNhat.forEach(item => {
        if (parseInt(<any>item.getAttribute('data-thuadatgannhatid')) === parseInt(id)) {
          item.classList.add('marker-selected');
        }
      })
    }
  }
  addPopupToSuggestedLand(selector, text) {

    const cacMarkerThuaDatGanNhat = document.querySelectorAll('#' + selector + ' [data-thuadatgannhatid]');
    console.log('cacMarkerThuaDatGanNhat', '#' + selector + ' [data-thuadatgannhatid]', cacMarkerThuaDatGanNhat);
    if (cacMarkerThuaDatGanNhat && cacMarkerThuaDatGanNhat.length > 0) {
      cacMarkerThuaDatGanNhat.forEach(item => {

        if (parseInt(item.getAttribute('data-thuadatgannhatid')) === parseInt(this.additionEstimateInfo.thuaDatHeThongGoiYId)) {
          item.innerHTML = '<div class="marker-popup">' + text + '</div>';
        }
      })
    }
  }
  unHighlightSelectedLand() {
    Array.from(document.getElementsByClassName('marker-html-round')).forEach(element => {
      element.classList.remove("marker-selected");
    });
  }

  updateDataThuaDatToForm(thuaDat) {
    this.form.idThuaDat = thuaDat.id;
    this.form.dienTich = thuaDat.dienTichThuaDat;
    this.form.dienTichSuDung = (Math.round((this.form.soTang * this.form.dienTich + Number.EPSILON) * 100) / 100) || this.form.dienTichSuDung;
    this.form.chieuRongMatTien = thuaDat.kichThuocMatTien;
    this.form.lon = thuaDat.tungDoGps;
    this.form.lat = thuaDat.hoanhDoGps;
    this.xuLyMatTienVaViTriIdThuaDat(thuaDat);
    this.form.huongDatId = thuaDat.huongDatId;
    this.form.hinhDangLoDatId = thuaDat.hinhDangLoDatId;

    /* Dinh gia dung phuong cua thua dat se chinh xac hon: số 3 Lô D3 Hạ Yên */
    this.form.tinhId = thuaDat.tinhId;
    this.form.quanHuyenId = thuaDat.quanId;
    this.form.phuongXaId = thuaDat.phuongId;
    this.form.duongId = thuaDat.duongId;

    this.diaChiTaiSan = new Address2(this.addressHttpService,
      thuaDat.tinhId, // Tỉnh
      thuaDat.quanId, // Quận
      thuaDat.duongId, // Đường
      thuaDat.phuongId, // Phường
      null, // Thôn xóm
      null, // Dự án
      this.form.ngo || null, // Ngõ
      this.form.ngachHem || null, // Ngách
      this.form.soNha || '', // Số nhà
    );
    this.diaChiTaiSan.initFinish.subscribe(data => {

    });
    this.canhBaoQuyHoach = thuaDat && thuaDat.canhBao || '';

    // this.form.urlAnhBanDoDiaChinh = thuaDat.urlAnhBanDoDiaChinh;
  }

  updateDataTaiSanToForm(callback) {
    let params = {
      lat: this.form.lat,
      lng: this.form.lon,
    };
    if (this.form.duongId) {
      params['duongId'] = this.form.duongId;
    }
    this.getNearestHouse(params, response => {

      this.form.idBatDongSanNhapLieu = response && response.bds && response.bds.id || null;
      this.form.loaiNhaId = response && response.ctrinh && response.ctrinh.loaiCongTrinhId;
      this.form.soTang = response && response.ctrinh && response.ctrinh.soTangNha;
      this.form.dienTichSuDung = (Math.round(this.form.soTang * this.form.dienTich * 100) / 100) || this.form.dienTichSuDung;
      this.form.namXayDung = response && response.ctrinh && response.ctrinh.namXayDung || this.currentYear;
      this.form.chatLuongConLai = response && response.ctrinh && response.ctrinh.chatLuongConLai || 100;

      this.diaChiTaiSan = new Address2(this.addressHttpService,
        this.form.tinhId || response && response.bds && response.bds.tinhId || null, // Tỉnh
        this.form.quanHuyenId || response && response.bds && response.bds.quanHuyenId || null, // Quận
        this.form.duongId || response && response.bds && response.bds.duongId || null, // Đường
        this.form.phuongXaId || response && response.bds && response.bds.phuongXaId || null, // Phường
        null, // Thôn xóm
        null, // Dự án
        response && response.bds && response.bds.dlNgo || this.form.ngo || null, // Ngõ
        response && response.bds && response.bds.dlNgachHem || this.form.ngachHem || null, // Ngách
        response && response.bds && response.ctrinh.soNha || this.form.soNha || '', // Số nhà
      );
      this.diaChiTaiSan.initFinish.subscribe(data => {
        this.dangLayThongTinGoiY = false;
        callback();
      });
    });
  }

  verifyUserLogin(callback, afterLogin?: Function) {
    if (!this.loginService.getAccountService().hasAnyAuthority(['ROLE_USER'])) {
      this.routeStateService.appendParamFromUrl('login', 'open');
      this.loginSsoService.login();
      this.loginSsoService.waitingForLogedIn(() => {
        afterLogin();
      });
      // this.modalRef = this.loginModalService.open('Bạn cần đăng nhập để có thể định giá.', result => {
      //     if (result === 'login success') {
      //         afterLogin();
      //     }
      // });
    } else {
      callback();
    }
  }
  initFormEstimate(estimateFrom, infoBds, callback) {
    this.state.initingFormEstimate = true;
    console.log(infoBds, infoBds.address);
    if (infoBds && infoBds.thuaDat) {
      this.loaiTaiSan = 'tai-san-dat';
    } else if (infoBds && infoBds.batDongSan) {
      this.loaiTaiSan = 'tai-san-nha';
    } else if (infoBds && infoBds.address) {
      this.loaiTaiSan = 'tai-san-chua-co';
      this.address = infoBds.address;
    } else {

    }
    this.estimateFrom = estimateFrom;
    this.additionEstimateInfo.thuaDatXungQuanh = null;
    this.additionEstimateInfo.thuaDatHeThongGoiYId = null;

    this.form = new RequestEstimate();

    const thuaDatTinhId = infoBds && infoBds.thuaDat && infoBds.thuaDat.tinhId;
    const thuaDatQuanHuyenId = infoBds && infoBds.thuaDat && infoBds.thuaDat.quanId;
    const thuaDatPhuongXaId = infoBds && infoBds.thuaDat && infoBds.thuaDat.phuongId;
    const thuaDatDuongId = infoBds && infoBds.thuaDat && infoBds.thuaDat.duongId;
    const thuaDatLat = infoBds && infoBds.thuaDat && infoBds.thuaDat.hoanhDoGps;
    const thuaDatLng = infoBds && infoBds.thuaDat && infoBds.thuaDat.tungDoGps;

    const taiSanTinhId = infoBds && infoBds.batDongSan && infoBds.batDongSan.tinhId;
    const taiSanQuanHuyenId = infoBds && infoBds.batDongSan && infoBds.batDongSan.quanHuyenId;
    const taiSanPhuongXaId = infoBds && infoBds.batDongSan && infoBds.batDongSan.phuongXaId;
    const taiSanDuongId = infoBds && infoBds.batDongSan && infoBds.batDongSan.duongId;
    const taiSanLat = infoBds && infoBds.batDongSan && infoBds.batDongSan.dlHoanhDoDanhDau;
    const taiSanLng = infoBds && infoBds.batDongSan && infoBds.batDongSan.dlTungDoDanhDau;

    this.form.tinhId = thuaDatTinhId
      || taiSanTinhId
      || this.address && this.address.tinh && this.address.tinh.choose && this.address.tinh.choose.id;
    this.form.quanHuyenId = thuaDatQuanHuyenId
      || taiSanQuanHuyenId
      || this.address && this.address.quan && this.address.quan.choose && this.address.quan.choose.id;
    this.form.duongId = thuaDatDuongId
      || taiSanDuongId
      || this.address && this.address.duong && this.address.duong.choose && this.address.duong.choose.id;
    this.form.phuongXaId = thuaDatPhuongXaId || taiSanPhuongXaId || this.address && this.address.phuong && this.address.phuong.choose && this.address.phuong.choose.id;
    this.form.ngo = (infoBds && infoBds.batDongSan && infoBds.batDongSan.dlNgo) || (this.address && this.address.ngo && this.address.ngo.choose && this.address.ngo.choose);
    this.form.ngachHem = (infoBds && infoBds.batDongSan && infoBds.batDongSan.dlNgachHem) || (this.address && this.address.ngachhem && this.address.ngachhem.choose && this.address.ngachhem.choose);
    this.form.soNha = (infoBds && infoBds.congTrinh && infoBds.congTrinh.soNha) || (this.address && this.address.soNha);

    this.form.lat = thuaDatLat || taiSanLat || infoBds.customLatLng && infoBds.customLatLng.lat;
    this.form.lon = thuaDatLng || taiSanLng || infoBds.customLatLng && infoBds.customLatLng.lng;
    // console.log('latlon trong form', [this.form.lat, this.form.lon]);

    this.form.loaiNhaId = infoBds && infoBds.congTrinh && infoBds.congTrinh.loaiCongTrinhId;
    this.form.soTang = infoBds && infoBds.congTrinh && infoBds.congTrinh.soTangNha;
    this.form.dienTichSuDung = (Math.round(this.form.soTang * this.form.dienTich * 100) / 100);
    this.form.namXayDung = infoBds && infoBds.congTrinh && infoBds.congTrinh.namXayDung || this.currentYear;
    this.form.chatLuongConLai = infoBds && infoBds.congTrinh && infoBds.congTrinh.chatLuongConLai || 100;

    this.form.idBatDongSanNhapLieu = infoBds && infoBds.batDongSan && infoBds.batDongSan && infoBds.batDongSan.id;
    if (!infoBds.congTrinh.loaiCongTrinhId) {
      this.form.loaiNhaId = 14;
    }
    // console.log('this.form.loaiNhaId', infoBds.congTrinh.loaiCongTrinhId);
    // setTimeout(() => {
    //     console.log('new Address');
    this.diaChiTaiSan = new Address2(this.addressHttpService,
      this.form.tinhId || null, // Tỉnh
      this.form.quanHuyenId || null, // Quận
      this.form.duongId || null, // Đường
      this.form.phuongXaId || null, // Phường
      null, // Thôn xóm
      null, // Dự án
      this.form.ngo || null, // Ngõ
      this.form.ngachHem || null, // Ngách
      this.form.soNha || '', // Số nhà
    );
    // }, 10000);



    if (this.form.tinhId) {

      this.dcsHttpService.getStaticInfo(this.form.tinhId).subscribe(staticInfoResponse => {
        if (staticInfoResponse.status === 0) {
          staticInfoResponse.payload.viTriDTO.forEach(item => {
            if (item.id === 5) item.id = 1;
            if (item.id === 6) item.id = 2;
            if (item.id === 7) item.id = 3;
            if (item.id === 8) item.id = 4;
          });
          this.staticInfo = this.helpers.makeStaticInfos(staticInfoResponse.payload);
          console.log('this.staticInfo', this.staticInfo);
        } else {
          this.toaster.pop('warning', 'Không lấy được static info');
        }
        if (this.loaiTaiSan === 'tai-san-dat') {
          this.updateDataThuaDatToForm(infoBds.thuaDat);
        }
        this.state.initingFormEstimate = false;
        callback();
      });
    } else {
      this.state.initingFormEstimate = false;
      this.toaster.pop('warning', 'Vui lòng chọn tỉnh');
    }
  }
  openFormEstimate(callback, afterLogin?: Function) {
    this.verifyUserLogin(() => {
      this.showEstimateForm = true;
      // this.dangLayThongTinGoiY = true;
      callback();
    }, () => {
      afterLogin();
    });
  }
  xuLyMatTienVaViTriIdThuaDat(thuaDat) {
    this.form.soMatTien = 1;
    this.form.chieuRongMatDuongHai = 0;
    this.form.chieuRongMatDuongBa = 0;
    this.form.viTriId = thuaDat && thuaDat.viTri || this.form.viTriId;
    this.form.viTriIdHai = null;
    this.form.viTriIdBa = null;
    /* Việt Anh confirm tất cả các thửa đất đều không có viTriHai, viTriBa nên set bằng viTri + 1 */
    this.form.chieuRongMatDuong = thuaDat && thuaDat.chieuRongMatDuong;
    if (thuaDat && thuaDat.chieuRongMatDuong2 && thuaDat.chieuRongMatDuong2 !== 0) {
      this.form.soMatTien = 2;
      this.form.chieuRongMatDuongHai = thuaDat.chieuRongMatDuong2;
      this.form.viTriIdHai = (thuaDat.viTri === 4) ? 4 : thuaDat.viTri + 1;
    }
    if (thuaDat && thuaDat.chieuRongMatDuong3 && thuaDat.chieuRongMatDuong3 !== 0) {
      this.form.soMatTien = 3;
      this.form.chieuRongMatDuongBa = thuaDat.chieuRongMatDuong3;
      this.form.viTriIdBa = (thuaDat.viTri === 4) ? 4 : thuaDat.viTri + 1;
    }
  }
  changeSoMatTien() {
    console.log(this.form.soMatTien);
    if (this.form.soMatTien == 1) {
      this.form.chieuRongMatDuongHai = null;
      this.form.chieuRongMatDuongBa = null;
      this.form.viTriIdHai = null;
      this.form.viTriIdBa = null;
    } else if (this.form.soMatTien == 2) {
      this.form.chieuRongMatDuongBa = null;
      this.form.viTriIdBa = null;
    }
  }
  getWardIdFromThuaDats(thuaDats: any[]) {
    // console.log('getWardIdFromThuaDat');
    const wardIdCount = {};
    let maxWardId = null;
    thuaDats.forEach(thuaDat => {
      const wardId = thuaDat._source.phuong_id;
      if (!wardIdCount[wardId]) {
        wardIdCount[wardId] = 1
      } else {
        wardIdCount[wardId] += 1
      }
      if (!maxWardId) {
        maxWardId = wardId;
      } else {
        if (wardIdCount[maxWardId] < wardIdCount[wardId]) {
          maxWardId = wardId;
        }
      }
    })
    // console.log('phuongId = ' + maxWardId);
    return maxWardId;
  }

}
