import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OnlyDayDatePickerService {
  public listDay: any;
  constructor() {
    this.listDay = [];
    for (let i = 0; i < 31; i++) {
      this.listDay.push(i);
    }
  }

}
