import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { Payload, ResponseThuaDatXungQuanh, ResponseBdsXungQuanh } from '../model/payload';
import { ThuaDat } from '../model/dcs/thua-dat.model';
import { DonGia } from '../model/estimate/don-gia';
import { RequestEstimate, ResponseEstimate } from '../model/estimate/estimate';
import { map } from 'rxjs/operators';
import { catchError, retry } from 'rxjs/operators';
import { createRequestOption } from '../util/request-util';
import { environment } from '../../../environments/environment';

type PayloadDonGia = Payload<{
  data: DonGia;
}>;

type PayLoadEstimate = Payload<{
  data: ResponseEstimate;
}>;

type PayLoadNumber = Payload<{
  data: number;
}>;

@Injectable({
  providedIn: 'root'
})
export class EstimateHttpService {
  public soLuotDinhGiaConLai: number;
  private sourceApi = environment.API.SERVER_ESTIMATE;
  constructor(private http: HttpClient) { }

  getDonGiaCongTrinh(idLoaiCongTrinh: number, tinhId: number): Observable<PayloadDonGia> {
    return this.http.get<PayloadDonGia>(
      this.sourceApi + '/api/dia-chi-cu-the/get-don-gia-ctxd' + '?loai-cong-trinh-id=' + idLoaiCongTrinh + '&tinh-id=' + tinhId
    );
  }

  getDonGiaDat(idDuong: number, idPhuong?: number): Observable<PayloadDonGia> {
    let params = 'id-duong=' + idDuong;
    if (idPhuong !== undefined && idPhuong !== null) {
      params += '&id-phuong=' + idPhuong;
    }
    return this.http.get<PayloadDonGia>(this.sourceApi + '/api/dia-chi-cu-the/chi-tiet-don-gia-dat?' + params);
  }

  submitCenEstimate(requestEstimate: RequestEstimate): Observable<PayLoadEstimate> {
    return this.http.post<PayLoadEstimate>(this.sourceApi + '/api/dia-chi-cu-the/estimate', requestEstimate);
    // return of({"status":0,"payload":{"data":{"idEstimate":1269,"giaDatMoHinh":{"donGia":254.36,"giaTri":6.893156000000001,"dienGiai":"Mô hình định giá bằng AI"},"giaDatTinRao":null,"giaCTXD":{"donGia":5.393317,"giaTri":0.26966585,"dienGiai":"Quyết định 06/2017/QĐ-UBND của thành phố Hà Nội."},"giaCTXDTinhThue":{"donGia":6.824,"giaTri":0.27296,"dienGiai":"Quyết định số: 3023/QĐ-UBND của thành phố Hà Nội"}}},"description":""})
  }

  getNumberRequest(): Observable<PayLoadNumber> {
    return this.http.get<PayLoadNumber>(this.sourceApi + '/api/number-request-remain-estimate').pipe(
      map((res) => {
        this.soLuotDinhGiaConLai = (res && res.payload && res.payload.data) || 0;
        return res;
      })
    );
  }

  layGiaThuaDat(idThuaDat: number): Observable<PayLoadNumber> {
    return this.http.get<PayLoadNumber>(this.sourceApi + '/api/dia-chi-cu-the/gia-thua-dat?id-thua-dat=' + idThuaDat);
  }

  layThuaDatXungQuanh(lat: number, lng: number, phuongXaId: number, limit, offset): Observable<Payload<ResponseThuaDatXungQuanh>> {
    return this.http.get<Payload<ResponseThuaDatXungQuanh>>(
      this.sourceApi +
      '/api/dia-chi-cu-the/gia-thua-dat-xung-quanhs' +
      '?lat=' +
      lat +
      '&lon=' +
      lng +
      '&phuong-xa-id=' +
      phuongXaId +
      '&limit=' +
      limit +
      '&offset=' +
      offset
    );
  }

  layThongTinQuyHoachQuaGPS(lat: number, lng: number): Observable<Payload<ResponseThuaDatXungQuanh>> {
    return this.http.get<Payload<ResponseThuaDatXungQuanh>>(
      this.sourceApi + '/api/dia-chi-cu-the/thua-dat-xung-quanh-by-gps' + '?lat=' + lat + '&lon=' + lng
    );
  }

  getInfoThuaDatAndPrice(phuongXaId: number, soTo, soThua): Observable<Payload<ResponseThuaDatXungQuanh>> {
    return this.http.get<any>(
      this.sourceApi +
      '/api/dia-chi-cu-the/thong-tin-thua-dat' +
      '?phuong-xa-id=' +
      phuongXaId +
      '&so-to=' +
      soTo +
      '&so-thua=' +
      soThua
    );
  }
  getBdsBySoToSoThua(body): any {
    return this.http.post(`${environment.API.SERVER_SEARCH}v2/search-so-to-so-thua`, body);
  }

  getInfoThuaDatAndPriceById(idThuaDat: number): Observable<Payload<ResponseThuaDatXungQuanh>> {
    return this.http.get<any>(this.sourceApi + '/api/dia-chi-cu-the/thua-dat/' + idThuaDat);
  }

  layBdsXungQuanh(id, limit, offset): Observable<Payload<ResponseBdsXungQuanh>> {
    return this.http.get<Payload<ResponseBdsXungQuanh>>(
      this.sourceApi +
      '/api/dia-chi-cu-the/gia-bat-dong-san-xung-quanhs' +
      '?id-bat-dong-san-nhap-lieu=' +
      id +
      '&limit=' +
      limit +
      '&offset=' +
      offset
    );
  }

  layBdsXungQuanhKhuVuc(id, limit, offset) {
    return this.http.get<any>(
      this.sourceApi + '/api/khu-vuc/gia-bat-dong-san-xung-quanhs' + '?id=' + id + '&limit=' + limit + '&offset=' + offset
    );
  }

  getDonGiaUocTinhBdsDaNhapLieu(id, duongId, phuongId, viTriId, lat, lng) {
    const body = {
      'id-bds-nhap-lieu': id,
      'duong-id': duongId,
      'phuong-id': phuongId,
      'vitri': viTriId,
      'lat': lat,
      'lon': lng
    }
    for (let key in body) {
      if (!body[key]) {
        delete body[key];
      }
    }
    return this.http.get<any>(this.sourceApi + '/api/dia-chi-cu-the/don-gia-uoc-tinh', {
      params: body
    });
  }

  getDonGiaUocTinhBdsChuaNhapLieu(duongId, phuongId, quanId, tinhId, viTriId) {
    return this.http.get<any>(
      this.sourceApi +
      '/api/dia-chi-cu-the/don-gia-uoc-tinh' +
      '?duong-id=' +
      duongId +
      '&phuong-id=' +
      phuongId +
      '&quan-id=' +
      quanId +
      '&tinh-id=' +
      tinhId +
      '&vitri=' +
      viTriId
    );
  }

  getAllPrice(body) {
    return this.http.post<any>(this.sourceApi + '/api/dia-chi-cu-the/get-all-gia', body);
  }

  getInfoMap(body) {
    return this.http.post<any>(this.sourceApi + '/api/dia-chi-cu-the/thong-tin-quy-hoach', body);
  }

  getFeedBacks(id) {
    return this.http.get<any>(this.sourceApi + '/api/khu-vuc/feedbacks?id=' + id);
  }

  setFeedBacks(body, id) {
    return this.http.post<any>(this.sourceApi + '/api/khu-vuc/feedbacks?id=' + id, body);
  }

  getDescriptionZone(body) {
    return this.http.post<any>(this.sourceApi + '/api/khu-vuc/description', body);
  }
  doVerifyCaptcha(body) {
    return this.http.get<any>(this.sourceApi + '/api/dia-chi-cu-the/siteverify?token-captcha=' + body);
  }
  doVerifyCaptchaForm(body) {
    return this.http.get<any>(this.sourceApi + '/api/dia-chi-cu-the/verify-captcha?token-captcha=' + body);
  }
  senLogError(body) {
    return this.http.post<any>(this.sourceApi + '/api/dia-chi-cu-the/frontend-exception', body);
  }
  rateEstimate(body) {
    return this.http.post(this.sourceApi + '/api/dia-chi-cu-the/rate-cenestimate', body);
  }

  layThuaDatHangXom(body) {
    return this.http.post<any>(this.sourceApi + '/api/dia-chi-cu-the/thua-dat-neighbor', body);
  }
  getlistHistoryEstimate(body) {
    const options = createRequestOption(body.params);
    return this.http.post<any>(this.sourceApi + '/api/dia-chi-cu-the/history-estimate', body.request, { params: options });
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };
  overviewBilling(body) {
    return this.http.post<any>(this.sourceApi + '/api/billing/overview', body);
  }
  traCuuQuyHoach(body, id) {
    return this.http.post<any>(this.sourceApi + '/api/dia-chi-cu-the/tra-cuu-quy-hoach/' + id, body)
  }
  layThongTinThuaDat(param) {
    const options = createRequestOption(param);
    return this.http.get<any>(this.sourceApi + '/api/dia-chi-cu-the/thong-tin-thua-dat', { params: options })
  }
}
