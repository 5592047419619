
import { map } from 'rxjs/operators';
import { createRequestOption } from 'src/app/shared/util/request-util';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Payload } from '../model/payload';
import { GiaTinRao } from '../model/analyst/gia-tin-rao';
import { AnalystAddress } from '../model/analyst/analyst-address';
import { Heatmap } from '../model/analyst/heatmap';
import { QueryHeatmap } from '../model/analyst/query-heatmap';
import { environment } from '../../../environments/environment';

type ResponseAnalystAddress = HttpResponse<AnalystAddress>;
type ResponseAnalystAddressArray = HttpResponse<AnalystAddress[]>;
type ResponseHeatmapArray = HttpResponse<Heatmap[]>;

type ResponseDSGiaTinRao = Payload<{ data: GiaTinRao[][] }>;

@Injectable({
  providedIn: 'root'
})
export class AnalystHttpService {
  private sourceApi = environment.API.SERVER_ANALYST;
  // private sourceApi = ''
  constructor(private http: HttpClient) { }

  layDSGiaPhanTichTinRao(
    listIds: {
      id: string;
    }[]
  ): Observable<ResponseDSGiaTinRao> {
    return this.http.post<ResponseDSGiaTinRao>(this.sourceApi + '/api/khu-vuc/get-all-gia', listIds);
  }

  layGiaPhanTichTinRaoXungQuanh(
    khuVuc: {
      idCity: number;
      idDistrict?: number;
    },
    limit,
    offset
  ): Observable<ResponseDSGiaTinRao> {
    return this.http.post<ResponseDSGiaTinRao>(
      this.sourceApi + '/api/khu-vuc/gia-khu-vuc-xung-quanh' + '?limit=' + limit + '&offset=' + offset,
      khuVuc
    );
  }

  // Lay thong tin phan tich theo dia chi
  queryAnalyst(req?: {
    id?: number; // id dia chi
    type?: string; // enum: { tinh, quan, duong, phuong, duan }
    version?: string; // vd: 1.1.0
  }): Observable<ResponseAnalystAddressArray> {
    const options = createRequestOption(req);
    return this.http
      .get<AnalystAddress[]>(this.sourceApi + '/api/analytics-address/search', { params: options, observe: 'response' })
      .pipe(map((res: ResponseAnalystAddressArray) => this.convertDateArrayFromServer(res)));
  }

  // TODO: 
  // Lay thong tin phan tich theo dia chi
  layBieuDoGiaTin(req?: any): Observable<ResponseAnalystAddressArray> {
    const options = createRequestOption(req);
    return this.http
      .get<AnalystAddress[]>(this.sourceApi + '/api/analytics-address/search-v2', { params: options, observe: 'response' })
      .pipe(map((res: ResponseAnalystAddressArray) => this.convertDateArrayFromServer(res)));
  }

  layBieuDoGiaTin2(req?: any): Observable<ResponseAnalystAddressArray> {
    const options = createRequestOption(req);
    return this.http
      .get<AnalystAddress[]>(this.sourceApi + '/api/analytics-address/search-v2', { params: options, observe: 'response' })
      .pipe(map((res: ResponseAnalystAddressArray) => this.convertDateArrayFromServer(res)));
  }

  queryTop(req?: any): Observable<HttpResponse<any>> {
    const options = createRequestOption(req.parameter);
    return this.http.post<any[]>(this.sourceApi + '/api/address-top/query', req.query, { params: options, observe: 'response' });
  }

  createFeedback(feedback: any): Observable<any> {
    return this.http.post<any>(this.sourceApi + '/api/feedbacks', feedback, { observe: 'response' });
  }

  // Lay du lieu hien thi ban do heatmap
  queryHeatMap(req: QueryHeatmap): Observable<ResponseHeatmapArray> {
    return this.http.post<any>(this.sourceApi + '/api/analytics-heat-maps/query', req, { observe: 'response' });
  }

  queryPoint(req: { 'point-x': string; 'point-y': string; version: string }): Observable<ResponseHeatmapArray> {
    const options = createRequestOption(req);
    return this.http.get<any>(this.sourceApi + '/query-from-point', { params: options, observe: 'response' });
  }

  protected convertDateFromClient(analyticsAddress: AnalystAddress): AnalystAddress {
    const copy: AnalystAddress = Object.assign({}, analyticsAddress, {
      timeUpdate:
        analyticsAddress.timeUpdate != null && analyticsAddress.timeUpdate.isValid() ? analyticsAddress.timeUpdate.toJSON() : null,
      dateTime: analyticsAddress.dateTime != null && analyticsAddress.dateTime.isValid() ? analyticsAddress.dateTime.toJSON() : null
    });
    return copy;
  }

  protected convertDateFromServer(res: ResponseAnalystAddress): ResponseAnalystAddress {
    if (res.body) {
      // res.body.timeUpdate = res.body.timeUpdate != null ? moment(res.body.timeUpdate) : null;
      // res.body.dateTime = res.body.dateTime != null ? moment(res.body.dateTime) : null;
      /* Xoa phần chuyển đổi human time <=> epoch time này vì không được sử dụng */
    }
    return res;
  }

  protected convertDateArrayFromServer(res: ResponseAnalystAddressArray): ResponseAnalystAddressArray {
    if (res.body && res.body.length > 0) {
      res.body.forEach((analystAddress: AnalystAddress) => {
        // analystAddress.timeUpdate = analystAddress.timeUpdate != null ? moment(analystAddress.timeUpdate) : null;
        // analystAddress.dateTime = analystAddress.dateTime != null ? moment(analystAddress.dateTime) : null;
        /* Xoa phần chuyển đổi human time <=> epoch time này vì không được sử dụng */
      });
    }
    return res;
  }

  phanTichTiLetangTruong(
    requestTiLeTangTruong: {
      loaiDiaChi: number;
      idCity: number;
      version: string;
    }
  ): Observable<ResponseDSGiaTinRao> {
    return this.http.post<ResponseDSGiaTinRao>(this.sourceApi + '/api/analysis/ti-le-tang-truong', requestTiLeTangTruong);
  }

  soLuongBatDongSanRaoBanTheoThoiGian(
    requestSoLuongBDS: {
      loaiDiaChi: string;
      idCity: any;
      useFor: string;
      typeBdsId: any;
      version: string;
      idDistrict: any;
    }
  ): Observable<ResponseDSGiaTinRao> {
    return this.http.post<ResponseDSGiaTinRao>(this.sourceApi + '/api/analysis/tang-truong-theo-thoi-gian', requestSoLuongBDS);
  }

  tangTruongBatDongSanRaoBanTheoKhuVuc(
    requestTangTruongTheoKhuVuc: any
  ): Observable<any> {
    return this.http.post<ResponseDSGiaTinRao>(this.sourceApi + '/api/analysis/tang-truong-theo-khu-vuc-all', requestTangTruongTheoKhuVuc);
  }

  soLuongBatDongSanTheoPhanKhuc(
    requestTangTruongTheoKhuVuc: any
  ): Observable<any> {
    return this.http.post<ResponseDSGiaTinRao>(this.sourceApi + '/api/analysis/nguon-cung-theo-phan-khuc', requestTangTruongTheoKhuVuc);
  }

  tangTruongBatDongSanRaoBanTheoKhuVucDetail(
    requestTangTruongTheoKhuVuc: any
  ): Observable<any> {
    return this.http.post<ResponseDSGiaTinRao>(this.sourceApi + '/api/analysis/tang-truong-theo-khu-vuc', requestTangTruongTheoKhuVuc);
  }

  getBieuDoGia(
    requestBieuDoGia: {
      idDiaChi?: number;
      idQuan?: number;
      type?: string;
      version?: string;
    }
  ): Observable<any> {
    return this.http.get<ResponseDSGiaTinRao>(
      this.sourceApi + '/api/analytics-address/bieu-do-gia?id-dia-chi=' + requestBieuDoGia.idDiaChi
      + '&id-quan=' + requestBieuDoGia.idQuan
      + '&type=' + requestBieuDoGia.type
      + '&version=' + requestBieuDoGia.version
    );
  }

  topStreetDetail(
    requestTopStreetDetail: any
  ): Observable<any> {
    return this.http.post<any>(this.sourceApi + '/api/analysis/top-street-detail', requestTopStreetDetail);
  }

  topIncreaseDetail(
    requestTopIncreaseDetail: any
  ): Observable<any> {
    return this.http.post<any>(this.sourceApi + '/api/analysis/top-increase-detail', requestTopIncreaseDetail);
  }

  getTopTangTruongKhuVuc(requestTangTruong: any): Observable<any> {
    return this.http.post<any>(this.sourceApi + '/api/khu-vuc/top-tang-truong', requestTangTruong);
  }
  nhanKetQuaDinhGia(body) {
    return this.http.post<any>(this.sourceApi + '/api/report/send-email-estimate', body, {});
  }
  nhanEmailHangThang(body) {
    return this.http.post<any>(this.sourceApi + '/api/report/dang-ky-bao-cao-hang-thang', body, {});
  }

  huyEmail(body) {
    return this.http.post<any>(this.sourceApi + '/api/report/huy-dang-ky', body, {});
  }

  // getListBdsFavourite(id): Observable<any> {
  //     return this.http.get<any>(this.sourceApi + '/api/get-bds-yeu-thich?id=' + id);
  // }
  getListBdsFavourite(): Observable<any> {
    return this.http.get<any>(this.sourceApi + '/api/get-bds-yeu-thich');
  }
  saveBdsFavourite(body) {
    return this.http.post<any>(this.sourceApi + '/api/save-bds-yeu-thich', body, {});
  }
  removeBdsFavourite(body) {
    return this.http.post<any>(this.sourceApi + '/api/delete-bds-yeu-thich', body, {});
  }
  totalUsersCommunity(site, formMonTh, toMonth, formYear, toYear): Observable<any[]> {
    let params = 'site=' + site;
    if (formMonTh !== '' && formMonTh) {
      params += '&from-month=' + formMonTh;
    }
    if (toMonth !== '' && toMonth) {
      params += '&to-month=' + toMonth;
    }
    if (formYear !== '' && formYear) {
      params += '&from-year=' + formYear;
    }
    if (toYear !== '' && toYear) {
      params += '&to-year=' + toYear;
    }
    return this.http.get<any[]>(this.sourceApi + '/api/total-users/detail?' + params, {
    });
  }
  areaTotalUsersCommunity(site, fromMonth, toMonth, fromYear, toYear, idCity?, idDistrict?): Observable<any[]> {
    let params = 'site=' + site;
    if (fromMonth !== '' && fromMonth) {
      params += '&from-month=' + fromMonth;
    }
    if (toMonth !== '' && toMonth) {
      params += '&to-month=' + toMonth;
    }
    if (fromYear !== '' && fromYear) {
      params += '&from-year=' + fromYear;
    }
    if (toYear !== '' && toYear) {
      params += '&to-year=' + toYear;
    }
    if (idCity) {
      params += '&id-city=' + idCity;
    }
    if (idDistrict) {
      params += '&id-district=' + idDistrict;
    }
    return this.http.get<any[]>(this.sourceApi + '/api/khu-vuc-quan-tams/detail?' + params, {
    });
  }
  dataAllTypeBds(site, fromMonth, toMonth, fromYear, toYear, idCity?, idDistrict?, idWard?): Observable<any[]> {
    let params = 'site=' + site;
    if (fromMonth !== '' && fromMonth) {
      params += '&from-month=' + fromMonth;
    }
    if (toMonth !== '' && toMonth) {
      params += '&to-month=' + toMonth;
    }
    if (fromYear !== '' && fromYear) {
      params += '&from-year=' + fromYear;
    }
    if (toYear !== '' && toYear) {
      params += '&to-year=' + toYear;
    }
    if (idCity) {
      params += '&id-city=' + idCity;
    }
    if (idDistrict) {
      params += '&id-district=' + idDistrict;
    }
    if (idWard) {
      params += '&id-ward=' + idWard;
    }
    return this.http.get<any[]>(this.sourceApi + '/api/ty-trong-loai-bds/detail?' + params, {
    });
  }
  getDataTopUser(site, fromMonth, toMonth, fromYear, toYear, limit, typeUser, idCity?, idDistrict?, idWard?): Observable<any[]> {
    let params = 'site=' + site;
    if (fromMonth !== '' && fromMonth) {
      params += '&from-month=' + fromMonth;
    }
    if (toMonth !== '' && toMonth) {
      params += '&to-month=' + toMonth;
    }
    if (fromYear !== '' && fromYear) {
      params += '&from-year=' + fromYear;
    }
    if (toYear !== '' && toYear) {
      params += '&to-year=' + toYear;
    }
    if (limit !== '' && limit) {
      params += '&limit=' + limit;
    }
    if (typeUser !== '' && typeUser) {
      params += '&type-user=' + typeUser;
    }
    if (idCity) {
      params += '&city-id=' + idCity;
    }
    if (idDistrict) {
      params += '&district-id=' + idDistrict;
    }
    if (idWard) {
      params += '&ward-id=' + idWard;
    }
    return this.http.get<any[]>(this.sourceApi + '/api/top-users/detail?' + params, {
    });
  }
}
