<ng-container *ngIf="loading; else loaded">
    <div
      class="text-center mt-3 d-flex justify-content-center align-items-center"
      style="height: 420px !important"
    >
      <div class="lds-dual-ring"></div>
    </div>
  </ng-container>
  <ng-template #loaded>
    <table class="w-100">
      <tr>
        <td>Loại BĐS</td>
        <td>Khoảng giá</td>
        <td>Loại T.Trưởng</td>
        <!-- <td>Hướng T.Trưởng</td>
                  <td>Biến động so với</td> -->
        <td *ngIf="type !== 'xem-tong-quan' && type !== 'khu-vuc-quan-tam-cua-ban'">Tìm thấy</td>
        <td *ngIf="type !== 'xem-tong-quan' && type !== 'khu-vuc-quan-tam-cua-ban'">Bật/tắt thông báo</td>
        <td *ngIf="type !== 'xem-tong-quan' && type !== 'khu-vuc-quan-tam-cua-ban'"></td>
      </tr>
      <ng-container *ngIf="criteriaList && criteriaList.length; else noData">
        <tr
          *ngFor="let item of criteriaList"
          [ngStyle]="{
            background:
              (type === 'xem-tong-quan' || type === 'khu-vuc-quan-tam-cua-ban') && selectedId === item.id
                ? '#d6d6d6'
                : 'transparent'
          }"
          [class.item]="type === 'xem-tong-quan' || type === 'khu-vuc-quan-tam-cua-ban'"
          (click)="selectItemToDrawChart(item, true);selectedId = item.id"
        >
          <td>
            <div>{{ item.typeBdsId === 5 ? 'Nhà mặt đường' : 'Nhà trong ngõ' }} - {{ item.useFor === 'SALE' ? 'Nhà bán' : 'Nhà thuê' }}</div>
            <div>{{ item.ten ? item.ten : 'Cả nước' }}</div>
          </td>
          <td>
            <div>{{ item.giaMin }} {{item.useFor === 'SALE' ? 'triệu/m2' : 'nghìn/m2/tháng'}}  đến {{ item.giaMax }} {{item.useFor === 'SALE' ? 'triệu/m2' : 'nghìn/m2/tháng'}}</div>
            <div>
              Trong
              <span [ngSwitch]="item.mucTimKiem">
                <span *ngSwitchCase="'tinh'">Tỉnh/Thành Phố</span>
                <span *ngSwitchCase="'quan_huyen'">Quận/Huyện</span>
                <span *ngSwitchCase="'phuong_xa'">Phường/Xã</span>
                <span *ngSwitchCase="'duong'">Đường</span>
                <span *ngSwitchDefault>-</span>
              </span>
            </div>
          </td>
          <td>
            <div>
              {{ item.loaiTangTruong === "gia" ? "Giá" : "Tin rao" }} Đang
              {{ item.huongTangTruong === "tang" ? "Tăng" : "Giảm" }}
            </div>
            <div>Ít nhất {{ item.tyLeTangTruong }}% so với quý trước</div>
            <div>Kéo dài trong {{ item.khoangTangTruong }} quý</div>
          </td>
          <!-- <td>{{commonService.huongTangTruongId[item.huongTangTruongId]}}</td>
                        <td>{{commonService.kieuBienDongId[item.kieuBienDongId]}}</td> -->
          <td *ngIf="type !== 'xem-tong-quan' && type !== 'khu-vuc-quan-tam-cua-ban'">
            <div class="text-center">
              <button
                (click)="xemChiTiet($event, item, false)"
                class="btn-view-detail"
                *ngIf="item.countResults; else noFound"
              >
                Xem {{ item.countResults }} khu vực
              </button>
              <ng-template #noFound>
                <div>Chưa tìm thấy</div>
              </ng-template>
            </div>
          </td>
          <td *ngIf="type !== 'xem-tong-quan' && type !== 'khu-vuc-quan-tam-cua-ban'">
            <div class="text-center">
              <label class="switch">
                <input
                  [checked]="item.enableNotiEmail"
                  type="checkbox"
                  (change)="allowNotiEmail($event, item.id)"
                />
                <span class="slider round"></span>
              </label>
            </div>
          </td>
          <td *ngIf="type !== 'xem-tong-quan' && type !== 'khu-vuc-quan-tam-cua-ban'">
            <button
              class="btn-view-detail"
              (click)="removeCriteriaEvent.emit(item.id)"
            >
              Xóa
            </button>
          </td>
        </tr>
      </ng-container>
      <ng-template #noData>
        <div class="mt-2 ml-1">Không có kết quả nào!</div>
      </ng-template>
    </table>
  </ng-template>
  