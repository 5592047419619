import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Payload } from '../model/payload';
import { ChiNhanh } from '../model/auth/chi-nhanh.model';

@Injectable({
    providedIn: 'root'
})
export class AuthHttpService {
    private sourceApi = environment.API.SERVER_API_G_AUTH;
    constructor(private http: HttpClient) {}

    layDanhSachChiNhanh(): Observable<Payload<ChiNhanh[]>> {
        return this.http.get<any>(this.sourceApi + `/api/chi-nhanhs/get-all`);
    }

    authGoogle(body) {
        return this.http.post<any>(this.sourceApi + `/api/authenticate-google`, body);
    }

    authFaceBook(body) {
        return this.http.post<any>(this.sourceApi + `/api/authenticate-facebook`, body);
    }
}
