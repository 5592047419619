import { InterceptorService } from './../../blocks/interceptor/interceptor.service';
import { Injectable } from '@angular/core';
import { AccountService } from '../auth/account.service';
import { AuthServerProvider } from '../auth/auth-jwt.service';


@Injectable({ providedIn: 'root' })
export class LoginService {
    constructor(
        private accountService: AccountService,
        private authServerProvider: AuthServerProvider,
        private interceptorService: InterceptorService) {

    }
    

    login(credentials, callback?) {
        const cb = callback || function () { };
        /* const that = this;*/
        return new Promise((resolve, reject) => {
            this.authServerProvider.login(credentials).subscribe(
                cdata => {
                    if (typeof Worker !== 'undefined') {
                        // Create a new
                        const worker = new Worker('../../layouts/landing/crs-landing/crs-landing.worker', { type: 'module' });

                        worker.onmessage = ({ data }) => {
                            // console.log(`page got message: ${data}`);
                            if (data.type === 'identity') {
                              if (data.status === 401) {
                                // this.account = null;
                                console.log('401');
                              } else {
                                console.log('Khong phai 401');
                                const account = JSON.parse(data.content);
                                console.log('data.account', account);
                                // console.log('result xhr', account);
                                console.log('account', account);
                                this.accountService.setIdentity(account).then(acc => {
                                    resolve(acc);
                                });
                              }
                            }
                        };

                        this.interceptorService.fingerBehaviorSubject.subscribe(finger => {
                            // console.log('finger subscribe', finger, this.interceptorService.jwt);
                            if (finger) {
                              const headers = this.interceptorService.appendCommonHeader(finger, this.interceptorService.jwt);
                              worker.postMessage({ type: 'identity', headers });
                            }
                        });
                    } else {
                        // Web Workers are not supported in this environment.
                        // You should add a fallback so that your program still executes correctly.
                    }
                    // this.accountService.identity(true).then(account => {
                    //     resolve(data);
                    // });
                    return cb();
                },
                err => {
                    this.logout();
                    reject(err);
                    return cb(err);
                }
            );
        });
    }

    loginWithToken(jwt, rememberMe, callback?) {
        const cb = callback || function () { };
        this.authServerProvider.loginWithToken(jwt, rememberMe);
        return new Promise((resolve, reject) => {
            if (jwt === undefined || jwt === null) {
                reject('auth-jwt-service Promise reject');
                return cb('auth-jwt-service Promise reject');
            } else {
                this.accountService.identity(true).then(account => {
                    resolve(account);
                });
                return cb();
            } 
        });
    }

    logout() {
        this.authServerProvider.logout().subscribe();
        this.accountService.authenticate(null);
    }

    isAuthenticated() {
        return this.accountService.isAuthenticated();
    }

    getAccountService() {
        return this.accountService;
    }

}
