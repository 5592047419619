
<div class="text-[#333333] text-[14px] font-[500] border-l border-l-[3px] border-[#6D3815] pl-[6px] mb-[10px]">Thông tin tài sản thẩm định</div>
<div class="flex flex-row justify-between w-full h-[auto] sm:h-[68px] bg-[#FFFBF6] border-[1px] border-dashed border-[#6D3815] rounded-[6px] px-[16px] py-[11px]">
    <div class="flex flex flex-col border-[#6D3815]">
        <div class="text-[#333333] text-[15px] font-[500]" *ngIf="!diaChiEdit">{{diaChi}}</div>
        <input nzSize="small" nz-input placeholder="Basic usage" [(ngModel)]="diaChi" *ngIf="diaChiEdit" />
        <div class="text-[#828282] text-[13px] font-[400]">{{phuongXa}}, {{quanHuyen}}, {{tinhThanh}}</div>
    </div>
    <div>
        <i *ngIf="!diaChiEdit" nz-icon nzType="edit" nzTheme="outline" (click)="diaChiEdit = true"></i>
        <i *ngIf="diaChiEdit" nz-icon nzType="check" nzTheme="outline" (click)="diaChiEdit = false"></i>
    </div>
</div>
<div class="w-full py-[16px]">
    <nz-radio-group [(ngModel)]="loaiYeuCauThamDinh" [disabled]="loading">
        <label nz-radio nzValue="DINH_GIA_SO_BO">Định giá sơ bộ</label>
        <label nz-radio nzValue="THAM_DINH_GIA">Thẩm định giá</label>
    </nz-radio-group>
</div>
<div class="mb-[10px]">
    <h4>Chi nhánh thẩm định <span class="text-[red]">*</span></h4>
    <nz-select [nzAllowClear]="false" [(ngModel)]="payload.chiNhanhId" [disabled]="loading" class="w-full" nzPlaceHolder="Chọn chi nhánh thẩm định">
        <nz-option [nzValue]="item.id" [nzLabel]="item.name" *ngFor="let item of danhSachChiNhanh"></nz-option>
    </nz-select>
</div>
<div class="text-[#333333] text-[14px] font-[500] border-l border-l-[3px] border-[#6D3815] pl-[6px] mb-[10px]">Thông tin người liên hệ</div>
<div class="flex flex-row mb-2 gap-3">
    <div class="w-1/2 flex flex-col">
        <div class="text-[#4F4F4F] text-[13px] font-[400] mb-2">Họ & tên <span class="text-[red]">*</span></div>
        <input [disabled]="loading" [(ngModel)]="payload.tenKH" nz-input placeholder="Họ & tên" />
        <div *ngIf="!payload.tenKH" class="py-1 text-[#950000] text-[12px]">Vui lòng điền họ và tên</div>
    </div>
    <div class="w-1/2 flex flex-col">
        <div class="text-[#4F4F4F] text-[13px] font-[400] mb-2">Số điện thoại <span class="text-[red]">*</span></div>
        <input [disabled]="loading" [(ngModel)]="payload.soDT" nz-input placeholder="Nhập SĐT" />
        <div *ngIf="!payload.soDT" class="py-1 text-[#950000] text-[12px]">Vui lòng điền số điện thoại</div>
    </div>
</div>
<div class="flex flex-col mb-2">
    <div class="text-[#4F4F4F] text-[13px] font-[400] mb-2" mb-1>Email <span class="text-[red]">*</span></div>
    <input [disabled]="loading" [(ngModel)]="payload.emailKH" nz-input placeholder="Nhập email" />
    <div *ngIf="!payload.emailKH" class="py-1 text-[#950000] text-[12px]">Vui lòng điền Email</div>
</div>
<div class="flex flex-col mb-2">
    <div class="text-[#4F4F4F] text-[13px] font-[400] mb-2">Ghi chú</div>
    <textarea
    [(ngModel)]="payload.chuY"
    [disabled]="loading"
        nz-input
        placeholder="Nhập ghi chú"
        [nzAutosize]="{ minRows: 3, maxRows: 6 }"
    ></textarea>
</div>
<div class="mb-2">
    <div class="text-[13px] text-[#4F4F4F] font-[500]">Lưu ý</div>
    <div class="text-[13px] text-[#4F4F4F] font-[400] font-italic">Yêu cầu thẩm định cho tài sản này sẽ được gửi tới CÔNG TY CỔ PHẦN THẨM ĐỊNH GIÁ THẾ KỶ - CEN VALUE. Dịch vụ này có thể phát sinh chi phí. Chi tiết liên hệ: (04) 32222.786</div>
</div>
<div class="flex justify-center">
    <button nz-button nzType="primary" [disabled]="loading || !payload.chiNhanhId || !payload.tenKH || !payload.soDT || !payload.emailKH" class="h-[40px]" (click)="yeuCauThamDinh()">Gửi yêu cầu thẩm định</button>
</div>


