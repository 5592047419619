import { Injectable } from "@angular/core";
import { BehaviorSubject, forkJoin, of, throwError } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { catchError, debounceTime, distinctUntilChanged, map, retry, switchMap, take, tap } from "rxjs/operators";
import { ToasterService } from "angular2-toaster";
import { SearchHttpService } from "../http/search-http.service";
import { createAnalysisObject } from "../interface/analysis.model";
import { tranFormDataChart } from "../rxjs-operator-custom/tranform-data.chart";
import { environment } from "../../../environments/environment";
import { Apollo } from "apollo-angular";
import { getAnalysisByAddressId } from "src/app/layouts/phan-tich-khu-vuc/query";

@Injectable({ providedIn: "root" })
export class PhanTichNangCaoService {
  khuVucDangXem$ = new BehaviorSubject(null);
  currentProvinceIdSelected$ = new BehaviorSubject<number>(null);
  currentProvinceIdSelectedToFilterCriteria$ = new BehaviorSubject<number>(
    null
  );
  searchResultsCriteria = [];
  allowSaveCriteria = false;
  listDienGiaiKhuVucQuanTam = [];
  pageSize = 4;
  pageSelected = 1;
  constructor(
    private http: HttpClient,
    private searchHttpService: SearchHttpService,
    private toasterService: ToasterService,
    private apollo: Apollo,
  ) { }
  dataChartInit = {
    chart: {
      type: "spline",
    },
    title: {
      style: {
        fontSize: "13px",
      },
      text: "Biểu đồ lịch sử giá các đường",
    },
    xAxis: {
      categories: [],
      // crosshair: true,
    },
    yAxis: {
      title: {
        text: null,
      },
    },
    tooltip: {
      //valueSuffix: '°C',
      enabled: true,
      useHTML: true,
      headerFormat: "<b >{point.key}</b><br/>",
      pointFormat:
        '<span >{point.ten}</span><br/><table style="width: 100%"><tr><td >Giá: </td>' +
        '<td style="text-align: right"><b style="color: {series.color}">{point.y: ,.2f} triệu/m2</b></td></tr>' +
        "<tr><td >Số tin: </td>" +
        '<td style="text-align: right"><b style="color: {series.color}">{point.soTin}</b></td></tr>',
      footerFormat: "</table>",
      // pointFormat: '<tr><td style="color: {series.color}">{point.ten}: </td>' +
      //     '<td style="text-align: right"><b>{point.y} EUR</b></td></tr>',
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: false,
        },
        enableMouseTracking: true,
      },
      series: {
        label: {
          enabled: false,
        },
      },
    },
    series: [
      { name: "", data: [] },
      { name: "", data: [] },
      { name: "", data: [] },
    ],
  };

  listDienGiaiKhuVucTiemNangNhat = [];
  BASE_URL = `${environment.API.KONG_SERVER}/g-data-statistic/v2/api/query-nang-cao`;
  BASE_URL_DEV = "https://gapi-sandbox.cenhomes.vn/dev/g-data-statistic/v2/api/query-nang-cao";

  getTopXuHuong(
    tinhId,
    quanId,
    loaiDiaChi = "duong",
    typeBdsId = 5,
    useFor = "SALE",
    loaiTangTruong = "gia"
  ) {
    const url = this.getUrlTongQuan(tinhId, quanId, loaiDiaChi, typeBdsId, useFor, 'top-tiem-nang');
    return this.http
      .get(url)
      .pipe(
        map((res: any) => {
          if (res && res.payload.data.length) {
            return res.payload.data.map((item) => {
              const analysis = createAnalysisObject({
                idDiaChi: item[0],
                typeBdsId,
                loaiDiaChi,
                useFor,
              });
              const api = this.getAnalysis(
                analysis
              );
              return api;
            });
          }
          return of([]);
        }),
        tranFormDataChart(),
        tap((result) => console.log("result graphql: ", result)),
        map((res) => {
          if (res.length) {
            const analysisList = res.filter(item => item.analysis && item.analysis.length);
            return this.generateDataSeriesChart(analysisList, loaiTangTruong);
          }
          return { series: [], years: [] };
        }),
        tap((result) => console.log("result series from graphql: ", result))
      );
  }

  // getDataChart(res, loaiDiaChi) {
  //   let capHC;
  //   switch (loaiDiaChi) {
  //     case "duong":
  //       capHC = "đường";
  //       break;
  //     case "phuong":
  //       capHC = "phường";
  //       break;
  //     case "quan":
  //       capHC = "quận";
  //       break;
  //     case "tinh":
  //       capHC = "tỉnh";
  //       break;
  //     default:
  //       capHC = "đường";
  //       break;
  //   }
  //   return {
  //     data: res["series"]?.slice(0, 3) ?? [],
  //     categories: res["years"] ?? [],
  //     title: `Biểu đồ lịch sử giá các ${capHC}`,
  //     yAxis: {
  //       title: { text: null },
  //     },
  //   };
  // }

  getTopKhuVucTiemNangNhat(
    tinhId,
    quanId,
    loaiDiaChi = "duong",
    typeBdsId = 5,
    useFor = "SALE",
    isTongQuan = true,
    size = 3,
    loaiTangTruong = "gia"
  ) {
    let url: string;
    if (isTongQuan) {
      url = `${this.getUrlTongQuan(tinhId, quanId, loaiDiaChi, typeBdsId, useFor, 'top-tiem-nang-theo-user', size)}&option=default`;
    } else {
      url = this.getUrlTongQuan(tinhId, quanId, loaiDiaChi, typeBdsId, useFor, 'top-tiem-nang-theo-user', size);
    }
    return this.http.get(url).pipe(
      map((res: any) => {
        if (res && res.payload.data.length) {
          this.listDienGiaiKhuVucTiemNangNhat = res.payload.data
            .map((item) =>
              item["list_queryNangCao"].map((i) => ({
                ...i,
                idDG: item.khuVucId,
              }))
            )
            .flat();
          return res.payload.data.map((item) => {
            const analysis = createAnalysisObject({
              idDiaChi: item.khuVucId,
              typeBdsId,
              loaiDiaChi,
              useFor,
            });
            const api = this.getAnalysis(
              analysis
            );
            return api;
          });
        }
        return of([]);
      }),
      tranFormDataChart(),
      tap((result) => console.log("result graphql: ", result)),
      map((res) => {
        if (res.length) {
          const listDienGiai = this.listDienGiaiKhuVucTiemNangNhat.map(
            (item) => {
              // const ten = res.find((i) => i.idDiaChi === item.idDG)?.ten ?? "";
              const diaChi =
                res.find((i) => i.idDiaChi === item.idDG)?.tenDayDu ?? "";
              const tienTo =
                res.find((i) => i.idDiaChi === item.idDG)?.tienTo ?? "";

              return { ...item, diaChi, tienTo };
            }
          );

          const analysisList = res.filter(item => item.analysis && item.analysis.length);
          return {
            ...this.generateDataSeriesChart(analysisList, loaiTangTruong),
            listDienGiai,
          };
        }
        return { series: [], years: [], listDienGiai: [] };
      }),
      tap((result) =>
        console.log(
          "result series from graphql getTopKhuVucTiemNangNhatTongQuan: ",
          result
        )
      )
    );
  }

  filterDataBaseCriteria(data) {
    let allDataRelateCriteria = [];
    let criteriaDetail;
    return this.http.post(`${this.BASE_URL}/search`, { ...data }).pipe(
      tap((res: any) => {
        if (res && res.payload && res.payload.data?.listAllKetQua?.length) {
          this.searchResultsCriteria = res.payload.data.listAllKetQua.map(
            (item) => ({
              idDiaChi: item.idDiaChi,
              ten: item.ten,
              tenDayDu: item.tenDayDu,
              tienTo: item.tienTo,
              properties: item.properties,
              loaiDiaChi: item.loaiDiaChi,
            })
          );
        } else {
          this.searchResultsCriteria = [];
        }
      }),
      map((res: any) => {
        if (res && res.payload && res.payload.data.listAllKetQua.length) {
          const result = res.payload.data;
          criteriaDetail = result.queryNangCaoDTO;
          allDataRelateCriteria = result.listAllKetQua;
          const {useFor, typeBdsId} = data;
          return result.listAllKetQua.slice(0, 3).map(item => {
            const body = {
              idDiaChi: item.idDiaChi,
              loaiDiaChi: item.loaiDiaChi,
              typeBdsId,
              useFor
            }
            return this.getAnalysis(body);
          });
        }
        return [];
      }),
      tranFormDataChart(),
      map(res => {
        console.log('allDataRelateCriteria: ', allDataRelateCriteria);
        console.log('queryNangCaoDTO: ', criteriaDetail);
        if (res && res.length) {
          const analysisList = res.filter(item => item.analysis && item.analysis.length > 0);
          const selectedIds = analysisList.map((item) => item.idDiaChi);
          return {
            ...this.generateDataSeriesChart(analysisList, data.loaiTangTruong),
            selectedIds,
            allData: allDataRelateCriteria.map((item) => {
              const checked = selectedIds.includes(item.idDiaChi);
              return {
                ...item,
                checked,
              };
            }),
            queryNangCaoDTO: criteriaDetail
          }
        }
        return { series: [], years: [] };
      }),
      tap(res => console.log('filterDataBaseCriteria WithChartGetFromGraphql: ', res))
    
    );
  }

  saveCriteria(criteria) {
    console.log("searchResultsCriteria: ", this.searchResultsCriteria);
    return this.http
      .post(`${this.BASE_URL}/luu-tieu-chi`, {
        queryUserDTO: criteria,
        analyticsAddressResDTOv2List: this.searchResultsCriteria,
      })
      .pipe(
        tap((result) => {
          if (result) {
            this.searchResultsCriteria = [];
            this.allowSaveCriteria = false;
            this.toasterService.pop({
              type: "success",
              title: "Lưu tiêu chí thành công!",
              showCloseButton: false,
            });
          }
        }),
        catchError((err) => of(err))
      );
  }

  getAllCriteria(tinhId, quanId, page: number, size = 4, isTongQuan = false) {
    const url = this.generateUrlGetCriteria(tinhId, quanId, page, size, isTongQuan);
    return this.http.get(url).pipe(tap((res) => console.log("all cre: ", res)));
  }
  private generateUrlGetCriteria(tinhId, quanId, page: number, size, isTongQuan) {
    let url: string;
    if (isTongQuan) {
      if (tinhId) {
        if (quanId) {
          url = `${this.BASE_URL}/get-ket-qua?page=${page}&size=${size}&tinh_id=${tinhId}&quan_id=${quanId}&option=default`;
        } else {

          url = `${this.BASE_URL}/get-ket-qua?page=${page}&size=${size}&tinh_id=${tinhId}&option=default`;
        }
      } else {
        url = `${this.BASE_URL}/get-ket-qua?page=${page}&size=${size}&option=default`;
      }
    } else {
      if (tinhId) {
        if (quanId) {
          url = `${this.BASE_URL}/get-ket-qua?page=${page}&size=${size}&tinh_id=${tinhId}&quan_id=${quanId}`;
        } else {

          url = `${this.BASE_URL}/get-ket-qua?page=${page}&size=${size}&tinh_id=${tinhId}`;
        }
      } else {
        url = `${this.BASE_URL}/get-ket-qua?page=${page}&size=${size}`;
      }
    }

    return url;
  }

  getInfoAddress() {
    return this.http.get(
      `${environment.API.KONG_SERVER}/c-search/v2/dinhgia-get?id=1_analytics_tinh`,
      {
        headers: {
          Authorization:
            "Finger OTo+OTk7OEA4bT9AOjg9OEBBPDs7bD08PThuOTk9bG49bjk7Pzs4O2xpbG0/",
        },
      }
    );
  }

  getProvinceBaseDistrictId(id: string) {
    return this.http.get(
      `${environment.API.KONG_SERVER}/c-search/v2/dinhgia-get?id=${id}`,
      {
        headers: {
          Authorization:
            "Finger OTo+OTk7OEA4bT9AOjg9OEBBPDs7bD08PThuOTk9bG49bjk7Pzs4O2xpbG0/",
        },
      }
    );
  }

  getAllCriteriaBaseAreaId(
    idDiaChi: number,
    typeDiaChi: string,
    page = 0,
    pageSize = 3
  ) {
    // &page=1&size=5
    return this.http
      .get(
        `${this.BASE_URL}/get-ket-qua-theo-khu-vuc?id_khu_vuc=${idDiaChi}&type_dia_chi=${typeDiaChi}&page=${page}&size=${pageSize}`
      )
      .pipe(tap((res) => console.log("get-ket-qua-theo-khu-vuc: ", res)));
  }

  getDetailCriteriaWithChartGetFromGraphql(criteriaId: string, loaiTangTruong = "gia") {
    let allDataRelateCriteria = [];
    let criteriaDetail;
    // const analysis = createAnalysisObject(body);
    return this.http
      .get(`${this.BASE_URL}/get-ket-qua-chi-tiet/${criteriaId}`)
      .pipe(
        map((res: any) => {
          if (res && res.payload && res.payload.data.listAllKetQua.length) {
            const data = res.payload.data;
            criteriaDetail = data.queryNangCaoDTO;
            allDataRelateCriteria = data.listAllKetQua;
            const {useFor, typeBdsId} = data.queryNangCaoDTO;
            return data.listAllKetQua.slice(0, 3).map(item => {
              const body = {
                idDiaChi: item.idDiaChi,
                loaiDiaChi: item.loaiDiaChi,
                typeBdsId,
                useFor
              }
              return this.getAnalysis(body);
            });
          }
          return [];
        }),
        tranFormDataChart(),
        map(res => {
          if (res && res.length) {
            const listAnalysis = res.filter(item => item.analysts && item.analysts.length > 0);
            const selectedIds = listAnalysis.map((item) => item.idDiaChi);
            return {
              ...this.generateDataSeriesChart(listAnalysis, loaiTangTruong),
              selectedIds,
              allData: allDataRelateCriteria.map((item) => {
                const checked = selectedIds.includes(item.idDiaChi);
                return {
                  ...item,
                  checked,
                };
              }),
              queryNangCaoDTO: criteriaDetail
            }
          }
          return { series: [], years: [] };
        }),
        tap(res => console.log('getDetailCriteriaWithChartGetFromGraphql: ', res))
      );
  }

  removeCriteria(id: number) {
    return this.http.post(`${this.BASE_URL}/xoa-query/${id}`, {}).pipe(
      tap((result) => {
        if (result) {
          this.toasterService.pop({
            type: "success",
            title: "Xóa tiêu chí thành công!",
            showCloseButton: false,
          });
        }
      }),
      catchError((err) => throwError(err))
    );
  }

  allowNotiToEmail(id: string, status: boolean) {
    return this.http
      .post(`${this.BASE_URL}/bat-tat-thong-bao`, { id, trangThai: status })
      .pipe(
        tap((result) => {
          if (result) {
            this.toasterService.pop({
              type: "success",
              title: "Bật thông báo email thành công!",
              showCloseButton: false,
            });
          }
        }),
        catchError((err) => throwError(err))
      );
  }

  disabledNotiToEmail(id: string, status: boolean) {
    return this.http
      .post(`${this.BASE_URL}/bat-tat-thong-bao`, { id, trangThai: status })
      .pipe(
        tap((result) => {
          if (result) {
            this.toasterService.pop({
              type: "success",
              title: "Tắt thông báo email thành công!",
              showCloseButton: false,
            });
          }
        }),
        catchError((err) => throwError(err))
      );
  }

  getAnalysisByAddressId(body, loaiTangTruong = 'gia') {
    return this.getAnalysis(body).pipe(
      map(res => {
        if (res['data'] && res['data']['address'] && res['data']['address']['analysts'].length) {
          const data = {
            ...res['data']['address'],
            idDiaChi: res['data']['address']['id'], 
            analysis: res['data']['address']['analysts'].map( i => ({...i, giaTrenDTTrungBinh: i.giaTrenDtTrungBinh}))
          };
          return {
            ...this.generateDataSeriesChart([data], loaiTangTruong)
          }
        }
        return null;
      })
    )
  }

  // getAnalysisByAddressId(queryNangCaoDTO, analyticsAddressResDTO) {
  //   return this.http
  //     .post(`${this.BASE_URL}/get-them-ket-qua-chi-tiet`, {
  //       queryNangCaoDTO,
  //       analyticsAddressResDTO: [analyticsAddressResDTO],
  //     })
  //     .pipe(
  //       map((res: any) => {
  //         if (res.payload && res.payload.data.length) {
  //           console.log("getAnalysisByAddressId result: ", res);
  //           const analysisData = res.payload.data;
  //           // phan tich dât trả ve de ve bieu do
  //           return {
  //             ...this.generateDataSeriesChart(
  //               analysisData,
  //               queryNangCaoDTO?.loaiTangTruong ?? "gia"
  //             ),
  //           };
  //         }
  //         return null;
  //       })
  //     );
  // }

  getAllProvince() {
    return this.searchHttpService.queryadress("tinh", 5).pipe(
      map((data) => {
        console.log("all quan: ", data);
        if (data && data.length) {
          return data.map((item) => ({ id: item.id, name: item.ten }));
        }
        return [];
      }),
      tap((data) => {
        if (data.length) {
          this.currentProvinceIdSelected$.next(data[0].id);
          // this.currentProvinceIdSelectedToFilterCriteria$.next(data[0].id);
        }
      }),
      map((provinces) => {
        if (provinces.length) {
          return [{ id: -1, name: "Cả nước" }, ...provinces];
        }
        return [];
      }),
    );
  }

  getAllDistrict(provinceId) {
    if (provinceId) {
      return this.searchHttpService.queryadress("quan", provinceId).pipe(
        map((districts) => {
          if (districts && districts.length) {
            return districts.map((item) => ({ id: item.id, name: item.ten }));
          }
          return [];
        })
      );
    }
    return of([]);
  }

  getAllDistrictByProvinceId() {
    return this.currentProvinceIdSelected$.asObservable().pipe(
      distinctUntilChanged((prev, next) => prev === next),
      switchMap((provinceId) => {
        if (provinceId) {
          return this.searchHttpService.queryadress("quan", provinceId);
        }
        return of(null);
      }),
      map((districts) => {
        if (districts && districts.length) {
          return districts.map((item) => ({ id: item.id, name: item.ten }));
        }
        return [];
      })
    );
  }

  getAllDistrictWhenChangeProvinceToFilterCriteria() {
    return this.currentProvinceIdSelectedToFilterCriteria$.asObservable().pipe(
      debounceTime(300),
      switchMap((provinceId) => {
        if (provinceId) {
          return this.searchHttpService.queryadress("quan", provinceId);
        }
        return of(null);
      }),
      map((districts) => {
        if (districts && districts.length) {
          return districts.map((item) => ({ id: item.id, name: item.ten }));
        }
        return [];
      })
    );
  }

  generateDataSeriesChart(data, loaiTangTruong) {
    if (!data.length) {
      return;
    }
    const dateList = [];
    const datesArr = data.map((item) => {
      const itemEnd = item.analysis.length ? item.analysis[item.analysis.length - 1] : null;
      return itemEnd
        ? +new Date(`${itemEnd.year}/${itemEnd.month}/28`)
        : new Date(null); // analysis = []
    });
    const endDate = new Date(Math.max(...datesArr));
    const startDate = this.getStartDateFromAnalysis(endDate); // get 12 thang gan nhat
    let series = [];
    data.forEach((item) => {
      while (startDate <= endDate) {
        dateList.push(new Date(startDate));
        startDate.setMonth(startDate.getMonth() + 3);
      }
      // console.log(dateList[0].getMonth() + 1)
      const analysis = item.analysis.filter(
        (item) => +new Date(`${item.year}/${item.month}/28`) >= +dateList[0]
      );
      const target = dateList.map((d) => ({
        quater: this.getQuater(d.getMonth() + 1, d.getFullYear()),
      }));
      const source = analysis.map((i) => ({
        quater: this.getQuater(i.month, i.year),
        giaTrenDTTrungBinh: i.giaTrenDTTrungBinh,
        soTin: i.soTin,
        ten: item.tenDayDu,
        useFor: i.useFor,
        checkBatThuong: i.checkBatThuong ?? false
      }));
      this.mergeByProperty(target, source, "quater");
      const data = target.map((item: any) => {
        return {
          y:
            loaiTangTruong === "sotin"
              ? item.soTin ?? null
              : item.giaTrenDTTrungBinh ?? null,
          soTin: item.soTin ?? 0,
          ten: item.ten ?? "",
          gia: item.giaTrenDTTrungBinh ?? 0,
          donvi: item.useFor === "SALE" ? "triệu/m2" : "nghìn/m2/tháng",
          className: item.checkBatThuong ? 'highcharts-color-bad' : null,
          isBatThuong: item.checkBatThuong ? '(bất thường)' : ''
        };
      });
      series.push({
        name: `${item.tienTo} ${item.ten}`,
        idDiaChi: item.idDiaChi,
        data,
      });
      console.log("target: ", target);
      console.log("source: ", source);
    });
    const years = dateList.map((d) =>
      this.getQuater(d.getMonth() + 1, d.getFullYear())
    );
    console.log("series: ", series);
    console.log("years: ", years);
    return { series, years };
  }

  mergeByProperty(target, source, prop) {
    source.forEach((sourceElement) => {
      let targetElement = target.find((targetElement) => {
        return sourceElement[prop] === targetElement[prop];
      });
      targetElement
        ? Object.assign(targetElement, sourceElement)
        : target.push(sourceElement);
    });
  }
  getQuater(month, year) {
    if (month < 4) {
      return `Q1/${year}`;
    } else if (month < 7) {
      return `Q2/${year}`;
    } else if (month < 10) {
      return `Q3/${year}`;
    } else {
      return `Q4/${year}`;
    }
  }


  getAnalysis(data) {
    return this.apollo
      .query({ query: getAnalysisByAddressId, variables: createAnalysisObject(data) });
  }

  getStartDateFromAnalysis(endDate) {
    const d = new Date(endDate);
    console.log(d.toLocaleDateString());
    d.setMonth(d.getMonth() - 24);
    console.log(d.toLocaleDateString());
    return new Date(d);
  }

  getTitleChart(capHanhChinh: string, loaiTangTruong = "gia") {
    switch (capHanhChinh) {
      case "tinh":
        return `Biểu đồ lịch sử ${loaiTangTruong === "gia" ? "giá" : "số tin"
          } của các tỉnh/thành phố`;
      case "quan_huyen":
      case "quan":
        return `Biểu đồ lịch sử ${loaiTangTruong === "gia" ? "giá" : "số tin"
          } của các quận/huyện`;
      case "phuong_xa":
      case "phuong":
        return `Biểu đồ lịch sử ${loaiTangTruong === "gia" ? "giá" : "số tin"
          } của các phường/xã`;
      case "duong":
        return `Biểu đồ lịch sử ${loaiTangTruong === "gia" ? "giá" : "số tin"
          } của các đường`;
      default:
        return `Biểu đồ lịch sử ${loaiTangTruong === "gia" ? "giá" : "số tin"
          } của các đường`;
    }
  }

  private getUrlTongQuan(tinhId, quanId, loaiDiaChi: string, typeBdsId: number, useFor: string, slug: string, pageSize = 5) {
    let url: string;
    if (tinhId) {
      if (quanId) {
        url = `${this.BASE_URL}/${slug}?level=${loaiDiaChi}&type_bds_id=${typeBdsId}&use_for=${useFor}&tinh_id=${tinhId}&quan_id=${quanId}&top_size=${pageSize}`;
      } else {

        url = `${this.BASE_URL}/${slug}?level=${loaiDiaChi}&type_bds_id=${typeBdsId}&use_for=${useFor}&tinh_id=${tinhId}&top_size=${pageSize}`;
      }
    } else {
      url = `${this.BASE_URL}/${slug}?level=${loaiDiaChi}&type_bds_id=${typeBdsId}&use_for=${useFor}&top_size=${pageSize}`;
    }
    return url;
  }
}
