import { FormGroup } from '@angular/forms';


export function RoadWidthValidator(chieuRongDuongControlName: string, viTriControlName: string): any {
    return (formGroup: FormGroup) => {
      const tinhId = formGroup.controls.tinhId.value;
      const control = formGroup.controls[chieuRongDuongControlName];
      const width = Number(control.value);
      const viTri = formGroup.controls[viTriControlName];
      if (width) {
        if (`${tinhId}` === '1') {
          if ((viTri.value === 2) && (width < 3.5)) {
            control.setErrors({ roadWidthInvalidVT2: true });
          } else if ((viTri.value === 3) && ((width <= 2) || (width >= 3.5))) {
            control.setErrors({ roadWidthInvalidVT3: true });
          } else if ((viTri.value === 4) && (width > 2)) {
            control.setErrors({ roadWidthInvalidVT4: true });
          } else {
            control.setErrors(null);
          }
        } else if (`${tinhId}` === '59') {
          if ((viTri.value === 2) && (width < 5)) {
            control.setErrors({ roadWidthInvalidVT259: true });
          } else if ((viTri.value === 3) && ((width <= 3) || (width >= 5))) {
            control.setErrors({ roadWidthInvalidVT359: true });
          } else if ((viTri.value === 4) && (width > 3)) {
            control.setErrors({ roadWidthInvalidVT459: true });
          } else {
            control.setErrors(null);
          }
        } else {

        }

      } else {
          // Set required
        // control.setErrors({ required: true });
      }
    };
}
export function MatTienValidator(): any {
  return (formGroup: FormGroup) => {
    const matTien = formGroup.controls.soMatTien;
    const chieuRongMatDuong = formGroup.controls.chieuRongMatDuong;
    const chieuRongMatDuongHai = formGroup.controls.chieuRongMatDuongHai;
    const chieuRongMatDuongBa = formGroup.controls.chieuRongMatDuongBa;
    const viTri = formGroup.controls.viTriId;
    const viTriHai = formGroup.controls.viTriIdHai;
    const viTriBa = formGroup.controls.viTriIdBa;
    /* Required validate chieu rong mat duong va vi tri 1 */
    if (!chieuRongMatDuong.value) {
      chieuRongMatDuong.setErrors({ chieuRongMatDuongRequired: true });
    }
    if (chieuRongMatDuong.value && chieuRongMatDuong.value < 0.1) {
      chieuRongMatDuong.setErrors({ chieuRongMatDuongQuaBe: true });
    }
    if (!viTri.value) {
      viTri.setErrors({ viTriRequired: true });
    }
    /* Required validate chieu rong mat duong 2 va vi tri 2 */
    if (matTien.value > 1) {
      if (!chieuRongMatDuongHai.value) {
        chieuRongMatDuongHai.setErrors({ chieuRongMatDuongHaiRequired: true });
      }
      if (chieuRongMatDuongHai.value && chieuRongMatDuongHai.value < 0.1) {
        chieuRongMatDuongHai.setErrors({ chieuRongMatDuongHaiQuaBe: true });
      }
      if (!viTriHai.value) {
        viTriHai.setErrors({ viTriHaiRequired: true });
      }
    } else {
      chieuRongMatDuongHai.setErrors(null);
      viTriHai.setErrors(null);
    }
    /* Required validate chieu rong mat duong 3 va vi tri 3 */
    if (matTien.value > 2) {
      if (!chieuRongMatDuongBa.value) {
        chieuRongMatDuongBa.setErrors({ chieuRongMatDuongBaRequired: true });
      }
      if (chieuRongMatDuongBa.value && chieuRongMatDuongBa.value < 0.1) {
        chieuRongMatDuongBa.setErrors({ chieuRongMatDuongBaQuaBe: true });
      }
      if (!viTriBa.value) {
        viTriBa.setErrors({ viTriBaRequired: true });
      }
    } else {
      chieuRongMatDuongBa.setErrors(null);
      viTriBa.setErrors(null);
    }
  };
}
export function LandTypeValidator(): any {
  return (formGroup: FormGroup) => {
    const dienTich = formGroup.controls.dienTich;
    if (!dienTich.value) {
      dienTich.setErrors({ required: true });
      return;
    }
    if (dienTich.value < 10) {
      dienTich.setErrors({ min: true });
      return;
    }
    if (dienTich.value > 999999) {
      dienTich.setErrors({ max: true });
      return;
    }

    const CLNControl = formGroup.controls.dienTichDatCLN;
    const HNKControl = formGroup.controls.dienTichDatHNK;
    const ODTControl = formGroup.controls.dienTichDatODT;
    const SKCControl = formGroup.controls.dienTichDatSKC;
    const TMDControl = formGroup.controls.dienTichDatTMD;
    const VLKControl = formGroup.controls.dienTichDatVLK;

    let tong = 0;
    if (!CLNControl.value) {
      tong += CLNControl.value;
    }
    if (
      !CLNControl.value &&
      !HNKControl.value &&
      !ODTControl.value &&
      !SKCControl.value &&
      !TMDControl.value &&
      !VLKControl.value
      ) {
        dienTich.setErrors(null);
        return;
      }
    if (
      (Number(CLNControl.value) +
      Number(HNKControl.value) +
      Number(ODTControl.value) +
      Number(SKCControl.value) +
      Number(TMDControl.value) +
      Number(VLKControl.value))
      === Number(dienTich.value)
      ) {
        dienTich.setErrors(null);
    } else {
      dienTich.setErrors({ checkSumError: true });
    }

    // ['dienTichDatODT', 'dienTichDatSKC', 'dienTichDatCLN', 'dienTichDatHNK', 'dienTichDatTMD', 'dienTichDatVLK']
  };
}

export function SoTangValidator(): any {
    return (formGroup: FormGroup) => {
        const soTangControl = formGroup.controls.soTang;
        const soTang = Number(soTangControl.value);
        const loaiNhaControl = formGroup.controls.loaiNhaId;
        const loaiNha = Number(loaiNhaControl.value);
        if (loaiNha === 14) {

        } else {
          if (!soTang) {
            soTangControl.setErrors({ stageRequired: true });
            return;
          }
        }

        if (loaiNha === 1) {
            if (soTang < 3 || soTang > 5) {
                soTangControl.setErrors({ invalid3To5Stage: true });
            }
        } else if (loaiNha === 2) {
            if (soTang < 6) {
                soTangControl.setErrors({ invalidOver6Stage: true });
            }
        } else {
            soTangControl.setErrors(null);
        }
    };
}

export function DienTichSuDungValidator(): any {
  return (formGroup: FormGroup) => {
      const dienTichSuDungControl = formGroup.controls.dienTichSuDung;
      const dienTichSuDung = Number(dienTichSuDungControl.value);
      const loaiNhaControl = formGroup.controls.loaiNhaId;
      const loaiNha = Number(loaiNhaControl.value);
      if (loaiNha === 14) {

      } else {
        if (!dienTichSuDung) {
          dienTichSuDungControl.setErrors({ dienTichSuDungRequired: true });
          return;
        }
      }
  };
}

export function EmailValidator(): any {
    return (formGroup: FormGroup) => {
      const dongYNhanEmail = formGroup.controls.dongYNhanEmail;
      const regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      const emailControl = formGroup.controls.email;
      if (!dongYNhanEmail.value) {
        emailControl.setErrors(null);
        return;
      }
      if (!regEmail.test(emailControl.value)) {
          emailControl.setErrors({ invalidEmail: true });
      } else {
          emailControl.setErrors(null);
      }
    };
}
