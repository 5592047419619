<div *ngIf="seoService.onBrowser">
    <div>
    <toaster-container [toasterconfig]="configToaster"></toaster-container>
    <div *ngIf="toggle.navbarVisible" id="cen-navbar">
        <!-- <router-outlet name="navbar"></router-outlet> -->
        <!-- <app-navbar></app-navbar> -->
    </div>
    <app-navbar-csr class="hidden md:block" *ngIf="navbarCsrService.show && toggle.headerVisible"></app-navbar-csr>
    <div class="container-fluid" id="cen-content" style="background-color: #FFFFFF;">
       <!-- <div class="card jh-card" style="background-color: #FFFFFF">-->
            <div class="cen-main" *ngIf="toggle.navbarVisible"></div>
            <router-outlet></router-outlet>
            <router-outlet name="popup"></router-outlet>
        <!--</div>-->
    </div>
    <div *ngIf="toggle.footerVisible" class="d-none d-md-block">
        <app-footer></app-footer>
    </div>
    
    </div>
</div>
<app-navbar-mobile *ngIf="seoService.onBrowser && seoService.onMobile"  class="block md:hidden" [stylebar]="0"></app-navbar-mobile>

<div *ngIf="!seoService.onBrowser">
    <router-outlet></router-outlet>
</div>

<app-popup mode="FULLHEIGHT" desktopWidth="40" mobileWidth="95" [(open)]="dangKyNhanBaoCaoService.formIsOpen">
    <ng-template app-popup-header>Đăng ký nhận báo cáo</ng-template>
    <ng-template app-popup-body>
        <app-dang-ky-nhan-bao-cao></app-dang-ky-nhan-bao-cao>
    </ng-template>
</app-popup>

<!-- <app-popup mode="FULLHEIGHT" desktopWidth="60" mobileWidth="90" [desktopHeight]="'90'" [(open)]="thamDinhGiaService.openPopupYeuCauThamDinhGia">
    <ng-template app-popup-body>
        <app-tham-dinh-gia></app-tham-dinh-gia>
    </ng-template>
</app-popup> -->

<div *ngIf="seoService.onBrowser">
    <app-popup desktopWidth="30" mobileWidth="90" [(open)]="appErrorService.popup.open">
        <ng-template app-popup-header>{{appErrorService.popup.header}}</ng-template>
        <ng-template app-popup-body>
            <div [innerHTML]="appErrorService.popup.body"></div>
        </ng-template>
    </app-popup>
</div>
<div *ngIf="seoService.onBrowser">
    <app-popup desktopWidth="40" mobileWidth="90" padding="0" paddingBottom="0" [(open)]="interceptorService.limitAccessPopup.open" (openChange)="unviewBds($event)">
        <ng-template app-popup-header>{{interceptorService.limitAccessPopup.header}}</ng-template>
        <ng-template app-popup-body>
            <div class="pt-0 pb-5 pl-5 pr-5">
                <span>Bạn tạm thời bị giới hạn truy cập do thực hiện quá nhiều yêu cầu trong thời gian ngắn. Hãy chứng tỏ
                    bạn không phải là robot.</span>
                <div>{{interceptorService.limitAccessPopup.body}}</div>
                <re-captcha (resolved)="interceptorService.resolvedCaptcha($event)" siteKey="6LeB0bcUAAAAABSKoSEZU17Z34DtfnHr_e7wqdYA"></re-captcha>
            </div>
        </ng-template>
    </app-popup>
</div>
<div *ngIf="seoService.onBrowser">
    <app-popup desktopWidth="40" mobileWidth="90" padding="0" paddingBottom="0" [(open)]="interceptorService.throwError">
        <ng-template app-popup-header>{{interceptorService.errorTitle}}</ng-template>
        <ng-template app-popup-body>
            <div class="p-3 flex justify-center" [innerHTML]="interceptorService.errorContent"></div>
        </ng-template>
        <ng-template app-popup-footer>
            <button class="btn btn-cen" (click)="interceptorService.throwError = false">Đồng ý</button>
        </ng-template>
    </app-popup>
</div>

