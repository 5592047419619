import { HttpStatus } from './../../map/uoc-luong-gia/uoc-luong-gia.const';
import { Router } from '@angular/router';
import { BdsDetailRequestType } from './../ban-do.model';
import { Helpers } from './../../../shared/util/helpers';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { catchError, map, delay } from 'rxjs/operators';
import { of } from 'zen-observable';
import { FavouriteBdsService } from '../../user-activity/favourite-BDS/favourite-BDS.service';
import { SnowPlowTrackerType } from '../../../shared/util/interface';
import { SnowplowService } from '../../../shared/util/tracking/snowplowService';

const loaiHinhAnh = {
  ANH_TONG_QUAN: 'Ảnh tổng quan',
  ANH_DIA_CHI: 'Ảnh địa chỉ',
  ANH_DUONG_TRUOC_MAT: 'Ảnh đường trước mặt'
};

@Injectable({
  providedIn: 'root'
})
export class SearchResultDetailService {
  public infoBds: any;
  public listImageNew: any;
  public status: HttpStatus;

  snowPlowTracker: SnowPlowTrackerType;
  
  public loadingManyInfo: boolean;
  constructor(
    private http: HttpClient,
    private helpers: Helpers,
    private router: Router,
    private favouriteBdsService: FavouriteBdsService,
    private snowplowService: SnowplowService
  ) {
    this.status = {
      loading: false,
      error: false,
      message: ''
    };
  }
  getInfoBds(bdsDetailRequest: BdsDetailRequestType): void {
    this.status.loading = true;
    this.status.error = false;
    this.status.message = '';
    this.infoBds = null;
    // console.log('this.infoBds123',this.infoBds)
    const param = bdsDetailRequest.src === 'external' ? '?src=' + bdsDetailRequest.src : '';
    this.http.get<any>(`${environment.API.SERVER_API_DATA_COLLECTION}/api/dia-chi-cu-the/get-info/${bdsDetailRequest.id}${param}`)
      .pipe(
        map(res => {
          let list_images = [];
          if (res && res.payload && res.payload.data) {
            const bds = res.payload.data.batDongSanDTO;
            // console.log('Input_ ', bds);
            const congTrinh = res.payload.data.congTrinhSubmitDTOSet[0].congTrinhDTO;
            if (congTrinh.urlAnhMatTien) {
              list_images.push(environment.API.SERVER_IMG + congTrinh.urlAnhMatTien.replace('image_data_watermark', 'image_data_watermark'));
            }
            if (congTrinh.urlAnhTongThe) {
              list_images.push(environment.API.SERVER_IMG + congTrinh.urlAnhTongThe.replace('image_data_watermark', 'image_data_watermark'));
            }
            if (bds.dlUrlAnhMatBang) {
              list_images.push(environment.API.SERVER_IMG + bds.dlUrlAnhMatBang.replace('image_data_watermark', 'image_data_watermark'));
            }
            if (bds.dlUrlAnhDuongPhiaTruoc) {
              list_images.push(environment.API.SERVER_IMG + bds.dlUrlAnhDuongPhiaTruoc.replace('image_data_watermark', 'image_data_watermark'));
            }
            /* Tạo đường dẫn đầy đủ cho SEO */
            const titleSEO = this.helpers.buildTitle({ "soNha": congTrinh.soNha, "dlNgachHem": bds["dlNgachHem"], "dlNgo": bds["dlNgo"], "duong": bds["duong"] },
              ["soNha", "dlNgachHem", "dlNgo", "duong"], ["id", "tienTo", "ten"]);
            const areaLinks = this.helpers.buildBreadcrumbCustomKey(bds, ['duong', 'phuongXa', 'quanHuyen', 'tinh'], ["tienTo", "ten", "id"]);

            const slug = this.helpers.createSlugFromTitle(titleSEO + ', - ' + areaLinks['phuongXa'].ten + '-' + areaLinks['quanHuyen'].ten + '-' + areaLinks['tinh'].ten + '-' + bds.id);

            this.router.navigate(['/ban-do'], {
              queryParams: {
                'tinh-id': bds && bds.tinh && bds.tinh.id,
                'quan-id': bds && bds.quanHuyen && bds.quanHuyen.id
              },
              queryParamsHandling: 'merge'
            });

            

            const {title, area} = this.generateTitleAndArea(bds, congTrinh);
            return {
              ...res.payload.data,
              ...congTrinh,
              ...bds,
              bdsId: bds.id,
              list_images,
              diaChi: this.generateDiaChi(bds, congTrinh),
              slug,
              batDongSan: bds,
              congTrinh: congTrinh,
              title,
              area,
              // manyAddress: manyAddress
            }
          }
          return null;
        }),
        catchError(err => {
          console.log('err', err)
          this.status.error = true;
          this.status.message = err.message;
          return of(null);
        })
      )
      .subscribe(res => {
        this.status.loading = false;
        console.log('res: ', res);
        this.infoBds = res;

        const listImage = JSON.parse(JSON.stringify(res.congTrinhSubmitDTOSet[0].congTrinhDTO.danhSachAnh));
        this.listImageNew = [];
        listImage.forEach(item => {
          this.listImageNew.push({
            src: `${environment.API.SERVER_IMG}${item.url}`,
            thumb: `${environment.API.SERVER_IMG}${item.url}`,
            subHtml: loaiHinhAnh[item.loaiHinhAnh],
          });
        });

        this.snowPlowTracker = {
          schema_type: 'bds_map_tracker',
          page_url: this.router.url,
          timestamp: '' + new Date().getTime(),
          finger: this.snowplowService.getFinger(),
          referer_url: this.snowplowService.getRefererUrl(),
          user_id: this.snowplowService.getUserId(),
          user_name: this.snowplowService.getUsername(),
          full_name: this.snowplowService.getFullname(),
          bds_id: this.infoBds?.batDongSanDTO?.id,
          bds_name: `${this.infoBds?.congTrinh?.soNha}, ${this.infoBds?.dlDuong},  ${this.infoBds?.dlXa},  ${this.infoBds?.dlHuyen},  ${this.infoBds?.dlTinh}`,
          //'Số nhà ' + ct.soNha + (bds.dlDuong && ', ' + bds.dlDuong) + (bds.dlXa && ', ' + bds.dlXa) + (bds.dlHuyen && ', ' + bds.dlHuyen) + (bds.dlTinh && ', ' + bds.dlTinh),
          street_id: this.infoBds?.batDongSanDTO?.duong?.id,
          ward_id: this.infoBds?.batDongSanDTO?.phuongXa?.id,
          district_id: this.infoBds?.batDongSanDTO?.quanHuyen?.id,
          city_id: this.infoBds?.batDongSanDTO?.tinh?.id,
          street_name: `${this.infoBds?.batDongSanDTO?.duong?.tienTo || ''} ${this.infoBds?.batDongSanDTO?.duong?.ten || ''}`,
          ward_name: `${this.infoBds?.batDongSanDTO?.phuongXa?.tienTo || ''} ${this.infoBds?.batDongSanDTO?.phuongXa?.ten || ''}`,
          district_name: `${this.infoBds?.batDongSanDTO?.quanHuyen?.tienTo || ''} ${this.infoBds?.batDongSanDTO?.quanHuyen?.ten || ''}`,
          city_name: `${this.infoBds?.batDongSanDTO?.tinh?.tienTo || ''} ${this.infoBds?.batDongSanDTO?.tinh?.ten || ''}`,
        };
        this.snowplowService.trackOnSelect(this.snowPlowTracker);
      });
  }
  generateTitleAndArea(bds: any, congTrinh: any) {
    let title = congTrinh.soNha ? congTrinh.soNha : '';
    if ((bds && bds.tinh && bds.tinh.id) === 59) {
      title = 'Số ' + title;
    } else {
      if (bds.dlNgo) {
        title = 'Số ' + title;
        title += ' ngõ ' + bds.dlNgo;
      }
      title += bds.dlNgachHem ? '/' + bds.dlNgachHem : '';
    }

    if (bds.duong) {
      if (bds.duong.ten.includes(' ')) {
        let themTienToDuong = '';
        if (!isNaN(parseInt(bds.duong.ten[0]))) {
          themTienToDuong = bds.duong.tienTo;
        }
        title += ' ' + themTienToDuong + bds.duong.ten;
      } else {
        title += ' ' + bds.duong.tienTo + ' ' + bds.duong.ten;
        title = '' + title;
      }
    }
    let area = '';
    ['phuongXa', 'quanHuyen', 'tinh'].forEach(ten => {
      if (bds[ten]) {
        if (area.length > 0) {
          area += ','
        }
        if (bds[ten].ten.includes(' ')) {
          area += ' ' + bds[ten].ten;
        } else {
          area += ' ' + bds[ten].tienTo + ' ' + bds[ten].ten
        }
      }
    });
    return { title, area };
  }


  generateDiaChi(bds, congTrinh) {
    let diaChi = '';
    if (congTrinh.soNha) {
      diaChi += `Số ${congTrinh.soNha}`;
      // if (Number.isInteger(+congTrinh.soNha)) {
      // } else {
      //   diaChi += congTrinh.soNha;
      // }
    }
    if (bds.dlNgo) {
      diaChi += `, Ngõ ${bds.dlNgo}`;
      // if (Number.isInteger(+bds.dlNgo)) {
      //   diaChi += `, Ngõ ${bds.dlNgo}`;
      // } else {
      //   diaChi += `, ${bds.dlNgo}`;
      // }
    }
    if (bds.dlNgachHem) {
      diaChi += `, Ngách ${bds.dlNgachHem}`;
      // if (Number.isInteger(+bds.dlNgachHem)) {
      // } else {
      //   diaChi += `, ${bds.dlNgachHem}`;
      // }
    }
    if (bds.duong) {
      diaChi += `, ${bds.duong.tienTo} ${bds.duong.ten}`;
    }
    if (bds.phuongXa) {
      if (Number.isInteger(+bds.phuongXa.ten)) {
        diaChi += `, ${bds.phuongXa.tienTo} ${bds.phuongXa.ten}`;
      } else {
        diaChi += ', ' + bds.phuongXa.ten;
      }
    }
    // if (bds.duAn) {
    //   if (bds.duAn.trangThai !== "KHONG_PHE_DUYET") {
    //     if (Number.isInteger(+bds.duAn.ten)) {
    //       diaChi += `, ${bds.duAn.tienTo} ${bds.duAn.ten}`;
    //     } else {
    //       diaChi += ', ' + bds.duAn.ten;
    //     }
    //   }
    // }
    if (bds.quanHuyen) {
      if (Number.isInteger(+bds.quanHuyen.ten)) {
        diaChi += `, ${bds.quanHuyen.tienTo} ${bds.quanHuyen.ten}`;
      } else {
        diaChi += ', ' + bds.quanHuyen.ten;
      }
    }
    if (bds.tinh) {
      diaChi += ', ' + bds.tinh.ten;
    }
    return diaChi;
  }
  viewBds(item) {
    this.router.navigate(['/ban-do'], {
      queryParams: {
        'bds-id': item.id
      },
      queryParamsHandling: 'merge'
    })
  }
}
