import { Component, OnInit } from '@angular/core';
import { SearchSuggestComponent } from './../search-suggest.component';

@Component({
  selector: 'app-search-suggest-mobile',
  templateUrl: './search-suggest-mobile.component.html',
  styleUrls: ['./search-suggest-mobile.component.scss']
})
export class SearchSuggestMobileComponent extends SearchSuggestComponent {

}
