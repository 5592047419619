import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { OnlyDayDatePickerService } from './only-day-date-picker.service';

@Component({
  selector: 'app-only-day-date-picker',
  templateUrl: './only-day-date-picker.component.html',
  styleUrls: ['./only-day-date-picker.component.scss']
})
export class OnlyDayDatePickerComponent implements OnInit {
  @Input() chooseDay: any;
  @Output() dateChange: EventEmitter<any> = new EventEmitter<any>();
  public isViewTooltip: boolean
  constructor(
    public datePickerService: OnlyDayDatePickerService
  ) {
    this.isViewTooltip = false
  }

  ngOnInit(): void { }
  changeDate(day) {
    this.chooseDay = day + 1;
    this.dateChange.emit(this.chooseDay);
  }
}
