import { Component, OnInit, AfterViewInit, Renderer2, ElementRef } from '@angular/core';
import { EMAIL_NOT_FOUND_TYPE } from 'src/app/shared';
import { PasswordResetInitService } from './password-reset-init.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'app-password-reset-init',
    styleUrls: ['../../account.scss'],
    templateUrl: './password-reset-init.component.html'
})
export class PasswordResetInitComponent implements OnInit, AfterViewInit {
    error: string;
    errorEmailNotExists: string;
    resetAccount: any;
    success: string;
    // submitCount = 0;
    public reactiveForm: FormGroup = new FormGroup({
        recaptchaReactive: new FormControl(null, Validators.required)
    });
    constructor(private passwordResetInitService: PasswordResetInitService, private elementRef: ElementRef, private renderer: Renderer2) {}

    ngOnInit() {
        this.resetAccount = {};
        // this.submitCount = 0;
    }

    ngAfterViewInit() {
        // this.renderer.invokeElementMethod(this.elementRef.nativeElement.querySelector('#email'), 'focus', []);
    }

    requestReset() {
        this.error = null;
        this.errorEmailNotExists = null;
        // this.submitCount = this.submitCount + 1;
        this.passwordResetInitService.save(this.resetAccount.email).subscribe(
            () => {
                this.success = 'OK';
            },
            response => {
                this.success = null;
                if (response.status === 400 && response.error.type === EMAIL_NOT_FOUND_TYPE) {
                    this.errorEmailNotExists = 'ERROR';
                } else {
                    this.error = 'ERROR';
                }
            }
        );
    }
}
