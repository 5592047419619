import { AfterViewInit, QueryList, ContentChildren, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { Component, OnInit, Directive, TemplateRef, ContentChild } from '@angular/core';

@Directive({ selector: 'ng-template[content-tabs-label]' })
export class ContentTabsLabelDirective {
  @Input() value: any;
  @Input() tabClass: string = 'tab';
  @Input() disabled: boolean = false;
  constructor(public templateRef: TemplateRef<any>) { }
}

@Component({
  selector: 'app-content-tabs',
  templateUrl: './content-tabs.component.html',
  styleUrls: ['./content-tabs.component.scss']
})
export class ContentTabsComponent implements OnInit, AfterViewInit {
  @Input() tabsClass: string = 'tabs';

  @Input() tabIndex: number;
  @Output() tabIndexChange: EventEmitter<number> = new EventEmitter<number>();

  @Input() tabValue: any;
  @Output() tabValueChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() tabChange: EventEmitter<any> = new EventEmitter<any>();
  @ContentChildren(ContentTabsLabelDirective) contentTabsLabel!: QueryList<ContentTabsLabelDirective>;
  constructor() {
   }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
    // console.log('contentTabsLabel', this.contentTabsLabel);
  }

  // get serializedPanes(): string {
    // return this.contentTabsLabel ? this.contentTabsLabel.map(p => p.id).join(', ') : '';
  // }
  selectTab(index: number, value: any): void {
    console.log('selectTab', index, this.tabIndex);
    this.tabIndex = index;
    this.tabValue = value;
    this.tabIndexChange.emit(index);
    this.tabValueChange.emit(value);
    this.tabChange.emit({
      index: index,
      value: value
    });
  }
}
