
import { Injectable } from '@angular/core';
import { SearchHttpService } from '../../shared/http/search-http.service';
import { Subject, of } from 'rxjs';
import { BieuDoTyTrongService } from './bieu-do-ty-trong-moi-gioi/bieu-do-ty-trong-moi-gioi.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ThongTinMoiGioiService {
  public idKey: any;
  public highChartsLoaded: Subject<boolean>;
  public loaiBds: any;
  public data: any;
  public giaChart: any;
  public soTinChart: any;
  public listTimeLine: any;
  public serialPrice: any;
  public serialItem: any;
  public phanKhucHoatDong: any;
  public khuVucHoatDong: any;
  public giaTriChaoBanBdsTrungBinh: any;

  public categories: any;
  public userInfo: any;

  public selectedTab: number = 0;
  public selectedTabSaleRent: number = 0;
  public human: Subject<any>;
  public agentName: string;
  public tinRaoVaGiaParams: any;
  public soTinRaoVaGiaMoiGioiLoading: boolean;
  public soTinRaoVaGiaMoiGioiError: boolean;

  public canhBaoChuyenSangThue: boolean = false;
  public canhBaoChuyenSangThueMessage: string = '';
  public canhBaoChuyenSangThueButtonText: string = '';
  public canhBaoChuyenSangThueTab: number = 1;

  public datePicker: any;

  public startDate: any = {
    month: undefined,
    year: undefined
  };
  public endDate: any = {
    month: undefined,
    year: undefined
  };
  public isNavbarFixed: boolean = true;
  dataChart: any;
  dataChartInit: any;
  dataChartSoTinInit: any;
  dataSoTinChart: {};
  constructor(
    public searchHttpService: SearchHttpService
  ) {
    this.highChartsLoaded = new Subject();
    this.data = {};
    this.loaiBds = [
      { field: 'BietThuLienKe', color: '#264653', name: 'Biệt thự liền kề', index: 0, check: false },
      { field: 'CanHoChungCu', color: '#606c38', name: 'Căn hộ chung cư', index: 1, check: false },
      { field: 'Dat', color: '#2a9d8f', name: 'Đất', index: 2, check: false },
      { field: 'KhoXuong', color: '#283618', name: 'Kho xưởng', index: 3, check: false },
      { field: 'KiOt', color: '#e9c46a', name: 'Kiot', index: 4, check: false },
      { field: 'LoaiKhac', color: '#dda15e', name: 'Loại khác', index: 5, check: false },
      { field: 'NhaMatPho', color: '#f4a261', name: 'Nhà mặt phố', index: 6, check: false },
      { field: 'NhaRieng', color: '#bc6c25', name: 'Nhà riêng', index: 7, check: false },
      { field: 'NhaTro', color: '#e76f51', name: 'Nhà trọ', index: 8, check: false },
      { field: 'TrangTrai', color: '#e63946', name: 'Trang Trại', index: 9, check: false },
      { field: 'VanPhong', color: '#457b9d', name: 'Văn Phòng', index: 10, check: false }
    ];

    this.tinRaoVaGiaParams = {
      keyId: null,
      fromMonth: null,
      toMonth: null,
      fromYear: null,
      toYear: null,
      useFor: 'SALE'
    };


    // console.log('this.datePicker', this.datePicker);
    this.human = new Subject();
    const dataChartInit = [];
    this.loaiBds.forEach((element, index) => {
      dataChartInit.push({
        id: element.field + '' + index,
        color: element.color,
        name: element.name,
        data: [0],
        stack: 'min'
      });
      dataChartInit.push({
        linkedTo: element.field + '' + index,
        color: element.color,
        name: element.name,
        data: [0],
        stack: 'avg'
      });
      dataChartInit.push({
        linkedTo: element.field + '' + index,
        color: element.color,
        name: element.name,
        data: [0],
        stack: 'max'
      });
    });
    this.dataChartInit = {

      chart: {
        type: 'column',
        height: 500
      },
      title: {
        text: undefined
      },
      xAxis: {
        categories: ['Apples'],
        title: {
          text: 'Thời gian'
        },
        labels: {
          y: 30
        }
      },
      yAxis: {
        allowDecimals: true,
        min: 0,
        title: {
          text: 'Tổng giá trong các tin rao'
        },
        stackLabels: {
          enabled: true,
          verticalAlign: 'bottom',
          crop: false,
          overflow: 'none',
          y: 20,
          formatter: function () {
            return this.stack;
          },
          style: {
            fontWeight: 'normal',
            color: 'gray'
          }
        }
      },
      tooltip: {
        formatter: function () {
          return '<b> Tháng ' + this.x + '</b><br/>' +
            this.series.name + ': ' + Math.round(this.y * 100) / 100 + '' +
            '/' + Math.round(this.point.stackTotal * 100) / 100 + '';
        }
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          // dataLabels: {
          //   enabled: true
          // }
        }
      },
      series:  dataChartInit
    };



    const dataChartSoTinInit = [];
    this.loaiBds.forEach((element, index) => {
      dataChartSoTinInit.push({
        // id: element.field + '' + index,
        color: element.color,
        name: element.name,
        data: [0, 0],
        // stack: 'min'
      });
    });
    this.dataChartSoTinInit = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'column'
      },
      title: {
        text: 'Browser market shares in January, 2018'
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: true
        },
        column: {
          stacking: 'normal'
        }
      },
      series: dataChartSoTinInit
    }
  }

  public tinRaoVaGiaUpdateParamsAndGetData(paramsUpdate: any): void {
    for (const item of Object.keys(paramsUpdate)) {
      this.tinRaoVaGiaParams[item] = paramsUpdate[item];
    }
    this.soTinRaoVaGiaMoiGioiLoading = true;
    this.soTinRaoVaGiaMoiGioiError = false;
    this.searchHttpService.soTinRaoVaGiaMoiGioi(this.tinRaoVaGiaParams)
      .pipe(catchError(error => {
        this.soTinRaoVaGiaMoiGioiError = true;
        this.soTinRaoVaGiaMoiGioiLoading = false;
        return of(null);
      }))
      .subscribe(res => {
        this.chartDataParse(res);
        this.soTinRaoVaGiaMoiGioiLoading = false;
      });
  }

  public chartDataParse(thongTin: any): void {
    console.log('thongTin', thongTin);
    const agentInfo = thongTin && thongTin.payload && thongTin.payload.data && thongTin.payload.data[0];
    this.agentName = agentInfo && agentInfo.fullName || '';
    this.human.next({
      name: agentInfo && agentInfo.fullName,
      phone: agentInfo && agentInfo.phone,
      email: agentInfo && agentInfo.email
    });
    this.listTimeLine = thongTin && thongTin.payload && thongTin.payload.data;
    if (!this.listTimeLine) {
      this.canhBaoChuyenSangThue = true;
      if (this.selectedTabSaleRent === 0) {
        this.canhBaoChuyenSangThueMessage = 'Không tìm thấy dữ liệu thị trường bán của môi giới này. Có thể môi giới này chỉ hoạt động ở thị trường cho thuê.';
        this.canhBaoChuyenSangThueButtonText = 'Chuyển sang tab thuê';
        this.canhBaoChuyenSangThueTab = 1;
      } else if (this.selectedTabSaleRent === 1) {
        this.canhBaoChuyenSangThueMessage = 'Không tìm thấy dữ liệu thị trường cho thuê của môi giới này. Có thể môi giới này chỉ hoạt động ở thị trường bán.';
        this.canhBaoChuyenSangThueButtonText = 'Chuyển sang tab bán';
        this.canhBaoChuyenSangThueTab = 0;
      }
    }
    this.userInfo = this.listTimeLine && this.listTimeLine[0];

    this.categoriesGet(this.listTimeLine);
    this.serialPriceGet(this.listTimeLine, 'price');
    this.serialItemGet(this.listTimeLine);

    this.getGiaTriChaoBanTBThangCuoi(this.listTimeLine, 'price');
    this.khuVucQuanTamGet(this.listTimeLine);
    console.log('this.khuVucHoatDong', this.khuVucHoatDong);

    this.seriesPriceUpdateChart('price');
    this.seriesItemUpdateChart();
  }

  public categoriesGet(listTimeLine: any): void {
    this.categories = [];
    if (listTimeLine) {
      listTimeLine.forEach(item => {
        this.categories.push(item.month + '/' + item.year);
      });
    }
  }
  public serialPriceGet(listTimeLine: any, priceField: string): void {
    this.serialPrice = {};
    this.loaiBds.forEach((element, index) => {
      this.serialPrice[element.field] = {
        min: [],
        avg: [],
        max: []
      };
      if (listTimeLine) {
        listTimeLine.forEach(item => {
          const price = JSON.parse(item['statistics' + element.field] || null);
          this.serialPrice[element.field].min.push(price && price[0][priceField + '_min'] || 0);
          this.serialPrice[element.field].avg.push(price && price[0][priceField + '_average'] || 0);
          this.serialPrice[element.field].max.push(price && price[0][priceField + '_max'] || 0);
        });
      }
    });
  }
  public khuVucQuanTamGet(listTimeLine: any): void {
    this.khuVucHoatDong = [];
    this.loaiBds.forEach((element, index) => {
      // this.serialItem[element.field] = [];

      if (listTimeLine) {
        // this.khuVucHoatDong = [];
        listTimeLine.forEach(item => {
          const khuVuc = JSON.parse(item['kvqt' + element.field] || null);
          // this.serialItem[element.field].push(soTin);
          const cityName = khuVuc && khuVuc[0] && khuVuc[0].city_ten;
          const districtName = khuVuc && khuVuc[0] && khuVuc[0].district_ten;
          const name = ((districtName && districtName + ', ') + cityName) || null;
          if (name && !this.khuVucHoatDong.includes(name)) {
            this.khuVucHoatDong.push(name);
          }
        });
      }
    });
  }
  public serialItemGet(listTimeLine: any): void {
    this.serialItem = {};
    this.phanKhucHoatDong = [];
    this.loaiBds.forEach((element, index) => {
      this.serialItem[element.field] = [];
      let phanKhucHienTaiCount = 0;
      if (listTimeLine) {
        listTimeLine.forEach(item => {
          const soTin = item['count' + element.field];
          this.serialItem[element.field].push(soTin);
          phanKhucHienTaiCount += soTin;
        });
        this.phanKhucHoatDong.push({ name: element.name, count: phanKhucHienTaiCount });
      }
    });
  }
  getGiaTriChaoBanTBThangCuoi(listTimeLine: any, priceField: string): void {
    this.giaTriChaoBanBdsTrungBinh = [];
    if (listTimeLine) {
      this.loaiBds.forEach((element, index) => {
        const price = JSON.parse(listTimeLine[listTimeLine.length - 1]['statistics' + element.field] || null);
        this.giaTriChaoBanBdsTrungBinh.push(price && price[0][priceField + '_average'] || 0);

      });
      this.giaTriChaoBanBdsTrungBinh.sort((a, b) => b - a);
      console.log('this.giaTriChaoBanBdsTrungBinh', this.giaTriChaoBanBdsTrungBinh);
      this.giaTriChaoBanBdsTrungBinh = [
        Math.round(this.giaTriChaoBanBdsTrungBinh[1] * 100) / 100,
        Math.round(this.giaTriChaoBanBdsTrungBinh[0] * 100) / 100
      ];
    }
  }
  public seriesPriceUpdateChart(priceField: string): void {
    const series = [];
    this.loaiBds.forEach((element, index) => {
      series.push({
        name: element.name,
        data: this.serialPrice[element.field].min,
        stack: 'min'
      },
        {
          name: element.name,
          data: this.serialPrice[element.field].avg,
          stack: 'avg'
        },
        {
          name: element.name,
          data: this.serialPrice[element.field].max,
          stack: 'max'
        });
    });
    this.dataChart = {};
    this.dataChart = {
      data: series,
      categories: this.categories,
      title: 'Biểu đồ thống kê giá theo phân khúc',
      subtitle: 'Biểu đồ giá nhỏ nhất, trung bình, lớn nhất của các phân khúc bất động sản theo thời gian',
      label: 'Tổng giá trong các tin rao'
    }
  }
  public seriesItemUpdateChart(): void {
    const series = [];
    const categories = [];
    this.loaiBds.forEach((element, index) => {
      series.push({
        name: element.name,
        data: this.serialItem[element.field],
      });
    });
    this.dataSoTinChart = {};
    this.dataSoTinChart = {
      data: series,
      categories: this.categories,
      title: 'Biểu đồ thống kê lượng tin rao theo phân khúc',
      subtitle: 'Biểu đồ giá nhỏ nhất, trung bình, lớn nhất của các phân khúc bất động sản',
      label: 'Số tin'
    }
  }

  tabChange($event): void {
    console.log($event);
    if ($event === 0) {
      this.serialPriceGet(this.listTimeLine, 'price');
      this.seriesPriceUpdateChart('price');
    } else if ($event === 1) {
      this.serialPriceGet(this.listTimeLine, 'price_per_meter');
      this.seriesPriceUpdateChart('price_per_meter');
    } else {

    }
  }

}
