import { PopupModule } from './shared/popup/popup.module';
import { PreventEnterNegativeNumberDirective } from './shared/directives/prevent-enter-negative-number.directive';
import { ButtonModule } from './layouts/components/button/button.model';
import { DropdownMenuModule } from './shared/dropdown-menu/dropdown-menu.module';
import { ContentTabsLabelDirective, ContentTabsComponent } from './shared/content-tabs/content-tabs.component';
import { CommonModule } from '@angular/common';
import { IconDirective } from './shared/directives/icon.directive';
import { HasAnyAuthorityDirective } from './shared/auth/has-any-authority.directive'
import { NgModule } from '@angular/core';
import { ClickOutSideDirective } from './shared/directives/click-out-side.directive';
import { DatePickerModule } from './shared/date-picker/date-picker.module';
import { LoadingComponent } from './shared/loading/loading.component'
import { DatePickerMobileComponent } from './shared/date-picker-mobile/date-picker-mobile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    DropdownMenuModule,
    DatePickerModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    PopupModule
  ],
  exports: [
    DropdownMenuModule,
    ButtonModule,
    DatePickerModule,
    IconDirective,
    ClickOutSideDirective,
    PreventEnterNegativeNumberDirective,
    HasAnyAuthorityDirective,
    ContentTabsLabelDirective, ContentTabsComponent, LoadingComponent, DatePickerMobileComponent
  ],
  declarations: [
    IconDirective,
    ClickOutSideDirective,
    PreventEnterNegativeNumberDirective,
    HasAnyAuthorityDirective,
    ContentTabsLabelDirective, ContentTabsComponent, LoadingComponent, DatePickerMobileComponent
  ]
})
export class AppShareModule { }
