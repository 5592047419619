<!-- <div *ngIf="addressTree && addressTree.idSelected">{{addressTree.idSelected.value | json}}</div> -->
<div class="search">
    <div class="container px-0">
        <div class="row">
            <div class="col-4 px-0">
                <app-search-suggest-small [type]="type" [placeholder]="placeholder"></app-search-suggest-small>
            </div>
            <div class="col-8 px-0 d-flex justify-content-end">
                <div class="chon-khu-vuc mr-2">
                    <app-dropdown-menu clickOutsideTechType="BACK_DROP">
                        <ng-template app-dropdown-menu-label>
                            <span *ngIf="addressTree && addressTree.lastSelected">{{addressTree.lastSelected.name}}</span>
                            <span *ngIf="!(addressTree && addressTree.lastSelected)">Vui lòng chọn</span>
                        </ng-template>
                        <ng-template app-dropdown-menu-items>
                            <form [formGroup]="addressTree.idSelected">
                                <ng-select 
                                    [items]="addressTree.list.tinhThanh"
                                    bindValue="id"
                                    bindLabel="ten"
                                    formControlName="tinhThanhId"
                                    [clearable]="false"
                                    placeholder="Tỉnh/Thành phố"
                                    (change)="changeTinh($event)"
                                    notFoundText="Không tìm thấy kết quả">
                                </ng-select>
                                <ng-select 
                                    [items]="addressTree.list.quanHuyen"
                                    bindValue="id"
                                    bindLabel="ten"
                                    formControlName="quanHuyenId"
                                    [clearable]="true"
                                    placeholder="Quận/Huyện"
                                    (change)="changeQuan($event)"
                                    notFoundText="Không tìm thấy kết quả">
                                </ng-select>
                                <span *ngIf="type === 'khu_vuc'">
                                    <ng-select 
                                        [items]="addressTree.list.phuongXa"
                                        bindValue="id"
                                        bindLabel="ten"
                                        formControlName="phuongXaId"
                                        [clearable]="true"
                                        placeholder="Phường/Xã"
                                        (change)="changePhuong($event)"
                                        notFoundText="Không tìm thấy kết quả">
                                    </ng-select>
                                    <ng-select 
                                        [items]="addressTree.list.duongPho"
                                        bindValue="id"
                                        bindLabel="ten"
                                        formControlName="duongPhoId"
                                        [clearable]="true"
                                        placeholder="Đường/Phố"
                                        (change)="changeDuong($event)"
                                        notFoundText="Không tìm thấy kết quả">
                                    </ng-select>
                                </span>
                                <span *ngIf="type === 'du_an'">
                                    <ng-select 
                                        [items]="addressTree.list.duAn"
                                        bindValue="id"
                                        bindLabel="ten"
                                        formControlName="duAnId"
                                        [clearable]="false"
                                        placeholder="Dự án"
                                        (change)="changeDuAn($event)"
                                        notFoundText="Không tìm thấy kết quả">
                                    </ng-select>
                                </span>
                            </form>
                        </ng-template>
                    </app-dropdown-menu>
                </div>
                <button class="button-xem-phan-tich bg-orange" style="padding: 0.375rem 0.75rem;" (click)="goToDesired()">Xem phân tích</button>
            </div>
        </div>

    </div>
</div>