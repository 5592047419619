

<app-dropdown-menu>
    <ng-template app-dropdown-menu-label>

        
        <div class="d-flex search shadow">
            <form [formGroup]="searchService.form" (ngSubmit)="searchService.onSubmit()">
                <input formControlName="text" type="text" [placeholder]="placeholder" />
            </form>
            <!-- <div class="status">
                <div *ngIf="searchService.suggestResults.loading" class="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div> -->
            <button class="bg-orange" style="padding: 0.375rem 0.75rem !important;">Tìm kiếm</button>
        </div>
    </ng-template>
    <ng-template app-dropdown-menu-items>
        <div class="warning" *ngIf="searchService.suggestResults.warning" [innerHTML]="searchService.suggestResults.warning"></div>
        <ul class="results">
            <li class="result" *ngFor="let item of searchService.suggestResults.items">
                <a class="link" [routerLink]="item.routerLink" app-dropdown-menu-click-close [queryParams]="item.queryParams">
                    <span [innerHTML]="item.title"></span>
                </a>
            </li>
        </ul>
    </ng-template>
</app-dropdown-menu>
