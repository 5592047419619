import { ThamDinhGiaService } from './tham-dinh-gia.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AccountSsoService } from 'src/app/core/auth/account-sso.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-tham-dinh-gia',
  templateUrl: './tham-dinh-gia.component.html',
  styleUrls: ['./tham-dinh-gia.component.scss']
})
export class ThamDinhGiaComponent implements OnInit {
  tabSelected = 'khach-hang';
  form: FormGroup;
  diaChi: string;
  diaChiEdit: boolean = false;
  phuongXa: string;
  quanHuyen: string;
  tinhThanh: string;
  loaiYeuCauThamDinh: any = 'DINH_GIA_SO_BO';
  danhSachChiNhanh: any;
  loading: boolean = false;
  payload: any = {
    tenKH: null,
    soDT: null,
    emailKH: null,
    diaChiKH: null,
    taiSanThamDinh: null,
    loaiTaiSan: 'BAT_DONG_SAN',
    mucDichThamDinh: null,
    yeuCauThamDinhSoBo: null,
    yeuCauThamDinhToanBo: null,
    chiNhanhId: 1,
    chuY: null
  }
  constructor(private fb: FormBuilder, public thamDinhGiaService: ThamDinhGiaService, private http: HttpClient, public accountSsoService: AccountSsoService, private nzNotificationService: NzNotificationService) { }

  get khForm() {
    return this.form.get('kh');
  }
  get tsForm() {
    return this.form.get('ts');
  }
  get tdForm() {
    return this.form.get('td');
  }

  ngOnInit(): void {
    this.getListChiNhanh();

    this.thamDinhGiaService.getAllMucDichThamDinh();
    this.initForm();
    this.form.valueChanges.subscribe(val => {
      console.log('val: ', val);
      // this.form.get('kh').invalid;
    });
    setTimeout(() => {
      this.payload.tenKH = this.accountSsoService.account.fullName;
      this.payload.emailKH = this.accountSsoService.account.email;
      this.payload.soDT = this.accountSsoService.account.mobile;
    }, 500);
  }

  nextToTaiSan() {
    this.validateForm('tai-san');
  }

  nextToThamDinh() {
    this.validateForm('tham-dinh');
  }

  yeuCauThamDinh() {
    this.loading = true;
    this.payload.taiSanThamDinh = `${this.diaChi} ${this.phuongXa}, ${this.quanHuyen}, ${this.tinhThanh}`;
    if (this.loaiYeuCauThamDinh === 'DINH_GIA_SO_BO') {
      this.payload.yeuCauThamDinhSoBo = true;
      this.payload.yeuCauThamDinhToanBo = false;
    } else {
      this.payload.yeuCauThamDinhSoBo = false;
      this.payload.yeuCauThamDinhToanBo = true;
    }
    console.log(this.payload);
    this.thamDinhGiaService.submitYeuCauThamDinh(this.payload).subscribe((res: any) => {
        console.log('yeuCauThamDinh', res);
        if (res.status === 0) {
          this.nzNotificationService.success('Thành công', '');
        } else {
          this.nzNotificationService.error('Không thành công', res.description);
        }
        this.loading = false;
    }, err => {
      this.loading = false;
    });
  }

  changeTab(tab: string) {
    this.validateForm(tab);
  }

  validateForm(tab: string) {
    switch (tab) {
      case 'khach-hang':
        this.tabSelected = 'khach-hang';
        break;
      case 'tai-san':
        const { tenKH, soDT, email, diaChiKH } = this.khForm.value;
        if (tenKH && soDT && diaChiKH) {
          this.tabSelected = 'tai-san';
        }
        break;
      case 'tham-dinh':
        const { taiSanThamDinh, loaiTaiSan, mucDichThamDinh } = this.tsForm.value;
        if (taiSanThamDinh && loaiTaiSan && mucDichThamDinh) {
          this.tabSelected = 'tham-dinh';
        }
        break;
    }
  }

  private initForm() {
    this.form = this.fb.group({
      kh: this.fb.group({
        tenKH: [null, Validators.required],
        soDT: [null, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
        emailKH: [null],
        diaChiKH: [null, Validators.required],
      }),
      ts: this.fb.group({
        taiSanThamDinh: [null, Validators.required],
        loaiTaiSan: ['BAT_DONG_SAN', Validators.required],
        mucDichThamDinh: [1, Validators.required],
      }),
      td: this.fb.group({
        yeuCauThamDinhSoBo: [true],
        yeuCauThamDinhToanBo: [false],
        chiNhanhId: [1, Validators.required],
        chuY: [null, Validators.required]
      })
    });
  }

  getListChiNhanh(): void {
    this.http.get<any>(`${environment.API.SERVER_GUI_YEU_CAU_THAM_DINH}`).subscribe(res => {
      this.danhSachChiNhanh = res.payload;
    });
  }
}
