import { NzNotificationService, NzNotificationModule } from 'ng-zorro-antd/notification';
import { DangKyNhanBaoCaoModule } from './layouts/landing/dang-ky-nhan-bao-cao/dang-ky-nhan-bao-cao.module';
import { PopupModule } from './shared/popup/popup.module';
import { NavbarModule } from './layouts/navbar/navbar.module';
import { NavbarCsrComponent } from './layouts/navbar/navbar-csr/navbar-csr.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FooterComponent } from './layouts/footer/footer.component';
import { CaptchaComponent } from './blocks/interceptor/captcha.component';
import { AuthExpiredInterceptor } from './blocks/interceptor/auth-expired.interceptor';
import { AuthInterceptor } from './blocks/interceptor/auth.interceptor';
import { JhiLoginModalComponent } from './shared/login/login.component';
// import { HasAnyAuthorityDirective } from './shared/auth/has-any-authority.directive';
import { CenhomeAnalystAccountModule } from './account/account.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { WindowRef } from './shared/util/tracking/window-ref';
import { RECAPTCHA_LANGUAGE, RecaptchaModule } from 'ng-recaptcha';
import { ToasterModule } from 'angular2-toaster';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppShareModule } from './app-share.modules';

import {
  GoogleLoginProvider,
  FacebookLoginProvider,
  AmazonLoginProvider,
} from 'angularx-social-login';
import { NavbarMobileModule } from './layouts/navbar/navbar-mobile/navbar-mobile.module';
import { GraphQLModule } from './graphql.module';
import { ThamDinhGiaModule } from './shared/tham-dinh-gia/tham-dinh-gia.module';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import en from '@angular/common/locales/en';
import { OverlayModule } from '@angular/cdk/overlay';

registerLocaleData(en);
// export function storageFactory() : OAuthStorage {
//   return localStorage
// }

declare global {
  interface Array<T> {
    groupBy(keyField: string): object;
  }
}

Object.defineProperty(Array.prototype, 'groupBy', {
  enumerable: false,
  value(prop) {
    return this.reduce((groups, item) => {
      const val = item[prop];
      groups[val] = groups[val] || [];
      groups[val].push(item);
      return groups;
    }, {});
  }
});


@NgModule({
  declarations: [
    AppComponent,
    JhiLoginModalComponent,
    CaptchaComponent,
    FooterComponent,
  ],
  imports: [
    NavbarModule,
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    NgxWebstorageModule.forRoot({ prefix: 'jhi', separator: '-' }),
    RecaptchaModule,
    ToasterModule.forRoot(),
    NgbModule,
    CommonModule,
    FormsModule,
    CenhomeAnalystAccountModule,
    // JhiLoginModalComponent,
    // HasAnyAuthorityDirective,
    SocialLoginModule,
    AppShareModule,
    NavbarMobileModule,
    GraphQLModule,
    PopupModule,
    DangKyNhanBaoCaoModule,
    ThamDinhGiaModule,
    OverlayModule,
    NzNotificationModule
  ],
  exports: [
    NgbModule,OverlayModule
    // RecaptchaModule,
    // CaptchaComponent
  ],
  entryComponents: [
    JhiLoginModalComponent,
    CaptchaComponent
  ],
  providers: [
    WindowRef,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthExpiredInterceptor,
      multi: true
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'vi', // use French language
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '120636936679-fmciaj6epkd7n8mgk2otmt18dusf7cg2.apps.googleusercontent.com'
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('487744198394672'),
          },
          // {
          //   id: AmazonLoginProvider.PROVIDER_ID,
          //   provider: new AmazonLoginProvider(
          //     'clientId'
          //   ),
          // },
        ],
      } as SocialAuthServiceConfig,
    },
    DatePipe,
    { provide: NZ_I18N, useValue: en_US },
    NzNotificationService,
    // { provide: OAuthStorage, useFactory: storageFactory }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
