<app-dropdown-menu>
    <ng-template app-dropdown-menu-label>
        <div class="d-flex search">
            <div class="search-bg">
                <form [formGroup]="searchService.form" (ngSubmit)="searchService.onSubmit()">
                    <input formControlName="text" class="input-search" type="text" [placeholder]="placeholder" />
                </form>
            </div>

            <div class="status">
                <div *ngIf="searchService.suggestResults.loading" class="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template app-dropdown-menu-items>
        <div class="warning" *ngIf="searchService.suggestResults.warning" [innerHTML]="searchService.suggestResults.warning"></div>
        <ul class="results">
            <li class="result" *ngFor="let item of searchService.suggestResults.items">
                <a class="link" [routerLink]="item.routerLink" app-dropdown-menu-click-close [queryParams]="item.queryParams">
                    <span [innerHTML]="item.title"></span>
                </a>
            </li>
        </ul>
    </ng-template>
</app-dropdown-menu>
