import { GPSType, BDSType } from './../../../map/form-search/form-search.model';
export class Bounds {
    south: number;
    west: number;
    north: number;
    east: number;
    constructor() { }

    // initFromBoundsGoogle(boundsGoogle: Geometry) {
    //   const viewport = boundsGoogle.viewport;
    //   this.south = viewport.southwest.lat;
    //   this.west = viewport.southwest.lng;
    //   this.north = viewport.northeast.lat;
    //   this.east = viewport.northeast.lng;
    // }

    initFromBoundsLeaflet(boundsLeaflet: any): void {
      this.south = boundsLeaflet.getSouth();
      this.west = boundsLeaflet.getWest();
      this.north = boundsLeaflet.getNorth();
      this.east = boundsLeaflet.getEast();
    }

    getBoundEs(): any {
      return {
        topLeft: {
          lat: this.north,
          lng: this.west
        },
        bottomRight: {
          lat: this.south,
          lng: this.east
        }
      };
    }
}

export function getBoundsFromLeaflet(boundsLeaflet: any): Bounds {
  const bounds = new Bounds();
  bounds.initFromBoundsLeaflet(boundsLeaflet);
  return bounds.getBoundEs();
}
export function getBoundsFromData(data: GPSType[]): Bounds {
    let latMax, lonMax, latMin, lonMin: number;
    latMax = lonMax = 0;
    latMin = lonMin = 1000;
    data.forEach(item => {
    //   const toaDo = property._source.toa_do_danh_dau;
      if (item) {
        if (item.lat > latMax) {
          latMax = item.lat;
        }
        if (item.lat < latMin) {
          latMin = item.lat;
        }
        if (item.lng > lonMax) {
          lonMax = item.lng;
        }
        if (item.lng < lonMin) {
          lonMin = item.lng;
        }
      }
    });
    if (latMin > latMax || lonMin > lonMax) {
      return null;
    } else {
      const bounds = new Bounds();
      bounds.south = latMin;
      bounds.north = latMax;
      bounds.west = lonMin;
      bounds.east = lonMax;
      return bounds;
    }
}

export function getPhuongXaIdListFromList(list: BDSType[]): number[] {
    // console.log('getPhuongXaIdListFromList', list);
    /* Trong list bất động sản nằm ở khu vực ranh giới giữa nhiều phường,
    cần lấy danh sách các phường để load được đầy đủ đường bound thửa
    Notice: Lưu lại cả quanHuyenId để check map and model khi load wms
    */
    const phuongXaIdList = [];
    const phuongXaQuanHuyenIdList = [];

    if (list) {
      list.forEach(element => {
        const phuongXaId = element.phuongXaId || null;
        const quanHuyenId = element.quanHuyenId || null;
        if (phuongXaId && !phuongXaIdList.includes(phuongXaId)) {
          phuongXaIdList.push(phuongXaId);
          phuongXaQuanHuyenIdList.push([phuongXaId, quanHuyenId]);
        }
      });
    }
    return phuongXaQuanHuyenIdList;
}
