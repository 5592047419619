<div class="modal-header">
    <h1 class="header-title mb-0">
        Thành viên</h1>
    <a (click)="close()" class="header-close"><svg aria-hidden="true" focusable="false" data-prefix="fal"
            data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"
            class="svg-inline--fa fa-times fa-w-10 fa-2x">
            <path fill="currentColor"
                d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
                class=""></path>
        </svg>
    </a>
</div>
<div class="container-login" [class.right-panel-active]="isSignUp" [class.left-panel-active]="isforget">

    <div class="form-container">
        <div class="form-zone">
            <div class="form-login forget-password">
                <h2 class="section-title">Quên mật khẩu</h2>
                <form id="forget-form" name="forgetform" #forgetform="ngForm" (ngSubmit)="requestReset()"
                    style="width: 100%">
                    <div class="form-group w-100">
                        <label for="email-forget">Email</label>
                        <div class="input-group mb-3 border-input-login">
                            <input type="text" class="form-control form-custom-login custom-input"
                                placeholder="Nhập vào email của bạn" id="email-forget" [(ngModel)]="emailForget"
                                name="forget" required pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                maxlength="100" minlength="5" #forget="ngModel" forget>
                            <div class="input-group-append">
                                <span class="input-group-text input-addon">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="envelope"
                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                                        class="svg-inline--fa fa-envelope fa-w-16 fa-2x">
                                        <path fill="currentColor"
                                            d="M464 64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM48 96h416c8.8 0 16 7.2 16 16v41.4c-21.9 18.5-53.2 44-150.6 121.3-16.9 13.4-50.2 45.7-73.4 45.3-23.2.4-56.6-31.9-73.4-45.3C85.2 197.4 53.9 171.9 32 153.4V112c0-8.8 7.2-16 16-16zm416 320H48c-8.8 0-16-7.2-16-16V195c22.8 18.7 58.8 47.6 130.7 104.7 20.5 16.4 56.7 52.5 93.3 52.3 36.4.3 72.3-35.5 93.3-52.3 71.9-57.1 107.9-86 130.7-104.7v205c0 8.8-7.2 16-16 16z"
                                            class=""></path>
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="forget.invalid && forgetform.submitted && forgetform.invalid">
                            <small class="form-text text-danger" *ngIf="forget.errors.required">
                                Bạn phải nhập e-mail
                            </small>
                            <small class="form-text text-danger" *ngIf="forget.errors.pattern">
                                Email của bạn không hợp lệ
                            </small>
                            <small class="form-text text-danger" *ngIf="forget.errors.minlength">
                                Email của bạn yêu cầu ít nhất 5 kí tự
                            </small>
                            <small class="form-text text-danger" *ngIf="forget.errors.maxlength">
                                Email của bạn tối đa chỉ 100 kí tự
                            </small>
                        </div>
                    </div>
                    <div class="text-forget">
                        <span *ngIf="success">
                            <i>( Chúng tôi đã gửi e-mail để xác nhận lại mật khẩu của bạn, hãy vào e-mail để kiểm tra
                                lại )</i>
                        </span>
                        <span *ngIf="errorEmailNotExists" class="form-text text-danger">
                            <i>({{errorEmailNotExists}})</i>
                        </span>
                    </div>
                    <div class="button-action row">
                        <div class="col-lg-4 p-0 mt-2">
                            <button class="btn btn-cenhomes" type="submit">Xác
                                nhận</button>
                        </div>
                        <div class="col-lg-4 p-0 ml-lg-3 mt-2">
                            <button class="btn btn-outline-cenhomes" (click)="isForGet()" type="button">Quay lại đăng
                                nhập</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="form-login sign-in-container">
                <p *ngIf="message" class="color-cen"><strong>{{message}}</strong></p>
                <h2 class="section-title">Đăng nhập</h2>
                <p>Đăng nhập để trải nghiệm đầy đủ chức năng và tiện ích của Cen định giá. </p>
                <form id="signin-form" name="signinform" #signinform="ngForm" (ngSubmit)="login()" style="width: 100%">
                    <div class="form-group w-100 m-0">
                        <label class="d-none d-sm-block" for="name">Email</label>
                        <div class="input-group mb-1 border-input-login">
                            <span>
                                <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="user" role="img"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                                    class="svg-inline--fa fa-user fa-w-14 fa-2x">
                                    <path fill="currentColor"
                                        d="M313.6 288c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4zM416 464c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16v-41.6C32 365.9 77.9 320 134.4 320c19.6 0 39.1 16 89.6 16 50.4 0 70-16 89.6-16 56.5 0 102.4 45.9 102.4 102.4V464zM224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm0-224c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z"
                                        class=""></path>
                                </svg>
                            </span>
                            <input type="text" class="form-control form-custom-login custom-input"
                                placeholder="Nhập tên đăng nhâp của bạn" id="name" [(ngModel)]="username"
                                name="namesignin" required namesignin #namesignin="ngModel">
                        </div>
                    </div>
                    <div class="row"
                        *ngIf="((namesignin.touched && namesignin.invalid) || (namesignin.invalid && signinform.submitted)) && signinform.invalid">
                        <small class="form-text text-danger">(Bạn phải nhập tên đăng nhập)</small>
                    </div>
                    <div class="form-group w-100 m-0 mt-3">
                        <label class="d-none d-sm-block" for="name">Mật khẩu</label>
                        <div class="input-group mb-1 border-input-login">
                            <span>
                                <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="key" role="img"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                                    class="svg-inline--fa fa-key fa-w-16 fa-2x">
                                    <path fill="currentColor"
                                        d="M336 32c79.529 0 144 64.471 144 144s-64.471 144-144 144c-18.968 0-37.076-3.675-53.661-10.339L240 352h-48v64h-64v64H32v-80l170.339-170.339C195.675 213.076 192 194.968 192 176c0-79.529 64.471-144 144-144m0-32c-97.184 0-176 78.769-176 176 0 15.307 1.945 30.352 5.798 44.947L7.029 379.716A24.003 24.003 0 0 0 0 396.686V488c0 13.255 10.745 24 24 24h112c13.255 0 24-10.745 24-24v-40h40c13.255 0 24-10.745 24-24v-40h19.314c6.365 0 12.47-2.529 16.971-7.029l30.769-30.769C305.648 350.055 320.693 352 336 352c97.184 0 176-78.769 176-176C512 78.816 433.231 0 336 0zm48 108c11.028 0 20 8.972 20 20s-8.972 20-20 20-20-8.972-20-20 8.972-20 20-20m0-28c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48z"
                                        class=""></path>
                                </svg>
                            </span>
                            <input type="password" class="form-control form-custom-login custom-input"
                                placeholder="Nhập mật khẩu của bạn" id="password" [(ngModel)]="password" name="signin"
                                autocomplete="password" required signin #signin="ngModel">
                        </div>
                    </div>
                    <div class="row"
                        *ngIf="((signin.touched && signin.invalid) || (signin.invalid && signinform.submitted)) && signinform.invalid">
                        <small class="form-text text-danger">(Bạn phải nhập tên đăng nhập)</small>
                    </div>
                    <div class="row" *ngIf="authenticationError">
                        <small class="form-text text-danger">(Bạn nhập sai tên hoặc mật khẩu hoặc chưa kích hoạt tài
                            khoản, xin vui lòng thử
                            lại)</small>
                    </div>
                    <div class="button-action row">
                        <div>Khi nhấn Đăng ký, bạn đã hiểu và đồng ý về các <a href="https://gianhadat.cenhomes.vn"> lưu
                                ý và khuyến nghị</a> của Cenhomes được đề cập tại website này.</div>
                        <div class="col-lg-4 p-0 mt-2">
                            <button class="btn btn-cenhomes" type="submit" [disabled]="logingin" name="btn-signin">Đăng
                                nhập</button>
                        </div>
                        <div class="col-lg-4 p-0 ml-lg-3 mt-2">
                            <button class="btn btn-outline-cenhomes" (click)="isChange()" type="button">Đăng ký</button>
                        </div>
                    </div>
                    <div class="list-action">
                        <div style="display: flex">
                            <input type="checkbox" [(ngModel)]="rememberMe" name="checkbox" id="checkbox" checked>
                            <span style="margin-left: 8px; font-size: 14px;">Lưu tài khoản của bạn</span>
                        </div>
                        <span>|</span>
                        <span style="cursor: pointer;font-size: 14px;" (click)="isForGet()">Quên mật khẩu</span>
                    </div>
                    <div class="list-icon-login">
                        <a href="javascript:void(0)" (click)="facebookLogin()">
                            <span><span appIconW="20" appIconH="20" appIcon="fa_bold_facebook_square"></span> </span>
                        </a>
                        <a href="javascript:void(0)" (click)="googleLogIn()">
                            <span><span appIconW="20" appIconH="20" appIcon="fa_bold_google_plus_square"></span></span>
                        </a>
                    </div>
                    <div class="text-login">
                        <span style="font-size: 14px;">hoặc sử dụng tài khoản social của bạn để đăng nhập nhanh</span>
                    </div>
                </form>
            </div>
            <div class="form-login sign-up-container">
                <h2 class="section-title">Đăng ký</h2>
                <form id="signup-form" name="signupform" #signupform="ngForm" (ngSubmit)="register()"
                    style="width: 100%">
                    <div class="form-group w-100 m-0" *ngIf="!successRegister">
                        <label for="email">Email</label>
                        <div class="input-group mb-1 border-input-login">
                            <input type="email" class="form-control form-custom-login custom-input"
                                placeholder="Nhập email của bạn" id="email" [(ngModel)]="registerAccount.email"
                                minlength=5 maxlength=100 #email="ngModel" email required
                                pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" name="email" autocomplete="email">
                            <div class="input-group-append">
                                <span class="input-group-text input-addon">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="envelope"
                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                                        class="svg-inline--fa fa-envelope fa-w-16 fa-2x">
                                        <path fill="currentColor"
                                            d="M464 64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM48 96h416c8.8 0 16 7.2 16 16v41.4c-21.9 18.5-53.2 44-150.6 121.3-16.9 13.4-50.2 45.7-73.4 45.3-23.2.4-56.6-31.9-73.4-45.3C85.2 197.4 53.9 171.9 32 153.4V112c0-8.8 7.2-16 16-16zm416 320H48c-8.8 0-16-7.2-16-16V195c22.8 18.7 58.8 47.6 130.7 104.7 20.5 16.4 56.7 52.5 93.3 52.3 36.4.3 72.3-35.5 93.3-52.3 71.9-57.1 107.9-86 130.7-104.7v205c0 8.8-7.2 16-16 16z"
                                            class=""></path>
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="email.invalid && signupform.submitted && signupform.invalid">
                            <small class="form-text text-danger" *ngIf="email.errors.required">
                                Bạn phải nhập e-mail
                            </small>
                            <small class="form-text text-danger" *ngIf="email.errors.pattern">
                                Email của bạn không hợp lệ
                            </small>
                            <small class="form-text text-danger" *ngIf="email.errors.minlength">
                                Email của bạn yêu cầu ít nhất 5 kí tự
                            </small>
                            <small class="form-text text-danger" *ngIf="email.errors.maxlength">
                                Email của bạn tối đa chỉ 100 kí tự
                            </small>
                        </div>
                    </div>
                    <div class="form-group w-100 m-0" *ngIf="!successRegister">
                        <label for="sdt">Số điện thoại</label>
                        <div class="input-group mb-1 border-input-login">
                            <input type="text" class="form-control form-custom-login custom-input"
                                placeholder="Nhập số điên thoại của bạn" id="sdt" [(ngModel)]="registerAccount.phone"
                                pattern="^\d{8,15}$" minlength=8 maxlength=15 required name="sdt" #sdt="ngModel" sdt>
                            <div class="input-group-append">
                                <span class="input-group-text input-addon">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="phone"
                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                                        class="svg-inline--fa fa-phone fa-w-16 fa-2x">
                                        <path fill="currentColor"
                                            d="M487.8 24.1L387 .8c-14.7-3.4-29.8 4.2-35.8 18.1l-46.5 108.5c-5.5 12.7-1.8 27.7 8.9 36.5l53.9 44.1c-34 69.2-90.3 125.6-159.6 159.6l-44.1-53.9c-8.8-10.7-23.8-14.4-36.5-8.9L18.9 351.3C5 357.3-2.6 372.3.8 387L24 487.7C27.3 502 39.9 512 54.5 512 306.7 512 512 307.8 512 54.5c0-14.6-10-27.2-24.2-30.4zM55.1 480l-23-99.6 107.4-46 59.5 72.8c103.6-48.6 159.7-104.9 208.1-208.1l-72.8-59.5 46-107.4 99.6 23C479.7 289.7 289.6 479.7 55.1 480z"
                                            class=""></path>
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="sdt.invalid && signupform.submitted && signupform.invalid">
                            <small class="form-text text-danger" *ngIf="sdt.errors.required || sdt.errors.pattern">
                                Số điện thoại phải từ 8-15 ký tự
                            </small>
                        </div>
                    </div>
                    <div class="form-group w-100 m-0" *ngIf="!successRegister">
                        <label for="password-signup">Mật khẩu</label>
                        <div class="input-group mb-1 border-input-login">
                            <input type="password" class="form-control form-custom-login custom-input"
                                placeholder="Nhập mật khẩu của bạn" id="password-signup"
                                [(ngModel)]="registerAccount.password" minlength=4 maxlength=50 required
                                #passwordsignup="ngModel" name="passwordsignup" name="passwordsignup"
                                autocomplete="password">
                            <div class="input-group-append">
                                <span class="input-group-text input-addon">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="lock-alt"
                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                                        class="svg-inline--fa fa-lock-alt fa-w-14 fa-2x">
                                        <path fill="currentColor"
                                            d="M224 420c-11 0-20-9-20-20v-64c0-11 9-20 20-20s20 9 20 20v64c0 11-9 20-20 20zm224-148v192c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V272c0-26.5 21.5-48 48-48h16v-64C64 71.6 136-.3 224.5 0 312.9.3 384 73.1 384 161.5V224h16c26.5 0 48 21.5 48 48zM96 224h256v-64c0-70.6-57.4-128-128-128S96 89.4 96 160v64zm320 240V272c0-8.8-7.2-16-16-16H48c-8.8 0-16 7.2-16 16v192c0 8.8 7.2 16 16 16h352c8.8 0 16-7.2 16-16z"
                                            class=""></path>
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="passwordsignup.invalid && signupform.submitted && signupform.invalid">
                            <small class="form-text text-danger" *ngIf="passwordsignup.errors.required">
                                Mật khẩu bắt buộc phải nhập.
                            </small>
                            <small class="form-text text-danger" *ngIf="passwordsignup.errors.minlength">
                                Mật khẩu bắt buộc phải có ít nhất 4 kí tự.
                            </small>
                            <small class="form-text text-danger" *ngIf="passwordsignup.errors.maxlength">
                                Mật khẩu bắt buộc tối đa là 50 kí tự.
                            </small>
                        </div>
                    </div>
                    <div class="form-group w-100 m-0" *ngIf="!successRegister">
                        <label for="confirm-password-signup">Xác nhận lại mật khẩu</label>
                        <div class="input-group mb-1 border-input-login">
                            <input type="password" class="form-control form-custom-login custom-input"
                                placeholder="Nhập mật khẩu của bạn" id="confirm-password-signup" name="confirmPassword"
                                #confirmPasswordInput="ngModel" [(ngModel)]="confirmPassword" minlength=4 maxlength=50
                                required name="confirmPasswordInput" autocomplete="password" confirmPasswordInput>
                            <div class="input-group-append">
                                <span class="input-group-text input-addon">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="key"
                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                                        class="svg-inline--fa fa-key fa-w-16 fa-2x">
                                        <path fill="currentColor"
                                            d="M336 32c79.529 0 144 64.471 144 144s-64.471 144-144 144c-18.968 0-37.076-3.675-53.661-10.339L240 352h-48v64h-64v64H32v-80l170.339-170.339C195.675 213.076 192 194.968 192 176c0-79.529 64.471-144 144-144m0-32c-97.184 0-176 78.769-176 176 0 15.307 1.945 30.352 5.798 44.947L7.029 379.716A24.003 24.003 0 0 0 0 396.686V488c0 13.255 10.745 24 24 24h112c13.255 0 24-10.745 24-24v-40h40c13.255 0 24-10.745 24-24v-40h19.314c6.365 0 12.47-2.529 16.971-7.029l30.769-30.769C305.648 350.055 320.693 352 336 352c97.184 0 176-78.769 176-176C512 78.816 433.231 0 336 0zm48 108c11.028 0 20 8.972 20 20s-8.972 20-20 20-20-8.972-20-20 8.972-20 20-20m0-28c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48z"
                                            class=""></path>
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="confirmPasswordInput.invalid && signupform.submitted && signupform.invalid">
                            <small class="form-text text-danger" *ngIf="confirmPasswordInput.errors.required">
                                Mật khẩu bắt buộc phải nhập.
                            </small>
                            <small class="form-text text-danger" *ngIf="confirmPasswordInput.errors.minlength">
                                Mật khẩu bắt buộc phải có ít nhất 4 kí tự.
                            </small>
                            <small class="form-text text-danger" *ngIf="confirmPasswordInput.errors.maxlength">
                                Mật khẩu bắt buộc tối đa là 50 kí tự.
                            </small>
                        </div>
                        <div *ngIf="doNotMatch">
                            <small class="form-text text-danger">
                                Mật khẩu không khớp nhau, hãy nhập lại
                            </small>
                        </div>
                    </div>

                    <div class="form-group w-100 m-0" *ngIf="!successRegister">
                        <label for="confirm-password-signup">Hoàn thành captcha để chứng tỏ bạn không phải là
                            robot</label>
                        <input type="hidden" [(ngModel)]="registerAccount.tokenCaptcha" #recaptchaConfirm="ngModel"
                            name="recaptchaConfirm" name="recaptchaConfirm" required>
                        <div *ngIf="recaptchaConfirm.invalid && signupform.submitted && signupform.invalid">
                            <small class="form-text text-danger" *ngIf="recaptchaConfirm.errors.required">
                                Captcha bắt buộc phải nhập.
                            </small>
                        </div>
                        <re-captcha #captchaRef="reCaptcha" (resolved)="resolved($event)"
                            siteKey="6LeB0bcUAAAAABSKoSEZU17Z34DtfnHr_e7wqdYA"></re-captcha>
                    </div>

                    <div class="form-group w-100 mt-2 mb-2" *ngIf="errorEmailExists || errorUserExists">
                        <span class="form-text text-danger">
                            <i *ngIf="errorEmailExists">({{errorEmailExists}})</i>
                            <i *ngIf="errorUserExists">({{errorUserExists}})</i>
                        </span>
                    </div>
                    <div class="form-group w-100 mt-2 mb-2" *ngIf="successRegister">
                        <span class="form-text text-success"><i>(Bạn đã đăng ký thành công, hãy vào email để kích hoạt
                                tài khoản)</i></span>
                    </div>

                    <!-- <button (click)="captchaRef.reset()">Submit</button> -->
                    <div class="button-action row">
                        <div class="col-lg-4 p-0 mt-2" *ngIf="!successRegister">
                            <button class="btn btn-cenhomes" type="submit">Hoàn thành</button>
                        </div>
                        <div class="col-lg-4 p-0 ml-lg-3 mt-2">
                            <button class="btn btn-outline-cenhomes" (click)="isChange()" type="button">Quay lại đăng
                                nhập</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
