// These constants are injected via webpack environment variables.
// You can add more variables in webpack.common.js or in profile specific webpack.<dev|prod>.js files.
// If you change the values in the webpack config files, you need to re run webpack to update the application
import { environment } from 'src/environments/environment';
export const VERSION = `${environment.version}`;
export const DEBUG_INFO_ENABLED = /*!!process.env.DEBUG_INFO_ENABLED*/ false;
// export const BUILD_TIMESTAMP = process.env.BUILD_TIMESTAMP;
export const DEFAULT_IMG_RE = '/content/images/default_bds.jpg';
export const VERSION_PHAN_TICH = 'tong_hop_1.0.0_quarter'


