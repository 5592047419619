import { SVG_ICON } from './../../app.global.constants';
import { Directive, Input, ElementRef } from '@angular/core';

@Directive({
  selector: '[appIcon]',
})
export class IconDirective {
  @Input() appIconW: number;
  @Input() appIconH: number;

  @Input() set
  appIcon(value: string) {
    // console.log('appIcon', value);
    this.appIconW = this.appIconW || 20;
    this.appIconH = this.appIconH || 20;
    this.el.nativeElement.innerHTML = SVG_ICON[value] || '';
    // console.log('this.el.nativeElement.innerHTML', this.el.nativeElement.innerHTML);
    // console.log(this.el.nativeElement, this.el.nativeElement.children[0]);
    if (this.el.nativeElement.children[0]) {
      this.el.nativeElement.children[0].style.width = this.appIconW + 'px';
      this.el.nativeElement.children[0].style.height = this.appIconH + 'px';
    }
  }
  constructor(private el: ElementRef) {
    // console.log('appIcon', el);

  }
// ${a + b}
}
