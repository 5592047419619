import { AccountService } from './core/auth/account.service';
import { AccountSsoService } from './core/auth/account-sso.service';
import { DangKyNhanBaoCaoService } from './layouts/landing/dang-ky-nhan-bao-cao/dang-ky-nhan-bao-cao.service';
import { OnInit, OnDestroy } from '@angular/core';
import { NavbarCsrService } from './layouts/navbar/navbar-csr/navbar-csr.service';
import { InterceptorService } from './blocks/interceptor/interceptor.service';
import { Component } from '@angular/core';
import { SeoService } from './shared/util/seo.service';
import { NavbarService } from './layouts/main/navbar.service';
import { ToasterConfig } from 'angular2-toaster';
import { AppErrorService } from './app-error.service';
import { ThamDinhGiaService } from './shared/tham-dinh-gia/tham-dinh-gia.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { first, filter } from 'rxjs/operators';
import { concat, zip } from 'rxjs';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent implements OnInit, OnDestroy {
  title = 'cenhomes';
  configToaster: ToasterConfig;
  constructor(
    public toggle: NavbarService,
    public seoService: SeoService,
    public interceptorService: InterceptorService,
    public navbarCsrService: NavbarCsrService,
    public appErrorService: AppErrorService,
    public dangKyNhanBaoCaoService: DangKyNhanBaoCaoService,
    public thamDinhGiaService: ThamDinhGiaService,
    private http: HttpClient,
    public activatedRoute: ActivatedRoute,
    public accountSsoService: AccountSsoService,
    public accountService: AccountService,
    private router: Router
  ) {
    this.configToaster = new ToasterConfig({
      showCloseButton: true,
      tapToDismiss: false,
      timeout: 4000,
      limit: 1
    });

    // this.oauthService.initCodeFlow();
    if (this.seoService.onBrowser) {
      if (environment.ssoType === 'sso') {
      } else if (environment.ssoType === 'cenhomesSSO') {

        setInterval(() => {
          this.http.post<any>(`${environment.cenhomesSSO.refreshToken}`, {
            refresh_token: localStorage.getItem('refresh_token')
          }).subscribe(refresh => {
            localStorage.setItem('access_token', refresh.access_token);
            localStorage.setItem('refresh_token', refresh.refresh_token);
          });
        }, 120*1000);
  
  
        this.router.events.subscribe(event => {
          if (event instanceof NavigationEnd) {
            const urlParse = this.router.parseUrl(this.router.url);
            console.log('this.router.url', this.router.url.split('?')[0], urlParse);
            if (this.router.url === '/' || this.router.url === '') {
              
            } else {
              // Nếu url là index.html và queryParams có code thì nghĩa là vừa redirect từ trang SSO sang
              if ((this.router.url.split('?')[0] === '/index.html') && (urlParse.queryParams && urlParse.queryParams.code)) {
                  this.http.post<any>(`${environment.cenhomesSSO.tokenEndpoint}`, {
                    code: urlParse.queryParams.code,
                    grant_type: "authorization_code",
                    client_id: `${environment.cenhomesSSO.clientId}`
                  }).subscribe(res => {
                    console.log('connect/token', res);
                    // localStorage.access_token = res.access_token;
                    localStorage.setItem('access_token', res.access_token);
                    localStorage.setItem('refresh_token', res.refresh_token);

                    const datetime = new Date().toLocaleString();
                    let token = urlParse.queryParams.code;
                    const requestOptions = {                                                                                                                                                                                 
                      headers: {
                        Authorization: 'Bearer ' + token,
                        // DateTime: datetime
                      }
                    };
                    console.log('requestOptions', requestOptions);
                    this.http.get<any>(`${environment.cenhomesSSO.mapUser}`, requestOptions).subscribe(info => {
                      this.http.get<any>(`${environment.cenhomesSSO.account}`, requestOptions).subscribe(account => {
                        const userInfo = (account.payload && account.payload.data) || account;
                        this.accountSsoService.setUserIdentity(userInfo);
                        this.accountSsoService.account = userInfo;
                        this.accountService.setIdentity(userInfo);
                        this.accountSsoService.SID = token;
                        const afterLogin = localStorage.getItem('afterLogin');
                        localStorage.removeItem('afterLogin');
                        location.href = afterLogin;
                      });
                    });
                  });
                  

                
              } else {
                const datetime = new Date().toLocaleString();
                  let token = localStorage.access_token || sessionStorage.access_token;
                  const requestOptions = {                                                                                                                                                                                 
                    headers: {
                      Authorization: 'Bearer ' + token,
                      // DateTime: datetime
                    }
                };
                this.http.get<any>(`${environment.cenhomesSSO.account}`, requestOptions).subscribe(account => {
                  console.log('received account', account);
                  const userInfo = (account.payload && account.payload.data) || account;
                  this.accountSsoService.setUserIdentity(userInfo);
                  this.accountSsoService.account = userInfo;
                  this.accountService.setIdentity(userInfo);
                  this.accountSsoService.SID = token;
                }, error => {
                });
              }
            }
          }
        });
      }
      
    }
    

    // this.oauthService.issuer = 'https://account-sandbox.cenhomes.vn';

    // // URL of the SPA to redirect the user to after login
    // this.oauthService.redirectUri = window.location.origin + "/index.html";

    // // The SPA's id. The SPA is registerd with this id at the auth-server
    // this.oauthService.clientId = "gianhadat";

    

    // // set the scope for the permissions the client should request
    // // The first three are defined by OIDC. The 4th is a usecase-specific one
    // this.oauthService.scope = "openid offline_access profile crm_app web marketing_app chat_app";

    // // set to true, to receive also an id_token via OpenId Connect (OIDC) in addition to the
    // // OAuth2-based access_token
    // this.oauthService.oidc = false; // ID_Token
    // this.oauthService.responseType = 'code';

    // // Use setStorage to use sessionStorage or another implementation of the TS-type Storage
    // // instead of localStorage
    // // this.oauthService.setStorage(sessionStorage);
    // this.oauthService.showDebugInformation = true;

    // // Discovery Document of your AuthServer as defined by OIDC
    // let url = 'https://account-sandbox.cenhomes.vn/.well-known/openid-configuration';

    // // Load Discovery Document and then try to login the user
    // this.oauthService.loadDiscoveryDocument(url).then(() => {

    //   // This method just tries to parse the token(s) within the url when
    //   // the auth-server redirects the user back to the web-app
    //   // It dosn't send the user the the login page
    //   this.oauthService.tryLogin({});

    // });
  }
  ngOnInit() {
    // this.seoService.setMobileScreen();
  }
  ngOnDestroy(): void {
    // this.seoService.unSetMobileScreen();
  }

  public login() {
    // this.oauthService.initLoginFlow();
    // const loginLink = `${environment.cenhomesSSO.loginLink}?response_type=code&client_id=${environment.cenhomesSSO.clientId}&redirect_uri=${environment.cenhomesSSO.redirectUri}&scope=openid%20offline_access%20profile%20web`
    // this.oauthService.initLoginFlowInPopup();
    // location.href = loginLink;
  }
}
