import { BanDoService } from 'src/app/layouts/ban-do/generic/ban-do.service';
import { ThamDinhGiaService } from './../../shared/tham-dinh-gia/tham-dinh-gia.service';
import { NavbarMobileService } from './navbar-mobile/navbar-mobile.service';
import { AccountSsoService } from './../../core/auth/account-sso.service';
import { LoginSsoService } from './../../core/login/login-sso.service';
import { InterceptorService } from './../../blocks/interceptor/interceptor.service';
import { isPlatformBrowser } from '@angular/common';
import { SeoService } from './../../shared/util/seo.service';
import { Component, OnInit, AfterViewInit, HostListener, NgModule, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { SessionStorageService, LocalStorageService } from 'ngx-webstorage';
import { CookieService } from 'ngx-cookie-service';
import { VERSION } from 'src/app/app.constants';
import { LoginModalService, LoginService } from 'src/app/core';
import { AccountService } from 'src/app/core/auth/account.service';

import { ActionService } from 'src/app/shared/util/actionService';
import { Subject } from 'rxjs';
import { NavigationStart, Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { SearchHttpService } from 'src/app/shared/http/search-http.service';
import { Helpers } from 'src/app/shared/util/helpers';
import { SmartSearchService } from 'src/app/shared/util/smartSearchService';

import { NavbarService } from '../main/navbar.service';
import { ThongTinMoiGioiService } from '../thong-tin-moi-gioi/thong-tin-moi-gioi.service';

export let browserRefresh = false;
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['navbar.scss']
})


// export let browserRefresh = false;
export class NavbarComponent implements OnInit, AfterViewInit {
  inProduction: boolean;
  isNavbarCollapsed: boolean;
  swaggerEnabled: boolean;
  modalRef: NgbModalRef;
  version: string;
  islogin = false;
  isDisplayNavBar: any;
  isShow: any;
  scrollDown: boolean;
  account: any;
  public listSuggest: any;
  public inputSearch = new Subject<string>();
  public inputTmpSearch: any;
  public isShowList: any;
  public isOpenSuggest: any;
  public isOpenSuggestIpad: any;
  public sidebarMenu: any = false;
  public showGiamTruTrachNhiem: boolean = true;
  public myString: string;
  public showGiamTru: any;
  public openGiamTru: any = false;
  checkUrl: string;
  rest: any;
  checkDinhGia: boolean;
  isKeyUpEnter = false;
  public showNen: boolean = false;
  colorMobileMenu: string;


  // public inPageNeedFixed: boolean;

  // public isOpenSuggest1: any;
  constructor(
    public smartSearchService: SmartSearchService,
    private loginService: LoginService,
    private sessionStorage: SessionStorageService,
    public accountService: AccountService,
    private loginModalService: LoginModalService,
    private $localStorage: LocalStorageService,
    private searchHttpService: SearchHttpService,
    private action: ActionService,
    public router: Router,
    private helpers: Helpers,
    public seoService: SeoService,
    private cookieService: CookieService,
    private activeRoute: ActivatedRoute,
    public navbarService: NavbarService,
    private interceptorService: InterceptorService,
    private renderer: Renderer2,
    public thongTinMoiGioiService: ThongTinMoiGioiService,
    public loginSsoService: LoginSsoService,
    public accountSsoService: AccountSsoService,
    public navbarMobileService: NavbarMobileService,
    public thamDinhGiaService: ThamDinhGiaService,
    public banDoService: BanDoService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // console.log('this.router.url', this.router.url)
        if (this.router.url.indexOf('/dinh-gia') === 0 || (this.router.url.indexOf('/phan-tich-khu-vuc') === 0) || (this.router.url.indexOf('/phan-tich-du-an') === 0)) {
          this.colorMobileMenu = undefined
        } else {
          this.colorMobileMenu = '/assets/images/img-menu.png'
        }
      }
    })

    this.version = VERSION ? 'v' + VERSION : '';
    this.isNavbarCollapsed = true;
    // router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(e => {
    if ('/landing' === this.router.url) {
      this.scrollDown = true;
    } else {
      this.scrollDown = false;
    }
  }

  ngOnInit(): void {
    console.log('navbar load');

    // this.loginService.getAccountService().identity().then(account => {
    //   this.account = account;
    // });
    /* Login when page loaded */
    this.loginService.getAccountService().getAuthenticationState().subscribe(account => {
      console.log('khi login', account);
      this.account = account;
      if (this.account) {
        this.$localStorage.store('account', this.account);
      }
      // console.log('Called getAccountService service', this.account);
    });
    /* Watching for account information change */
    // this.accountService.getAuthenticationState().subscribe(account => {
    //     console.log('Watching for account information change');
    //     this.account = account;
    // });

    this.isDisplayNavBar = true;
    this.isShow = false;

    // this.searchHttpService.searchTerm(this.inputSearch).subscribe(
    //     res => {
    //         if (res.hits.hits !== undefined) {
    //             this.listSuggest = res.hits.hits;
    //             this.suggestOpen(1);
    //         } else {
    //             this.listSuggest = [];
    //         }
    //         if (this.listSuggest.length === 0) {
    //             this.suggestClose();
    //         }
    //     },
    //     e => {
    //         this.suggestClose();
    //     },
    //     () => {}
    // );


  }

  showGTTN() {
    this.showGiamTruTrachNhiem = false;
    this.myString = this.showGiamTruTrachNhiem.toString();
    this.cookieService.set('hide', 'true', 1);
  }
  showSearch(): void {
    if (this.isShow === true) {
      this.isShow = false;
      this.showNen = false;
    }
  }
  inputSearchKeyUpEvent(event: any, value) {
    this.isKeyUpEnter = false;
    /* Sự kiện gõ vào ô tìm kiếm */
    if (event.keyCode === 13) {
      /* Nếu ấn Enter */
      this.smartSearchService.search();
      this.isKeyUpEnter = true;
      console.log('this.isKeyUpEnter', this.isKeyUpEnter);
      console.log('this.smartSearchService.searchModeSelected', this.smartSearchService.searchModeSelected)
      // this.isOpenSuggest = false;
      // this.isOpenSuggestIpad = false;
    } else if (event.keyCode === "ArrowDown") {
      /* Nếu ấn nút xuống */

    } else {
      /* Nếu gõ các ký tự khác */
      if (
        this.smartSearchService.inputTmpSearch !== undefined
        && this.smartSearchService.inputTmpSearch !== null
        && this.smartSearchService.inputTmpSearch.trim() !== '') {
        this.smartSearchService.inputSearch.next(value);
        // this.smartSearchService.dinhgiaSuggest();
      }
    }
  }
  ngAfterViewInit(): void {
    this.showGiamTruTrachNhiem = (this.cookieService.get('hide') !== 'true');
  }
  // inputSearchClickEvent() {
  //     this.smartSearchService.inputTmpSearch = this.inputTmpSearch;
  //     this.smartSearchService.search();
  // }

  // suggestClose() {
  //     if (this.isOpenSuggest) {
  //         const that = this;
  //         setTimeout(() => {
  //             if (that.isOpenSuggest) {
  //                 that.isOpenSuggest = !that.isOpenSuggest;
  //             }
  //         }, 200);
  //     }
  // }

  // suggestOpen(dataCase) {
  //     if (dataCase === 0) {
  //         if (this.listSuggest === undefined) {
  //             this.searchHttpService.getSuggest('').subscribe(
  //                 res => {
  //                     if (res.hits.hits !== undefined) {
  //                         this.listSuggest = res.hits.hits;
  //                     } else {
  //                         this.listSuggest = [];
  //                     }
  //                 },
  //                 e => {
  //                     // console.log('error server ' + e);
  //                 },
  //                 () => {}
  //             );
  //         }
  //     }
  //     if (!this.isOpenSuggest && (this.listSuggest !== undefined && this.listSuggest.length > 0) && this.isShow) {
  //         this.isOpenSuggest = !this.isOpenSuggest;
  //     }
  // }

  // suggestClose1() {
  //     if (this.isOpenSuggest1) {
  //         this.isOpenSuggest1 = !this.isOpenSuggest1;
  //     }
  // }

  // suggestOpen1(dataCase) {
  //     if (dataCase === 0) {
  //         if (this.listSuggest === undefined) {
  //             this.searchHttpService.getSuggest('').subscribe(
  //                 res => {
  //                     if (res.hits.hits !== undefined) {
  //                         this.listSuggest = res.hits.hits;
  //                     } else {
  //                         this.listSuggest = [];
  //                     }
  //                 },
  //                 e => {
  //                     // console.log('error server ' + e);
  //                 },
  //                 () => {}
  //             );
  //         }
  //     }
  //     if (!this.isOpenSuggest1 && (this.listSuggest !== undefined && this.listSuggest.length > 0)) {
  //         this.isOpenSuggest1 = !this.isOpenSuggest1;
  //     }
  // }

  // suggestIndex(index) {
  //     let id = '';
  //     const caseData = index._id.indexOf('collect_bds') !== -1 ? true : false;
  //     if (caseData) {
  //         id = index._id.split('_')[0];
  //     } else {
  //         id = index._id;
  //     }
  //     this.inputTmpSearch = index._source.tieu_de;
  //     this.isShow = false; // dong search navbar
  //     this.isOpenSuggest = false; // dong Suggest
  //     if (caseData) {
  //         this.sessionStorage.store('idBDS', id);
  //         this.router.navigate(['/dinh-gia/' + this.helpers.createSlugFromTitle(index._source.tieu_de + '-' + id)], {
  //             queryParams: {}
  //         });
  //     } else {
  //         let slug ='';
  //         const area = this.helpers.getAreaInfoFromSource(index._source);
  //         const hanhChinh = ["duong", "phuong_xa", "quan_huyen", "tinh"];
  //         for (let i = 0; i < hanhChinh.length; i++) {
  //             if (area[hanhChinh[i]].ten !== "") {
  //                 slug = area[hanhChinh[i]].friendly;
  //                 break;
  //             }
  //         }
  //         const itemZone = {
  //             id: index._source.id,
  //             duong: index._source.duong,
  //             phuong_xa: index._source.phuong_xa,
  //             du_an: index._source.du_an,
  //             quan_huyen: index._source.quan_huyen,
  //             tinh: index._source.tinh,
  //             img: index._source.img_urls[0]
  //         };
  //         this.sessionStorage.store('zone', itemZone);
  //         this.router.navigate(['/dinh-gia-khu-vuc/' + slug], {
  //             queryParams: { }
  //         });
  //     }
  // }

  // searchRealEstate(event: any, value) {
  //     if (event.keyCode === 13) {
  //         if (value === undefined || value === null || value.trim() === '') {
  //             return;
  //         }
  //         this.searchHttpService.searchRealEstate(this.inputTmpSearch, 0).subscribe(res => {
  //             const results = res.hits.hits;
  //             const resultFilter = results.filter(item => item._id.includes('collect_bds'));
  //             /*
  //             Chỗ này cần "query thử " xem có bao nhiêu kế t quả mặc dù sang trang danh-sach-bat-dong-san chấp nhận phải
  //             query lại. Mục đích là để nếu kết quả mà trả về duy nhất một bất động sản thì bay thẳng đến bất động sản
  //             đó luôn mà không phải qua trang list nữa
  //             */
  //             //    console.log(resultFilter.length);
  //             if (resultFilter.length <= 0) {
  //                 // console.log('resultFilter');
  //                 // console.log(res);

  //                 /* Phải có đường và số nhà thì mới bay tới trang chi tiết được */
  //                 if(this.helpers.isKeyExist(res, ['full_address',['address', 'street']])) {
  //                     const fullAddress = res.full_address;
  //                     const title = this.helpers.buildTitle(fullAddress, ["address", "alley", "lane", "street"], ["id", "tien_to", "ten"]);
  //                     const areaLinks = this.helpers.buildBreadcrumbCustomKey(fullAddress, ['street', 'ward', 'district', 'city'], ["tien_to", "ten", "id"]);
  //                     // console.log(areaLinks);
  //                     const slug = this.helpers.createSlugFromTitle(
  //                         title + ', - ' + areaLinks['ward'].ten + '-' + areaLinks['district'].ten + '-' + areaLinks['city'].ten
  //                     ) + '-' +
  //                     (fullAddress.address && fullAddress.address !== '' ? fullAddress.address : '0') + '.' +
  //                     (fullAddress.alley && fullAddress.alley !== '' ? fullAddress.alley : '0') + '.' +
  //                     (fullAddress.lane && fullAddress.lane !== '' ? fullAddress.lane : '0') + '.' +
  //                     (areaLinks['street'].id !== '' ? areaLinks['street'].id : '0') + '.' +
  //                     (areaLinks['ward'].id !== '' ? areaLinks['ward'].id : '0') + '.' +
  //                     (areaLinks['district'].id !== '' ? areaLinks['district'].id : '0') + '.' +
  //                     (areaLinks['city'].id !== '' ? areaLinks['city'].id : '0');

  //                     this.router.navigate(['/dinh-gia/' + slug], {
  //                         queryParams: {}
  //                     });
  //                 } else {
  //                     /* Nếu không vẫn bay đến trang danh sach */
  //                     this.router.navigate(['/danh-sach-bat-dong-san'], {
  //                         queryParams: {
  //                             query: this.inputTmpSearch
  //                         }
  //                     });
  //                 }
  //                 // this.router.navigate(['/danh-sach-bat-dong-san'], {
  //                 //     queryParams: {
  //                 //         query: this.inputTmpSearch
  //                 //     }
  //                 // });
  //                 // this.searchHttpService.searchFilterRealEstate(body, this.currentPage - 1, 10).subscribe(data => {

  //                 // });
  //             } else if (resultFilter.length === 1) {
  //                 const title = this.helpers.gettitleAdressFromSource(resultFilter[0]._source);
  //                 const areaLinks = this.helpers.getAreaInfoFromSource(resultFilter[0]._source);
  //                 const slug = this.helpers.createSlugFromTitle(
  //                     title + ', - ' + areaLinks['phuong_xa'].ten + '-' + areaLinks['quan_huyen'].ten + '-' + areaLinks['tinh'].ten + '-' + resultFilter[0]._source.id
  //                 );
  //                 // console.log(slug);
  //                 this.router.navigate(['/dinh-gia/' + slug], {
  //                     queryParams: {}
  //                 });
  //             } else {
  //                 /* Nếu thấy nhiều kết quả thì bay tới trang danh sách kết quả tìm kiếm */
  //                 this.router.navigate(['/danh-sach-bat-dong-san'], {
  //                     queryParams: {
  //                         query: this.inputTmpSearch
  //                     }
  //                 });
  //             }
  //         });
  //         /* this.isShow = false;
  //         this.search(0);*/
  //         document.getElementById('searchNavbar').click();
  //     } else {
  //         this.inputSearch.next(value);
  //     }
  // }


  collapseNavbar() {
    this.isNavbarCollapsed = true;
  }

  isAuthenticated() {
    // const authen = this.loginService.isAuthenticated();
    const authen = false;
    // console.log(this.accountService.authenticated);
    return this.loginService.getAccountService().isAuthenticated();

  }

  login() {
    this.loginSsoService.login();
    // this.modalRef = this.loginModalService.open();
  }

  close() {
    this.modalRef.close();
  }

  logout() {
    this.collapseNavbar();
    this.loginService.logout();
    this.router.navigate(['']);
  }

  toggleNavbar() {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
    this.navbarCollapse();
  }

  getImageUrl() {
    return this.isAuthenticated() ? this.loginService.getAccountService().getImageUrl() : null;
  }

  displayNavBar() {
    this.isDisplayNavBar = !this.isDisplayNavBar;
  }


  navbarCollapse() {
    if (this.isNavbarCollapsed) {
      document
        .getElementsByClassName('sidebar')
        .item(0)
        .setAttribute('class', 'sidebar sidebar-collapse-hidden');
    } else {
      document
        .getElementsByClassName('sidebar')
        .item(0)
        .setAttribute('class', 'sidebar sidebar-collapse-display');
    }
  }


  // search(caseData) {
  //     this.isOpenSuggest = false;
  //     if (caseData === 0) {
  //         this.router.navigate(['/danh-sach-bat-dong-san'], {
  //             queryParams: {
  //                 query: this.inputTmpSearch
  //             }
  //         });
  //     }
  // }
  // showSearch() {
  //   if (this.isShow = true) {
  //     this.isShow = false;
  //   }
  // }

  showList() {
    this.isShowList = !this.isShowList;
  }

  isCheckRouter() {
    if (this.router.url === '/landing') {
      if (this.isShow) {
        this.isShow = !this.isShow;
      }
      return false;
    }
    return true;
  }
  // isCheckRouterDinhGia() {
  //     if (this.checkUrl === '/dinh-gia') {
  //         this.checkDinhGia === false;
  //     }
  //     this.checkDinhGia === true;
  // }


  openSearchForMobi() {
    console.log('clickSearchMobile')
    this.action.actionOpenSearch(true);
  }
  parseInt(item) {
    return parseInt(item, 10);
  }
}
