import { Helpers } from './../util/helpers';
import { debounceTime, catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { SearchHttpService } from '../http/search-http.service';

export type SearchType = 'nha_dat_collect' | 'du_an' | 'khu_vuc' | 'thong_tin_moi_gioi';

interface StatusType {
  loading: boolean;
  error: boolean;
}

@Injectable({
  providedIn: 'any'
})
export class SearchSuggestService {
  public areaReference = {
    'analytics_tinh': 0,
    'analytics_quan_huyen': 1,
    'analytics_phuong_xa': 2,
    'analytics_duong': 3,
  };
  public apiCase: SearchType;
  public subscribeAPI: any;
  public status: StatusType;
  public suggestResult: any;
  public searchString: string;
  public searchStringSubscribe: BehaviorSubject<string>;
  public finish: BehaviorSubject<string>;
  isOpenInputMobileSearch = false;
  constructor(
    public searchHttpService: SearchHttpService,
    public helpers: Helpers
  ) {

    this.status = {
      loading: false,
      error: false
    };
    this.searchString = '';
    this.searchStringSubscribe = new BehaviorSubject(null);
    this.searchStringSubscribe.pipe(debounceTime(500)).subscribe(value => {

      let api = null;

      if (this.apiCase === 'nha_dat_collect' || this.apiCase === 'du_an' || this.apiCase === 'khu_vuc') {
        api = this.searchHttpService.getSuggest(this.apiCase, value);
      } else if (this.apiCase === 'thong_tin_moi_gioi') {
        const params = {
          search: value,
          offset: 1,
          limit: 10
        };
        api = this.searchHttpService.listBroker(params);
      }
      if (api) {
        this.subscribeAPI = api
          .pipe(catchError(val => {
            this.status.error = true;
            return of([]);
          }))
          .subscribe(response => {
            if (this.apiCase === 'du_an') {
              this.suggestResult = this.formatResultGetSuggest(response, 'du_an');
            }
            else if (this.apiCase === 'khu_vuc') {
              this.suggestResult = this.formatResultGetSuggest(response, 'khu_vuc');
            } else if (this.apiCase === 'thong_tin_moi_gioi') {
              this.suggestResult = this.formatResultListBroker(response);
            }
            this.status.loading = false;
            console.log('formatResultGetSuggest', this.searchString, this.suggestResult)
            // console.log('this', this.status.loading)
          });
      }
    });
  }

  public inputChange($event): void {
    if (this.subscribeAPI) {
      this.subscribeAPI.unsubscribe();
    }
    this.status.loading = true;
    this.status.error = false;
    if ($event.target.value && $event.target.value !== '') {
      this.searchStringSubscribe.next($event.target.value);
    } else {
      this.status.loading = false;
    }
  }



  public formatResultGetSuggest(data: any, type: string): any {
    const suggestResult = [];
    const result = data && data.hits && data.hits.hits || [];
    result.forEach(element => {
      let slug = '';
      if (type === 'du_an') {
        slug = '/phan-tich-du-an/' + this.helpers.createSlugFromTitle(element._source.tieu_de) + '-' + element._source.id.replace('_analytics_du_an', '');
      } else if (type === 'khu_vuc') {
        if (element._source.address_type.type === 'tinh') {
          slug = '/phan-tich-khu-vuc/' + this.helpers.createSlugFromTitle(
            element._source.tinh.tien_to + '-' + element._source.tinh.ten
          ) + '-' + element._source.tinh.id;
        }
        else if (element._source.address_type.type === 'quan_huyen') {
          slug = '/phan-tich-khu-vuc/' + this.helpers.createSlugFromTitle(
            element._source.quan_huyen.tien_to + '-' + element._source.quan_huyen.ten
          ) + '-' + element._source.quan_huyen.id;
        }
        else if (element._source.address_type.type === 'phuong_xa') {
          slug = '/phan-tich-khu-vuc/' + this.helpers.createSlugFromTitle(
            element._source.phuong_xa.tien_to + '-' + element._source.phuong_xa.ten
          ) + '-' + element._source.phuong_xa.id;
        }
        else if (element._source.address_type.type === 'duong') {
          slug = '/phan-tich-khu-vuc/' + this.helpers.createSlugFromTitle(
            element._source.duong.tien_to + '-' + element._source.duong.ten
          ) + '-' + element._source.duong.id;
        } else {
          slug = '/phan-tich-khu-vuc/' + this.helpers.createSlugFromTitle(
            element._source.tieu_de
          ) + '-' + element._source.id;
          slug = slug
            .replace('_analytics_tinh', '')
            .replace('_analytics_quan_huyen', '')
            .replace('_analytics_phuong_xa', '')
            .replace('_analytics_duong', '');
        }
      } else {

      }
      suggestResult.push({
        name: element._source.tieu_de,
        slug: slug
      });
    });
    return suggestResult;
  }

  public formatResultListBroker(data): any {
    const suggestResult = [];
    const result = data && data.payload && data.payload.data || [];
    result.forEach(element => {
      suggestResult.push(element);
    });
    console.log(suggestResult);
    return suggestResult;
  }
  // this.searchHttpService.getSuggest


  // Mobile Search
  public openMobileSearch() {
    this.isOpenInputMobileSearch = !this.isOpenInputMobileSearch;
    console.log('this.isOpenInputMobileSearch', this.isOpenInputMobileSearch)
  }
}

