import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';

import { LoginModalService } from '../../core';
import { ActivateService } from './activate.service';
import { SeoService } from '../../shared/util/seo.service';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html'
})
export class ActivateComponent implements OnInit {
  error: string;
  success: string;
  modalRef: NgbModalRef;

  constructor(private activateService: ActivateService, private loginModalService: LoginModalService, private route: ActivatedRoute, private seoService: SeoService) { }

  ngOnInit() {
    if (this.seoService.onBrowser) {
      this.route.queryParams.subscribe(params => {
        if (!this.success) {
          this.activateService.get(params['key']).subscribe(
            res => {
              this.error = null;
              this.success = 'OK';
              console.log('this.success', this.success);
            },
            err => {
              this.success = null;
              this.error = 'ERROR';
              console.log('this.error', this.error)
            }
          );
        }
      });
    }
  }

  login() {
    this.modalRef = this.loginModalService.open();
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.

  }
}
