import { NgControl } from '@angular/forms';
import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appValidateToaDoForm]'
})
export class ValidateToaDoFormDirective {

  constructor(private control: NgControl) { }
  @HostListener('input',['$event']) onEvent(event){
    if (/^[0-9.]*$/.test(event.target.value)) {
      // val += `${event.target.value}`;
      // this.control.control.setValue(event.target.value);
    } else {
      console.log('hihi: ', event.target.value);
      this.control.control.setValue(event.target.value ? event.target.value?.slice(0, event.target.value.length - 1) : null);
    }

  }
}
