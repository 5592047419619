import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { SeoService } from '../../shared/util/seo.service';

@Injectable({
  providedIn: 'root'
})
export class LoadExternalScriptService {
  public listScript = {}
  constructor(
    public seoService: SeoService
  ) { }

  public loadDynamicScript(src): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.seoService.onBrowser) {
        if (!this.listScript.hasOwnProperty(src)) {
          this.listScript[src] = new BehaviorSubject(null)
          const scriptElement = window.document.createElement('script');
          scriptElement.src = src;
          window.document.body.appendChild(scriptElement);
          scriptElement.onload = () => {
            for (const [key, value] of Object.entries(this.listScript)) {
              if (key === src) {
                this.listScript[src].next(true);
              }
            }
            resolve();
          };

          scriptElement.onerror = () => {
            reject();
          };

        } else {
          resolve();
        }
      } else {
        resolve();
      }


    });
  }
}
