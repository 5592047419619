import { Directive, Output, EventEmitter, ElementRef } from '@angular/core';

@Directive({
  selector: '[appClickOutSide]'
})
export class ClickOutSideDirective {

  constructor(private el: ElementRef) { 
    // console.log('App click out side', el);
    const backgroundClick = document.createElement("div");
    backgroundClick.style.zIndex = '2';
    backgroundClick.style.position = 'fixed';
    el.nativeElement.parentNode.prepend(backgroundClick);
  }
  @Output('clickOutside') clickOutside: EventEmitter<any> = new EventEmitter();

}
