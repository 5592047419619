import { Route } from '@angular/router';

// import { UserRouteAccessService } from 'src/app/core';
import { SettingsComponent } from './settings.component';

export const settingsRoute: Route = {
    path: 'settings',
    component: SettingsComponent,
    data: {
        authorities: ['ROLE_USER'],
        pageTitle: 'global.menu.account.settings'
    },
    // canActivate: [UserRouteAccessService]
};
