import { Payload, ResponseTaiSan, ResponseBdsXungQuanh } from './../model/payload';
import { map, retry, switchMap, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { StaticInfo } from '../model/dcs/static-info';
import { SessionStorageService } from 'ngx-webstorage';
import { GeoMeta } from '../model/dcs/geo-meta.model';
import { ThuaDat } from '../model/dcs/thua-dat.model';
import { BatDongSan } from '../model/dcs/bat-dong-san.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DcsHttpService {
  public loading: any = {
    getInfoBds: false,
  };
  public error: any = {
    getInfoBds: false,
  };
  constructor(private http: HttpClient, private $sessionStorage: SessionStorageService) { }

  // Lay thong tin tinh theo tinhId
  getStaticInfo(tinhId: number): Observable<Payload<StaticInfo>> {
    const data = this.$sessionStorage.retrieve('thongTinTinh_' + tinhId);
    if (data !== null) {
      return of(data);
    } else {
      return this.http.get<any>(environment.API.SERVER_API_DATA_COLLECTION + '/api/static-info/get-all-with-tinh?tinh-id=' + tinhId).pipe(
        map(staticInfo => {
          this.$sessionStorage.store('thongTinTinh_' + tinhId, staticInfo);
          return staticInfo;
        })
      );
    }
  }

  // Lay thong tin bat dong san theo idBds
  getInfoBds(idBds: number, from?: any): Observable<Payload<ResponseTaiSan>> {
    this.loading.getInfoBds = true;
    this.error.getInfoBds = false;
    let param = from === 'external' ? '?src=' + from : ''
    return this.http.get<any>(environment.API.SERVER_API_DATA_COLLECTION + '/api/dia-chi-cu-the/get-info/' + idBds + param)
      .pipe(
        retry(0),
        switchMap(value => {
          // console.log('value from switch map', value);
          this.loading.getInfoBds = false;
          return of(value);
        }),
        catchError(err => {
          // console.log('Co loi xay ra', err);
          this.loading.getInfoBds = false;
          this.error.getInfoBds = true;
          return of('{"hits":{"hits":[]}}');
        })
      );
  }

  // Lay metadata cua ban do quy hoach theo idBds
  // layBanDoQuyHoach(idBds: number): Observable<Payload<GeoMeta>> {
  //     return this.http.get<any>(environment.API.SERVER_API_DATA_COLLECTION + '/api/dia-chi-cu-the/quy-hoach-trong-khu-vuc/' + idBds);
  // }

  // Lay thong tin thua dat theo so to so thua
  // layThongTinThuaDat(phuongXaId, soTo, soThua): Observable<ThuaDat[]> {
  //     return this.http.get<any>(
  //         environment.API.SERVER_API_DATA_COLLECTION +
  //             '/api/dia-chi-cu-the/thong-tin-thua-dat' +
  //             '?phuong-xa-id=' +
  //             phuongXaId +
  //             '&so-to=' +
  //             soTo +
  //             '&so-thua=' +
  //             soThua
  //     );
  // }

  layBdsXungQuanh(id, phuongId, quanId, limit, offset): Observable<Payload<ResponseBdsXungQuanh>> {
    return this.http.get<Payload<ResponseBdsXungQuanh>>(
      environment.API.SERVER_API_DATA_COLLECTION +
      '/api/dia-chi-cu-the/bat-dong-san-xung-quanh' +
      '?id-bat-dong-san-nhap-lieu=' +
      id +
      '&phuong-id=' +
      phuongId +
      '&quan-id=' +
      quanId +
      '&limit=' +
      limit +
      '&offset=' +
      offset
    );
  }

  layBdsXungQuanhKhuVuc(duongId, phuongId, quanId, tinhId, limit, offset): Observable<Payload<ResponseBdsXungQuanh>> {
    return this.http.get<Payload<ResponseBdsXungQuanh>>(
      environment.API.SERVER_API_DATA_COLLECTION +
      '/api/dia-chi-cu-the/bds-khu-vuc' +
      '?duong-id=' +
      duongId +
      '&phuong-id=' +
      phuongId +
      '&quan-id=' +
      quanId +
      '&tinh-id=' +
      tinhId +
      '&limit=' +
      limit +
      '&offset=' +
      offset
    );
  }

  getBDSTuongDuongByGps(lat, lng, duongId, phuongId, quanId, tinhId, viTri) {
    return this.http.get<any>(environment.API.SERVER_API_DATA_COLLECTION +
      '/api/dia-chi-cu-the/bds-tuong-duong-by-gps?lat=' + lat +
      '&lon=' + lng +
      '&duong-id=' + duongId +
      '&phuong-id=' + phuongId +
      '&quan-id=' + quanId +
      '&tinh-id=' + tinhId +
      '&vitri=' + viTri
    );
  }

  getListAddress(type, parentId?): Observable<any[]> {
    let params = 'type=' + type;
    if (parentId !== '' && parentId) {
      params += '&idPre=' + parentId;
    }
    return this.http.get<any[]>(environment.API.SERVER_API_DATA_COLLECTION + '/api/dia-chis?' + params, {
    });
  }
  getAddress(type, id?): Observable<any[]> {
    let params = 'type=' + type;
    if (id !== '' && id) {
      params += '&id=' + id;
    }
    return this.http.get<any[]>(environment.API.SERVER_API_DATA_COLLECTION + '/api/get-dia-chis?' + params, {
    });
  }

  nhaDatNeighbor(lat, lng, duongId) {
    return this.http.post<any>(environment.API.SERVER_API_DATA_COLLECTION + '/api/bds-submit/nha-dat-neighbor', { lat: lat, lon: lng, duongId: duongId });
  }
}
