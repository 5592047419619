import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { SearchType } from './search-suggest.service';
import { SearchService } from 'src/app/layouts/landing/crs-landing/search/search.service';
@Component({
  selector: 'app-search-suggest',
  templateUrl: './search-suggest.component.html',
  styleUrls: ['./search-suggest.component.scss'],
})
export class SearchSuggestComponent implements OnInit, OnDestroy {
  @Input() isOpenInput: boolean
  @Input() type: SearchType;
  @Input() placeholder: string;
  constructor(
    public searchService: SearchService,
    public router: Router
  ) {
    this.isOpenInput = false;
  }
  ngOnInit(): void {
    this.searchService.setDefaultType(this.type);
    this.searchService.typeSearch = this.type;
  }
  ngOnDestroy(): void {
  }

}
