import { SeoService } from './../util/seo.service';
import { Component, OnInit, Input, Directive, ContentChild, TemplateRef, ElementRef, Output, EventEmitter } from '@angular/core';
type PopupMode = 'NORMAL' | 'FULLHEIGHT';

@Directive({ selector: 'ng-template[app-popup-header]' })
export class PopupHeaderDirective {
  constructor(public templateRef: TemplateRef<any>) { }
}

@Directive({ selector: 'ng-template[app-popup-body]' })
export class PopupBodyDirective {
  constructor(public templateRef: TemplateRef<any>) { }
}

@Directive({ selector: 'ng-template[app-popup-footer]' })
export class PopupFooterDirective {
  constructor(public templateRef: TemplateRef<any>) { }
}

@Component({
  selector: 'app-popup',
  templateUrl: './style-1/popup.component.html',
  styleUrls: ['./style-1/popup.component.scss']
})
export class PopupComponent implements OnInit {
  @Input() type?: string;
  innerWidth: number;
  @Input() open: boolean;
  /* Điều khiển chiều rộng */
  @Input() width; // 40 default
  @Input() desktopWidth;
  @Input() mobileWidth;
  @Input() popupWidth;
  /* Điều khiển chiều cao */
  @Input() height; // 40 default
  @Input() desktopHeight;
  @Input() mobileHeight;
  @Input() popupHeight;
  @Input() borderRadius = 16;
  @Input() desktopBorderRadius;
  @Input() mobileBorderRadius;
  @Input() padding = 32;
  @Input() paddingTop = 0;
  @Input() paddingBottom = 32;
  @Input() closeButton = true;
  @Input() top;
  @Output() openChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() mode: PopupMode = 'NORMAL';

  @ContentChild(PopupHeaderDirective, { static: false }) popupHeader!: PopupHeaderDirective;
  @ContentChild(PopupBodyDirective, { static: false }) popupBody!: PopupBodyDirective;
  @ContentChild(PopupFooterDirective, { static: false }) popupFooter!: PopupFooterDirective;

  shouldShow = true;
  constructor(
    public seoService: SeoService
  ) {
    if (this.seoService.onBrowser) {
      this.innerWidth = window && window.innerWidth;
    }
    
  }
  toggle() {
    this.shouldShow = !this.shouldShow;
  }


  ngOnInit(): void {
    if (this.seoService.onBrowser) {
      /* Điều khiển border-radius */
      if (this.innerWidth > 576) {
        this.borderRadius = this.desktopBorderRadius;
      } else {
        this.borderRadius = this.mobileBorderRadius;
      }
      this.borderRadius = this.borderRadius || 16;
      /* Điều khiển chiều rộng */
      if (this.innerWidth > 576) {
        this.popupWidth = this.desktopWidth;
      } else {
        this.popupWidth = this.mobileWidth;
      }
      this.popupWidth = this.popupWidth || this.width || '30';
      /* Điều khiển chiều cao */
      if (this.innerWidth > 576) {
        this.popupHeight = this.desktopHeight;
      } else {
        this.popupHeight = this.mobileHeight;
      }
      this.popupHeight = this.popupHeight || this.height || '80';
      if (this.mode === 'FULLHEIGHT') {
        this.popupHeight = window.innerHeight - 32;
      }
    }
  }

  close() {
    this.openChange.emit(false);
  }

}
