import { Component, OnInit, Input, Directive, ContentChild, TemplateRef, Output } from '@angular/core';
import { EventEmitter } from 'events';

@Directive({ selector: 'ng-template[label]' })
export class LabelButtonDirective {
  constructor(public templateRef: TemplateRef<any>) { }
}

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() type: string;
  @Input() text: string;
  @Input() disabled: boolean;
  @Output() btnClick = new EventEmitter();
  @ContentChild(LabelButtonDirective, {static: false}) labelButton!: LabelButtonDirective;
  constructor() { }

  ngOnInit(): void {
  }

}
