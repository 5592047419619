import { Injectable, Inject, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class RouteStateService {
    public history: any = [];
    public lastTwoHistory: any = [];

    public routeParamHistory: any = [];
    public routeAction: Subject<string> = new Subject<string>();
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
  ) {
    this.activeRoute.paramMap.subscribe(param => {
      this.routeParamHistory.push(this.router.parseUrl(this.router.url).queryParams);
    });

    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe(({urlAfterRedirects}: NavigationEnd) => {
      this.history = [...this.history, urlAfterRedirects];
    //     this.history.push(urlAfterRedirects);
      if (this.history.length > 2) {
        this.lastTwoHistory = this.history.slice(this.history.length-2);
        // console.log('this.lastTwoHistory', this.lastTwoHistory);
        // console.log('urlAfterRedirects', this.history.slice(this.history.length-2));
      }
      this.routeParamHistory.push(this.router.parseUrl(this.router.url).queryParams);
      if (this.routeParamHistory.length > 5) {
        this.routeParamHistory.shift();
      }
      // console.log('this.routeParamHistory', this.routeParamHistory);
      // console.log('this.routeParamHistory', this.routeParamHistory);
    });


    this.router.events
    .pipe(filter(event => event instanceof NavigationStart))
    .subscribe((event: NavigationStart) => {
      // console.log(event);
      if (event.navigationTrigger === 'popstate') {
        setTimeout(() => {
          // console.log('this.routeParamHistory', this.routeParamHistory);
          this.routeAction.next(this.getBackAction());
        },100);
        // this.routeAction.next(this.getBackAction());
        // console.log('this.routeParamHistory', this.routeParamHistory);
      }
    });
  }

  getBackAction() {
    
    let lastUrl = this.routeParamHistory[this.routeParamHistory.length - 1];
    let nearLastUrl = this.routeParamHistory[this.routeParamHistory.length - 2];
    console.log(nearLastUrl, lastUrl);
    let paramRemoved = ['bds-id', 'thuadat-id', 'estimate', 'login'];

      for (let i = 0; i < paramRemoved.length; i++) {
        // console.log('item', item);

        // console.log((item in lastUrl) && !(item in nearLastUrl));
        
        console.log(nearLastUrl[paramRemoved[i]], lastUrl[paramRemoved[i]]);
        console.log((nearLastUrl[paramRemoved[i]] === undefined) && (lastUrl[paramRemoved[i]] !== undefined));
        if ((nearLastUrl[paramRemoved[i]] !== undefined) && (lastUrl[paramRemoved[i]] === undefined)) {
          
          return paramRemoved[i]
        }
      }

    return '';
  }


  appendParamFromUrl(param, value) {
    let urlParam = this.router.parseUrl(this.router.url);
    let urlSplit = this.router.url.split('?');
    urlParam.queryParams[param] = value;
    this.router.navigate([urlSplit && urlSplit[0]], {
        queryParams: urlParam.queryParams
    });
  }
  removeParamFromUrl(param) {
    let urlParse = this.router.parseUrl(this.router.url);
    let urlSplit = this.router.url.split('?');
    if (urlParse.queryParams[param]) {
        delete urlParse.queryParams[param];
        this.router.navigate([urlSplit && urlSplit[0]], {
            queryParams: urlParse.queryParams
        });
    }
  }
}
