import { SearchSuggestComponent } from './../search-suggest.component';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-suggest-small',
  templateUrl: './search-suggest-small.component.html',
  styleUrls: ['./search-suggest-small.component.scss']
})
export class SearchSuggestSmallComponent extends SearchSuggestComponent {

}
