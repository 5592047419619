import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { FormBuilder } from '@angular/forms';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { GPSType, MarkerType } from './form-search.model';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class FormSearchService {

  constructor(private fb: FormBuilder, private http: HttpClient, private location: Location) { }

  getTraCuuThuaDat(soTo, soThua, phuongXaId) {
    return this.http.get(`https://gapi-sandbox.cenhomes.vn/dev/g-data-estimate/v1/api/dia-chi-cu-the/thong-tin-thua-dat?so-to=${soTo}&so-thua=${soThua}&phuong-xa-id=${phuongXaId}`).pipe(
      map((res: any) => {
        if (res && res.payload.data && res.payload.data.length) {
          return res.payload.data;
        }
        return [];
      }),
      catchError(err => {
        return of([]);
      })
    );
  }

  dinhGiaFormHouse(body, pages, size) {
    return this.http.post<any>(environment.API.SERVER_SEARCH + 'v2/dinhgia-form-house?page=' + pages + '&size=' + size, body)
      .pipe(
        map(res => {
          if (res && res.payload.hits && res.payload.hits.hits && res.payload.hits.hits.length) {
            return res.payload.hits.hits.map(i => {
              const item = i?._source;
              return {
                id: item?.id,
                title: this.generateTitle(item),
                shortTitle: this.generateShortTitle(item),
                direction: item?.huong_nha,
                soTang: item?.so_tang_nha,
                tinhId: item?.tinh?.id,
                phuongXaId: item?.phuong_xa?.id,
                quanHuyenId: item?.quan_huyen?.id,
                img:  item?.url_anh_mat_tien ? environment.API.SERVER_IMG +  item?.url_anh_mat_tien?.replace('image_data_watermark', 'image_data_watermark_500x430') : null
              }
            })
          }
          return [];
        }),
        catchError(err => {
          return of([]);
        })
      );
  }
  private generateShortTitle(item) {
    // ${item?.so_nha} ${item?.duong?.ten}
    let title = '';
    if (item.so_nha) {
      if (Number.isInteger(+item.so_nha)) {
        title += `Số ${item.so_nha}`;  
      } else {

        title += item.so_nha;
      }
    }
    if (item.dl_ngo) {
      if (Number.isInteger(+item.dl_ngo)) {
        title += `, Ngõ ${item.dl_ngo}`;
      } else {
        title += `, ${item.dl_ngo}`;
      }
    }

    if (item.dl_ngach_hem) {
      if (Number.isInteger(+item.dl_ngach_hem)) {
        title += `, Ngách ${item.dl_ngach_hem}`;
      } else {
        title += `, ${item.dl_ngach_hem}`;
      }
    }
    if (item.duong) {
      if (Number.isInteger(+item.duong.ten)) {
        title += `, ${item.duong.tien_to} ${item.duong.ten}`;
      } else if(item.duong?.ten?.split(' ')[0] === 'Số') {
        title += `, ${item.duong.tien_to} ${item.duong?.ten?.split(' ')[1]}`;
      } else {
        title += `, ${item.duong.ten}`;
      }
    }
    return title;
  }

  private generateTitle(item) {
    let title = '';
    if (item?.phuong_xa) {
      if (Number.isInteger(+item.phuong_xa.ten)) {
        title += `${item.phuong_xa.tien_to} ${item.phuong_xa.ten}`;
      } else {
        title += item.phuong_xa.ten;
      }
    }
    if (item?.quan_huyen) {
      if (Number.isInteger(+item.quan_huyen.ten)) {
        title += `, ${item.quan_huyen.tien_to} ${item.quan_huyen.ten}`;
      } else {
        title += ', ' + item.quan_huyen.ten;
      }
    }
    if (item?.tinh) {
      title += ', ' + item?.tinh?.ten; 
    }
    return title;
  }
  dinhGiaFormGps(body, pages, size) {
    return this.http.post<any>(environment.API.SERVER_SEARCH + 'v2/dinhgia-form-gps?page=' + pages + '&size=' + size, body)
      .pipe(
        map(res => {
          if (res && res.payload.hits && res.payload.hits.hits && res.payload.hits.hits.length) {
            return res.payload.hits.hits.map(i => {
              const item = i?._source;
              const {lat, lon} = item?.toa_do_danh_dau;
              return {
                code: item?.code,
                GPS: {
                  lat,
                  lng: lon
                }
              }
            })
          }
          return [];
        }),
        catchError(err => {
          return of([]);
        })
      );
  }

  redirectToUrlWithoutReloadPage(formValue) {
    const { tinhId, quanId, phuongId, duongId, thonXomId, ngoId, ngachHemId, soNha } = formValue;
    const params = {'tinh-id': tinhId, 'quan-id': quanId, 'phuong-id': phuongId, 'duong-id': duongId, 'thon-xom-id': thonXomId, 'ngo': ngoId, 'ngach-hem': ngachHemId, 'so-nha': soNha};
    const obj = this.removeEmpty(params);
      console.log('params: ', obj);
      const segment = this.location.path().split('?')[0];
      let url = '';
      Object.keys(obj).forEach((key, index) => {
        if (index === Object.keys(obj).length - 1) {
          url += `${key}=${obj[key]}`;
        } else {

          url += `${key}=${obj[key]}&`;
        }
      });
      console.log('url: ', url);
      this.location.go(`${segment}?${url}`);
  }


  removeEmpty(obj) {
    return Object.entries(obj)
    .filter(([_, v]) => v != null)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
  }


  public searchAddress(): void {
    /*
    let params: FormSearchRequest; // Lấy từ diaChi và bounds
    Gọi API this.http.post(`https://gapi.cenhomes.vn/c-search/v2/dinhgia-form-gps?page=0&size=50`, params)
    response trả về danhSachGPS, nếu gọi API bị lỗi or timeout thì đi qua pipe cho danhSachGPS = []
    this.danhSachBDS.emit(danhSachGPS)
    Gọi API this.http.post(`https://gapi.cenhomes.vn/c-search/v2/dinhgia-form-house?page=0&size=50`, params)
    response trả về danhSachGPS, nếu gọi API bị lỗi or timeout thì đi qua pipe cho danhSachGPS = []
    this.danhSachBDS.emit(danhSachBDS)
    */

  }
  public searchLandPlotMapSheet(): void {
    /*
    Goi API https://gapi-sandbox.cenhomes.vn/dev/c-search/v2/search-so-to-so-thua
    phuong_xa_id: 407
    so_thua: "20"
    so_to: "20"
    */
  }


  initFormTraCuuToaDo() {
    return this.fb.group({
      lat: [null],
      lng: [null]
    });
  }

  initFormTraCuuThuaDat() {
    return this.fb.group({
      soTo: [null],
      soThua: [null],
    });
  }

  initFormTimKiemNangCao() {
    return this.fb.group({
      tinhId: [null],
      quanId: [null],
      phuongId: [null],
      thonXomId: [null],
      duongId: [null],
      ngoId: [null],
      ngachHemId: [null],
    });
  }
}
