import { InterceptorService } from './../../blocks/interceptor/interceptor.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthServerProvider {
    constructor(
        private http: HttpClient,
        private $localStorage: LocalStorageService,
        private $sessionStorage: SessionStorageService,
        private interceptorService: InterceptorService
    ) {

     }

    getToken() {
        return this.$localStorage.retrieve('g-auth-jwt') || this.$sessionStorage.retrieve('g-auth-jwt');
    }

    login(credentials): Observable<any> {
        const data = {
            username: credentials.username,
            password: credentials.password,
            rememberMe: credentials.rememberMe
        };
        return this.http
            .post(environment.API.SERVER_API_G_AUTH + '/auth/authenticate', data, { observe: 'response' })
            .pipe(map(authenticateSuccess.bind(this)));

        function authenticateSuccess(resp) {
            this.$localStorage.store('user_id', resp.body.user_id);
            this.$localStorage.store('full_name', resp.body.full_name);
            // this.$localStorage.store('account', resp.body.extraUserDTO);
            const jwt = resp.body.id_token;
            if (jwt) {
                this.storeAuthenticationToken(jwt, credentials.rememberMe);
                return jwt;
            }
        }
    }

    loginWithToken(jwt, rememberMe) {
        if (jwt) {
            this.storeAuthenticationToken(jwt, rememberMe);
            // return Promise.resolve(jwt);
        } /* else {
            return Promise.reject('auth-jwt-service Promise reject'); // Put appropriate error message here
        }*/
    }

    storeAuthenticationToken(jwt, rememberMe) {
        // if (rememberMe) {
        //     // localStorage.setItem('app-authenticationtoken', jwt);
        //     this.$localStorage.store('g-auth-jwt', jwt);
        //     this.interceptorService.jwt = jwt;
        //     this.interceptorService.jwtBehaviorSubject.next(jwt);
        // } else {
        //     // localStorage.setItem('app-authenticationtoken', jwt);
        //     this.$sessionStorage.store('g-auth-jwt', jwt);
        //     this.interceptorService.jwt = jwt;
        //     this.interceptorService.jwtBehaviorSubject.next(jwt);
        // }
    }

    logout(): Observable<any> {
        return new Observable(observer => {
            this.$localStorage.clear('g-auth-jwt');
            this.$sessionStorage.clear('g-auth-jwt');
            this.$localStorage.clear('full_name');
            this.$sessionStorage.clear('full_name');
            this.$localStorage.clear('user_id');
            this.$sessionStorage.clear('user_id');
            this.$localStorage.clear('account');
            // this.interceptorService.jwt = null;
            // this.interceptorService.jwtBehaviorSubject.next(null);
            observer.complete();
        });
    }
}
