import { Injectable, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, map, retry, catchError, finalize, throwIfEmpty } from 'rxjs/operators';
import { Helpers } from 'src/app/shared/util/helpers';
import { SearchHttpService } from 'src/app/shared/http/search-http.service';
@Injectable({
  providedIn: 'root'
})
export class SmartSearchService {
  item = [];
  public statusType;
  public inputTmpSearch;
  public inputSearch = new Subject<any>();
  // public suggestResult: Subject<any> = new Subject<any>();
  public suggestResult: any;
  public suggestResultOrigin: any;
  public isLoading: boolean;
  public searchMode: any;
  public searchModeSelected: number = 0;
  public getSuggestSubscribe: any;

  public windowWidth: number;
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.windowWidth = window.innerWidth;
  }

  constructor(
    private router: Router,
    private searchHttpService: SearchHttpService,
    private helpers: Helpers
  ) {
    this.searchMode = [
      {
        catType: 'nha_dat_collect',
        name: 'Tài sản',
        selected: true,
        icon: 'fas fa-home',
        svg: 'fa_bold_home',
        placeholder: 'Nhập địa chỉ BĐS. Ví dụ: 137 Nguyễn Ngọc Vũ',
        value: ''
      },
      {
        catType: 'du_an',
        name: 'Dự án',
        selected: false,
        icon: 'fas fa-building',
        svg: 'fa_bold_building',
        placeholder: 'Nhập tên dự án. Ví dụ: Vinhomes Royal City',
        value: ''
      },
      {
        catType: 'khu_vuc',
        name: 'Khu vực',
        selected: false,
        icon: 'fas fa-map-marker-alt',
        svg: 'fa_bold_map_marker',
        placeholder: 'Nhập tên khu vực. Ví dụ: Quận Cầu Giấy',
        value: ''
      }
    ];
    // console.log('this.searchMode', this.searchMode);
    this.windowWidth = window.innerWidth;

    this.getSuggestSubscribe = this.inputSearch.pipe(
      debounceTime(100)
    ).subscribe(searchTextValue => {
      if (searchTextValue === '') {
        this.isLoading = false;
      }
      this.searchHttpService.getSuggest(this.searchMode[this.searchModeSelected].catType, searchTextValue).subscribe(response => {
        this.suggestResultOrigin = response && response.hits && response.hits.hits;
        this.suggestResult = this.buildSuggestion(response);
        this.isLoading = false;
      });
    });
  }

  dinhgiaSuggest() {


  }


  search() {
    if (this.inputTmpSearch === undefined || this.inputTmpSearch === null || this.inputTmpSearch.trim() === '') {
      return;
    }
    /* Unsubscribe để hủy theo dõi suggest khi ấn enter, sau đó subscrible lại  */
    this.getSuggestSubscribe.unsubscribe();
    this.getSuggestSubscribe = this.inputSearch.pipe(
      debounceTime(100)
    ).subscribe(searchTextValue => {
      this.searchHttpService.getSuggest(this.searchMode[this.searchModeSelected].catType, searchTextValue).subscribe(response => {
        this.suggestResultOrigin = response && response.hits && response.hits.hits;
        this.suggestResult = this.buildSuggestion(response);
        this.isLoading = false;
      });
    });

    this.isLoading = false;

    if (this.searchModeSelected === 0) {
      /* Khi enter nếu là search tài sản thì bắn string qua bên bản đồ xử lý */
      this.router.navigate(['/map'], {
        queryParams: {
          query: this.inputTmpSearch
        }
      });
    } else {
      /* Còn nếu là search khu vực hoặc dự án thì cần gọi lại suggest (vì có thể khi ấn enter suggest chưa thực hiện xong) và lấy kết quả thứ nhất */
      this.searchHttpService.getSuggest(this.searchMode[this.searchModeSelected].catType, this.inputTmpSearch).subscribe(response => {
        this.suggestResult = this.buildSuggestion(response);
        this.isLoading = false;
        // if (this.suggestResult.length > 0) {
        //     this.gotoLinkFromsuggest(this.suggestResult[0], '');
        // } else {
        //     /* Không tìm thấy suggest */
        // }

      });
    }

    (<any>window).ga('send', 'event', {
      eventCategory: 'Search',
      eventAction: 'click',
      eventLabel: 'mainSearch',
      eventValue: this.inputTmpSearch
    });


  }

  goToLocation(tinhId, quanId, phuongId, duongId, ngo, ngach, soNha, latLng) {
    this.router.navigate(['/map'], {
      queryParams: {
        "tinh-id": tinhId,
        "quan-id": quanId,
        "phuong-id": phuongId,
        "duong-id": duongId,
        "ngo": ngo,
        "ngach-hem": ngach,
        "so-nha": soNha,
        "coord": '{"lat":' + latLng[0] + ',"lng":' + latLng[1] + '}'
      }
      // ?tinh-id=1&quan-id=28&duong-id=29240&so-nha=12000&coord=
    });
  }

  gotoLinkFromsuggest(suggest, title) {
    console.log('suggest', suggest);
    // console.log(suggest.slug);

    if (suggest.slug !== '') {
      this.router.navigate([suggest.slug]);
    } else {
      if (suggest.bds.id) {
        this.router.navigate(['/map'], {
          queryParams: {
            'bds-id': suggest.bds.id,
            'from': suggest.bds.from
          }
        });
      }
    }
    // this.router.navigate([suggest]);
  }

  changeSearchMode(index) {
    // console.log(index);
    this.searchModeSelected = index;
    this.searchMode.forEach(element => {
      element.selected = false;
    });
    this.searchMode[index].selected = true;
    this.inputTmpSearch = this.searchMode[index].value;
  }


  buildSuggestion(response) {
    this.suggestResult = [];
    if (response.hits.hits.length > 0) {
      response.hits.hits.forEach(element => {
        let bdsId = undefined;
        let slug = '';
        if (element._source.id && element._id.indexOf('analytics_du_an') !== -1) {
          slug = '/phan-tich-du-an/' + this.helpers.createSlugFromTitle(element._source.tieu_de) + '-' + element._source.id.replace("_analytics_du_an", "");
          // slug = '';
        } else if (element._source.id && element._source.from && (element._source.from.indexOf('collect_bds') !== -1 || element._source.from.indexOf('external') !== -1)) {
          /* Nếu có id */
          bdsId = element._source.id;
          if (this.windowWidth >= 720) {
            /* Để rỗng để đánh dấu là cần bay đến bản đồ */
            slug = '';
          } else {
            // slug = '/dinh-gia/' + this.helpers.createSlugFromTitle(element._source.tieu_de) + '-' + bdsId;
            slug = '';
          }
        } else if (element._id.indexOf('crawl_address') !== -1) {
          /* Nếu không có id */
          bdsId = '' + (element._source.so_nha ? element._source.so_nha.replace(/\/|\\/g, ':') : '0') + '.'
            + (element._source.dl_ngach ? element._source.dl_ngach.replace(/\/|\\/g, ':') : '0') + '.'
            + (element._source.dl_ngo ? element._source.dl_ngo.replace(/\/|\\/g, ':') : '0') + '.'
            + (element._source.duong.id ? element._source.duong.id : '0') + '.'
            + (element._source.phuong_xa.id ? element._source.phuong_xa.id : '0') + '.'
            + (element._source.quan_huyen.id ? element._source.quan_huyen.id : '0') + '.'
            + (element._source.tinh.id ? element._source.tinh.id : '0');
          slug = '/dinh-gia/' + this.helpers.createSlugFromTitle(element._source.tieu_de) + '-' + bdsId;
        } else {
          /* Nếu là khu vực */
          const area = this.helpers.getAreaInfoFromSource(element._source);
          const hanhChinh = ["duong", "phuong_xa", "quan_huyen", "tinh"];
          for (let i = 0; i < hanhChinh.length; i++) {
            if (area[hanhChinh[i]].ten !== "") {
              slug = area[hanhChinh[i]].friendly;
              break;
            }
          }
          slug = '/phan-tich-khu-vuc/' + slug;
          // if (element._source && element._source.address_type) {
          //     slug = slug + '-' +  element._source.address_type.id;
          // }

        }

        this.suggestResult.push({
          title: highLight(this.inputTmpSearch, element._source.tieu_de),
          iconClass: 'far fa-flag',
          slug: slug,
          bds: element._source
        });
        
        console.log('his.suggestResult', this.inputTmpSearch, this.suggestResult);

      });
    } else {

    }
    return this.suggestResult;
  }

}

export function highLight(text, result): any {
  const regex = new RegExp(`(${addAccents(text)})`, 'gi');
  // const match = regex.exec(result);
  console.log('highLight', regex, '/(ph[uùúụủũưừứựửữ][oòóọỏõôồốộổỗơờớợởỡ]ng li[eèéẹẻẽêềếệểễ]t)/gi');
  // if (regex.test(boDau(result))) {
  return (result).replace(regex, '<b>$1</b>');
  // } else {
    
  // }
  return result;
}
export function boDau(str): any {
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, ' ');
  str = str.replace(/\s+/g, ' ');
  str = str.trim();
  // console.log(str);
  return str;
}

  // characters to strip from start and end of the input string
export const endRegExp = new RegExp('^[^\\w]+|[^\\w]+$', 'g');

  // characters used to break up the input string into words
export const breakRegExp = new RegExp('[^\\w\'-]+', 'g');

var openLeft = false;
var openRight = false;

// convert ASCII to wildcard
function addAccents(input): string {
  let retval = input;
  // retval = retval.replace(/([ao])e/ig, '$1');
  retval = retval.replace(/a/ig, '[aàáạảãâầấậẩẫăằắặẳẵ]');
  retval = retval.replace(/e/ig, '[eèéẹẻẽêềếệểễ]');
  retval = retval.replace(/o/ig, '[oòóọỏõôồốộổỗơờớợởỡ]');
  retval = retval.replace(/u/ig, '[uùúụủũưừứựửữ]');
  retval = retval.replace(/d/ig, '[dđ]');
  retval = retval.replace(/i/ig, '[iìíịỉĩ]');
  retval = retval.replace(/y/ig, '[yỳýỵỷỹ]');
  retval = retval.replace(/ /ig, '|');
  return retval;
}

export function setRegex(input): any {
  input = input.replace(/\\u[0-9A-F]{4}/g, ''); // remove missed unicode
  input = input.replace(endRegExp, '');
  input = input.replace(breakRegExp, '|');
  input = input.replace(/^\||\|$/g, '');
  input = addAccents(input);
  if (input) {
    let re = '(' + input + ')';
    if (!openLeft) {
      re = '(?:^|[\\b\\s])' + re;
    }
    if (!openRight) {
      re = re + '(?:[\\b\\s]|$)';
    }
    const matchRegExp = new RegExp(re, 'gi');
    return matchRegExp;
  }
  return false;
}
