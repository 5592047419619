import { Component, OnInit, Directive, TemplateRef, Input, Output, EventEmitter, ContentChild, HostListener, Injectable } from '@angular/core';

export type ClickOutsideTechType = 'BACK_DROP' | 'CLICK_OUTSIDE';

@Injectable({
  providedIn: 'any'
})
export class DropdownMenuService {
  public open: boolean;
  constructor() {
    this.open = false;
  }
}

@Directive({
  selector: 'ng-template[app-dropdown-menu-label]',
  // providers: [DropdownMenuService]
})
export class DropdownMenuLabelDirective {
  constructor(public templateRef: TemplateRef<any>) { }
}

@Directive({
  selector: 'ng-template[app-dropdown-menu-items]',
  // providers: [DropdownMenuService]
})
export class DropdownMenuItemsDirective {
  constructor(public templateRef: TemplateRef<any>) { }
}

@Directive({
  selector: '[app-dropdown-menu-click-close]',
  // providers: [DropdownMenuService]
})
export class ClickToCloseDirective {
  constructor(
    public dropdownMenuService: DropdownMenuService,

    ) { }
  @HostListener('click', ['$event']) onClick($event): any {
    // Có thời gian cần xử lý trường hợp này emit openChange
    this.dropdownMenuService.open = false;

  }
}

@Component({
  selector: 'app-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss'],
  providers: [DropdownMenuService]
})
export class DropdownMenuComponent implements OnInit {
  @Input() clickOutsideTechType: ClickOutsideTechType;
  @Input() set open(value: boolean) {
    this.dropdownMenuService.open = value;
  }
  @Input() menuLeftAlign: boolean;
  @Output() openChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ContentChild(DropdownMenuLabelDirective, {static: false}) dropdownMenuLabel!: DropdownMenuLabelDirective;
  @ContentChild(DropdownMenuItemsDirective, {static: false}) dropdownMenuItems!: DropdownMenuItemsDirective;

  constructor(public dropdownMenuService: DropdownMenuService) {
    this.clickOutsideTechType = 'CLICK_OUTSIDE';
  }

  ngOnInit(): void {
  }
  onClickedOutside(e: Event): void {
    if (this.clickOutsideTechType === 'CLICK_OUTSIDE') {
      this.closeMenu();
    }
  }
  openMenu(): void {
    this.dropdownMenuService.open = true;
    this.openChange.emit(true);
  }
  closeMenu(): void {
    this.dropdownMenuService.open = false;
    this.openChange.emit(false);
  }
}
