export class RequestEstimate {
  idBatDongSanNhapLieu?: number;
  idThuaDat?: number;
  soToBanDo?: string;
  soThua?: string;
  dienTich: number;
  viTriId?: number;
  viTriIdHai?: number;
  viTriIdBa?: number;
  chieuRongMatTien: number;
  soMatTien?: number;
  chieuRongMatDuong?: number;
  chieuRongMatDuongBa?: number;
  chieuRongMatDuongHai?: number;
  huongDatId: number;
  hinhDangLoDatId?: number;
  loaiBatDongSanId?: number;
  duongId?: number;
  duong?: string;
  phuongXaId?: number;
  phuongXa?: string;
  duAnId?: number;
  duAn?: string;
  quanHuyen?: string;
  quanHuyenId?: number;
  tinhId?: number;
  tinh?: string;
  ngo?: string;
  ngachHem?: string;
  soNha?: string;
  lat?: number;
  lon?: number;

  loaiNhaId?: number;
  namXayDung?: number;
  dienTichSuDung?: number;
  soTang?: number;
  chatLuongConLai: number;

  soPhongNgu?: number;
  soNhaVeSinh?: number;

  // loaiQuan: string; // NOI_THANH or NGOAI_THANH
  coNhieuThuaDat: boolean;
  dienTichDatODT: number;
  dienTichDatSKC: number;
  dienTichDatTMD: number;
  dienTichDatNNP: number;
  dienTichDatCLN: number;
  dienTichDatHNK: number;

  idThuaDatNguoiDung?: number;
  urlAnhBanDoDiaChinh?: string;
  email: string;
  other?: string
}
class GiaTaiSan {
  donGia: number;
  giaTri: number;
  dienGiai: string;
  otherGias: GiaTaiSan;
}
export class ResponseEstimate {
  idEstimate: number;
  giaDatMoHinh: GiaTaiSan;
  giaDatTinRao: GiaTaiSan;
  giaCTXD: GiaTaiSan;
  giaCTXDTinhThue: GiaTaiSan;
  other?: string
}
